import { useCallback, useState } from 'react';
export function useLocalStorage() {
    const [value, setValue] = useState(null);
    const setItem = useCallback((key, value) => {
        localStorage.setItem(key, value);
        setValue(value);
    }, []);
    const getItem = useCallback((key) => {
        const value = localStorage.getItem(key);
        setValue(value);
        return value;
    }, []);
    const removeItem = useCallback((key) => {
        localStorage.removeItem(key);
        setValue(null);
    }, []);
    return { value, setItem, getItem, removeItem };
}
