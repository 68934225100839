import { ReactComponent as ArrowLeft } from '@public/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '@public/icons/arrow-right.svg';
import { Button, ButtonSize, ButtonType } from '@components/button';
import { classNames } from '@utils/class-names';
import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
export default function PaginationPanel({ currentPage, numberOfAvailablePages, setNewPage, loading, }) {
    const { t } = useTranslation('paginationPanel');
    numberOfAvailablePages = Math.ceil(numberOfAvailablePages);
    const generatePageButton = useCallback((pageNumber) => {
        return (React.createElement("button", { key: `paginationButton-${pageNumber}`, onClick: () => setNewPage(pageNumber), disabled: currentPage === pageNumber, className: classNames('inline-flex items-center px-4 py-2 text-sm font-medium rounded-lg', currentPage === pageNumber
                ? 'text-p200Green bg-backgroundGreen'
                : 'text-n500DarkGrey'), "data-testid": `panel-button-${pageNumber}` }, pageNumber));
    }, [currentPage, setNewPage]);
    const getOptions = useCallback(() => {
        const options = [];
        for (let i = 1; i <= Math.min(numberOfAvailablePages, 3); i++) {
            options.push(generatePageButton(i));
        }
        options.push(generateFiller(numberOfAvailablePages, currentPage, generatePageButton));
        for (let i = Math.max(4, numberOfAvailablePages - 2); i <= numberOfAvailablePages; i++) {
            options.push(generatePageButton(i));
        }
        return options;
    }, [numberOfAvailablePages, currentPage, generatePageButton]);
    return (React.createElement("nav", { className: "flex items-center justify-between gap-2 px-6 lg:px-8 py-3 w-full" },
        React.createElement(Button, { disabled: currentPage === 1, onClick: () => setNewPage(currentPage - 1), IconLeading: ArrowLeft, label: React.createElement("span", { className: "hidden sm:inline" }, t('previous')), buttonType: ButtonType.SECONDARY, buttonSize: ButtonSize.LARGE, loading: loading, "data-testid": "previous-button" }),
        React.createElement("div", { className: "hidden 2md:-mt-px 2md:flex" }, getOptions()),
        React.createElement("div", { className: "block 2md:-mt-px 2md:hidden text-n500DarkGrey text-sm font-medium" }, t('pageOf', {
            currentPage,
            numberOfPages: numberOfAvailablePages,
        })),
        React.createElement(Button, { disabled: currentPage >= numberOfAvailablePages, onClick: () => setNewPage(currentPage + 1), buttonSize: ButtonSize.LARGE, buttonType: ButtonType.SECONDARY, IconTrailing: ArrowRight, label: React.createElement("span", { className: "hidden sm:inline" }, t('next')), loading: loading, "data-testid": "next-button" })));
}
const generateFiller = (numberOfAvailablePages, currentPage, generatePageButton) => {
    return (React.createElement(React.Fragment, { key: "filler" },
        currentPage - 1 > 4 && (React.createElement("span", { key: "spacer-1", className: "inline-flex items-center px-4 text-sm font-medium text-gray-500", "data-testid": "first-spacer" }, "...")),
        currentPage > 4 &&
            currentPage < numberOfAvailablePages - 1 &&
            generatePageButton(currentPage - 1),
        currentPage > 3 &&
            currentPage < numberOfAvailablePages - 2 &&
            generatePageButton(currentPage),
        currentPage > 2 &&
            currentPage < numberOfAvailablePages - 3 &&
            generatePageButton(currentPage + 1),
        currentPage + 1 < numberOfAvailablePages - 3 && (React.createElement("span", { key: "spacer-2", className: "inline-flex items-center px-4 text-sm font-medium text-gray-500", "data-testid": "second-spacer" }, "..."))));
};
