import { Button, ButtonSize, ButtonType } from '@components/button';
import { ReactComponent as EditIcon } from '@public/icons/edit.svg';
import { FieldType } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { ReactComponent as FiltersLines } from '@public/icons/filters-lines.svg';
import { InlineActionButton } from '@components/inline-action-button';
import { PayrollProviderStaffRoleEnum, } from '@lohndialog/shared/src/constants';
import { PayrollProviderStaffStateBadge } from '@components/badge';
import { SidePanel } from '@components/side-panel';
import { ReactComponent as UserPlusIcon } from '@public/icons/user-plus.svg';
import { usePayrollProvider } from '../../context/payrollprovider-context';
import { useTranslation } from 'react-i18next';
import { validateEmail } from '@lohndialog/shared/src/validation';
import InputModal from '@components/modals/input-modal';
import PayrollProviderEditSlideIn from '@components/payroll-provider-edit-slide-in/payroll-provider-edit-slide-in';
import PpEmployeeFilterPanel from '@components/pp-employee-table/pp-employee-filter-panel';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SearchInputField from '@components/search-input-field';
import SortingPaginatedTable from '@components/table/sorting-paginated-table';
import nestClient from '@api/nest-client';
export default function PpEmployeeTable({ staffMembers, currentPage, numberOfAvailablePages, setCurrentPage, sort, setSort, loading, setStateFilter, stateFilter, setSearchTerm, totalCount, updateStaffMember, forceUpdate, }) {
    const { t } = useTranslation('ppTable');
    const { t: tCompany } = useTranslation('company');
    const { payrollProvider } = usePayrollProvider();
    const [inviteModalOpen, setInviteModalOpen] = useState(false);
    const [slideInEditModalOpen, setSlideInEditModalOpen] = useState(false);
    const [interactingStaffMember, setInteractingStaffMember] = useState(undefined);
    const inviteUser = useCallback(async (data) => {
        const email = data.email;
        if (!email || !payrollProvider) {
            return;
        }
        const staff = await nestClient.invitePayrollProviderUser({
            email,
            payrollProviderId: payrollProvider.id,
            role: [PayrollProviderStaffRoleEnum.PP_ADMIN],
        });
        setInteractingStaffMember(staff);
        setSlideInEditModalOpen(true);
        forceUpdate();
    }, [forceUpdate, payrollProvider]);
    useEffect(() => {
        if (interactingStaffMember) {
            setInteractingStaffMember(staffMembers.find((staffMember) => staffMember.id === interactingStaffMember.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [staffMembers]);
    const EditCell = useCallback(({ rowData: staffMember }) => {
        return (React.createElement(InlineActionButton, { Icon: EditIcon, title: tCompany('actionButton.documents'), onClick: () => {
                setInteractingStaffMember(staffMember);
                setSlideInEditModalOpen(true);
            } }));
    }, [tCompany]);
    const topHeader = (React.createElement("div", { className: "rounded-lg flex flex-col xl:flex-row xl:justify-between text-sm font-medium gap-x-4 gap-y-2 w-full overflow-hidden" },
        React.createElement(PpEmployeeFilterPanel, { setFilter: setStateFilter, filter: stateFilter, className: "col-span-3 shadow-xs" }),
        React.createElement("div", { className: "hidden xl:block" }),
        React.createElement("div", { className: "flex flex-col xs:flex-row xl:flex-grow gap-x-4 gap-y-2 xl:justify-end" },
            React.createElement(SearchInputField, { setSearchTerm: setSearchTerm, placeHolder: t('search') }),
            React.createElement(Button, { className: "bg-white", buttonSize: ButtonSize.SMALL, buttonType: ButtonType.SECONDARY, label: t('filter'), IconLeading: FiltersLines })),
        React.createElement(Button, { IconLeading: UserPlusIcon, label: t('invite'), buttonType: ButtonType.SECONDARY, buttonSize: ButtonSize.LARGE, className: "bg-white", "data-testid": "invite-button", onClick: () => setInviteModalOpen(true) })));
    const [selectedItems, setSelectedItems] = useState([]);
    const columns = useMemo(() => [
        {
            headContent: t('name'),
            CellContent: NameCell,
            sortable: true,
            identifier: 'name',
        },
        {
            headContent: t('nickname'),
            CellContent: NickNameCell,
            sortable: true,
            identifier: 'nickname',
        },
        {
            headContent: t('email'),
            CellContent: EmailCell,
            sortable: false,
            identifier: 'email',
        },
        {
            headContent: t('managingCompaniesCount'),
            CellContent: ManagingCompaniesCellNameCell,
            sortable: true,
            identifier: 'extra.managingCompaniesCount',
        },
        {
            headContent: t('managingEmployeesCount'),
            CellContent: ManagingEmployeesCellNameCell,
            sortable: true,
            identifier: 'extra.managingEmployeesCount',
        },
        {
            headContent: t('state'),
            CellContent: StateCell,
            sortable: true,
            identifier: 'state',
        },
        {
            headContent: '',
            CellContent: EditCell,
            identifier: '_edit',
        },
    ], [EditCell, t]);
    return (React.createElement(React.Fragment, null,
        React.createElement(SortingPaginatedTable, { currentPage: currentPage, numberOfAvailablePages: numberOfAvailablePages, setCurrentPage: setCurrentPage, sort: sort, loading: loading, topHeader: topHeader, getId: (staff) => staff.id.toString(), data: staffMembers, selectedItems: selectedItems, setSelectedItems: setSelectedItems, totalResults: totalCount, setSort: setSort, toggleAll: () => setSelectedItems((prevState) => prevState.length > 0 ? [] : [...staffMembers]), columns: columns, className: "filter-none" }),
        React.createElement(InputModal, { modalTitle: t('invite'), explanation: React.createElement("p", null, t('inviteDetail')), configObject: {
                email: {
                    placeHolder: t('email'),
                    title: t('email'),
                    required: true,
                    validationFunction: validateEmail,
                    fieldType: FieldType.TEXT,
                },
            }, submitFunction: inviteUser, cancelLabel: t('cancel'), submitLabel: t('submit'), open: inviteModalOpen, setOpen: setInviteModalOpen }),
        interactingStaffMember && (React.createElement(SidePanel, { show: slideInEditModalOpen, onClose: () => {
                setSlideInEditModalOpen(false);
                setInteractingStaffMember(undefined);
            }, title: t('editStaffMember'), dismissible: false },
            React.createElement(PayrollProviderEditSlideIn, { staffMember: interactingStaffMember, updateStaffMember: updateStaffMember, loading: loading })))));
}
const NameCell = ({ rowData: staffMember, }) => (React.createElement("p", { className: "mr-2" }, staffMember.lastname || staffMember.firstname
    ? `${staffMember.lastname}, ${staffMember.firstname}`
    : ''));
const NickNameCell = ({ rowData: staffMember, }) => React.createElement("p", { className: "mr-2" }, staffMember.nickname);
const EmailCell = ({ rowData: staffMember, }) => {
    var _a, _b, _c;
    return (React.createElement("p", { className: "mr-2" }, (_c = (_b = (_a = staffMember.user) === null || _a === void 0 ? void 0 : _a.email) !== null && _b !== void 0 ? _b : staffMember.inviteEmail) !== null && _c !== void 0 ? _c : ''));
};
const ManagingCompaniesCellNameCell = ({ rowData: staffMember }) => (React.createElement("p", { className: "mr-2" }, staffMember.managingCompaniesCount));
const ManagingEmployeesCellNameCell = ({ rowData: staffMember }) => (React.createElement("p", { className: "mr-2" }, staffMember.managingEmployeesCount));
const StateCell = ({ rowData: staffMember, }) => React.createElement(PayrollProviderStaffStateBadge, { state: staffMember.state });
