import { CompanyWizard } from '@components/company-wizard/company-wizard';
import { LoadingSpinner } from '@components/loading-spinner';
import { MAX_NUMBER_OF_INDIVIDUAL_NOTIFICATIONS } from '@lohndialog/shared/src/validation';
import { NotificationContext } from '../context/notification-context';
import { addGenericNotificationFunction, addSpecificNotificationsFunction, } from '@utils/addNotificationUtils';
import { useNavigate } from '@hooks/useNavigate';
import { useNestClient } from '@hooks/useNestClient';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useContext } from 'react';
import nestClient from '@api/nest-client';
export default function CompanyWizardView() {
    const notificationState = useContext(NotificationContext);
    const { t } = useTranslation('errorHandling');
    const redirectUrl = '/employees';
    const navigate = useNavigate();
    const addSpecificNotifications = useCallback(addSpecificNotificationsFunction, [t, notificationState]);
    const addGenericNotification = useCallback(addGenericNotificationFunction, [
        t,
        notificationState,
    ]);
    const { result: company, error } = useNestClient('getCompany', []);
    if (error) {
        throw error;
    }
    return (React.createElement(React.Fragment, null, company ? (React.createElement(CompanyWizard, { initialCompany: company, onClose: () => navigate({ pathname: redirectUrl }), onFinish: async (company, companySchema) => {
            if (company.id) {
                nestClient.setErrorHandler((apiError) => {
                    const validationErrors = apiError.errorData.validationErrors;
                    if (validationErrors.length <
                        MAX_NUMBER_OF_INDIVIDUAL_NOTIFICATIONS) {
                        addSpecificNotifications(validationErrors, companySchema, notificationState, t);
                    }
                    else {
                        addGenericNotification(notificationState, t);
                    }
                });
                await nestClient.updateCompany(company).then(() => {
                    navigate({ pathname: redirectUrl });
                });
            }
        }, isAdmin: false })) : (React.createElement(LoadingSpinner, null))));
}
