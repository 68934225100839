import { Select } from '@components/input/select';
import { useTranslation } from 'react-i18next';
import React from 'react';
export const getMainPayrollProviderContactPersonName = (mainPayrollProviderContactPerson) => {
    var _a, _b;
    if (!mainPayrollProviderContactPerson) {
        return '';
    }
    if (!mainPayrollProviderContactPerson.firstname &&
        !mainPayrollProviderContactPerson.lastname) {
        return ((_b = (_a = mainPayrollProviderContactPerson.nickname) !== null && _a !== void 0 ? _a : mainPayrollProviderContactPerson.inviteEmail) !== null && _b !== void 0 ? _b : '');
    }
    return `${mainPayrollProviderContactPerson.firstname} ${mainPayrollProviderContactPerson.lastname}`;
};
export const MainPPContactField = ({ mainPayrollProviderContactPerson, setMainPayrollProviderContactPerson, possibleContactPersons, hasAdminRights, }) => {
    const { t } = useTranslation('ppContactField');
    return (React.createElement(Select, { key: 'mainPayrollProviderContactPerson', value: mainPayrollProviderContactPerson === null || mainPayrollProviderContactPerson === void 0 ? void 0 : mainPayrollProviderContactPerson.id, displayValue: (value) => getMainPayrollProviderContactPersonName(possibleContactPersons.find((possibleContactPerson) => possibleContactPerson.id === value)), options: possibleContactPersons.map((contactPerson) => contactPerson.id), title: t('mainPayrollProviderContactPerson'), onChange: (newValue) => {
            if (newValue !== undefined) {
                const newPerson = possibleContactPersons.find((possibleContactPerson) => possibleContactPerson.id === newValue);
                if (newPerson) {
                    setMainPayrollProviderContactPerson(newPerson);
                }
            }
        }, classNameContainer: 'col-span-6', required: true, disabled: !hasAdminRights }));
};
