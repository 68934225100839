import { ReactComponent as AlertTriangleIcon } from '@public/icons/alert-triangle.svg';
import { Entry, ProfileParagraph } from '@components/profile/profile-paragraph';
import { Environment } from '../../environment.global';
import { FieldType } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { useAuth } from '../../context/auth-context';
import { useProfile } from '../../context/profile-context';
import { useTranslation } from 'react-i18next';
import { validateEmail, validateStringsAreEqual, } from '@lohndialog/shared/src/validation';
import InputModal from '@components/modals/input-modal';
import React, { useCallback, useState } from 'react';
import nestClient from '@api/nest-client';
export function UserCard() {
    var _a, _b;
    const { t } = useTranslation('profile');
    const { logout } = useAuth();
    const profile = useProfile();
    const [showUserDataDialog, setShowUserDataDialog] = useState(false);
    const updateUserEmail = useCallback(async (email) => {
        var _a, _b;
        if (!email || email === ((_a = profile.state.profile) === null || _a === void 0 ? void 0 : _a.email)) {
            return;
        }
        if (!((_b = profile.state.profile) === null || _b === void 0 ? void 0 : _b.sub)) {
            return;
        }
        await nestClient.updateUserEmail(profile.state.profile.sub, email);
        logout({
            logoutParams: {
                returnTo: `${Environment.PUBLIC_URL}/logout`,
            },
        });
    }, [logout, profile.state.profile]);
    const updateUserPassword = useCallback(async () => {
        var _a;
        if (!((_a = profile.state.profile) === null || _a === void 0 ? void 0 : _a.sub)) {
            return;
        }
        window.location.href = await nestClient.updateUserPassword(profile.state.profile.sub);
    }, [(_a = profile.state.profile) === null || _a === void 0 ? void 0 : _a.sub]);
    return (React.createElement(ProfileParagraph, { title: t('userData') },
        React.createElement(Entry, { property: `${t('loginName')}:`, value: ((_b = profile.state.profile) === null || _b === void 0 ? void 0 : _b.email)
                ? profile.state.profile.email
                : '' }),
        React.createElement("button", { "data-testid": "userDataDialogButton", className: "w-fit", onClick: () => setShowUserDataDialog(true) }, React.createElement("u", null, t('editUser'))),
        React.createElement("button", { className: "w-fit", "data-testid": "changeUserPasswordButton", onClick: () => void updateUserPassword() }, React.createElement("u", null, t('editPassword'))),
        React.createElement(InputModal, { modalTitle: t('changeUserDataTitle'), explanation: React.createElement("div", { className: "flex flex-col items-center -mt-4" },
                React.createElement("div", { className: "mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-r100Red bg-opacity-20 sm:mx-0 sm:h-10 sm:w-10" },
                    React.createElement(AlertTriangleIcon, { className: "h-6 w-6 text-r100Red", "aria-hidden": "true" })),
                React.createElement("p", { className: "pt-2" }, t('changeUserDataDetail'))), configObject: {
                email: {
                    placeHolder: t('email'),
                    title: t('email'),
                    required: true,
                    validationFunction: validateEmail,
                    fieldType: FieldType.TEXT,
                },
                emailConfirm: {
                    placeHolder: t('email'),
                    title: t('emailConfirm'),
                    required: true,
                    validationFunction: validateEmail,
                    fieldType: FieldType.TEXT,
                },
            }, groupValidator: {
                emailsMatch: {
                    keys: ['email', 'emailConfirm'],
                    validationFunction: validateStringsAreEqual,
                },
            }, submitFunction: (input) => updateUserEmail(input.email), cancelLabel: t('cancel'), submitLabel: t('confirm'), open: showUserDataDialog, setOpen: setShowUserDataDialog })));
}
