var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { TextInput } from '@components/input/text-input';
import React from 'react';
const MAX_DATE_FOUR_DIGIT = '9999-12-31';
export const DateInput = (_a) => {
    var { value } = _a, rest = __rest(_a, ["value"]);
    return (React.createElement(TextInput, Object.assign({ value: value !== null && value !== void 0 ? value : '', type: "date", max: MAX_DATE_FOUR_DIGIT }, rest)));
};
