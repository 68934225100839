export var DependentTargets;
(function (DependentTargets) {
    DependentTargets["undefined"] = "undefined";
    DependentTargets["defined"] = "defined";
    DependentTargets["empty"] = "empty";
    DependentTargets["notEmpty"] = "notEmpty";
    DependentTargets["equal"] = "equal";
    DependentTargets["notEqual"] = "notEqual";
    DependentTargets["startsNotWith"] = "startsNotWith";
})(DependentTargets || (DependentTargets = {}));
export var FieldType;
(function (FieldType) {
    FieldType["TEXT"] = "text";
    FieldType["COMBOBOX"] = "combobox";
    FieldType["DROPDOWN"] = "dropdown";
    FieldType["NUMBER"] = "number";
    FieldType["DATE"] = "date";
    FieldType["DATETIME"] = "datetime";
    FieldType["TEL"] = "tel";
    FieldType["EMAIL"] = "email";
    FieldType["URL"] = "url";
    FieldType["CHECKBOX"] = "checkbox";
    FieldType["IBAN"] = "iban";
    FieldType["BIC"] = "bic";
    FieldType["CHILD"] = "child";
    FieldType["SEARCH"] = "search";
})(FieldType || (FieldType = {}));
export var WizardCluster;
(function (WizardCluster) {
    WizardCluster["PersonalData"] = "PersonalData";
    WizardCluster["Address"] = "Address";
    WizardCluster["Tax"] = "Tax";
    WizardCluster["PaymentData"] = "PaymentData";
    WizardCluster["PaymentSettings"] = "PaymentSettings";
    WizardCluster["AccountingData"] = "AccountingData";
    WizardCluster["NONE"] = "NONE";
    WizardCluster["AdditionalData"] = "AdditionalData";
    WizardCluster["Occupation"] = "Occupation";
    WizardCluster["RetirementInsurance"] = "RetirementInsurance";
    WizardCluster["HealthInsurance"] = "HealthInsurance";
    WizardCluster["CareInsurance"] = "CareInsurance";
    WizardCluster["CompanyDetails"] = "CompanyDetails";
    WizardCluster["Employment"] = "Employment";
    WizardCluster["Payment"] = "Payment";
    WizardCluster["Mapping"] = "Mapping";
    WizardCluster["BasicInformation"] = "BasicInformation";
    WizardCluster["AccountingInformation"] = "AccountingInformation";
    WizardCluster["PaymentInformation"] = "PaymentInformation";
    WizardCluster["SocialSecurityBasic"] = "SocialSecurityBasic";
    WizardCluster["SocialSecurityRegistrationNumber"] = "SocialSecurityRegistrationNumber";
    WizardCluster["TaxOffice"] = "TaxOffice";
    WizardCluster["ProfessionalAssociation"] = "ProfessionalAssociation";
    WizardCluster["FinancialAccountingBasic"] = "FinancialAccountingBasic";
    WizardCluster["FinancialAccountingOutputFile"] = "FinancialAccountingOutputFile";
    WizardCluster["FinancialAccountingCostUnit"] = "FinancialAccountingCostUnit";
    WizardCluster["SocialFund"] = "SocialFund";
    WizardCluster["TariffAgreement"] = "TariffAgreement";
    WizardCluster["EmployeeControl"] = "EmployeeControl";
})(WizardCluster || (WizardCluster = {}));
