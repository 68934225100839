import emailValidator from 'email-validator';
import dayjs from './util/dayjs';
import { BIC, IBAN } from 'ibankit';
import { FieldType, } from './dtos/entity-schema.dto';
export const MAX_LENGTH_DEFAULT_STRING = 255;
export const MAX_NUMBER_OF_INDIVIDUAL_NOTIFICATIONS = 5;
export var ValidationResult;
(function (ValidationResult) {
    ValidationResult["TOO_LONG"] = "TOO_LONG";
    ValidationResult["TOO_SHORT"] = "TOO_SHORT";
    ValidationResult["WRONG_FORMAT"] = "WRONG_FORMAT";
    ValidationResult["EMPTY"] = "EMPTY";
    ValidationResult["OUT_OF_RANGE"] = "OUT_OF_RANGE";
    ValidationResult["OUT_OF_RANGE_MIN"] = "OUT_OF_RANGE_MIN";
    ValidationResult["OUT_OF_RANGE_MAX"] = "OUT_OF_RANGE_MAX";
    ValidationResult["VALID"] = "VALID";
    ValidationResult["NOT_EQUAL"] = "NOT_EQUAL";
    ValidationResult["DUPLICATE"] = "DUPLICATE";
})(ValidationResult || (ValidationResult = {}));
export var ValidationType;
(function (ValidationType) {
    ValidationType[ValidationType["basic"] = 0] = "basic";
    ValidationType[ValidationType["date"] = 1] = "date";
    ValidationType[ValidationType["socialSecurityNumber"] = 2] = "socialSecurityNumber";
})(ValidationType || (ValidationType = {}));
export function normalizeString(string) {
    return string === null || string === void 0 ? void 0 : string.trim();
}
export const validateEmail = (email, maxLength = MAX_LENGTH_DEFAULT_STRING, minLength) => {
    if (email === undefined || email === null) {
        return ValidationResult.EMPTY;
    }
    if (typeof email !== 'string') {
        return ValidationResult.WRONG_FORMAT;
    }
    if (email.trim() === '') {
        return ValidationResult.EMPTY;
    }
    if (minLength != undefined && email.length < minLength) {
        return ValidationResult.TOO_SHORT;
    }
    if (email.length > maxLength) {
        return ValidationResult.TOO_LONG;
    }
    if (!emailValidator.validate(email)) {
        return ValidationResult.WRONG_FORMAT;
    }
    return ValidationResult.VALID;
};
export const validateNumber = (value, min, max) => {
    return validateRange(min, max)(value);
};
export const validateString = (string, maxLength = MAX_LENGTH_DEFAULT_STRING, regex, minLength) => {
    if (string === undefined || string === null) {
        return ValidationResult.EMPTY;
    }
    if (typeof string !== 'string') {
        return ValidationResult.WRONG_FORMAT;
    }
    string = normalizeString(string);
    if (!string) {
        return ValidationResult.EMPTY;
    }
    if (minLength != undefined && string.length < minLength) {
        return ValidationResult.TOO_SHORT;
    }
    if (string.length > maxLength) {
        return ValidationResult.TOO_LONG;
    }
    if (regex && !new RegExp(regex).exec(string)) {
        return ValidationResult.WRONG_FORMAT;
    }
    return ValidationResult.VALID;
};
export const validateRegex = (regexString) => {
    if (!regexString) {
        return () => ValidationResult.VALID;
    }
    const regex = new RegExp(regexString);
    return (input) => {
        if (input === undefined || input === null) {
            return ValidationResult.EMPTY;
        }
        if (typeof input !== 'string') {
            return ValidationResult.WRONG_FORMAT;
        }
        return regex.exec(input.trim())
            ? ValidationResult.VALID
            : ValidationResult.WRONG_FORMAT;
    };
};
export const validateStringsAreEqual = (strings) => {
    const equal = strings === null || strings === void 0 ? void 0 : strings.map((string) => normalizeString(string)).every((value, _index, array) => value === array[0]);
    return equal ? ValidationResult.VALID : ValidationResult.NOT_EQUAL;
};
const PHONE_REGEX = '^\\+?\\d{1,4}?[-.\\s]?\\(?\\d{1,3}?\\)?[-.\\s]?\\d{1,4}[-.\\s]?\\d{1,4}[-.\\s]?\\d{1,9}$';
export const MAX_LENGTH_PHONE_NUMBER = 20;
export const validatePhoneNumber = (value, maxLength = MAX_LENGTH_PHONE_NUMBER, minLength) => validateString(value, maxLength, PHONE_REGEX, minLength);
export const validateUrl = (value, maxLength = MAX_LENGTH_DEFAULT_STRING, minLength) => {
    if (value === undefined || value === null) {
        return ValidationResult.EMPTY;
    }
    if (typeof value !== 'string') {
        return ValidationResult.WRONG_FORMAT;
    }
    value = normalizeString(value);
    if (!value) {
        return ValidationResult.EMPTY;
    }
    if (minLength != undefined && value.length < minLength) {
        return ValidationResult.TOO_SHORT;
    }
    if (maxLength !== undefined && value.length > maxLength) {
        return ValidationResult.TOO_LONG;
    }
    let parsedUrl;
    try {
        parsedUrl = new URL(value).toString();
    }
    catch (error) {
        try {
            // Try being more lax in the validation by prepending a default protocol
            const laxValue = 'https://' + value;
            parsedUrl = new URL(laxValue).toString();
        }
        catch (error) {
            return ValidationResult.WRONG_FORMAT;
        }
    }
    if (new RegExp(`^(https://)?${value}/?$`).exec(parsedUrl)) {
        return ValidationResult.VALID;
    }
    else {
        return ValidationResult.WRONG_FORMAT;
    }
};
export const validateChild = (value, required) => {
    if (!value) {
        return ValidationResult.EMPTY;
    }
    if (typeof value !== 'object') {
        return ValidationResult.WRONG_FORMAT;
    }
    if (required &&
        (!value.birthDate || !value.proofDate || !value.firstname)) {
        return ValidationResult.EMPTY;
    }
    const shouldIgnoreEmptyBirthDate = !required && !value.birthDate;
    const shouldIgnoreEmptyProofDate = !required && !value.proofDate;
    if (!shouldIgnoreEmptyBirthDate && !dayjs(value.birthDate).isValid()) {
        return ValidationResult.WRONG_FORMAT;
    }
    if (!shouldIgnoreEmptyProofDate && !dayjs(value.proofDate).isValid()) {
        return ValidationResult.WRONG_FORMAT;
    }
    return ValidationResult.VALID;
};
export const validatePossibleValues = (value, possibleValues) => {
    if (Object.keys(possibleValues).length === 0 && value === undefined) {
        return ValidationResult.VALID;
    }
    if (value === undefined || value === null) {
        return ValidationResult.EMPTY;
    }
    return possibleValues.hasOwnProperty(value)
        ? ValidationResult.VALID
        : ValidationResult.WRONG_FORMAT;
};
export const validateEnum = (value, enumObject) => {
    if (value === undefined || value === null) {
        return ValidationResult.EMPTY;
    }
    return Object.values(enumObject).includes(value)
        ? ValidationResult.VALID
        : ValidationResult.WRONG_FORMAT;
};
export const validateDateBoundaries = (boundaries, genericFields, supervisingGenericFields, mainEntity) => {
    return (input) => {
        if (!input) {
            return { result: ValidationResult.EMPTY };
        }
        const inputToCheck = typeof input === 'string' ? dayjs(input) : input;
        if (!inputToCheck.isValid()) {
            return { result: ValidationResult.WRONG_FORMAT };
        }
        if (!boundaries || boundaries.length === 0) {
            return { result: ValidationResult.VALID };
        }
        let failedDateResult;
        boundaries.some((boundary) => {
            const dateResult = validateBoundary(inputToCheck, boundary, genericFields, supervisingGenericFields, mainEntity);
            if (dateResult.result !== ValidationResult.VALID) {
                failedDateResult = dateResult;
                return true;
            }
            return false;
        });
        if (failedDateResult) {
            return failedDateResult;
        }
        return { result: ValidationResult.VALID };
    };
};
const validateBoundary = (input, boundary, genericFields, supervisingGenericFields, mainEntity) => {
    const baseDate = getBaseDate(boundary, genericFields, supervisingGenericFields, mainEntity);
    if (!baseDate) {
        return { result: ValidationResult.VALID };
    }
    switch (boundary.comparisonType) {
        case 'before':
            if (input.isBefore(baseDate, 'day')) {
                return { result: ValidationResult.VALID };
            }
            else {
                return {
                    result: ValidationResult.WRONG_FORMAT,
                    comparator: 'before',
                    referenceDate: baseDate,
                };
            }
        case 'beforeOrEqual':
            if (input.isBefore(baseDate, 'day') ||
                input.isSame(baseDate, 'day')) {
                return { result: ValidationResult.VALID };
            }
            else {
                return {
                    result: ValidationResult.WRONG_FORMAT,
                    comparator: 'beforeOrEqual',
                    referenceDate: baseDate,
                };
            }
        case 'after':
            if (input.isAfter(baseDate, 'day')) {
                return { result: ValidationResult.VALID };
            }
            else {
                return {
                    result: ValidationResult.WRONG_FORMAT,
                    comparator: 'after',
                    referenceDate: baseDate,
                };
            }
        case 'afterOrEqual':
            if (input.isAfter(baseDate, 'day') ||
                input.isSame(baseDate, 'day')) {
                return { result: ValidationResult.VALID };
            }
            else {
                return {
                    result: ValidationResult.WRONG_FORMAT,
                    comparator: 'afterOrEqual',
                    referenceDate: baseDate,
                };
            }
        case 'equal':
            if (input.isSame(baseDate, 'day')) {
                return { result: ValidationResult.VALID };
            }
            else {
                return {
                    result: ValidationResult.WRONG_FORMAT,
                    comparator: 'equal',
                    referenceDate: baseDate,
                };
            }
    }
};
export const getBaseDate = (boundary, genericFields, supervisingGenericFields, mainEntity) => {
    let baseDate;
    boundary.references.some((reference) => {
        if (reference.dateReference) {
            baseDate = getReferenceDate(reference.dateReference);
        }
        else if (reference.fieldReference) {
            const field = reference.fieldReference;
            const fieldValue = field.entity === mainEntity
                ? genericFields[field.fieldKey]
                : supervisingGenericFields[field.fieldKey];
            if (fieldValue &&
                (fieldValue.type === FieldType.DATE ||
                    fieldValue.type === FieldType.DATETIME)) {
                baseDate = dayjs(fieldValue.value);
            }
        }
        if (baseDate && reference.durationValue && reference.durationUnit) {
            baseDate = dayjs(baseDate
                .add(reference.durationValue, reference.durationUnit)
                .toDate());
        }
        return baseDate !== undefined;
    });
    return baseDate;
};
export const getReferenceDate = (dateReference) => {
    let date = dayjs().startOf('day');
    if (dateReference.year !== 'now') {
        date = date.set('year', dateReference.year);
    }
    if (dateReference.month !== 'now') {
        switch (dateReference.month) {
            case 'last':
                date = date.endOf('year');
                break;
            case 'first':
                date = date.startOf('year');
                break;
            default:
                date = date.set('month', dateReference.month - 1);
        }
    }
    if (dateReference.day !== 'now') {
        switch (dateReference.day) {
            case 'last':
                date = date.endOf('month');
                break;
            case 'first':
                date = date.startOf('month');
                break;
            default:
                date = date.set('date', dateReference.day);
        }
    }
    return date;
};
export const validateRange = (start, end) => {
    return (input) => {
        if (input == null) {
            return ValidationResult.EMPTY;
        }
        if (start !== undefined) {
            start = typeof start === 'string' ? parseFloat(start) : start;
            if (start > input) {
                return ValidationResult.OUT_OF_RANGE_MIN;
            }
        }
        if (end !== undefined) {
            end = typeof end === 'string' ? parseFloat(end) : end;
            if (end < input) {
                return ValidationResult.OUT_OF_RANGE_MAX;
            }
        }
        return ValidationResult.VALID;
    };
};
export const getStringValidatorFn = (validatorFnName) => {
    switch (validatorFnName) {
        case 'iban':
            return validateIban;
        case 'bic':
            return validateBic;
        default:
            return undefined;
    }
};
export const getDateValidatorFn = (_validatorFnName) => {
    // no validators yet
    return undefined;
};
export const getNumberValidatorFn = (_validatorFnName) => {
    // no validators yet
    return undefined;
};
export const getCheckboxValidatorFn = (_validatorFnName) => {
    // no validators yet
    return undefined;
};
export const getChildValidatorFn = (_validatorFnName) => {
    return undefined;
};
export const validateIban = (iban) => {
    const stringResult = validateString(iban);
    if (stringResult !== ValidationResult.VALID) {
        return stringResult;
    }
    const clearedIban = iban === null || iban === void 0 ? void 0 : iban.replace(/\s/g, '');
    if (!(clearedIban === null || clearedIban === void 0 ? void 0 : clearedIban.startsWith('DE'))) {
        return ValidationResult.VALID;
    }
    if ((clearedIban === null || clearedIban === void 0 ? void 0 : clearedIban.startsWith('DE')) && clearedIban.length !== 22) {
        return ValidationResult.WRONG_FORMAT;
    }
    if (IBAN.isValid(clearedIban !== null && clearedIban !== void 0 ? clearedIban : '')) {
        return ValidationResult.VALID;
    }
    else {
        return ValidationResult.WRONG_FORMAT;
    }
};
export const validateBic = (bic) => {
    const stringResult = validateString(bic);
    if (stringResult !== ValidationResult.VALID) {
        return stringResult;
    }
    if (BIC.isValid(bic !== null && bic !== void 0 ? bic : '')) {
        return ValidationResult.VALID;
    }
    else {
        return ValidationResult.WRONG_FORMAT;
    }
};
export const validateSocialSecurityNumber = (fieldValue, genericFields) => {
    var _a, _b;
    if (typeof fieldValue.value !== 'string') {
        return {
            result: ValidationResult.WRONG_FORMAT,
            backendMessage: `Value is not of type string. Got: "${fieldValue.value}"`,
            frontendMessage: 'wrongFormat',
        };
    }
    const socialSecurityNumber = fieldValue.value;
    if (socialSecurityNumber.length !== 12) {
        return {
            result: ValidationResult.WRONG_FORMAT,
            backendMessage: `Value is not of length 12. Got: "${fieldValue.value}"`,
            frontendMessage: 'wrongLengthSocialSecurity',
        };
    }
    const birthDate = (_a = genericFields['birthDate']) === null || _a === void 0 ? void 0 : _a.value;
    if (birthDate && typeof birthDate === 'string') {
        const date = dayjs(birthDate).format('DDMMYY');
        if (socialSecurityNumber.substring(2, 8) !== date) {
            return {
                result: ValidationResult.WRONG_FORMAT,
                backendMessage: `Value does not contain the birth date at substring 2-7. Got: "${fieldValue.value}"`,
                frontendMessage: 'wrongBirthDate',
            };
        }
    }
    const nameAtBirth = (_b = genericFields['nameAtBirth']) === null || _b === void 0 ? void 0 : _b.value;
    if (nameAtBirth &&
        typeof nameAtBirth === 'string' &&
        socialSecurityNumber.charAt(8) !== nameAtBirth.charAt(0)) {
        return {
            result: ValidationResult.WRONG_FORMAT,
            backendMessage: `Value does not contain the first char of name at birth at position 8. Got: "${fieldValue.value}"`,
            frontendMessage: 'wrongNameAtBirth',
        };
    }
    return { result: ValidationResult.VALID };
};
