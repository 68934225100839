import { ReactComponent as RefreshIcon } from '@public/icons/refresh-cw.svg';
import { ReactComponent as XOctagonIcon } from '@public/icons/x-octagon.svg';
import { classNames } from '@utils/class-names';
import { usePagination } from '@hooks/usePagination';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState } from 'react';
import SortingPaginatedTable from '@components/table/sorting-paginated-table';
import sortBy from 'lodash.sortby';
export const CompanyImportTable = ({ data, loading, selectedItems, setSelectedItems, headers, }) => {
    const { t } = useTranslation('companyImport');
    const [sort, setSort] = useState(null);
    const sortedData = useMemo(() => {
        let sortedData;
        switch (sort === null || sort === void 0 ? void 0 : sort.fieldName) {
            case 'state':
                sortedData = sortBy(data);
                break;
            case 'mandatorNumber':
                sortedData = sortBy(data, (row) => { var _a, _b; return (_b = (_a = row.value) === null || _a === void 0 ? void 0 : _a.genericFields.mandatorNumber) === null || _b === void 0 ? void 0 : _b.value; });
                break;
            case 'name':
                sortedData = sortBy(data, (row) => { var _a, _b; return (_b = (_a = row.value) === null || _a === void 0 ? void 0 : _a.genericFields.name) === null || _b === void 0 ? void 0 : _b.value; });
                break;
            case 'errors':
                sortedData = sortBy(data, (row) => row.errors.length);
                break;
            default:
                sortedData = data;
                break;
        }
        if ((sort === null || sort === void 0 ? void 0 : sort.direction) === 'DESC') {
            sortedData.reverse();
        }
        return sortedData;
    }, [data, sort]);
    const { currentPageIndex, setCurrentPageIndex, numberOfPages, paginatedData, } = usePagination(sortedData, 5);
    // Map internalHeaderNames to header names used in the CSV
    const ErrorsCell = useMemo(() => {
        const internalHeaderNameMap = headers.reduce((previousValue, currentValue) => previousValue.set(currentValue.internalName, currentValue.name), new Map());
        return ({ rowData }) => (React.createElement(Errors, { errors: rowData.errors, headerNameMap: internalHeaderNameMap }));
    }, [headers]);
    return (React.createElement(SortingPaginatedTable, { data: paginatedData, setCurrentPage: setCurrentPageIndex, currentPage: currentPageIndex, numberOfAvailablePages: numberOfPages, loading: loading, getId: (rowData, index) => index.toString(), toggleAll: () => setSelectedItems((prevState) => prevState.size > 0 ? new Set() : new Set(data)), sort: sort, setSort: setSort, totalResults: data.length, selectedItems: Array.from(selectedItems), setSelectedItems: (selectedItems) => {
            if (typeof selectedItems === 'function') {
                setSelectedItems((prevState) => new Set(selectedItems(Array.from(prevState))));
            }
            else {
                setSelectedItems(new Set(selectedItems));
            }
        }, columns: [
            {
                identifier: 'state',
                CellContent: StateCell,
                cellClassName: '',
                sortable: true,
                headContent: t('state'),
                headClassName: '',
            },
            {
                identifier: 'mandatorNumber',
                CellContent: MandatorNumberCell,
                cellClassName: '',
                sortable: true,
                headContent: t('mandatorNumber'),
                headClassName: '',
            },
            {
                identifier: 'name',
                CellContent: NameCell,
                cellClassName: '',
                sortable: true,
                headContent: t('name'),
                headClassName: '',
            },
            {
                identifier: 'errors',
                CellContent: ErrorsCell,
                cellClassName: '',
                sortable: true,
                headContent: t('errors'),
                headClassName: '',
            },
        ] }));
};
const Errors = ({ errors, headerNameMap, }) => {
    const { t } = useTranslation('errorHandling');
    if (!errors.length) {
        return;
    }
    return (React.createElement("ul", null, errors.map(({ property, code }, index) => (React.createElement("li", { key: index }, t('formattedError', {
        property: headerNameMap.get(property),
        code,
    }))))));
};
const ErrorIcon = () => (React.createElement(Icon, { IconComponent: XOctagonIcon, className: "bg-r100Error outline-r50Error", iconClass: "text-r600Error" }));
const UpdateIcon = () => {
    const { t } = useTranslation('companyImport');
    return (React.createElement(Icon, { IconComponent: RefreshIcon, className: "bg-p100Green/30 outline-p100Green/10", iconClass: "text-p100Green", title: t('updateTitleText') }));
};
const Icon = ({ IconComponent, iconClass, className, title, }) => (React.createElement("div", { className: classNames(className, 'flex flex-shrink-0 h-6 w-6 items-center justify-center rounded-full outline outline-4'), title: title },
    React.createElement(IconComponent, { className: classNames(iconClass, 'h-4 w-4'), "aria-hidden": "true" })));
const StateCell = ({ rowData }) => {
    if (rowData.errors.length) {
        return React.createElement(ErrorIcon, null);
    }
    else if (!rowData.isNew) {
        return React.createElement(UpdateIcon, null);
    }
};
const NameCell = ({ rowData }) => { var _a, _b, _c; return (_c = (_b = (_a = rowData.value) === null || _a === void 0 ? void 0 : _a.genericFields.name) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : ''; };
const MandatorNumberCell = ({ rowData, }) => {
    var _a, _b, _c;
    const { t } = useTranslation('companyImport');
    return ((_c = (_b = (_a = rowData.value) === null || _a === void 0 ? void 0 : _a.genericFields.mandatorNumber) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : t('mandatorNumberUnknown'));
};
