import { LoadingSpinner } from '@components/loading-spinner';
import { SendInvite } from '@components/send-invite';
import { useNestClient } from '@hooks/useNestClient';
import { useTranslation } from 'react-i18next';
import Dialog from '@components/modals/dialog';
import React, { useEffect, useState } from 'react';
import nestClient from '@api/nest-client';
export const EmployeeInviteModal = ({ dialogOpen, setDialogOpen, employeeId, refreshEmployees, }) => {
    const { t } = useTranslation('employeeInviteModal');
    const [employee, setEmployee] = useState();
    const { result: schema, error: errorSchema } = useNestClient('getEmployeeSchema', [false]);
    useEffect(() => {
        const getEmployee = async () => {
            setEmployee(await nestClient.getEmployee(employeeId));
        };
        void getEmployee();
    }, [employeeId]);
    let content;
    if (errorSchema) {
        content = React.createElement("p", null, t('error'));
    }
    else if (!employee || !schema) {
        content = React.createElement(LoadingSpinner, null);
    }
    else {
        content = (React.createElement(SendInvite, { employee: employee, setEmployee: (employeeDto) => {
                setEmployee(employeeDto);
            }, schema: schema, refreshEmployees: refreshEmployees }));
    }
    return (React.createElement(Dialog, { dismissible: true, open: dialogOpen, setOpen: setDialogOpen, title: t('title'), actions: [] }, content));
};
