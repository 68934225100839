import { ReactComponent as AirplayIcon } from '@public/icons/airplay.svg';
import { GenericTable } from '@components/table/generic-table';
import { CompanyStateBadge } from '@components/badge';
import { ReactComponent as DownloadIcon } from '@public/icons/download.svg';
import { ReactComponent as FileTextIcon } from '@public/icons/file-text.svg';
import { ReactComponent as InfoIcon } from '@public/icons/info.svg';
import { InlineActionButton } from '@components/inline-action-button';
import { NotificationContext } from '../context/notification-context';
import { NotificationType } from '@components/notifications/notification';
import { ReactComponent as RepeatIcon } from '@public/icons/repeat.svg';
import { ReactComponent as SettingsIcon } from '@public/icons/settings.svg';
import { ReactComponent as UploadIcon } from '@public/icons/upload.svg';
// eslint-disable-next-line no-restricted-imports
import { useNavigate } from 'react-router-dom';
import { usePagination } from '@hooks/usePagination';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import dayjs from '@lohndialog/shared/src/util/dayjs';
import sortBy from 'lodash.sortby';
export default ({ className, companies, schema, loading, setImportModalOpen, }) => {
    const { t } = useTranslation('company');
    const [selectedColumns, setSelectedColumns] = useState([
        'name',
        'mandatorNumber',
        '_currentAccountingPeriod',
        '_state',
        '_actions',
    ]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [sort, setSort] = useState(null);
    const sortedCompanies = useMemo(() => {
        if (!sort) {
            return companies;
        }
        const sorted = sortBy(companies, (a) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            switch (sort.fieldName) {
                case '_currentAccountingPeriod': {
                    const monthA = (_b = (_a = a.genericFields.currentAccountingMonth) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : 0;
                    const yearA = (_d = (_c = a.genericFields.currentAccountingYear) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : 0;
                    return dayjs(`${monthA}/${yearA}`, 'M/YYYY').unix();
                }
                case '_state':
                    return a.state;
                case 'mandatorNumber':
                    return parseInt((_f = (_e = a.genericFields[sort.fieldName]) === null || _e === void 0 ? void 0 : _e.value) !== null && _f !== void 0 ? _f : '0');
                case 'name':
                    return ((_h = (_g = a.genericFields[sort.fieldName]) === null || _g === void 0 ? void 0 : _g.value) !== null && _h !== void 0 ? _h : '').toLowerCase();
                default:
                    return (_j = a.genericFields[sort.fieldName]) === null || _j === void 0 ? void 0 : _j.value;
            }
        });
        if (sort.direction === 'DESC') {
            sorted.reverse();
        }
        return sorted;
    }, [companies, sort]);
    const { setCurrentPageIndex, currentPageIndex, numberOfPages, paginatedData, } = usePagination(sortedCompanies, 20);
    const ActionsCell = useCallback(({ rowData }) => {
        const notificationState = useContext(NotificationContext);
        const { t } = useTranslation('company');
        const placeholderCallback = useCallback(() => {
            notificationState === null || notificationState === void 0 ? void 0 : notificationState.addNotification({
                notificationType: NotificationType.INFO,
                title: t('placeholder'),
                Icon: InfoIcon,
            });
        }, [notificationState, t]);
        const navigate = useNavigate();
        return (React.createElement("div", { className: "flex flex-row space-x-2" },
            React.createElement(InlineActionButton, { Icon: FileTextIcon, title: t('actionButton.documents'), onClick: placeholderCallback }),
            React.createElement(InlineActionButton, { Icon: UploadIcon, title: t('actionButton.upload'), onClick: () => setImportModalOpen(rowData), testId: `uploadActionButton-${rowData.slug}` }),
            React.createElement(InlineActionButton, { Icon: DownloadIcon, title: t('actionButton.download'), onClick: placeholderCallback }),
            React.createElement(InlineActionButton, { Icon: RepeatIcon, title: t('actionButton.reset'), onClick: placeholderCallback }),
            React.createElement(InlineActionButton, { Icon: SettingsIcon, title: t('actionButton.edit'), onClick: () => {
                    navigate(`/admin/companyOverview/${rowData.id}`);
                }, testId: `companyEditActionButton-${rowData.slug}` }),
            React.createElement(InlineActionButton, { Icon: AirplayIcon, title: t('actionButton.open'), onClick: () => {
                    navigate(`/${rowData.slug}/`);
                }, testId: `companyViewActionButton-${rowData.slug}` })));
    }, [setImportModalOpen]);
    const columnOverrides = useMemo(() => {
        return {
            name: {
                identifier: 'name',
                headContent: t('name'),
                partial: true,
            },
            mandatorNumber: {
                identifier: 'mandatorNumber',
                headContent: t('mandatorNumber'),
                partial: true,
            },
            _currentAccountingPeriod: {
                identifier: '_currentAccountingPeriod',
                sortable: true,
                headContent: t('currentAccountingPeriod'),
                CellContent: AccountingPeriodCell,
            },
            _state: {
                identifier: '_state',
                headContent: t('state'),
                CellContent: StateCell,
                sortable: true,
            },
            _actions: {
                identifier: '_actions',
                headContent: '',
                sortable: false,
                CellContent: ActionsCell,
            },
        };
    }, [ActionsCell, t]);
    return (React.createElement(GenericTable, { setSort: setSort, sort: sort, loading: loading, data: paginatedData, numberOfAvailablePages: numberOfPages, currentPage: currentPageIndex, setCurrentPage: setCurrentPageIndex, getId: (company) => company.id, totalResults: companies.length, selectedItems: selectedItems, setSelectedItems: setSelectedItems, selectedColumns: selectedColumns, setSelectedColumns: setSelectedColumns, toggleAll: () => setSelectedItems((prevState) => prevState.length > 0 ? [] : [...companies]), columnOverrides: columnOverrides, schema: schema, className: className }));
};
const AccountingPeriodCell = ({ rowData: company, }) => {
    var _a, _b, _c, _d;
    const month = (_b = (_a = company.genericFields.currentAccountingMonth) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '?';
    const year = (_d = (_c = company.genericFields.currentAccountingYear) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : '?';
    return `${month}/${year}`;
};
const StateCell = ({ rowData: company }) => (React.createElement(CompanyStateBadge, { state: company.state }));
