import { getTitle } from '@components/generic-forms/generic-form';
import { FieldType } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { Select } from '@components/input/select';
import { localizeString } from '@utils/formatting';
import React, { useMemo } from 'react';
import i18n from '../../i18n';
const NON_REQUIRED_EMPTY_OPTION = 'non_required_empty_option';
export const DropdownField = ({ fieldName, field, fieldValue, setValue, className, disabled, required, }) => {
    var _a;
    // eslint-disable-next-line sonarjs/cognitive-complexity
    const sortedKeys = useMemo(() => {
        if (field.type !== FieldType.DROPDOWN ||
            (fieldValue && fieldValue.type !== FieldType.DROPDOWN)) {
            return [];
        }
        else {
            const options = Object.entries(field.possibleValues)
                .sort(([_aKey, aTranslations], [_bKey, bTranslations]) => {
                const stringAValue = aTranslations[i18n.language];
                const stringBValue = bTranslations[i18n.language];
                if (!stringAValue) {
                    if (!stringBValue) {
                        return 0;
                    }
                    else {
                        return -1;
                    }
                }
                if (!stringBValue) {
                    return 1;
                }
                return stringAValue.localeCompare(stringBValue, undefined, {
                    numeric: true,
                });
            })
                .map((entry) => entry[0]);
            return required ? options : [NON_REQUIRED_EMPTY_OPTION, ...options];
        }
    }, [field, fieldValue, required]);
    if (field.type !== FieldType.DROPDOWN ||
        (fieldValue && fieldValue.type !== FieldType.DROPDOWN)) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(Select, { key: fieldName, value: (_a = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value) !== null && _a !== void 0 ? _a : (required ? undefined : NON_REQUIRED_EMPTY_OPTION), displayValue: (value) => localizeString(field.possibleValues[value], i18n.language, ''), options: sortedKeys, title: getTitle(field, required), onChange: (newValue) => {
            if (newValue !== undefined) {
                setValue(fieldName, field.type, newValue === NON_REQUIRED_EMPTY_OPTION
                    ? undefined
                    : newValue);
            }
        }, classNameContainer: className, disabled: disabled, required: required, runInitialValidation: true }));
};
