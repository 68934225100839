import CompanyDashboardEditTable from '@components/company-dashboard/company-dashboard-edit-table';
import CompanyDashboardHeader from '@components/company-dashboard/company-dashboard-header';
import CompanyDashboardInformationPanel from '@components/company-dashboard/company-dashboard-information-panel';
import CompanyDashboardPanel from '@components/company-dashboard/company-dashboard-panel';
import React from 'react';
export default function CompanyDashboard({ company, companySchema, setUpdateCounter, isAdmin, hasAdminRights, }) {
    return (React.createElement("div", { className: "flex flex-col gap-8 max-h-[calc(100%-8rem)]" },
        React.createElement(CompanyDashboardHeader, { company: company, isAdmin: isAdmin }),
        React.createElement(CompanyDashboardPanel, { company: company }),
        React.createElement(CompanyDashboardInformationPanel, { company: company, companySchema: companySchema }),
        React.createElement(CompanyDashboardEditTable, { isAdmin: isAdmin, company: company, forceUpdate: () => setUpdateCounter((counter) => counter + 1), hasAdminRights: hasAdminRights })));
}
