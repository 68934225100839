import { Button, ButtonSize, ButtonType } from '@components/button';
import { LoadingSpinner } from '@components/loading-spinner';
import { UserCompanyRoleEnum, } from '@lohndialog/shared/src/constants';
import { calculateRedirectFor } from '../../routing/redirectForRole';
// eslint-disable-next-line no-restricted-imports
import { useNavigate } from 'react-router-dom';
import { useNestClient } from '@hooks/useNestClient';
import { useTranslation } from 'react-i18next';
import Dialog from '@components/modals/dialog';
import React, { useCallback, useState } from 'react';
import nestClient from '@api/nest-client';
export function AuthenticatedInvitationView({ tokenId, isPayrollProviderToken, }) {
    var _a;
    const { t } = useTranslation('invitation');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const navigate = useNavigate();
    const { result: token, loading } = useNestClient(isPayrollProviderToken
        ? 'getPayrollProviderTokenInformation'
        : 'getTokenInformation', [tokenId]);
    const isHREmployee = (_a = token === null || token === void 0 ? void 0 : token.role) === null || _a === void 0 ? void 0 : _a.includes(UserCompanyRoleEnum.ADMIN);
    const acceptInviteToken = useCallback(async () => {
        setShowSpinner(true);
        try {
            if (isPayrollProviderToken) {
                await nestClient.acceptPayrollProviderInviteToken(tokenId);
                navigate('/admin');
            }
            else {
                await nestClient.acceptInviteToken(tokenId);
                const employeeToken = token;
                if (isHREmployee) {
                    navigate(`/${employeeToken.companySlug}/`);
                }
                else {
                    calculateRedirectFor(employeeToken.companySlug, employeeToken.employeeId, employeeToken.isSelfService, employeeToken.employeeComplete, navigate);
                }
            }
        }
        catch (_) {
            setShowError(true);
        }
        finally {
            setShowSpinner(false);
        }
    }, [isHREmployee, isPayrollProviderToken, navigate, token, tokenId]);
    const declineInviteToken = useCallback(async () => {
        setShowSpinner(true);
        if (isPayrollProviderToken) {
            await nestClient.declinePayrollProviderInviteToken(tokenId);
        }
        else {
            await nestClient.declineInviteToken(tokenId);
        }
        setShowSpinner(false);
        navigate('/');
    }, [isPayrollProviderToken, navigate, tokenId]);
    if (loading) {
        return (React.createElement(LoadingSpinner, { className: "h-5 absolute inset-x-0 inset-y-auto" }));
    }
    if (!token) {
        return React.createElement("p", null, t('tokenInvalid'));
    }
    return (React.createElement(React.Fragment, null,
        showSpinner && (React.createElement(LoadingSpinner, { className: "h-5 absolute inset-x-0 inset-y-auto" })),
        React.createElement("p", { className: "mb-5" }, isPayrollProviderToken
            ? t('invitationTextPayrollProvider', {
                payrollProviderName: token.payrollProviderName,
            })
            : t('invitationTextEmployee', {
                role: `hr${isHREmployee}`,
                companyName: token.companyName,
            })),
        React.createElement("p", { className: "mb-5" }, t('dialogAcceptTitle')),
        React.createElement("div", { className: "flex flex-row space-x-2" },
            React.createElement(Button, { buttonSize: ButtonSize.LARGE, buttonType: ButtonType.PRIMARY, label: t('acceptButton'), "data-testid": "acceptBtn", onClick: () => void acceptInviteToken() }),
            React.createElement(Button, { buttonSize: ButtonSize.LARGE, buttonType: ButtonType.PRIMARY, label: t('declineButton'), "data-testid": "declineBtn", onClick: () => {
                    setDialogOpen(true);
                } })),
        React.createElement(Dialog, { dismissible: true, open: dialogOpen, setOpen: setDialogOpen, title: t('dialogDeclineTitle'), actions: [
                {
                    buttonProps: {
                        label: t('declineButton'),
                        buttonSize: ButtonSize.SMALL,
                        buttonType: ButtonType.SECONDARY,
                        onClick: () => void declineInviteToken(),
                        tabIndex: 1,
                        testId: 'declineBtnConfirm',
                    },
                },
                {
                    buttonProps: {
                        label: t('abortButton'),
                        buttonSize: ButtonSize.SMALL,
                        buttonType: ButtonType.PRIMARY,
                        onClick: () => {
                            setDialogOpen(false);
                        },
                        tabIndex: 2,
                        testId: 'declineBtnAbort',
                    },
                },
            ] }),
        React.createElement(Dialog, { dismissible: true, open: showError, setOpen: setShowError, title: t('error'), actions: [
                {
                    buttonProps: {
                        label: t('abortButton'),
                        buttonSize: ButtonSize.SMALL,
                        buttonType: ButtonType.PRIMARY,
                        onClick: () => {
                            setShowError(false);
                        },
                        tabIndex: 1,
                        testId: 'declineBtnAbort',
                    },
                },
                {
                    buttonProps: {
                        label: t('navigateToDashboard'),
                        buttonSize: ButtonSize.SMALL,
                        buttonType: ButtonType.SECONDARY,
                        onClick: () => {
                            if (isPayrollProviderToken) {
                                navigate('/admin');
                            }
                            else {
                                const route = isHREmployee
                                    ? '/'
                                    : '/dashboard';
                                navigate(`/${token
                                    .companySlug}${route}`);
                            }
                        },
                        tabIndex: 2,
                        testId: 'navigateToDashboardBtn',
                    },
                },
            ] }, isPayrollProviderToken
            ? t('alreadyMemberPayrollProvider')
            : t('alreadyMemberCompany'))));
}
