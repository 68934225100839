import { CompanySwitch } from '@components/profile/company-switch';
import { EmployeeProfileCard } from '@components/profile/employee-profile-card';
import { UserCard } from '@components/profile/user-card';
import { useProfile } from '../context/profile-context';
import React, { useEffect } from 'react';
export default function ProfileView() {
    const profile = useProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => profile.fetchProfile(), []);
    return (React.createElement("div", { className: "space-y-8" },
        React.createElement(EmployeeProfileCard, null),
        React.createElement(UserCard, null),
        React.createElement(CompanySwitch, null)));
}
