import { ReactComponent as CheckIcon } from '@public/icons/check.svg';
import { ErrorCode } from 'react-dropzone';
import { ReactComponent as FileIcon } from '@public/icons/file.svg';
import { LoadingSpinner } from '@components/loading-spinner';
import { ServerErrorCode } from '@lohndialog/shared/src/server-error-codes';
import { ReactComponent as TrashIcon } from '@public/icons/trash-2.svg';
import { UploadStatus } from './types';
import { classNames } from '@utils/class-names';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import i18n from '../../i18n';
import prettyBytes from 'pretty-bytes';
const colors = {
    [UploadStatus.UPLOADING]: {
        iconClass: 'text-p100Green',
        iconBackgroundClass: 'bg-p100Green/30',
        iconOutlineClass: 'outline-p100Green/10',
        ringClass: 'ring-n400GreyCheteau',
        filenameTextClass: 'text-n600DarkBlue',
        progressTextClass: 'text-n500DarkGrey',
        progressBackgroundClass: 'bg-tertiaryBtnHover',
    },
    [UploadStatus.SUCCESSFUL]: {
        iconClass: 'text-p100Green',
        iconBackgroundClass: 'bg-p100Green/30',
        iconOutlineClass: 'outline-p100Green/10',
        ringClass: 'ring-p100Green',
        filenameTextClass: 'text-n600DarkBlue',
        progressTextClass: 'text-n500DarkGrey',
        progressBackgroundClass: 'bg-white',
    },
    [UploadStatus.FAILED]: {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        iconClass: 'text-r600Error',
        iconBackgroundClass: 'bg-r100Error',
        iconOutlineClass: 'outline-r50Error',
        ringClass: 'ring-r300Error',
        filenameTextClass: 'text-r700Error',
        progressTextClass: 'text-r600Error',
        progressBackgroundClass: 'bg-r25Error',
    },
    [UploadStatus.INVALID]: {
        iconClass: 'text-r600Error',
        iconBackgroundClass: 'bg-r100Error',
        iconOutlineClass: 'outline-r50Error',
        ringClass: 'ring-r300Error',
        filenameTextClass: 'text-r700Error',
        progressTextClass: 'text-r600Error',
        progressBackgroundClass: 'bg-r25Error',
    },
};
export function UploadProgress({ id, uploadState, uploadSize, uploadProgress, filename, deleteCallback, deleteInvalidCallback, retryCallback, rejectionErrorCode, }) {
    const { t } = useTranslation('upload');
    const [isFocused, setIsFocused] = useState(false);
    function showIcon(uploadState) {
        switch (uploadState) {
            case UploadStatus.SUCCESSFUL: {
                return (React.createElement(React.Fragment, null,
                    React.createElement("button", { onClick: () => deleteCallback(id, true), "aria-label": "delete", className: "hidden group-focus:block group-hover:block self-start p-2 -mt-2 -mr-2" },
                        React.createElement(TrashIcon, { className: classNames(colors[UploadStatus.FAILED]
                                .filenameTextClass, 'h-4 w-4'), "aria-hidden": "true", "data-testid": "delete-successful" })),
                    React.createElement("div", { className: "flex items-center rounded-full h-4 w-4 bg-p100Green px-0.5 group-focus:hidden group-hover:hidden" },
                        React.createElement(CheckIcon, { className: "text-white" }))));
            }
            case UploadStatus.UPLOADING:
                return (React.createElement(LoadingSpinner, { className: classNames(colors[uploadState].iconClass, 'h-7 w-7') }));
            case UploadStatus.FAILED:
            case UploadStatus.INVALID:
                return (React.createElement(TrashIcon, { onClick: () => uploadState === UploadStatus.INVALID &&
                        deleteInvalidCallback
                        ? deleteInvalidCallback(id)
                        : deleteCallback(id, false), className: classNames(colors[uploadState].filenameTextClass, 'h-4 w-4 cursor-pointer'), "aria-hidden": "true", "data-testid": "delete-failed" }));
        }
    }
    function getErrorMessageKey(errorCode) {
        switch (errorCode) {
            case ErrorCode.FileTooLarge:
                return 'fileTooLarge';
            case ErrorCode.FileInvalidType:
                return 'unknownFileType';
            case ErrorCode.TooManyFiles:
                return 'tooManyFiles';
            case ServerErrorCode.NO_COMPANY:
            case ServerErrorCode.NO_EMPLOYEE:
            case ServerErrorCode.INVALID_FILE:
                return errorCode;
            default:
                return '';
        }
    }
    function getProgressString(progress, status, rejectionErrorCode) {
        if (rejectionErrorCode) {
            return `${t('rejected')}: ${t(getErrorMessageKey(rejectionErrorCode))}`;
        }
        return `${t('uploadProgress', {
            progressInPercent: progress * 100,
        })}`;
    }
    return (React.createElement("div", { tabIndex: -1, onFocus: () => setIsFocused(true), onBlur: () => setIsFocused(false), className: classNames(uploadState === UploadStatus.FAILED ? 'h-32' : 'h-18', uploadState === UploadStatus.SUCCESSFUL
            ? 'cursor-pointer'
            : 'pointer-events-auto', isFocused && uploadState === UploadStatus.SUCCESSFUL
            ? 'ring-r100Red'
            : colors[uploadState].ringClass, 'group w-full overflow-hidden rounded-lg ring-1 bg-white relative'), "data-testid": "upload-progress" },
        React.createElement("div", { className: classNames(colors[uploadState].progressBackgroundClass, 'absolute inset-0 h-full transition-all duration-250 ease-linear'), style: {
                width: `${uploadProgress * 100}%`,
            } }),
        React.createElement("div", { className: "absolute inset-0 flex py-4 pl-3 pr-4" },
            React.createElement("div", { className: classNames(colors[uploadState].iconBackgroundClass, colors[uploadState].iconOutlineClass, 'flex flex-shrink-0 h-8 w-8 items-center justify-center rounded-full outline outline-4') },
                React.createElement(FileIcon, { className: classNames(colors[uploadState].iconClass, 'h-4 w-4'), "aria-hidden": "true" })),
            React.createElement("div", { className: "flex w-0 flex-1 justify-between" },
                React.createElement("div", { className: "ml-4 w-0 flex-1" },
                    React.createElement("p", { className: classNames(colors[uploadState].filenameTextClass, 'text-sm font-medium truncate') }, filename),
                    React.createElement("p", { className: classNames(colors[uploadState].progressTextClass, 'mt-0.5 text-sm') },
                        t('uploadedFileSize', {
                            fileSize: prettyBytes(uploadSize, {
                                locale: i18n.language,
                            }),
                            fileSizeBytes: uploadSize,
                        }),
                        ' - ',
                        React.createElement("span", { className: "tabular-nums" }, getProgressString(uploadProgress, uploadState, rejectionErrorCode))),
                    uploadState === UploadStatus.FAILED && (React.createElement("button", { className: classNames(colors[uploadState].filenameTextClass, 'mt-1 text-sm font-medium cursor-pointer'), onClick: () => retryCallback(id), "data-testid": "retry-button" }, t('tryAgain'))))),
            React.createElement("div", { className: "flex flex-shrink-0 pl-4" }, showIcon(uploadState)))));
}
