export const isProd = process.env.NODE_ENV === 'production';
export const isDev = process.env.NODE_ENV === 'development';
export const isTest = process.env.NODE_ENV === 'test';
export const isDebug = !!process.env.DEBUG;
export const isCI = !!process.env.CI;
export function definedOrThrow(variableName, value) {
    /* istanbul ignore next */
    if (!value) {
        throw new Error(`Missing environment variable process.env.${variableName}, you need to provide it for the application to run.`);
    }
    return value;
}
export function defineBoolean(defaultValue, value) {
    if (!value) {
        return defaultValue;
    }
    return value.toLowerCase() === 'true';
}
// We need to access the environment variables statically with process.env.__NAME__
// since the value is replaced at build-time and needs to appear in the script verbatim.
// See https://create-react-app.dev/docs/adding-custom-environment-variables/.
export const Environment = {
    PUBLIC_URL: definedOrThrow('REACT_APP_PUBLIC_URL', process.env.REACT_APP_PUBLIC_URL),
    NEST_API_URL: definedOrThrow('REACT_APP_NEST_API_URL', process.env.REACT_APP_NEST_API_URL),
    REACT_APP_ENABLE_AUTH0: defineBoolean(true, process.env.REACT_APP_ENABLE_AUTH0),
    REACT_APP_AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
    REACT_APP_AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    REACT_APP_AUTH0_API_AUDIENCE: process.env.REACT_APP_AUTH0_API_AUDIENCE,
};
