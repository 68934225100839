import { DOCUMENT_PAGE_SIZE } from '@utils/constants';
import { useNestClient } from '@hooks/useNestClient';
import { useSearchParams } from 'react-router-dom';
import DocumentGroupTable from '@components/documents/document-group-table';
import React, { useEffect, useMemo, useState } from 'react';
export default function DocumentGroupTableWrapper({ personal, employeeDocuments, }) {
    const [sortGrouped, setSortGrouped] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [resultPage, setResultPage] = useState(0);
    const [forceUpdateCounter, setForceUpdateCounter] = useState(0);
    const [, setSearchParams] = useSearchParams();
    const [numberOfAvailablePages, setNumberOfAvailablePages] = useState(0);
    const accountingPeriodRequest = useMemo(() => {
        return {
            pageNumber: currentPage === resultPage ? currentPage : resultPage,
            pageSize: DOCUMENT_PAGE_SIZE,
            sort: sortGrouped !== null && sortGrouped !== void 0 ? sortGrouped : undefined,
            personal: personal,
            employeeDocuments: employeeDocuments,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, sortGrouped, forceUpdateCounter, employeeDocuments]);
    const { result: accountingPeriodData, loading: loading } = useNestClient('getAccountingPeriodData', [accountingPeriodRequest], { data: [], totalCount: 0, page: 0 });
    useEffect(() => {
        setNumberOfAvailablePages(Math.ceil(accountingPeriodData.totalCount / DOCUMENT_PAGE_SIZE));
        setResultPage(accountingPeriodData.page);
    }, [accountingPeriodData]);
    const updatePage = (newPage) => {
        setResultPage(newPage);
        setCurrentPage(newPage);
    };
    return (React.createElement(DocumentGroupTable, { documents: accountingPeriodData.data, documentSchema: null, currentPage: resultPage, numberOfAvailablePages: numberOfAvailablePages, setCurrentPage: updatePage, sort: sortGrouped !== null && sortGrouped !== void 0 ? sortGrouped : undefined, setSort: setSortGrouped, loading: loading, totalCount: accountingPeriodData.totalCount, setForceUpdateCounter: setForceUpdateCounter, setSearchParams: setSearchParams, forceUpdateCounter: forceUpdateCounter }));
}
