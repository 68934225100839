import { LoadingSpinner } from '@components/loading-spinner';
import { useNestClient } from '@hooks/useNestClient';
import { useParams } from 'react-router-dom';
import CompanyDashboard from '@components/company-dashboard/company-dashboard';
import React, { useEffect, useState } from 'react';
export default function PrCompanyDashboardView() {
    const { companyId } = useParams();
    const [updateCounter, setUpdateCounter] = useState(0);
    const { result: company, error, reload, } = useNestClient('getCompanyById', [companyId !== null && companyId !== void 0 ? companyId : '']);
    const { result: companySchema, loading: schemaLoading, error: schemaError, } = useNestClient('getCompanySchema', [true, true], { fields: [] }); // is always admin
    useEffect(() => {
        reload();
    }, [reload, updateCounter]);
    if (error || schemaError) {
        throw error;
    }
    if (!company || schemaLoading) {
        return React.createElement(LoadingSpinner, null);
    }
    return (React.createElement(CompanyDashboard, { company: company, companySchema: companySchema, setUpdateCounter: setUpdateCounter, isAdmin: true, hasAdminRights: true }));
}
