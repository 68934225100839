import { useCompany } from '../context/company-context';
// eslint-disable-next-line no-restricted-imports
import { useNavigate } from 'react-router-dom';
import { usePayrollProvider } from '../context/payrollprovider-context';
import NoCompanyError from '@components/errors/NoCompanyError';
import React, { useEffect } from 'react';
export const AuthorizedCompanyRoute = ({ children, requiredRoles }) => {
    const { company, error } = useCompany();
    const navigate = useNavigate();
    useEffect(() => {
        if ((requiredRoles === null || requiredRoles === void 0 ? void 0 : requiredRoles.length) &&
            !error &&
            company &&
            !requiredRoles.some((role) => company.companyRoles.includes(role))) {
            navigate('/');
        }
    }, [company, error, navigate, requiredRoles]);
    if (!(requiredRoles === null || requiredRoles === void 0 ? void 0 : requiredRoles.length)) {
        return React.createElement(React.Fragment, null, children);
    }
    if (error) {
        return React.createElement(NoCompanyError, { error: error });
    }
    if (!company) {
        // Still loading
        return React.createElement(React.Fragment, null);
    }
    if (requiredRoles.some((role) => company.companyRoles.includes(role))) {
        return React.createElement(React.Fragment, null, children);
    }
    else {
        return React.createElement(React.Fragment, null);
    }
};
export const AuthorizedPayrollProviderRoute = ({ children, requiredRoles }) => {
    const { payrollProvider, error } = usePayrollProvider();
    const navigate = useNavigate();
    useEffect(() => {
        if (error ||
            (payrollProvider &&
                (requiredRoles === null || requiredRoles === void 0 ? void 0 : requiredRoles.length) &&
                !requiredRoles.some((role) => payrollProvider.roles.includes(role)))) {
            navigate('/');
        }
    }, [error, navigate, payrollProvider, requiredRoles]);
    if (!(requiredRoles === null || requiredRoles === void 0 ? void 0 : requiredRoles.length)) {
        return React.createElement(React.Fragment, null, children);
    }
    if (error) {
        return React.createElement(React.Fragment, null);
    }
    if (!payrollProvider) {
        // Still loading
        return React.createElement(React.Fragment, null);
    }
    if (requiredRoles.some((role) => payrollProvider.roles.includes(role))) {
        return React.createElement(React.Fragment, null, children);
    }
    else {
        return React.createElement(React.Fragment, null);
    }
};
