import { ReactComponent as AlertTriangleIcon } from '@public/icons/alert-triangle.svg';
import { ReactComponent as ArrowLeftIcon } from '@public/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '@public/icons/arrow-right.svg';
import { Button, ButtonSize, ButtonType, } from '@components/button';
import { Wizard as ReactUseWizard, useWizard } from 'react-use-wizard';
import { WizardHeader } from '@components/wizard/wizard-header';
import { useTranslation } from 'react-i18next';
import CloseButton, { CloseButtonSize, CloseButtonType, } from '@components/close-button';
import Dialog from '@components/modals/dialog';
import React, { useCallback, useState, } from 'react';
const StepWrapper = ({ onClose, showCloseButton, children, finalStepActions, }) => {
    const scrollToTop = useCallback(() => window.scrollTo({ top: 0 }), []);
    const { previousStep, nextStep, isLastStep, isFirstStep } = useWizard();
    const { t } = useTranslation('common');
    return (React.createElement("div", { className: "relative min-h-full w-full flex flex-col px-6 pt-8 mx-auto max-w-[700px]" },
        showCloseButton && (React.createElement(CloseButton, { className: "absolute top-2 right-2", onClick: onClose, type: CloseButtonType.GREY, size: CloseButtonSize.MEDIUM, testId: "closeBtn" })),
        children,
        React.createElement("div", { className: "flex-grow" }),
        React.createElement("div", { className: "col-span-2 flex w-full items-end justify-center space-x-4 py-8 sticky bottom-0 bg-white" },
            React.createElement(Button, { label: t('button.previous'), buttonType: ButtonType.SECONDARY, buttonSize: ButtonSize.LARGE, onClick: () => {
                    previousStep();
                    scrollToTop();
                }, IconLeading: ArrowLeftIcon, disabled: isFirstStep, className: "flex-1 max-w-[12rem] w-0" }),
            isLastStep ? (finalStepActions === null || finalStepActions === void 0 ? void 0 : finalStepActions.map((action) => (React.createElement(Button, Object.assign({ key: action.key }, action.buttonProps))))) : (React.createElement(Button, { label: t('button.next'), buttonType: ButtonType.PRIMARY, buttonSize: ButtonSize.LARGE, IconTrailing: ArrowRightIcon, onClick: () => {
                    void nextStep();
                    scrollToTop();
                }, type: "submit", className: "flex-1 max-w-[12rem] w-0", testId: "nextBtn" })))));
};
export function Wizard({ steps, value, setValue, actionButtons, onClose, showCloseButton, onFinish, finalStepActions, }) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [saving, setSaving] = useState(false);
    const { t } = useTranslation('wizard');
    return (React.createElement("div", { className: "wizard w-full min-h-full flex flex-col md:flex-row bg-white" },
        React.createElement(ReactUseWizard, { header: React.createElement(WizardHeader, { stepsValid: steps.map(({ isValid }) => isValid(value)), steps: steps, actionButtons: actionButtons }), wrapper: React.createElement(StepWrapper, { onClose: () => {
                    if (unsavedChanges) {
                        setDialogOpen(true);
                    }
                    else {
                        onClose();
                    }
                }, showCloseButton: showCloseButton, finalStepActions: finalStepActions }) }, steps.map(({ ReactComponent, key }) => (React.createElement(ReactComponent, { value: value, setValue: (...args) => {
                setUnsavedChanges(true);
                setValue(...args);
            }, unsavedChanges: unsavedChanges, key: key })))),
        React.createElement(Dialog, { dismissible: true, open: dialogOpen, setOpen: setDialogOpen, title: t('cancelDialog.title'), Icon: AlertTriangleIcon, actions: [
                {
                    buttonProps: {
                        label: t('cancelDialog.saveAndExitBtn'),
                        buttonSize: ButtonSize.SMALL,
                        buttonType: ButtonType.PRIMARY,
                        onClick: () => {
                            var _a;
                            setSaving(true);
                            ((_a = onFinish(value)) !== null && _a !== void 0 ? _a : Promise.resolve()).finally(() => setSaving(false));
                        },
                        loading: saving,
                        tabIndex: 1,
                        testId: 'saveAndExitBtn',
                    },
                },
                {
                    buttonProps: {
                        label: t('cancelDialog.discardBtn'),
                        buttonSize: ButtonSize.SMALL,
                        buttonType: ButtonType.DESTRUCTIVE,
                        onClick: () => {
                            setDialogOpen(false);
                            onClose();
                        },
                        tabIndex: 2,
                        testId: 'discardBtn',
                    },
                },
            ] }, t('cancelDialog.exitWithoutSave'))));
}
