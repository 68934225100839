import { classNames } from '@utils/class-names';
import { useValidation } from '@hooks/useValidation';
import React, { useCallback, useEffect } from 'react';
export function RadioGroup({ options, displayValue = (option) => option, onChange, value, title, validate: validateFn, errorMessages, className, name, inputTestId, disabled = false, shouldValidate = true, required, runInitialValidation = false, }) {
    const [isValid, errorMessage, runValidation] = useValidation({
        validateFn: validateFn,
        messageKeys: errorMessages,
        required,
    });
    useEffect(() => {
        if (runInitialValidation && value) {
            runValidation(value);
        }
    }, 
    // Ignore eslint error because we want to run this effect only once, not every time the value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [runValidation, runInitialValidation]);
    const radioHandler = useCallback((event) => {
        onChange(event.target.value);
        if (shouldValidate) {
            runValidation(event.target.value);
        }
    }, [onChange, runValidation, shouldValidate]);
    return (React.createElement("div", { className: classNames(className, 'min-h-[5.75rem] flex flex-col', disabled ? 'cursor-not-allowed' : '') },
        React.createElement("legend", { className: classNames('w-full text-sm font-medium inline-block leading-tight', disabled ? 'text-n500DarkGrey' : 'text-n600DarkBlue') }, title),
        React.createElement("div", { className: "flex items-center min-h-[2.75rem]" },
            React.createElement("fieldset", { className: classNames('flex gap-x-4 gap-y-2 flex-wrap'), disabled: disabled }, options.map((option) => (React.createElement(RadioButton, { key: option, label: displayValue(option), value: option, checked: option === value, onChange: radioHandler, name: name, inputTestId: `${inputTestId}-${option}`, disabled: disabled, onBlur: () => {
                    runValidation(value !== null && value !== void 0 ? value : '');
                } }))))),
        React.createElement("span", { "data-testid": "errorMessage", className: classNames('block text-n600DarkBlue text-sm mt-1 opacity-0 transition-opacity min-h-[40px]', !isValid && 'opacity-100', !isValid && 'font-bold !text-r100Red') },
            "\u200B",
            errorMessage)));
}
export function RadioButton({ label, value, checked, onChange, className, name, disabled = false, inputTestId, onBlur, }) {
    return (React.createElement("label", { className: classNames('inline-flex items-center', disabled ? 'cursor-not-allowed' : '') },
        React.createElement("input", { className: classNames(className, 'h-4 w-4 !border-n500DarkGrey !bg-white', 'hover:!border-p200Green hover:enabled:!bg-p50LightGreen', 'hover:bg-none', 'focus:ring focus:ring-offset-0 focus:ring-p50LightGreen', 'disabled:!bg-n100Whisper disabled:!border-[#EAECF0] disabled:cursor-not-allowed', 'checked:!border-p200Green', "checked:!bg-[url('data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%228%22%20cy%3D%228%22%20r%3D%223%22%20fill%3D%22%2301575C%22%2F%3E%3C%2Fsvg%3E')]"), type: "radio", value: value, checked: checked, onChange: onChange, name: name, "data-testid": inputTestId, onBlur: onBlur }),
        React.createElement("span", { className: "ml-2" }, label)));
}
