import { getTitle } from '@components/generic-forms/generic-form';
import { FieldType, } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { MAX_OPTIONS_DISPLAYED } from '@utils/constants';
import { localizeString } from '@utils/formatting';
import Combobox from '@components/input/combobox';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import i18n from '../../i18n';
import nestClient from '@api/nest-client';
export const SearchField = ({ fieldName, field, fieldValue, setValue, className, disabled, required, }) => {
    const [options, setOptions] = useState({});
    const [sortedKeys, setSortedKeys] = useState([]);
    const [hasMoreActions, setHasMoreActions] = useState(false);
    const fetchOptionsCallback = useCallback((input) => {
        const fetchValues = async () => {
            if (field.type !== FieldType.SEARCH ||
                (fieldValue && fieldValue.type !== FieldType.SEARCH)) {
                setSortedKeys([]);
                setOptions({});
                return;
            }
            if (input.trim().length < 3) {
                setSortedKeys([]);
                setOptions({});
                return;
            }
            const rawOptions = await nestClient.searchablePossibleValues(field.id, input, i18n.language);
            const keys = rawOptions.map((option) => option[0]);
            const newOptions = {};
            rawOptions.forEach((option) => {
                newOptions[option[0]] = option[1];
            });
            setHasMoreActions(rawOptions.length > MAX_OPTIONS_DISPLAYED);
            setSortedKeys(keys.slice(0, MAX_OPTIONS_DISPLAYED));
            setOptions(newOptions);
        };
        void fetchValues();
    }, [field, fieldValue]);
    const fetchValuesForCurrentValue = useCallback(() => {
        if ((fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value) && fieldValue.type === FieldType.SEARCH) {
            const fetchInitialValue = async () => {
                const rawOptions = await nestClient.getPossibleValueForIdAndName(field.id, fieldValue.value, i18n.language);
                const keys = rawOptions.map((option) => option[0]);
                const newOptions = {};
                rawOptions.forEach((option) => {
                    newOptions[option[0]] = option[1];
                });
                setSortedKeys(keys);
                setOptions(newOptions);
            };
            void fetchInitialValue();
        }
    }, [field.id, fieldValue]);
    useLayoutEffect(() => {
        fetchValuesForCurrentValue();
    }, [fetchValuesForCurrentValue, field.id, fieldValue]);
    const getDisplayValue = useCallback((value) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (value && options[value]) {
            return localizeString(options[value], i18n.language);
        }
        return '';
    }, [options]);
    if (field.type !== FieldType.SEARCH ||
        (fieldValue && fieldValue.type !== FieldType.SEARCH)) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(Combobox, { key: fieldName, value: fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value, displayValue: getDisplayValue, options: sortedKeys, title: getTitle(field, required), onChange: (newValue) => {
            if (newValue) {
                setValue(fieldName, field.type, newValue);
            }
        }, classNameContainer: className, disabled: disabled, onUserInput: fetchOptionsCallback, onBlur: fetchValuesForCurrentValue, required: required, runInitialValidation: true, hasMoreOptions: hasMoreActions }));
};
