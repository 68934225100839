import { ReactComponent as HelpCircleIcon } from '@public/icons/help-circle.svg';
import { Popover } from '@headlessui/react';
import { classNames } from '@utils/class-names';
import { usePopper } from 'react-popper';
import React, { useState } from 'react';
import sanitizeHtml from 'sanitize-html';
export const InfoText = ({ text, className }) => {
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState();
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        modifiers: [
            {
                name: 'preventOverflow',
                options: {
                    altAxis: true,
                    padding: 10,
                },
            },
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                },
            },
        ],
    });
    const clean = sanitizeHtml(text, {
        allowedTags: ['b', 'i', 'em', 'strong', 'br'],
    });
    return (React.createElement(Popover, { className: classNames('relative', className) },
        React.createElement(Popover.Button, { ref: setReferenceElement, "data-testid": "popover-btn" },
            React.createElement(HelpCircleIcon, { className: "text-p200Green h-5 w-5" })),
        React.createElement(Popover.Panel, Object.assign({ className: "w-80 p-3 bg-white rounded-lg shadow-infoText whitespace-pre-line z-10 text-n500DarkGrey border border-n300Grey", ref: setPopperElement, "data-testid": "popover-panel", style: styles.popper }, attributes.popper),
            React.createElement("div", { dangerouslySetInnerHTML: { __html: clean } }))));
};
