import { getTitle } from '@components/generic-forms/generic-form';
import { FieldType } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { TextInput } from '@components/input/text-input';
import { ValidationType, validateSocialSecurityNumber, } from '@lohndialog/shared/src/validation';
import React, { useMemo } from 'react';
export const TextField = ({ fieldName, field, fieldValue, setValue, className, disabled, validate, required, shouldValidate, genericFields, }) => {
    var _a;
    const isTextTypeField = field.type === FieldType.TEXT;
    const validator = useMemo(() => {
        if (isTextTypeField &&
            fieldName === 'socialSecurityNumber' &&
            fieldValue) {
            return (input) => validateSocialSecurityNumber({ type: FieldType.TEXT, value: input }, genericFields);
        }
        return undefined;
    }, [fieldName, fieldValue, genericFields, isTextTypeField]);
    if (!isTextTypeField ||
        (fieldValue && fieldValue.type !== FieldType.TEXT)) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(TextInput, { key: fieldName, name: fieldName, value: (_a = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value) !== null && _a !== void 0 ? _a : '', title: getTitle(field, required), onChange: (newValue) => setValue(fieldName, field.type, newValue), maxLength: field.maxLength, minLength: field.minLength, regex: field.regex, classNameContainer: className, disabled: disabled, validate: validator !== null && validator !== void 0 ? validator : validate, required: required, inputTestId: `textInput-${fieldName}`, shouldValidate: shouldValidate, validationType: fieldName === 'socialSecurityNumber'
            ? ValidationType.socialSecurityNumber
            : ValidationType.basic, runInitialValidation: true }));
};
