import { Checkbox } from '@components/input/checkbox';
import { useTranslation } from 'react-i18next';
import React from 'react';
export const SignatureBox = ({ onChange, checked, }) => {
    const { t } = useTranslation('signature');
    return (React.createElement("div", { className: "mb-4" },
        React.createElement("h2", { className: "mt-2" }, t('signatureHeading')),
        React.createElement("div", { className: "bg-n200LightGrey rounded-2xl p-6 mb-4" },
            React.createElement("div", { className: "flex gap-4 items-center" },
                React.createElement(Checkbox, { checked: checked, onChange: (e) => onChange(e.target.checked), "data-testid": "checkboxSignature" }),
                React.createElement("p", null, t('consent'))))));
};
