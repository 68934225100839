import { CompanyContext } from '../context/company-context';
import { PayrollProviderContext } from '../context/payrollprovider-context';
import { useContext } from 'react';
export const useRole = (roles) => {
    var _a, _b, _c, _d;
    const company = (_a = useContext(CompanyContext)) === null || _a === void 0 ? void 0 : _a.company;
    const payrollProvider = (_b = useContext(PayrollProviderContext)) === null || _b === void 0 ? void 0 : _b.payrollProvider;
    if (!(roles === null || roles === void 0 ? void 0 : roles.length)) {
        return true;
    }
    const currentRoles = [
        ...((_c = company === null || company === void 0 ? void 0 : company.companyRoles) !== null && _c !== void 0 ? _c : []),
        ...((_d = payrollProvider === null || payrollProvider === void 0 ? void 0 : payrollProvider.roles) !== null && _d !== void 0 ? _d : []),
    ];
    return roles.some((role) => currentRoles.includes(role));
};
