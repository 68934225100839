import { Button, ButtonSize, ButtonType } from '@components/button';
import { ReactComponent as DownloadIcon } from '@public/icons/download.svg';
import { ReactComponent as EditIcon } from '@public/icons/edit.svg';
import { WizardCluster, } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { NavLink } from '@components/nav-link';
import { Tabs } from '@components/tabs';
import { useNestClient } from '@hooks/useNestClient';
import { usePayrollProvider } from '../context/payrollprovider-context';
import { useTranslation } from 'react-i18next';
import FileSaver from 'file-saver';
import GenericSummaryTab, { containsCollapsibleFields, } from '@components/generic-summary-tab';
import PlaceholderView from '@views/placeholder-view';
import PpEmployees from '@components/pp-employee-table/pp-employees';
import React, { useCallback } from 'react';
import nestClient from '@api/nest-client';
export default function PayrollProviderSettingsView() {
    const { t } = useTranslation('payrollProviderSettings');
    const { payrollProvider } = usePayrollProvider();
    const { result: schema, error: errorLoadingSchema } = useNestClient('getPayrollProviderSchema', [false]);
    if (errorLoadingSchema) {
        throw errorLoadingSchema;
    }
    const getJsonSchema = useCallback(() => {
        const downloadSchema = async () => {
            try {
                const file = await nestClient.getJsonSchema();
                FileSaver.saveAs(file);
            }
            catch (e) {
                console.error(e);
            }
        };
        void downloadSchema();
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex flex-row justify-between items-center" },
            React.createElement("h1", null, t('title')),
            React.createElement("div", { className: "space-x-2" },
                React.createElement(Button, { IconLeading: DownloadIcon, label: t('schemaDownload'), buttonType: ButtonType.SECONDARY, buttonSize: ButtonSize.LARGE, className: "bg-white", "data-testid": "schema-button", onClick: getJsonSchema }),
                React.createElement(NavLink, { to: "/admin/edit", addCompanyPrefix: false },
                    React.createElement(Button, { IconLeading: EditIcon, label: t('edit'), buttonType: ButtonType.SECONDARY, buttonSize: ButtonSize.LARGE, className: "bg-white", "data-testid": "edit-button" })))),
        React.createElement(Tabs, { className: "my-8", tabs: [
                {
                    id: 1,
                    title: t('ppData'),
                    content: (React.createElement(PPDataTab, { schema: schema, payrollProvider: payrollProvider })),
                    expandable: containsCollapsibleFields(schema, WizardCluster.BasicInformation, WizardCluster.Address),
                },
                {
                    id: 2,
                    title: t('presentation'),
                    content: (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "flex flex-col justify-between basis-1/3 md:basis-1/5" },
                            React.createElement("div", { className: "text-xs text-n500DarkGrey font-medium leading-4 mb-1.5" }, t('dataPrivacyInformation')),
                            React.createElement("div", { className: "text-sm font-inter text-black font-medium leading-4 mb-6" }, payrollProvider === null || payrollProvider === void 0 ? void 0 : payrollProvider.dataPrivacyInformation),
                            React.createElement("div", { className: "text-xs text-n500DarkGrey font-medium leading-4 mb-1.5" }, t('imprint')),
                            React.createElement("div", { className: "text-sm font-inter text-black font-medium leading-4" }, payrollProvider === null || payrollProvider === void 0 ? void 0 : payrollProvider.imprint)))),
                },
            ] }),
        React.createElement(Tabs, { withoutMargin: true, tabs: [
                {
                    id: 1,
                    title: t('employees'),
                    content: React.createElement(PpEmployees, null),
                },
                {
                    id: 2,
                    title: t('permissionGroups'),
                    content: React.createElement(PlaceholderView, { title: "" }),
                },
                {
                    id: 3,
                    title: t('subPP'),
                    content: React.createElement(PlaceholderView, { title: "" }),
                },
            ] })));
}
const PPDataTab = ({ schema, payrollProvider, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(GenericSummaryTab, { schema: schema, wizardCluster: WizardCluster.BasicInformation, entity: payrollProvider }),
        React.createElement("hr", { className: "border-n300Grey my-6" }),
        React.createElement(GenericSummaryTab, { schema: schema, wizardCluster: WizardCluster.Address, entity: payrollProvider })));
};
