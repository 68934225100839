import { ReactComponent as AlertCircleIcon } from '@public/icons/alert-circle.svg';
import { ValidationType, } from '@lohndialog/shared/src/validation';
import { classNames } from '@utils/class-names';
import { formatErrorMessages, useValidation } from '@hooks/useValidation';
import React, { forwardRef, useCallback, useEffect, useId, useMemo, } from 'react';
// eslint-disable-next-line sonarjs/cognitive-complexity
function TextInputInner({ type = 'text', value, onChange: onChangeFn, placeholder, title, classNameContainer, classNameInput, name, inputTestId, validate: validateFn, maxLength, minLength, errorMessages = {}, onBlur, additionalErrorMessage, required, transform = (input) => input, regex, min, max, disabled = false, shouldValidate = true, percentage = false, validationType = ValidationType.basic, runInitialValidation = true, }, ref) {
    const [isValid, errorMessage, runValidation] = useValidation({
        validateFn: validateFn,
        messageKeys: errorMessages,
        maxLength: maxLength,
        minLength: minLength,
        regex: regex,
        min: min,
        max: max,
        required: required,
        validationType: validationType,
    });
    useEffect(() => {
        if (runInitialValidation && value) {
            runValidation(transform(value));
        }
    }, 
    // Ignore eslint error because we want to run this effect only once, not every time the value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [runValidation, runInitialValidation, transform]);
    const shouldDisplayError = useMemo(() => {
        return !disabled && !isValid && shouldValidate;
    }, [disabled, isValid, shouldValidate]);
    const id = useId();
    const textBoxStyle = classNames('px-3.5 py-2.5 rounded-md border border-n400GreyCheteau w-full text-n600DarkBlue shadow-input focus:shadow-none', shouldDisplayError && errorMessage
        ? 'border-r300Error focus:border-r300Error focus:ring-4 focus:ring-[#FEE4E2]'
        : 'focus:border-p200Green focus:ring-4 focus:ring-[#F1FDF7]');
    const onChange = useCallback((event) => {
        const parsedValue = transform(event.target.value);
        onChangeFn(parsedValue);
        if (!isValid && shouldValidate) {
            runValidation(parsedValue);
        }
    }, [transform, onChangeFn, isValid, shouldValidate, runValidation]);
    const showErrorMessage = !!(maxLength && value.length > maxLength * 0.9) ||
        !isValid ||
        additionalErrorMessage;
    return (React.createElement("div", { className: classNameContainer },
        title && (React.createElement("label", { htmlFor: id, className: classNames('w-full text-sm font-medium inline-block leading-tight', disabled ? 'text-n500DarkGrey' : 'text-n600DarkBlue') }, title)),
        React.createElement("div", { className: "relative" },
            type === 'textarea' ? (React.createElement("textarea", { id: id, className: classNames(textBoxStyle, 'resize-none h-full', classNameInput, disabled
                    ? '!text-n500DarkGrey cursor-not-allowed bg-n100Whisper' //'text-p200Green cursor-not-allowed border-p200Green'
                    : ''), value: value, onChange: onChange, placeholder: placeholder, name: name, ref: ref, "data-testid": inputTestId, onBlur: (event) => {
                    const parsedValue = transform(event.target.value);
                    if (shouldValidate) {
                        runValidation(parsedValue);
                    }
                    onBlur === null || onBlur === void 0 ? void 0 : onBlur();
                }, disabled: disabled })) : (React.createElement("div", { className: "flex items-center" },
                React.createElement("input", { id: id, className: classNames(textBoxStyle, classNameInput, 'leading-snug', disabled
                        ? '!text-n500DarkGrey cursor-not-allowed bg-n100Whisper' //'text-p200Green cursor-not-allowed border-p200Green'
                        : ''), type: type, value: value, onChange: onChange, placeholder: placeholder, name: name, max: max, min: min, ref: ref, "data-testid": inputTestId, onBlur: (event) => {
                        const parsedValue = transform(event.target.value);
                        if (shouldValidate) {
                            runValidation(parsedValue);
                        }
                        event.target.setCustomValidity('');
                        onBlur === null || onBlur === void 0 ? void 0 : onBlur();
                    }, disabled: disabled }),
                percentage && (React.createElement("div", { className: "text-n600DarkBlue text-sm absolute right-1" }, "%")))),
            shouldDisplayError && (React.createElement(AlertCircleIcon, { className: "absolute text-r100Red right-7 h-4 top-0 bottom-0 my-auto" }))),
        React.createElement("span", { className: classNames('block text-n600DarkBlue text-sm mt-1 opacity-0 transition-opacity relative w-full min-h-[40px]', showErrorMessage && 'opacity-100', (shouldDisplayError ||
                (!!maxLength && value.length > maxLength)) &&
                'font-bold !text-r100Red') },
            "\u200B",
            shouldDisplayError && (React.createElement("span", { "data-testid": "errorMessage", className: "absolute max-w-full" }, formatErrorMessages(isValid, maxLength, value.length, errorMessage, additionalErrorMessage))))));
}
export const TextInput = forwardRef(TextInputInner);
