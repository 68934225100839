import { Dropzone } from '@components/upload/drop-zone';
import { UploadProgress } from '@components/upload/upload-progress';
import { UploadStatus } from '@components/upload/types';
import React, { useCallback, useRef, useState, } from 'react';
export function Upload({ fileUploads, setFileUploads, uploadFuncDB, deleteFuncDB, maxFileSize, acceptedFileFormats, maxFiles = Infinity, }) {
    const [invalidFiles, setInvalidFiles] = useState([]);
    const count = useRef(0);
    const updateUploadStatus = useCallback((id, data) => setFileUploads((fileUploads) => fileUploads.map((fileUpload) => fileUpload.id === id
        ? Object.assign(Object.assign({}, fileUpload), data) : fileUpload)), [setFileUploads]);
    const uploadFile = useCallback((files, rejectedFiles) => {
        const newUploads = files.map((file) => ({
            file,
            status: UploadStatus.UPLOADING,
            name: file.name,
            size: file.size,
            progress: 0,
            id: count.current++,
        }));
        setFileUploads((oldUploads) => [...newUploads, ...oldUploads]);
        if (rejectedFiles === null || rejectedFiles === void 0 ? void 0 : rejectedFiles.length) {
            const newRejectedFiles = rejectedFiles.map((file) => (Object.assign(Object.assign({}, file), { id: count.current++ })));
            setInvalidFiles((oldRejectedFiles) => [
                ...newRejectedFiles,
                ...oldRejectedFiles,
            ]);
        }
        newUploads.forEach((upload) => {
            const { id, file } = upload;
            void uploadFuncDB(file, (progress) => updateUploadStatus(id, { progress }))
                .then((remoteId) => updateUploadStatus(id, {
                remoteId: remoteId,
                status: UploadStatus.SUCCESSFUL,
            }))
                .catch((error) => {
                const errorKey = error.errorData.errorKey;
                if (errorKey) {
                    updateUploadStatus(id, {
                        status: UploadStatus.FAILED,
                        sanityError: errorKey,
                    });
                }
                else {
                    updateUploadStatus(id, {
                        status: UploadStatus.FAILED,
                    });
                }
            });
        });
    }, [setFileUploads, updateUploadStatus, uploadFuncDB]);
    const deleteValidFile = useCallback((id, deleteRemote) => {
        let fileToDelete;
        const newData = fileUploads.filter((fileUpload) => {
            if (fileUpload.id === id) {
                fileToDelete = fileUpload;
                if (deleteRemote && fileToDelete.remoteId) {
                    /*
                    NOTE:
                    We just try to delete it. If it works all is fine.
                    And if it doesn't work, then the file is still there remotely
                    but no longer appears on the upload list, so we don't do anything to it anymore.
                     */
                    void deleteFuncDB(fileToDelete.remoteId);
                }
                return undefined;
            }
            else {
                return fileUpload;
            }
        });
        setFileUploads(newData);
    }, [deleteFuncDB, setFileUploads, fileUploads]);
    const deleteInvalidFile = useCallback((id) => {
        setInvalidFiles((previousInvalid) => previousInvalid.filter((invalidFile) => invalidFile.id !== id));
    }, []);
    const retryUpload = useCallback((id) => {
        const newData = fileUploads.find((fileUpload) => fileUpload.id === id);
        if (newData) {
            deleteValidFile(id, false);
            uploadFile([newData.file]);
        }
    }, [deleteValidFile, uploadFile, fileUploads]);
    return (React.createElement("div", { className: "pb-2 space-y-2" },
        fileUploads.length < maxFiles && (React.createElement(Dropzone, { onDrop: uploadFile, maxFileSize: maxFileSize, acceptedFileFormats: acceptedFileFormats, maxFiles: maxFiles - fileUploads.length })),
        invalidFiles.map((file) => {
            return (React.createElement(UploadProgress, { key: file.id, id: file.id, filename: file.file.name, uploadProgress: 0, uploadSize: file.file.size, uploadState: UploadStatus.INVALID, deleteCallback: deleteValidFile, retryCallback: retryUpload, deleteInvalidCallback: deleteInvalidFile, rejectionErrorCode: file.errors[0].code }));
        }),
        fileUploads.map((file) => {
            return (React.createElement(UploadProgress, { key: file.id, id: file.id, filename: file.name, uploadProgress: file.progress, uploadSize: file.size, uploadState: file.status, deleteCallback: deleteValidFile, retryCallback: retryUpload, rejectionErrorCode: file.sanityError }));
        })));
}
