import { COMPANY_SLUG_HEADER } from '@lohndialog/shared/src/constants';
import { HeaderHandler } from '@api/header-handler';
// eslint-disable-next-line no-restricted-imports
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useProfile } from './profile-context';
import { useTranslation } from 'react-i18next';
import React, { createContext, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState, } from 'react';
import nestClient from '@api/nest-client';
export const CompanyContext = createContext(null);
export function useCompany() {
    const state = useContext(CompanyContext);
    if (!state) {
        throw new Error('useCompany must be used within a CompanyProvider');
    }
    return state;
}
export const CompanyProvider = ({ children }) => {
    var _a, _b;
    const { companySlug } = useParams();
    const [company, _setCompany] = useState();
    const profile = useProfile();
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation('errors');
    const redirectToCompany = useCallback((newSlug) => {
        if (companySlug === newSlug) {
            return;
        }
        const locationWithCompanySlug = `/${newSlug}${location.pathname}`;
        navigate(locationWithCompanySlug, { replace: true });
    }, [companySlug, location.pathname, navigate]);
    const defaultCompany = ((_a = profile.state.profile) === null || _a === void 0 ? void 0 : _a.companies)
        ? profile.state.profile.companies[0]
        : undefined;
    useEffect(() => {
        if (!companySlug && defaultCompany) {
            redirectToCompany(defaultCompany.slug);
        }
    }, [companySlug, defaultCompany, redirectToCompany]);
    const setCompanyHeader = useCallback((companySlug) => {
        nestClient.companyHandler = new HeaderHandler(() => companySlug
            ? {
                [COMPANY_SLUG_HEADER]: companySlug,
            }
            : {});
    }, []);
    const setCompany = useCallback((company) => {
        _setCompany(company);
        setCompanyHeader(company === null || company === void 0 ? void 0 : company.slug);
        if (company) {
            redirectToCompany(company.slug);
        }
    }, [redirectToCompany, setCompanyHeader]);
    // Setting the company must be done as early as possible.
    // Otherwise, backend requests might be made before the company was set, resulting in error 400.
    useLayoutEffect(() => {
        var _a, _b;
        setCompany((_b = (_a = profile.state.profile) === null || _a === void 0 ? void 0 : _a.companies.find((company) => company.slug === companySlug)) !== null && _b !== void 0 ? _b : defaultCompany);
    }, [
        setCompany,
        companySlug,
        defaultCompany,
        (_b = profile.state.profile) === null || _b === void 0 ? void 0 : _b.companies,
    ]);
    const state = useMemo(() => {
        let error = undefined;
        if (!profile.state.profile &&
            profile.state.error &&
            !profile.state.pending) {
            error = new Error(t('failedToLoadProfile'));
        }
        if (!company &&
            !defaultCompany &&
            !profile.state.pending &&
            !profile.state.error) {
            error = new Error(t('noCompany'));
        }
        return {
            company: company,
            setCompany,
            error,
        };
    }, [
        profile.state.profile,
        profile.state.error,
        profile.state.pending,
        company,
        defaultCompany,
        setCompany,
        t,
    ]);
    return (React.createElement(CompanyContext.Provider, { value: state }, children));
};
