import { EMPLOYEE_PAGE_SIZE } from '@utils/constants';
import { useNestClient } from '@hooks/useNestClient';
import PpEmployeeTable from '@components/pp-employee-table/pp-employee-table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import nestClient from '@api/nest-client';
export default function PpEmployees() {
    const [filter, setFilter] = useState('all');
    const [searchTerm, setSearchTerm] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [sort, setSort] = useState(null);
    const [resultPage, setResultPage] = useState(0);
    const [forceUpdateCounter, setForceUpdateCounter] = useState(0);
    const [numberOfAvailablePages, setNumberOfAvailablePages] = useState(0);
    const staffRequest = useMemo(() => {
        return {
            pageNumber: currentPage === resultPage ? currentPage : resultPage,
            pageSize: EMPLOYEE_PAGE_SIZE,
            filter: filter,
            searchTerm,
            sort: sort !== null && sort !== void 0 ? sort : undefined,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, filter, searchTerm, sort, forceUpdateCounter]);
    const { result: staffMembers, loading } = useNestClient('getPaginatedPayrollProviderStaff', [staffRequest], { data: [], totalCount: 0, page: 0 });
    useEffect(() => {
        setNumberOfAvailablePages(Math.ceil(staffMembers.totalCount / EMPLOYEE_PAGE_SIZE));
        setResultPage(staffMembers.page);
    }, [staffMembers]);
    const updatePage = (newPage) => {
        setResultPage(newPage);
        setCurrentPage(newPage);
    };
    const updateStaffMember = async (staffMember) => {
        await nestClient.updatePayrollProviderStaff(staffMember);
        setForceUpdateCounter(1 + forceUpdateCounter); // no ++ because forceUpdateCounter is const
    };
    const forceUpdate = useCallback(() => {
        setForceUpdateCounter(1 + forceUpdateCounter);
    }, [forceUpdateCounter]);
    return (React.createElement(PpEmployeeTable, { staffMembers: staffMembers.data, currentPage: resultPage, setCurrentPage: updatePage, numberOfAvailablePages: numberOfAvailablePages, sort: sort !== null && sort !== void 0 ? sort : undefined, setSort: setSort, loading: loading, stateFilter: filter, setStateFilter: setFilter, setSearchTerm: setSearchTerm, totalCount: staffMembers.totalCount, updateStaffMember: updateStaffMember, forceUpdate: forceUpdate }));
}
