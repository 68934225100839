import { getTitle } from '@components/generic-forms/generic-form';
import { FieldType } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { localizeString } from '@utils/formatting';
import Combobox from '@components/input/combobox';
import React, { useMemo } from 'react';
import i18n from '../../i18n';
import sortBy from 'lodash.sortby';
export const ComboboxField = ({ fieldName, field, fieldValue, setValue, className, disabled, required, }) => {
    const sortedKeys = useMemo(() => {
        if (field.type !== FieldType.COMBOBOX ||
            (fieldValue && fieldValue.type !== FieldType.COMBOBOX)) {
            return [];
        }
        if (fieldName === 'healthInsurance') {
            const sortedHealthInsurances = sortBy(Object.entries(field.possibleValues).filter(([entry, _localizedString]) => entry !== '-1'), ([_entry, localizedString]) => localizedString[i18n.language]).map((entry) => entry[0]);
            return ['-1', ...sortedHealthInsurances];
        }
        return sortBy(Object.entries(field.possibleValues), ([_entry, localizedString]) => localizedString[i18n.language]).map((entry) => entry[0]);
    }, [field, fieldValue, fieldName]);
    if (field.type !== FieldType.COMBOBOX ||
        (fieldValue && fieldValue.type !== FieldType.COMBOBOX)) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(Combobox, { key: fieldName, value: fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value, displayValue: (value) => localizeString(field.possibleValues[value], i18n.language), options: sortedKeys, title: getTitle(field, required), onChange: (newValue) => {
            if (newValue) {
                setValue(fieldName, field.type, newValue);
            }
        }, classNameContainer: className, disabled: disabled, required: required, runInitialValidation: true }));
};
