var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { LoadingSpinner } from '@components/loading-spinner';
import { classNames } from '@utils/class-names';
export var ButtonSize;
(function (ButtonSize) {
    ButtonSize["SMALL"] = "SMALL";
    ButtonSize["LARGE"] = "LARGE";
})(ButtonSize || (ButtonSize = {}));
export var ButtonType;
(function (ButtonType) {
    ButtonType["PRIMARY"] = "PRIMARY";
    ButtonType["SECONDARY"] = "SECONDARY";
    ButtonType["TERTIARY"] = "TERTIARY";
    ButtonType["DESTRUCTIVE"] = "DESTRUCTIVE";
    ButtonType["TABLE_ACTION"] = "TABLE_ACTION";
    ButtonType["SWITCH"] = "SWITCH";
})(ButtonType || (ButtonType = {}));
const focusClasses = 'focus-visible:outline-p200Green focus-visible:outline focus-visible:outline-2 ';
const enabledClasses = 'enabled:active:shadow-none enabled:active:shadow-inner';
const types = {
    [ButtonType.PRIMARY]: {
        main: 'text-white bg-p200Green shadow-xs border border-transparent border-box ' +
            'hover:!bg-primaryBtnHover hover:!text-white ' +
            'disabled:bg-primaryBtnDisabled ' +
            focusClasses +
            enabledClasses,
        icons: 'h-5',
    },
    [ButtonType.SECONDARY]: {
        main: 'text-n600DarkBlue border border-n300Grey shadow-xs border-box ' +
            'hover:!bg-n300Grey hover:!border-n400GreyCheteau hover:!text-n600DarkBlue ' +
            'disabled:!text-n400GreyCheteau disabled:!border-n200LightGrey ' +
            'focus-visible:shadow-xsFocused focus-visible:outline-none ' +
            enabledClasses +
            ' group',
        icons: 'text-n500DarkGrey group-disabled:text-n400GreyCheteau h-5',
    },
    [ButtonType.TERTIARY]: {
        main: 'text-n500DarkGrey border border-transparent border-box ' +
            'hover:!bg-tertiaryBtnHover hover:text-n500DarkGrey ' +
            'disabled:text-n400GreyCheteau ' +
            'focus-visible:outline-p200Green',
        icons: 'h-5',
    },
    [ButtonType.DESTRUCTIVE]: {
        main: 'text-r100Red border border-r100Red border-box ' +
            'hover:!bg-tertiaryBtnHover ' +
            'disabled:text-r300Error disabled:border-r300Error ' +
            'focus-visible:outline-r300Error',
        icons: 'h-5',
    },
    [ButtonType.SWITCH]: {
        main: 'text-p200Green border border-n400GreyCheteau border-box ' +
            focusClasses +
            'active:!transform-none',
        icons: 'h-5',
    },
    [ButtonType.TABLE_ACTION]: {
        main: 'text-black bg-white shadow-xs border border-n400GreyCheteau border-box ' +
            'hover:!bg-tertiaryBtnHover ' +
            'disabled:bg-primaryBtnDisabled ' +
            focusClasses +
            enabledClasses,
        icons: 'h-5 text-n500DarkGrey',
    },
};
const sizes = {
    [ButtonSize.SMALL]: 'text-base py-2.5 px-[16px]',
    [ButtonSize.LARGE]: 'text-lg py-[13px] px-[22px]',
};
export function Button(_a) {
    var { label, IconLeading, IconTrailing, buttonType, buttonSize, disabled = false, loading = false, iconClassName = '', testId, alignContentStart = false, type = 'button' } = _a, rest = __rest(_a, ["label", "IconLeading", "IconTrailing", "buttonType", "buttonSize", "disabled", "loading", "iconClassName", "testId", "alignContentStart", "type"]);
    return (React.createElement(React.Fragment, null,
        React.createElement("button", Object.assign({}, rest, { type: type, className: classNames('inline-flex relative items-center gap-x-1.5 rounded-lg text-sm font-bold', !loading && 'enabled:active:translate-y-0.5', 'disabled:cursor-not-allowed', loading && 'cursor-default', types[buttonType].main, sizes[buttonSize], rest.className), disabled: disabled, onClick: disabled || loading ? undefined : rest.onClick }, (testId ? { 'data-testid': testId } : {})),
            loading && (React.createElement(LoadingSpinner, { className: "h-5 absolute inset-x-0 inset-y-auto" })),
            React.createElement("div", { className: classNames('w-full inline-flex relative items-center gap-x-1.5', alignContentStart
                    ? 'justify-start'
                    : 'justify-center', loading && 'invisible') },
                React.createElement(React.Fragment, null,
                    IconLeading && (React.createElement(IconLeading, { className: classNames('flex-shrink-0', types[buttonType].icons, iconClassName) })),
                    label,
                    IconTrailing && (React.createElement(IconTrailing, { className: classNames('flex-shrink-0', types[buttonType].icons, iconClassName) })))))));
}
