import { CompanyContext } from '../context/company-context';
import { FieldType, } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { LoadingSpinner } from '@components/loading-spinner';
import { PayrollProviderContext } from '../context/payrollprovider-context';
import { TabsContext } from '@components/tabs';
import { filterAndSortGenericFieldNames } from '@utils/generic-fields';
import { getTitle } from '@components/generic-summary-card';
import { localizeString } from '@utils/formatting';
import { useTranslation } from 'react-i18next';
import React, { useContext, useMemo } from 'react';
import dayjs from '@lohndialog/shared/src/util/dayjs';
import i18n from '../i18n';
export default function GenericSummaryTab({ schema, wizardCluster, entity, }) {
    const { expanded } = useContext(TabsContext);
    const company = useContext(CompanyContext);
    const payrollProvider = useContext(PayrollProviderContext);
    const sortedFields = useMemo(() => filterAndSortGenericFieldNames(schema !== null && schema !== void 0 ? schema : { fields: {} }, wizardCluster, company, payrollProvider, !expanded), [schema, wizardCluster, expanded, company, payrollProvider]);
    if (!schema || !entity) {
        return React.createElement(LoadingSpinner, null);
    }
    return (React.createElement("div", { className: "grid grid-cols-[repeat(auto-fit,_minmax(100px,_max-content))] gap-4" }, sortedFields.map((fieldName) => {
        return (React.createElement("div", { key: fieldName, className: "flex flex-col justify-between w-max" },
            React.createElement("div", { className: "text-xs text-n500DarkGrey font-medium leading-4 mb-1.5" }, getTitle(schema.fields[fieldName])),
            React.createElement("div", { className: "text-sm font-inter text-black font-medium leading-4" },
                React.createElement(Value, { fieldName: fieldName, field: schema.fields[fieldName], entity: entity }))));
    })));
}
export const Value = ({ fieldName, field, entity, }) => {
    const { t } = useTranslation('common');
    const genericFieldValue = entity.genericFields[fieldName];
    const getLocalizedString = (key) => {
        var _a;
        const translation = (_a = field.customTranslations) === null || _a === void 0 ? void 0 : _a[key][i18n.language];
        return translation !== null && translation !== void 0 ? translation : t(key);
    };
    const renderChildValues = (subfieldKeys) => {
        if (field.type !== FieldType.CHILD) {
            return null;
        }
        return subfieldKeys.map((key) => (React.createElement("div", { key: key },
            React.createElement("div", { className: "text-xs text-n500DarkGrey font-medium leading-4 mb-1.5" }, t(key)),
            (genericFieldValue === null || genericFieldValue === void 0 ? void 0 : genericFieldValue.value)[key])));
    };
    switch (genericFieldValue === null || genericFieldValue === void 0 ? void 0 : genericFieldValue.type) {
        case FieldType.TEXT:
        case FieldType.EMAIL:
        case FieldType.NUMBER:
        case FieldType.URL:
        case FieldType.TEL:
            return React.createElement(React.Fragment, null, genericFieldValue.value);
        case FieldType.DATE:
            return (React.createElement(React.Fragment, null, dayjs(genericFieldValue.value).format(t('dateFormat'))));
        case FieldType.CHECKBOX:
            if (field.type !== FieldType.CHECKBOX) {
                return React.createElement(React.Fragment, null, "-");
            }
            return (React.createElement(React.Fragment, null, getLocalizedString(genericFieldValue.value ? 'yes' : 'no')));
        case FieldType.COMBOBOX:
        case FieldType.DROPDOWN:
            if (field.type !== FieldType.COMBOBOX &&
                field.type !== FieldType.DROPDOWN) {
                return React.createElement(React.Fragment, null, "-");
            }
            return (React.createElement(React.Fragment, null, localizeString(field.possibleValues[genericFieldValue.value], i18n.language, '')));
        case FieldType.CHILD:
            if (field.type !== FieldType.CHILD) {
                return React.createElement(React.Fragment, null, "-");
            }
            return (React.createElement("div", null, renderChildValues([
                'firstname',
                'divertingLastName',
                'birthDate',
                'proofDate',
            ])));
        default:
            return React.createElement(React.Fragment, null, "-");
    }
};
export const containsCollapsibleFields = (schema, ...clusters) => {
    if (!schema) {
        return false;
    }
    for (const value of Object.values(schema.fields)) {
        if (!clusters.includes(value.wizardCluster)) {
            continue;
        }
        if (!value.visibleByDefault) {
            return true;
        }
    }
    return false;
};
