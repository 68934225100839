import { PayrollProviderStaffState } from '@lohndialog/shared/src/constants';
import { classNames } from '@utils/class-names';
import { useTranslation } from 'react-i18next';
import React from 'react';
export default function PpEmployeeFilterPanel({ filter, setFilter, className, }) {
    const { t } = useTranslation('statusBadge');
    return (React.createElement("div", { className: classNames('flex flex-row items-start max-w-full overflow-hidden', className) }, ['all', ...Object.values(PayrollProviderStaffState)].map((value) => (React.createElement("button", { className: classNames('px-1 sm:px-4 py-2.5 first:rounded-l-lg last:rounded-r-lg border border-n300Grey font-medium text-xs xs:text-sm flex-shrink flex-grow overflow-hidden text-ellipsis whitespace-nowrap', filter === value
            ? 'bg-backgroundGreen'
            : 'bg-white'), onClick: () => setFilter(value), key: value, "data-testid": `filter-button-${value}` }, t(value.toLowerCase()))))));
}
