import { Button, ButtonSize, ButtonType } from '@components/button';
import { ReactComponent as CheckIcon } from '@public/icons/check.svg';
import { EMPLOYEE_PAGE_SIZE } from '@utils/constants';
import { GenericTable } from '@components/table/generic-table';
import { InviteResultEnum } from '@lohndialog/shared/src/constants';
import { NameCell } from '@components/employees/employee-table';
import { ReactComponent as XCircleIcon } from '@public/icons/x-circle.svg';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import nestClient from '@api/nest-client';
export default function BulkUpdateSlideIn({ employeeIds, schema, closeSlideIn, }) {
    const { t } = useTranslation('bulkInvite');
    const [shownEmployees, setShownEmployees] = useState([]);
    const [page, setPage] = useState(0);
    const [totalResults, setTotalResults] = useState(0);
    const [numberOfAvailablePages, setNumberOfAvailablePages] = useState(0);
    const [selectedColumns, setSelectedColumns] = useState([
        'name',
        'privateEmail',
    ]);
    const [sort, setSort] = useState(null);
    const [loading, setLoading] = useState(false);
    const [inviteResults, setInviteResults] = useState();
    const [showingInviteResults, setShowingInviteResults] = useState(false);
    useEffect(() => {
        const getData = async () => {
            const result = await nestClient.getPaginatedUserLessEmployees(employeeIds, page, EMPLOYEE_PAGE_SIZE, sort !== null && sort !== void 0 ? sort : undefined);
            setShownEmployees(result.data);
            setTotalResults(result.totalCount);
            setNumberOfAvailablePages(Math.ceil(result.totalCount / EMPLOYEE_PAGE_SIZE));
        };
        void getData();
    }, [employeeIds, page, sort]);
    const ResultCell = useCallback(({ rowData: employee }) => {
        if (!showingInviteResults || !inviteResults) {
            return null;
        }
        if (inviteResults.get(employee.id)) {
            return (React.createElement("span", { className: "text-r600Error", "data-testid": `error-result-${employee.id}` },
                React.createElement(XCircleIcon, { className: "h-5 w-5" })));
        }
        return (React.createElement("span", { className: "text-p100Green", "data-testid": `success-result-${employee.id}` },
            React.createElement(CheckIcon, { className: "h-5 w-5" })));
    }, [inviteResults, showingInviteResults]);
    const EnhancedNameCell = useCallback(({ rowData: employee }) => {
        let errorMessage;
        if (showingInviteResults) {
            const possibleError = inviteResults === null || inviteResults === void 0 ? void 0 : inviteResults.get(employee.id);
            if (possibleError) {
                if (possibleError.resultType === InviteResultEnum.NO_EMAIL) {
                    errorMessage = t('noEmail');
                }
                else if (possibleError.resultType ===
                    InviteResultEnum.INTERNAL_ERROR &&
                    possibleError.errorMessage) {
                    errorMessage = possibleError.errorMessage;
                }
            }
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(NameCell, { rowData: employee }),
            errorMessage && (React.createElement("p", { className: "text-r600Error" }, errorMessage))));
    }, [inviteResults, showingInviteResults, t]);
    const columnOverrides = useMemo(() => ({
        name: {
            headContent: t('name'),
            CellContent: EnhancedNameCell,
            sortable: true,
            identifier: 'name',
        },
        result: {
            headContent: t('result'),
            CellContent: ResultCell,
            sortable: true,
            identifier: 'result',
        },
    }), [EnhancedNameCell, ResultCell, t]);
    const handleInvite = useCallback(() => {
        setLoading(true);
        const sendOutInvites = async () => {
            const errorMap = new Map();
            await Promise.all(employeeIds.map(async (id) => {
                const result = await nestClient.sendBulkEmployeeInvite(id);
                if (result.resultType !== InviteResultEnum.SUCCESS) {
                    errorMap.set(id, result);
                }
            }));
            setInviteResults(errorMap);
            setShowingInviteResults(true);
            if (!selectedColumns.includes('result')) {
                setSelectedColumns([...selectedColumns, 'result']);
            }
            setLoading(false);
        };
        void sendOutInvites();
    }, [employeeIds, selectedColumns]);
    return (React.createElement("div", { className: "flex flex-col h-full" },
        React.createElement("div", { className: "grow" },
            React.createElement(GenericTable, { currentPage: page, numberOfAvailablePages: numberOfAvailablePages, setCurrentPage: setPage, getId: (employee) => employee.id.toString(), data: shownEmployees, columnOverrides: columnOverrides, selectedColumns: selectedColumns, setSelectedColumns: setSelectedColumns, schema: schema, totalResults: totalResults, className: "mt-8", hideResultCounter: true, setSort: setSort, sort: sort, loading: loading })),
        React.createElement("div", { className: "sticky bg-white py-2 px-6 bottom-0 text-right" }, showingInviteResults ? (React.createElement(Button, { label: t('continue'), testId: "continueBtn", buttonType: ButtonType.PRIMARY, buttonSize: ButtonSize.LARGE, className: "w-1/2", loading: loading, onClick: closeSlideIn })) : (React.createElement(Button, { label: t('inviteBtn'), testId: "inviteBtn", buttonType: ButtonType.PRIMARY, buttonSize: ButtonSize.LARGE, className: "w-1/2", loading: loading, onClick: handleInvite })))));
}
