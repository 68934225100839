var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { FieldType, } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { localizeString } from '@utils/formatting';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import SortingPaginatedTable from '@components/table/sorting-paginated-table';
import dayjs from '@lohndialog/shared/src/util/dayjs';
import i18n from '../../i18n';
export const GenericTable = (_a) => {
    var { selectedColumns, columnOverrides, schema } = _a, rest = __rest(_a, ["selectedColumns", "columnOverrides", "schema"]);
    const { t } = useTranslation('table');
    const columns = useMemo(() => {
        const getGenericColumn = (identifier) => {
            const field = schema === null || schema === void 0 ? void 0 : schema.fields[identifier];
            if (!field) {
                return null;
            }
            const Cell = ({ rowData }) => renderCell(field, rowData.genericFields[identifier], t);
            return {
                identifier,
                sortable: true,
                headContent: localizeString(field.label, i18n.language),
                CellContent: Cell,
            };
        };
        return [
            ...selectedColumns
                .map((identifier) => {
                const genericColumn = getGenericColumn(identifier);
                const override = columnOverrides[identifier];
                if (override && !override.partial) {
                    return override;
                }
                if (!genericColumn) {
                    // no generic column and no (partial) override.
                    // This may happen while the schema is still loading
                    return null;
                }
                return Object.assign(Object.assign({}, genericColumn), override);
            })
                .filter(Boolean),
        ];
    }, [schema, selectedColumns, columnOverrides, t]);
    if (rest.selectedItems && rest.setSelectedItems && rest.toggleAll) {
        return (React.createElement(SortingPaginatedTable, Object.assign({}, rest, { selectedItems: rest.selectedItems, setSelectedItems: rest.setSelectedItems, toggleAll: rest.toggleAll, selectedActions: rest.selectedActions, columns: columns, altLayout: rest.altLayout })));
    }
    else {
        const { selectedItems: _selectedItems, setSelectedItems: _setSelectedItems, toggleAll: _toggleAll, selectedActions: _selectedActions } = rest, nonSelectableProps = __rest(rest, ["selectedItems", "setSelectedItems", "toggleAll", "selectedActions"]);
        return (React.createElement(SortingPaginatedTable, Object.assign({}, nonSelectableProps, { columns: columns, altLayout: rest.altLayout })));
    }
};
const renderCell = (fieldSpecs, fieldValue, t) => {
    if ((fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value) === undefined) {
        return '';
    }
    switch (fieldValue.type) {
        case FieldType.DROPDOWN:
        case FieldType.COMBOBOX:
            if (fieldSpecs.type !== FieldType.DROPDOWN &&
                fieldSpecs.type !== FieldType.COMBOBOX) {
                /* istanbul ignore next */
                return ''; // invalid
            }
            return localizeString(fieldSpecs.possibleValues[fieldValue.value], i18n.language);
        case FieldType.CHECKBOX:
            return fieldValue.value ? t('yes') : t('no');
        case FieldType.DATE:
            return dayjs(fieldValue.value).format(t('dateFormat'));
        case FieldType.DATETIME:
            return dayjs(fieldValue.value).format(t('dateFormat'));
        case FieldType.CHILD:
            return (React.createElement("div", { className: "flex flex-col" },
                React.createElement("p", null, fieldValue.value.firstname),
                React.createElement("p", null, fieldValue.value.divertingLastName),
                React.createElement("p", null, dayjs(fieldValue.value.birthDate).format(t('dateFormat'))),
                React.createElement("p", null, dayjs(fieldValue.value.proofDate).format(t('dateFormat')))));
        case FieldType.TEXT:
        case FieldType.URL:
        case FieldType.TEL:
        case FieldType.EMAIL:
        case FieldType.NUMBER:
        default:
            return fieldValue.value;
    }
};
