var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ReactComponent as ArrowDown } from '@public/icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from '@public/icons/arrow-up.svg';
import { Checkbox } from '@components/input/checkbox';
import { classNames } from '@utils/class-names';
import { useTranslation } from 'react-i18next';
import PaginationPanel from '@components/table/pagination-panel';
import React, { useCallback } from 'react';
export default function SortingPaginatedTable(_a) {
    var { topHeader, currentPage, numberOfAvailablePages, setCurrentPage, sort, setSort, loading, data, columns, getId, totalResults, hideResultCounter = false, className, altLayout = false } = _a, rest = __rest(_a, ["topHeader", "currentPage", "numberOfAvailablePages", "setCurrentPage", "sort", "setSort", "loading", "data", "columns", "getId", "totalResults", "hideResultCounter", "className", "altLayout"]);
    const { t } = useTranslation('table');
    const sortButtonFunction = useCallback((fieldName) => () => {
        if ((sort === null || sort === void 0 ? void 0 : sort.fieldName) === fieldName) {
            if (sort.direction === 'ASC') {
                setSort(null);
            }
            else {
                setSort({ fieldName, direction: 'ASC' });
            }
        }
        else {
            setSort({ fieldName, direction: 'DESC' });
        }
    }, [sort, setSort]);
    const getSortArrow = (fieldName) => {
        if ((sort === null || sort === void 0 ? void 0 : sort.fieldName) === fieldName) {
            if (sort.direction === 'DESC') {
                return React.createElement(ArrowDown, { className: "h-4 w-4 ml-2" });
            }
            else {
                return React.createElement(ArrowUp, { className: "h-4 w-4 ml-2" });
            }
        }
        else {
            return undefined;
        }
    };
    const isPaginated = currentPage !== undefined &&
        numberOfAvailablePages !== undefined &&
        setCurrentPage !== undefined;
    const rowIsSelected = useCallback((rowData) => {
        var _a;
        return !!((_a = rest.selectedItems) === null || _a === void 0 ? void 0 : _a.find((data) => JSON.stringify(data) === JSON.stringify(rowData)));
    }, [rest.selectedItems]);
    return (React.createElement("div", { className: classNames('w-full overflow-x-auto drop-shadow-table', className, altLayout && '!drop-shadow-none') },
        React.createElement("div", { className: classNames('inline-block min-w-full align-middle bg-white rounded-2xl overflow-hidden', altLayout && '!rounded-t-none') },
            React.createElement("div", null,
                React.createElement("div", null, topHeader ? (React.createElement("div", { className: "pt-4 px-6" }, topHeader)) : (React.createElement(React.Fragment, null))),
                !hideResultCounter &&
                    (!rest.selectedItems ||
                        rest.selectedItems.length === 0) && (React.createElement("div", { className: classNames('py-4 px-6', rest.selectedItems &&
                        rest.selectedItems.length > 0 &&
                        'bg-p50LightGreen') },
                    React.createElement("p", { className: "font-bold" }, t('totalResults', {
                        total: totalResults,
                    }))))),
            rest.selectedItems && rest.selectedItems.length > 0 && (React.createElement("div", { className: 'py-4 px-6 bg-p50LightGreen flex justify-between items-center mt-1' },
                React.createElement("p", { className: "font-bold" }, t('selected', {
                    selected: rest.selectedItems.length,
                    total: totalResults,
                })),
                rest.selectedActions)),
            React.createElement("div", { className: "relative" },
                React.createElement("table", { className: "min-w-full sm:border-spacing-6 lg:border-spacing-8" },
                    React.createElement("thead", { className: "bg-n100Whisper" },
                        React.createElement("tr", null,
                            rest.selectedItems && (React.createElement("th", { scope: "col", className: "items-center justify-center pl-4 sm:w-12" },
                                React.createElement(Checkbox, { type: "checkbox", "data-testid": "selectAllRowsCheckbox", checked: rest.selectedItems.length ===
                                        totalResults, onChange: rest.toggleAll, indeterminate: rest.selectedItems.length > 0 &&
                                        rest.selectedItems.length !==
                                            totalResults }))),
                            columns.map((column, index) => {
                                return (React.createElement("th", { key: column.identifier, scope: "col", className: classNames('px-3 py-3.5 text-left text-sm font-medium text-n500DarkGrey', column.headClassName, !rest.selectedItems &&
                                        index === 0 &&
                                        'pl-6') },
                                    React.createElement("button", { className: "flex items-center", onClick: column.sortable
                                            ? sortButtonFunction(column.identifier)
                                            : undefined, "data-testid": `sort-${column.identifier}-button` },
                                        column.headContent,
                                        column.sortable &&
                                            getSortArrow(column.identifier))));
                            }))),
                    React.createElement("tbody", { className: "bg-white text-n500DarkGrey" }, data.map((rowData, index) => {
                        var _a;
                        const id = getId(rowData, index);
                        return (React.createElement("tr", { key: id, className: ((_a = rest.selectedItems) === null || _a === void 0 ? void 0 : _a.includes(rowData))
                                ? 'bg-gray-50'
                                : undefined, "data-testid": `table-row-${id}` },
                            rest.selectedItems && (React.createElement("td", { className: "text-center align-middle pl-4 sm:w-12 h-full" },
                                React.createElement(Checkbox, { "data-testid": "selectRowCheckbox", value: id, checked: rowIsSelected(rowData), onChange: (e) => rest.setSelectedItems(e.target.checked
                                        ? [
                                            ...rest.selectedItems,
                                            rowData,
                                        ]
                                        : rest.selectedItems.filter((p) => JSON.stringify(p) !==
                                            JSON.stringify(rowData))) }))),
                            columns.map((col) => {
                                return (React.createElement("td", { key: `${id}-${col.identifier}`, className: classNames('py-4 px-3 text-sm font-medium table-cell align-middle', !rest.selectedItems &&
                                        'pl-6', rowIsSelected(rowData)
                                        ? '!text-p100Green'
                                        : 'text-n600DarkBlue', col.cellClassName) },
                                    React.createElement(col.CellContent, { rowData: rowData })));
                            })));
                    }))),
                isPaginated && (React.createElement(PaginationPanel, { currentPage: currentPage + 1, numberOfAvailablePages: numberOfAvailablePages, setNewPage: (newPage) => setCurrentPage(newPage - 1), loading: loading }))))));
}
