import { Auth0Provider, useAuth0, } from '@auth0/auth0-react';
import { Environment } from '../environment.global';
import { HeaderHandler } from '@api/header-handler';
import { parseRedirectUri } from '@utils/auth-utils';
import { useDummyAuth } from '@hooks/useDummyAuth';
// eslint-disable-next-line no-restricted-imports
import { useNavigate } from 'react-router-dom';
import React, { createContext, useCallback, useContext, useEffect, useMemo, } from 'react';
import nestClient from '@api/nest-client';
export const AuthStateContext = createContext(null);
const useAuthInternal = () => {
    const { isAuthenticated: dummyIsAuthenticated, user: dummyUser, login: dummyLogin, logout: dummyLogout, getAccessTokenSilently: dummyGetToken, isLoading: dummyIsLoading, } = useDummyAuth();
    const { isAuthenticated: auth0IsAuthenticated, user: auth0User, loginWithRedirect: auth0LoginWithRedirect, logout: auth0Logout, getAccessTokenSilently: auth0GetToken, isLoading: auth0IsLoading, } = useAuth0();
    return Environment.REACT_APP_ENABLE_AUTH0
        ? {
            isAuthenticated: auth0IsAuthenticated,
            user: auth0User,
            loginWithRedirect: auth0LoginWithRedirect,
            logout: auth0Logout,
            getAccessTokenSilently: auth0GetToken,
            isLoading: auth0IsLoading,
        }
        : {
            isAuthenticated: dummyIsAuthenticated,
            user: dummyUser,
            loginWithRedirect: dummyLogin,
            logout: dummyLogout,
            getAccessTokenSilently: dummyGetToken,
            isLoading: dummyIsLoading,
        };
};
const isAuth0Error = (e) => {
    return typeof (e === null || e === void 0 ? void 0 : e.error) === 'string';
};
export const AuthProviderInternal = ({ children, }) => {
    const { isAuthenticated, user, loginWithRedirect, logout, getAccessTokenSilently, isLoading, } = useAuthInternal();
    useEffect(() => {
        nestClient.authHandler = new HeaderHandler(async () => {
            try {
                return {
                    Authorization: `Bearer ${await getAccessTokenSilently({
                        authorizationParams: {
                            audience: Environment.REACT_APP_AUTH0_API_AUDIENCE,
                        },
                        cacheMode: 'on',
                    })}`,
                };
            }
            catch (e) {
                if (isAuth0Error(e)) {
                    if (e.error === 'login_required') {
                        void loginWithRedirect();
                    }
                    if (e.error === 'consent_required') {
                        void loginWithRedirect();
                    }
                }
                throw e;
            }
        });
    }, [isAuthenticated, user === null || user === void 0 ? void 0 : user.sub, getAccessTokenSilently, loginWithRedirect]);
    const login = useCallback((options = {}) => {
        var _a;
        var _b;
        options.authorizationParams
            ? ((_a = (_b = options.authorizationParams).redirect_uri) !== null && _a !== void 0 ? _a : (_b.redirect_uri = Environment.PUBLIC_URL))
            : (options.authorizationParams = {
                redirect_uri: Environment.PUBLIC_URL,
            });
        return loginWithRedirect(options);
    }, [loginWithRedirect]);
    const value = useMemo(() => ({
        isAuthenticated,
        user,
        login,
        logout,
        isLoading,
    }), [isAuthenticated, user, login, logout, isLoading]);
    return (React.createElement(AuthStateContext.Provider, { value: value }, children));
};
export function useAuth() {
    const state = useContext(AuthStateContext);
    if (!state) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return state;
}
export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    if (Environment.REACT_APP_ENABLE_AUTH0) {
        if (Environment.REACT_APP_AUTH0_CLIENT_ID &&
            Environment.REACT_APP_AUTH0_DOMAIN) {
            return (React.createElement(Auth0Provider, { clientId: Environment.REACT_APP_AUTH0_CLIENT_ID, domain: Environment.REACT_APP_AUTH0_DOMAIN, authorizationParams: {
                    audience: Environment.REACT_APP_AUTH0_API_AUDIENCE,
                }, useRefreshTokens: true, useRefreshTokensFallback: true, cacheLocation: 'localstorage', onRedirectCallback: () => {
                    const returnTo = parseRedirectUri();
                    if (returnTo) {
                        navigate(returnTo);
                    }
                } },
                React.createElement(AuthProviderInternal, null, children)));
        }
        else {
            throw new Error('Missing auth0 config');
        }
    }
    else {
        return React.createElement(AuthProviderInternal, null, children);
    }
};
