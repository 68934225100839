import { DateInput } from '@components/input/date-input';
import { getTitle } from '@components/generic-forms/generic-form';
import { FieldType } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { InfoText } from '@components/info-text';
import { TextInput } from '@components/input/text-input';
import { classNames } from '@utils/class-names';
import { localizeString } from '@utils/formatting';
import { useTranslation } from 'react-i18next';
import React from 'react';
import i18n from '../../i18n';
export const ChildField = ({ fieldName, field, fieldValue, setValue, className, disabled, required, shouldValidate, }) => {
    var _a, _b;
    const { t } = useTranslation('nestedFields');
    if (field.type !== FieldType.CHILD ||
        (fieldValue && fieldValue.type !== FieldType.CHILD)) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement("div", { className: "col-span-6: md:col-span-12" },
        React.createElement("label", { className: classNames('w-full text-sm font-medium inline-block leading-tight', disabled ? 'text-n500DarkGrey' : 'text-n600DarkBlue') }, getTitle(field, required)),
        React.createElement("div", { className: "grid grid-cols-6 lg:grid-cols-12 gap-x-8 gap-y-3 mt-3 items-end" },
            React.createElement("div", { className: className },
                field.infoTextFirstName && (React.createElement("div", { className: "w-full flex flex-row justify-end items-end" },
                    React.createElement(InfoText, { text: localizeString(field.infoTextFirstName, i18n.language) }))),
                React.createElement(TextInput, { key: `${fieldName}-firstname`, value: (_a = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value.firstname) !== null && _a !== void 0 ? _a : '', title: t('firstname'), onChange: (newValue) => {
                        var _a;
                        return setValue(fieldName, field.type, {
                            firstname: newValue,
                            divertingLastName: (_a = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value.divertingLastName) !== null && _a !== void 0 ? _a : '',
                            birthDate: fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value.birthDate,
                            proofDate: fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value.proofDate,
                        });
                    }, disabled: disabled, required: required, inputTestId: `textInput-${fieldName}-firstname`, shouldValidate: shouldValidate, runInitialValidation: true })),
            React.createElement("div", { className: className },
                field.infoTextLastName && (React.createElement("div", { className: "w-full flex flex-row justify-end items-end" },
                    React.createElement(InfoText, { text: localizeString(field.infoTextLastName, i18n.language) }))),
                React.createElement(TextInput, { key: `${fieldName}-divertingLastName`, value: (_b = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value.divertingLastName) !== null && _b !== void 0 ? _b : '', title: t('divertingLastName'), onChange: (newValue) => {
                        var _a;
                        return setValue(fieldName, field.type, {
                            firstname: (_a = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value.firstname) !== null && _a !== void 0 ? _a : '',
                            divertingLastName: newValue,
                            birthDate: fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value.birthDate,
                            proofDate: fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value.proofDate,
                        });
                    }, disabled: disabled, required: required, inputTestId: `textInput-${fieldName}-divertingLastName`, shouldValidate: shouldValidate, runInitialValidation: true })),
            React.createElement("div", { className: className },
                field.infoTextBirthDate && (React.createElement("div", { className: "w-full flex flex-row justify-end items-end" },
                    React.createElement(InfoText, { text: localizeString(field.infoTextBirthDate, i18n.language) }))),
                React.createElement(DateInput, { key: `${fieldName}-birthdate`, value: (fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value.birthDate)
                        ? fieldValue.value.birthDate
                        : undefined, title: t('birthDate'), onChange: (newValue) => {
                        var _a, _b, _c;
                        return setValue(fieldName, field.type, {
                            firstname: (_a = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value.firstname) !== null && _a !== void 0 ? _a : '',
                            divertingLastName: (_b = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value.divertingLastName) !== null && _b !== void 0 ? _b : '',
                            birthDate: newValue,
                            proofDate: (_c = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value.proofDate) !== null && _c !== void 0 ? _c : '',
                        });
                    }, disabled: disabled, required: required, inputTestId: `textInput-${fieldName}-birthdate`, shouldValidate: shouldValidate, runInitialValidation: true })),
            React.createElement("div", { className: className },
                field.infoTextProofDate && (React.createElement("div", { className: "w-full flex flex-row justify-end items-end" },
                    React.createElement(InfoText, { text: localizeString(field.infoTextProofDate, i18n.language) }))),
                React.createElement(DateInput, { key: `${fieldName}-proofDate`, value: fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value.proofDate, title: t('proofDate'), onChange: (newValue) => {
                        var _a, _b, _c;
                        return setValue(fieldName, field.type, {
                            firstname: (_a = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value.firstname) !== null && _a !== void 0 ? _a : '',
                            divertingLastName: (_b = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value.divertingLastName) !== null && _b !== void 0 ? _b : '',
                            birthDate: (_c = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value.birthDate) !== null && _c !== void 0 ? _c : '',
                            proofDate: newValue,
                        });
                    }, disabled: disabled, required: required, inputTestId: `textInput-${fieldName}-proofDate`, shouldValidate: shouldValidate, runInitialValidation: true })))));
};
