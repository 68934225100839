import { Button, ButtonSize, ButtonType } from '@components/button';
// eslint-disable-next-line no-restricted-imports
import { useNavigate, useRouteError } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Error from '@components/errors/error';
import React from 'react';
export default function ErrorView() {
    const error = useRouteError();
    const navigate = useNavigate();
    const { t } = useTranslation('errors');
    return (React.createElement(Error, { error: error },
        React.createElement(Button, { label: t('goBack'), buttonType: ButtonType.PRIMARY, buttonSize: ButtonSize.LARGE, onClick: () => navigate(-1) })));
}
