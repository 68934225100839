import { Button, ButtonSize, ButtonType } from '@components/button';
import { getRedirectUri } from '@utils/auth-utils';
import { useAuth } from '../../context/auth-context';
import { useTranslation } from 'react-i18next';
import React from 'react';
export function UnauthenticatedInvitationView() {
    const { login } = useAuth();
    const { t } = useTranslation('invitation');
    return (React.createElement(React.Fragment, null,
        React.createElement("p", { className: "mb-5" }, t('notLoggedIn')),
        React.createElement("div", { className: "flex flex-row space-x-2" },
            React.createElement(Button, { buttonSize: ButtonSize.LARGE, buttonType: ButtonType.PRIMARY, label: t('loginButton'), "data-testid": "loginBtn", onClick: () => {
                    (async () => {
                        await login({
                            authorizationParams: {
                                redirect_uri: getRedirectUri(),
                            },
                        });
                    })();
                } }),
            React.createElement(Button, { buttonSize: ButtonSize.LARGE, buttonType: ButtonType.PRIMARY, label: t('registerButton'), "data-testid": "registerBtn", onClick: () => {
                    (async () => {
                        await login({
                            authorizationParams: {
                                screen_hint: 'signup',
                                redirect_uri: getRedirectUri(),
                            },
                        });
                    })();
                } }))));
}
