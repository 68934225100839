import { useEffect, useState } from 'react';
export const usePagination = (data, itemsPerPage) => {
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [paginatedData, setPaginatedData] = useState(data.slice(0, itemsPerPage));
    useEffect(() => setPaginatedData(data.slice(itemsPerPage * currentPageIndex, itemsPerPage * currentPageIndex + itemsPerPage)), [currentPageIndex, data, itemsPerPage]);
    return {
        currentPageIndex,
        paginatedData,
        setCurrentPageIndex,
        numberOfPages: Math.ceil(data.length / itemsPerPage),
    };
};
