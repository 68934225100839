import { Button, ButtonSize, ButtonType } from '@components/button';
import { GenericTable } from '@components/table/generic-table';
import { ReactComponent as DownloadIcon } from '@public/icons/download.svg';
import { ReactComponent as FolderIcon } from '@public/icons/folder.svg';
import { LoadingSpinner } from '@components/loading-spinner';
import { useTranslation } from 'react-i18next';
import FileSaver from 'file-saver';
import React, { useCallback, useMemo, useState } from 'react';
import dayjs from '@lohndialog/shared/src/util/dayjs';
import nestClient from '@api/nest-client';
const getFolderName = (document, t) => {
    if (document.accountingPeriodMonth && document.accountingPeriodYear) {
        return `${t('payrollDocuments')} ${dayjs()
            .set('month', document.accountingPeriodMonth - 1)
            .set('year', document.accountingPeriodYear)
            .format('MM.YYYY')}`;
    }
    if (document.accountingPeriodYear) {
        return `${t('yearlyDocuments')} ${document.accountingPeriodYear}`;
    }
    return t('noTimeFrame');
};
export default function DocumentGroupTable({ documents, documentSchema, currentPage, numberOfAvailablePages, setCurrentPage, sort, setSort, loading, totalCount, setForceUpdateCounter, forceUpdateCounter, setSearchParams, }) {
    const { t } = useTranslation('documentList');
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([
        'name',
        'type',
        'timeframe',
        'lastChanged',
        'download',
    ]);
    const triggerReload = useCallback(() => {
        setForceUpdateCounter(++forceUpdateCounter);
    }, [forceUpdateCounter, setForceUpdateCounter]);
    const bulkDownloadDocuments = useCallback(async () => {
        const idArray = selectedItems.map((item) => item.documentIds).flat(1);
        try {
            const file = await nestClient.downloadDocuments(idArray, t('documents'), true);
            FileSaver.saveAs(file);
            setSelectedItems([]);
        }
        finally {
            triggerReload();
        }
    }, [selectedItems, t, triggerReload]);
    const TypeCell = useCallback(() => {
        return React.createElement("div", { className: "text-n500DarkGrey" }, t('folder'));
    }, [t]);
    const TimeframeCell = useCallback(({ rowData: document }) => {
        return (React.createElement("div", { className: "text-n500DarkGrey" }, getFolderName(document, t)));
    }, [t]);
    const FolderNameCell = useCallback(({ rowData: document }) => {
        return (React.createElement("div", { className: 'group flex items-center rounded-full px-5 py-3 text-sm font-medium pointer-events-auto cursor-pointer', onClick: () => {
                setSearchParams({
                    year: document.accountingPeriodYear,
                    month: document.accountingPeriodMonth,
                    name: getFolderName(document, t),
                });
                setForceUpdateCounter((old) => old + 1);
            } },
            React.createElement("div", { className: "bg-p50LightGreen w-[28px] h-[28px] rounded-full text-p100Green flex justify-center items-center shrink-0" },
                React.createElement(FolderIcon, { className: "w-4 h-4" })),
            React.createElement("div", { className: "ml-1 select-none" }, getFolderName(document, t))));
    }, [setForceUpdateCounter, setSearchParams, t]);
    const columnOverrides = useMemo(() => {
        const LastChangedCell = ({ rowData: document, }) => {
            return (React.createElement("div", { className: "text-n500DarkGrey" }, new Date(document.mostRecentUpdatedDocument).toLocaleString('de-DE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            })));
        };
        return {
            name: {
                identifier: 'name',
                sortable: true,
                headContent: t('folderName'),
                CellContent: FolderNameCell,
            },
            type: {
                identifier: 'type',
                sortable: false,
                headContent: t('folderType'),
                CellContent: TypeCell,
            },
            timeframe: {
                identifier: 'timeframe',
                sortable: true,
                headContent: t('timeframe'),
                CellContent: TimeframeCell,
            },
            lastChanged: {
                identifier: 'mostRecentUpdatedDocument',
                sortable: true,
                headContent: t('lastChanged'),
                CellContent: LastChangedCell,
            },
            download: {
                identifier: 'download',
                sortable: false,
                headContent: (React.createElement("span", { className: "sr-only" }, t('download'))),
                CellContent: DownloadCell,
            },
        };
    }, [t, FolderNameCell, TypeCell, TimeframeCell]);
    return (React.createElement(GenericTable, { currentPage: currentPage, numberOfAvailablePages: numberOfAvailablePages, setCurrentPage: setCurrentPage, sort: sort, setSort: setSort, loading: loading, getId: (document) => getFolderName(document, t), data: documents, columnOverrides: columnOverrides, selectedColumns: selectedColumns, setSelectedColumns: setSelectedColumns, schema: documentSchema, totalResults: totalCount, selectedItems: selectedItems, setSelectedItems: setSelectedItems, toggleAll: () => setSelectedItems((prevState) => {
            const entries = prevState.map((entry) => getFolderName(entry, t));
            const currentNonSelectedItems = documents.filter((document) => !entries.includes(getFolderName(document, t)));
            if (currentNonSelectedItems.length === 0) {
                return [];
            }
            else {
                return [...prevState, ...currentNonSelectedItems];
            }
        }), selectedActions: React.createElement(Button, { buttonType: ButtonType.TABLE_ACTION, buttonSize: ButtonSize.SMALL, label: t('download'), iconClassName: "w-4 h-4", IconLeading: DownloadIcon, onClick: () => {
                void bulkDownloadDocuments();
            }, testId: "bulk-download-button" }), className: "rounded-t-none", altLayout: true }));
}
const DownloadCell = ({ rowData: folder, }) => {
    const { t } = useTranslation('documentList');
    const [downloading, setDownloading] = useState(false);
    const downloadFiles = useCallback(async (documentIds, documentName) => {
        setDownloading(true);
        try {
            const file = await nestClient.downloadDocuments(documentIds, documentName, true);
            FileSaver.saveAs(file);
        }
        finally {
            setDownloading(false);
        }
    }, []);
    const handleClick = () => {
        downloadFiles(folder.documentIds, getFolderName(folder, t)).catch((e) => {
            throw e;
        });
    };
    if (downloading) {
        return (React.createElement(LoadingSpinner, { className: "w-[28px] h-[28px] text-p100Green ml-0" }));
    }
    return (React.createElement("button", { className: "bg-p50LightGreen w-[28px] h-[28px] rounded-full text-p100Green flex justify-center items-center", onClick: handleClick, "data-testid": `download-folder-${getFolderName(folder, t)}-button` },
        React.createElement(DownloadIcon, { className: "w-4 h-4" })));
};
