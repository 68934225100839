import { Notification } from '@components/notifications/notification';
import { NotificationContext, useProfileNotification, } from '../../context/notification-context';
import React, { useContext } from 'react';
export default function NotificationList() {
    useProfileNotification();
    const notificationState = useContext(NotificationContext);
    if (!notificationState) {
        throw new Error('NotificationList must be used within a NotificationContext');
    }
    const { notifications } = notificationState;
    return (React.createElement("div", { className: "fixed top-16 2md:top-0 z-20 w-full pointer-events-none" }, notifications.map((notification) => (React.createElement("div", { className: "flex justify-end my-2 px-4 w-full", key: notification.id }, React.createElement(Notification, { notificationType: notification.notificationType, title: notification.title, Icon: notification.Icon, onClose: notification.onClose }, notification.children))))));
}
