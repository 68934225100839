import { Dialog, Transition } from '@headlessui/react';
import CloseButton, { CloseButtonSize, CloseButtonType, } from '@components/close-button';
import React, { Fragment, useRef } from 'react';
export const SidePanel = ({ show, onClose, children, dismissible = true, title, }) => {
    const containerRef = useRef(null);
    return (React.createElement(Transition.Root, { show: show, as: Fragment },
        React.createElement(Dialog, { as: "div", className: "relative z-40", onClose: dismissible
                ? () => {
                    onClose(true);
                }
                : () => {
                    // noop if not dismissible
                }, initialFocus: containerRef },
            React.createElement("div", { className: "fixed inset-0 left-auto z-30 flex top-16 2md:top-0 sm:w-[540px] w-full" },
                React.createElement(Transition.Child, { as: Fragment, enter: "transition ease-in-out duration-300 transform", enterFrom: "translate-x-full", enterTo: "translate-x-0", leave: "transition ease-in-out duration-300 transform", leaveFrom: "translate-x-0", leaveTo: "translate-x-full" },
                    React.createElement(Dialog.Panel, { className: "relative flex flex-col bg-white overflow-y-auto h-full max-h-screen w-full shadow-sidePanel" },
                        React.createElement("div", { className: "flex flex-row items-center pt-2 px-6", ref: containerRef },
                            React.createElement(Dialog.Title, { className: "font-medium text-lg leading-7 text-n600DarkBlue order-none hyphens-auto" }, title),
                            React.createElement(CloseButton, { onClick: () => onClose(false), size: CloseButtonSize.LARGE, type: CloseButtonType.GREY, className: "flex-shrink-0 flex-grow-0", testId: "closeSidePanelBtn" })),
                        children))))));
};
