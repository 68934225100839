import { Button, ButtonSize, ButtonType } from '@components/button';
import { Checkbox } from '@components/input/checkbox';
import { FieldType, } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { TextInput } from '@components/input/text-input';
import { UserCompanyRoleEnum } from '@lohndialog/shared/src/constants';
import { classNames } from '@utils/class-names';
import { localizeString } from '@utils/formatting';
import { usePromiseState } from '@hooks/usePromise';
import { useTranslation } from 'react-i18next';
import { validateEmail } from '@lohndialog/shared/src/validation';
import React, { useCallback, useState } from 'react';
import dayjs from '@lohndialog/shared/src/util/dayjs';
import i18n from '../i18n';
import nestClient from '@api/nest-client';
export const SendInvite = ({ employee, setEmployee, schema, refreshEmployees, }) => {
    var _a, _b, _c, _d;
    const [_, setInvite, submitting, submitError, resetInvite] = usePromiseState(null);
    const [isAdmin, setIsAdmin] = useState((_a = employee.token) === null || _a === void 0 ? void 0 : _a.role.includes(UserCompanyRoleEnum.ADMIN));
    const emailFieldSpec = schema.fields.privateEmail;
    const { t } = useTranslation('invitation');
    const { t: tCommon } = useTranslation('common');
    const submit = useCallback(() => {
        var _a;
        resetInvite();
        void setInvite(nestClient.sendEmployeeInvite(employee.id, {
            role: isAdmin
                ? [UserCompanyRoleEnum.ADMIN, UserCompanyRoleEnum.USER]
                : [UserCompanyRoleEnum.USER],
            email: (_a = employee.genericFields.privateEmail) === null || _a === void 0 ? void 0 : _a.value,
        })).then((employee) => {
            setEmployee(employee);
            refreshEmployees();
        });
    }, [
        resetInvite,
        setInvite,
        employee.id,
        (_b = employee.genericFields.privateEmail) === null || _b === void 0 ? void 0 : _b.value,
        isAdmin,
        setEmployee,
        refreshEmployees,
    ]);
    const withdraw = useCallback(() => {
        resetInvite();
        void setInvite(nestClient.withdrawEmployeeInvite(employee.id)).then((employee) => {
            setEmployee(employee);
            refreshEmployees();
        });
    }, [resetInvite, setInvite, employee.id, setEmployee, refreshEmployees]);
    return (React.createElement(React.Fragment, null,
        employee.token && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: classNames('bg-n200LightGrey rounded-2xl p-6 mb-4') },
                React.createElement("h3", { className: "font-bold text-base leading-5 text-n600DarkBlue mb-6" }, t('existingInviteHeading')),
                React.createElement("div", { className: "grid grid-cols-2" },
                    React.createElement("p", null, t('invitationExpiresAt')),
                    React.createElement("p", null, dayjs(employee.token.expirationDate).format(tCommon('dateFormat'))),
                    React.createElement("p", null, t('status')),
                    React.createElement("p", null, t(`state.${dayjs(employee.token.expirationDate).isBefore(dayjs())
                        ? 'expired'
                        : employee.token.state}`)),
                    React.createElement("p", null, t('invitedAs')),
                    React.createElement("p", null, t(employee.token.role.includes(UserCompanyRoleEnum.ADMIN)
                        ? 'invitedAsAdmin'
                        : 'invitedAsUser'))),
                React.createElement(Button, { label: t('withdraw'), buttonSize: ButtonSize.LARGE, buttonType: ButtonType.PRIMARY, onClick: withdraw, loading: submitting, className: "mt-6", "data-testid": "btnWithdraw" })))),
        React.createElement("div", { className: classNames('bg-n200LightGrey rounded-2xl p-6 mb-4') },
            React.createElement("h3", { className: "font-bold text-base leading-5 text-n600DarkBlue mb-6" }, t('sendNewInviteHeading')),
            React.createElement("form", { onSubmit: (event) => {
                    submit();
                    event.preventDefault();
                } },
                React.createElement("p", { className: "text-r600Error" }, submitError === null || submitError === void 0 ? void 0 : submitError.message),
                React.createElement(TextInput, { value: (_d = (_c = employee.genericFields.privateEmail) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : '', title: localizeString(emailFieldSpec.label, i18n.language), onChange: (newValue) => setEmployee(Object.assign(Object.assign({}, employee), { genericFields: Object.assign(Object.assign({}, employee.genericFields), { privateEmail: {
                                value: newValue,
                                type: FieldType.EMAIL,
                            } }) })), validate: validateEmail, maxLength: emailFieldSpec.maxLength, required: false, inputTestId: "emailInput" }),
                React.createElement("label", { className: "block" },
                    React.createElement(Checkbox, { checked: isAdmin, onChange: (e) => setIsAdmin(e.target.checked), "data-testid": "checkboxAdmin" }),
                    React.createElement("span", { className: "ml-2" }, t('isHr'))),
                React.createElement(Button, { label: t('sendInvitation'), buttonSize: ButtonSize.LARGE, buttonType: ButtonType.PRIMARY, loading: submitting, className: "mt-6 mb-2", "data-testid": "btnSendInvite", type: "submit" }),
                React.createElement("p", null, t('sendInvitationExplanation'))))));
};
