import { WizardCluster, } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { LoadingSpinner } from '@components/loading-spinner';
import { MainHRContactField } from '@components/company-dashboard-wizard/main-hr-contact-field';
import { MainPPContactField } from '@components/company-dashboard-wizard/main-pp-contact-field';
import { useTranslation } from 'react-i18next';
import GenericForm from '@components/generic-forms/generic-form';
import React, { useCallback } from 'react';
export default (schema, setMainHrContactPerson, setMainPPContactPerson, possibleContactPersons, possiblePayrollProviderContactPersons, hasAdminRights, supervisingValue, mainHRContactPerson, mainPayrollProviderContactPerson) => ({
    key: 'step1',
    isValid: () => true,
    stepLabel: (t) => t('step1.label', {
        ns: 'companyWizard',
    }),
    stepLabelDetails: (t) => t('step1.labelDetails', {
        ns: 'companyWizard',
    }),
    ReactComponent: ({ value, setValue }) => {
        const { t } = useTranslation('companyWizard', {
            keyPrefix: 'payrollDataStep',
        });
        const setGenericField = useCallback((fieldIdentifier, type, newValue) => {
            setValue(fieldIdentifier, type, newValue);
        }, [setValue]);
        if (!schema) {
            return React.createElement(LoadingSpinner, null);
        }
        return (React.createElement("div", { className: "mx-8 mt-4" },
            React.createElement("h1", null, t('title')),
            React.createElement(GenericForm, { value: value, supervisingValue: supervisingValue, setValue: setGenericField, schema: schema, wizardCluster: WizardCluster.PaymentSettings, overview: true, customFields: [
                    React.createElement(MainHRContactField, { key: 'mainHRContactPersonField', possibleContactPersons: possibleContactPersons, setMainHrContactPerson: setMainHrContactPerson, mainHRContactPerson: mainHRContactPerson, hasAdminRights: hasAdminRights }),
                    React.createElement(MainPPContactField, { key: 'mainPPContactPersonField', possibleContactPersons: possiblePayrollProviderContactPersons, setMainPayrollProviderContactPerson: setMainPPContactPerson, mainPayrollProviderContactPerson: mainPayrollProviderContactPerson, hasAdminRights: hasAdminRights }),
                ] }),
            React.createElement("h2", null, t('subLabelAccountingData')),
            React.createElement(GenericForm, { value: value, supervisingValue: supervisingValue, setValue: setGenericField, schema: schema, wizardCluster: WizardCluster.AccountingData, overview: true }),
            React.createElement("h2", null, t('subLabelEmployeeControl')),
            React.createElement(GenericForm, { value: value, supervisingValue: supervisingValue, setValue: setGenericField, schema: schema, wizardCluster: WizardCluster.EmployeeControl, overview: true })));
    },
});
