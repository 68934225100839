import { ReactComponent as ChevronUp } from '@public/icons/chevron-up.svg';
import { ReactComponent as ChevronsLeft } from '@public/icons/chevrons-left.svg';
import { ReactComponent as StepIcon } from '@public/icons/step-icon-base.svg';
import { ReactComponent as StepIconChecked } from '@public/icons/step-icon-checked.svg';
import { classNames } from '@utils/class-names';
import { useTranslation } from 'react-i18next';
import { useWizard } from 'react-use-wizard';
import React, { useState } from 'react';
const StepIndicator = ({ checked, disabled, lineDisabled, onClick, isLast, label, labelDetails, showLabels, }) => {
    const disabledClass = classNames('transition-opacity', 
    // eslint-disable-next-line sonarjs/no-duplicate-string
    disabled && 'opacity-50');
    const Icon = checked ? StepIconChecked : StepIcon;
    return (React.createElement("div", { className: "flex flex-row items-start" },
        React.createElement("div", { className: "flex flex-col items-center" },
            React.createElement("button", { onClick: onClick, className: classNames('flex flex-row space-x-4') },
                React.createElement(Icon, { className: classNames('flex-shrink-0 m-2', disabledClass) })),
            !isLast && (React.createElement("div", { className: classNames('bg-white w-0.5 h-8 md:h-12 rounded-full transition-opacity', lineDisabled && 'opacity-50') }))),
        showLabels && (React.createElement("div", { className: "mt-2.5" },
            React.createElement("button", { onClick: onClick },
                React.createElement("p", { className: "text-lg text-left" }, label)),
            React.createElement("p", { className: classNames('text-sm', disabledClass) }, labelDetails)))));
};
const HorizontalStepIndicator = ({ checked, disabled, lineDisabled, onClick, isLast, }) => {
    const disabledClass = classNames('transition-opacity', disabled && 'opacity-50');
    const Icon = checked ? StepIconChecked : StepIcon;
    return (React.createElement(React.Fragment, null,
        React.createElement("button", { onClick: onClick, className: "flex-shrink-0" },
            React.createElement(Icon, { className: classNames('m-1 h-6', disabledClass) })),
        !isLast && (React.createElement("div", { className: classNames('bg-white h-0.5 flex-1 rounded-full transition-opacity', lineDisabled && 'opacity-50') }))));
};
export function WizardHeader({ stepsValid, steps, actionButtons, }) {
    const { activeStep, stepCount, goToStep } = useWizard();
    const [expanded, setExpanded] = useState(true);
    const { t } = useTranslation();
    return (React.createElement("div", { className: classNames('bg-p200Green text-white flex-shrink-0 w-full flex flex-row items-center p-4 overflow-y-auto scrollbar-hide sticky top-16 2md:top-0 z-[5]', 'max-h-screen md:h-[calc(100vh_-_4rem)] 2md:h-auto', expanded ? 'flex-wrap md:flex-nowrap' : 'h-14', 'md:py-10 md:flex-col', expanded
            ? 'md:w-[464px] md:px-12 md:max-w-1/2 md:items-start'
            : 'md:w-20 md:px-6 md:items-center') },
        React.createElement("button", { onClick: () => setExpanded((previous) => !previous), className: "hidden md:block" },
            React.createElement(ChevronsLeft, { className: classNames(!expanded && 'rotate-180') })),
        React.createElement("div", { className: classNames('flex flex-col flex-1 md:mt-20', expanded ? 'items-start' : 'items-center hidden md:block') }, steps.map((step, index) => (React.createElement(StepIndicator, { checked: stepsValid[index], disabled: index > activeStep, lineDisabled: index > activeStep - 1, key: index, onClick: () => goToStep(index), isLast: index === stepCount - 1, label: step.stepLabel(t), labelDetails: step.stepLabelDetails(t), showLabels: expanded })))),
        React.createElement("div", { className: classNames('flex-grow flex flex-row items-center', expanded ? 'hidden' : 'md:hidden') }, steps.map((step, index) => (React.createElement(HorizontalStepIndicator, { checked: stepsValid[index], disabled: index > activeStep, lineDisabled: index > activeStep - 1, key: index, onClick: () => goToStep(index), isLast: index === stepCount - 1 })))),
        actionButtons && expanded && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "flex-grow hidden md:block" }),
            React.createElement("div", { className: "hidden bg-p200Green mt-10 md:block" }, actionButtons))),
        React.createElement("button", { onClick: () => setExpanded((previous) => !previous), className: "block md:hidden self-start" },
            React.createElement(ChevronUp, { className: classNames(!expanded && 'rotate-180') })),
        actionButtons && expanded && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "w-full mt-8 md:mt-0" }),
            React.createElement("div", { className: "bg-p200Green w-full max-w-[464px] mx-auto block md:hidden" }, actionButtons)))));
}
