import { ValidationResult, ValidationType, validateRegex, } from '@lohndialog/shared/src/validation';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
export const useValidation = ({ validateFn, messageKeys = {}, maxLength, minLength, regex, min, max, required = false, validationType = ValidationType.basic, }) => {
    const [isValid, setIsValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const regexValidateFn = useMemo(() => (regex ? validateRegex(regex) : undefined), [regex]);
    const { t } = useTranslation('formValidation');
    const { t: tCommon } = useTranslation('common');
    const getErrorMessage = useCallback((validationResult, value) => {
        var _a, _b, _c, _d, _e, _f, _g;
        switch (validationResult) {
            case ValidationResult.VALID:
                return;
            case ValidationResult.EMPTY:
                return t((_a = messageKeys[ValidationResult.EMPTY]) !== null && _a !== void 0 ? _a : 'empty');
            case ValidationResult.TOO_LONG:
                return t((_b = messageKeys[ValidationResult.TOO_LONG]) !== null && _b !== void 0 ? _b : 'tooLong', {
                    length: typeof value === 'string' ? value.length : 0,
                    maxLength,
                });
            case ValidationResult.TOO_SHORT:
                return t((_c = messageKeys[ValidationResult.TOO_SHORT]) !== null && _c !== void 0 ? _c : 'tooShort', {
                    length: typeof value === 'string' ? value.length : 0,
                    minLength,
                });
            case ValidationResult.WRONG_FORMAT:
                return t((_d = messageKeys[ValidationResult.WRONG_FORMAT]) !== null && _d !== void 0 ? _d : 'wrongFormat');
            case ValidationResult.OUT_OF_RANGE:
                return t((_e = messageKeys[ValidationResult.OUT_OF_RANGE]) !== null && _e !== void 0 ? _e : 'outOfRangeMinMax', {
                    min: min,
                    max: max,
                });
            case ValidationResult.OUT_OF_RANGE_MIN:
                return t((_f = messageKeys[ValidationResult.OUT_OF_RANGE]) !== null && _f !== void 0 ? _f : 'outOfRangeMin', {
                    min: min,
                });
            case ValidationResult.OUT_OF_RANGE_MAX:
                return t((_g = messageKeys[ValidationResult.OUT_OF_RANGE]) !== null && _g !== void 0 ? _g : 'outOfRangeMax', {
                    max: max,
                });
        }
    }, [t, messageKeys, maxLength, minLength, min, max]);
    const getDateErrorMessage = useCallback((validationResult) => {
        switch (validationResult.result) {
            case ValidationResult.VALID:
                return;
            case ValidationResult.EMPTY:
                return t('empty');
            case ValidationResult.WRONG_FORMAT:
                if (validationResult.referenceDate &&
                    validationResult.comparator) {
                    return t('wrongFormatWithReference', {
                        comparator: t(validationResult.comparator, {
                            referenceDate: validationResult.referenceDate.format(tCommon('dateFormat')),
                        }),
                    });
                }
                else {
                    return t('wrongFormat');
                }
            default:
                return;
        }
    }, [t, tCommon]);
    const getSocialSecurityNumberErrorMessage = useCallback((errorKey) => {
        if (!errorKey) {
            return '';
        }
        return t(errorKey);
    }, [t]);
    const validateFunction = useCallback((value) => {
        let validationResult = ValidationResult.VALID;
        let validationFunctionResult;
        if (validateFn) {
            validationFunctionResult = validateFn(value);
            switch (validationType) {
                case ValidationType.date:
                    validationResult = validationFunctionResult.result;
                    break;
                case ValidationType.socialSecurityNumber:
                    validationResult = validationFunctionResult.result;
                    break;
                case ValidationType.basic:
                default:
                    validationResult =
                        validationFunctionResult;
            }
        }
        if (required &&
            (value === undefined || value === '') &&
            validationResult === ValidationResult.VALID) {
            validationResult = ValidationResult.EMPTY;
        }
        return [validationResult, validationFunctionResult];
    }, [validateFn, required, validationType]);
    const validate = useCallback((value) => {
        var _a, _b;
        let [validationResult, validationFunctionResult] = validateFunction(value);
        if (maxLength &&
            typeof value === 'string' &&
            value.length > maxLength) {
            validationResult = ValidationResult.TOO_LONG;
        }
        if (minLength &&
            typeof value === 'string' &&
            value.length < minLength) {
            validationResult = ValidationResult.TOO_SHORT;
        }
        if (typeof value === 'string' &&
            (required || value !== '') &&
            regexValidateFn &&
            validationResult === ValidationResult.VALID) {
            validationResult = regexValidateFn(value);
        }
        if (!required && validationResult === ValidationResult.EMPTY) {
            setIsValid(true);
        }
        else {
            switch (validationType) {
                case ValidationType.date:
                    setErrorMessage((_a = getDateErrorMessage(validationFunctionResult)) !== null && _a !== void 0 ? _a : errorMessage);
                    break;
                case ValidationType.socialSecurityNumber:
                    setErrorMessage(getSocialSecurityNumberErrorMessage(validationFunctionResult.frontendMessage));
                    break;
                case ValidationType.basic:
                default:
                    setErrorMessage((_b = getErrorMessage(validationResult, value)) !== null && _b !== void 0 ? _b : errorMessage);
            }
            setIsValid(validationResult === ValidationResult.VALID);
        }
    }, [
        validateFunction,
        maxLength,
        minLength,
        required,
        regexValidateFn,
        validationType,
        getDateErrorMessage,
        errorMessage,
        getSocialSecurityNumberErrorMessage,
        getErrorMessage,
    ]);
    return [isValid, errorMessage, validate];
};
export const formatErrorMessages = (isValid, maxLength, length, errorMessage, additionalErrorMessage) => [
    isValid &&
        maxLength &&
        length > maxLength * 0.9 &&
        `${length}/${maxLength}`,
    !isValid && errorMessage,
    additionalErrorMessage ? additionalErrorMessage : '',
]
    .filter(Boolean)
    .join(' ');
