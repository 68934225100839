import { ReactComponent as ArrowRightIcon } from '@public/icons/arrow-right.svg';
import { Button, ButtonSize, ButtonType } from '@components/button';
import { ReactComponent as HelpCircleIcon } from '@public/icons/help-circle.svg';
import { LoadingSpinner } from '@components/loading-spinner';
import { Wizard } from '@components/wizard/wizard';
// eslint-disable-next-line no-restricted-imports
import { useNavigate } from 'react-router-dom';
import { useNestClient } from '@hooks/useNestClient';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo, useState } from 'react';
import ReviewStep from '@components/company-wizard/review-step';
import Step1 from '@components/company-wizard/step1';
import Step2 from '@components/company-wizard/step2';
import Step3 from '@components/company-wizard/step3';
import Step4 from '@components/company-wizard/step4';
import Step5 from '@components/company-wizard/step5';
export function CompanyWizard({ initialCompany, onClose, onFinish, isAdmin, }) {
    const [company, setCompany] = useState(initialCompany);
    const [saving, setSaving] = useState(false);
    const { t } = useTranslation('companyWizard');
    const { t: tCommon } = useTranslation('common');
    const navigate = useNavigate();
    const { result: companySchema, loading, error, } = useNestClient('getCompanySchema', [true, false], { fields: [] }); // is always admin
    const { result: payrollProviderFields, loading: payrollProviderFieldsLoading, error: payrollProviderFieldsError, } = useNestClient('getPayrollProviderFields', [company.id], {
        fields: [],
    });
    const finish = useCallback(async () => {
        setSaving(true);
        try {
            await onFinish(company, companySchema);
        }
        finally {
            setSaving(false);
        }
    }, [company, companySchema, onFinish]);
    const updateCompany = useCallback((fieldIdentifier, type, newValue) => {
        if (newValue === undefined || newValue === '') {
            const rest = Object.assign({}, company.genericFields);
            delete rest[fieldIdentifier];
            setCompany((value) => (Object.assign(Object.assign({}, value), { genericFields: rest })));
        }
        else {
            setCompany((value) => (Object.assign(Object.assign({}, value), { genericFields: Object.assign(Object.assign({}, value.genericFields), { [fieldIdentifier]: {
                        value: newValue,
                        type,
                    } }) })));
        }
    }, [company.genericFields]);
    const steps = useMemo(() => [
        Step1(companySchema, payrollProviderFields),
        Step2(companySchema, payrollProviderFields),
        Step3(companySchema, payrollProviderFields),
        Step4(companySchema, payrollProviderFields),
        Step5(companySchema, payrollProviderFields),
        ReviewStep(companySchema, () => navigate({
            pathname: isAdmin
                ? `/admin/dashboard`
                : `/${company.slug}/employees`,
        })),
    ], [company.slug, companySchema, isAdmin, navigate, payrollProviderFields]);
    if (loading || payrollProviderFieldsLoading) {
        return (React.createElement("div", { className: "w-full h-full flex justify-center items-center" },
            React.createElement(LoadingSpinner, null)));
    }
    if (error || payrollProviderFieldsError) {
        // let the router handle this error
        throw error;
    }
    return (React.createElement(Wizard, { setValue: updateCompany, value: company, onClose: onClose, showCloseButton: true, steps: steps, onFinish: finish, actionButtons: React.createElement(React.Fragment, null,
            React.createElement(Button, { label: t('help'), buttonType: ButtonType.SECONDARY, buttonSize: ButtonSize.SMALL, IconLeading: HelpCircleIcon, 
                // TODO LD-128: make visible as soon as texts are provided
                className: "text-white w-full mb-4 hidden", iconClassName: "text-white" }),
            React.createElement(Button, { label: t('saveAndExit'), buttonType: ButtonType.PRIMARY, buttonSize: ButtonSize.SMALL, className: "w-full !bg-white !text-p200Green", onClick: () => void finish(), loading: saving })), finalStepActions: [
            {
                buttonProps: {
                    label: tCommon('button.save'),
                    buttonSize: ButtonSize.LARGE,
                    buttonType: ButtonType.PRIMARY,
                    onClick: () => {
                        void finish();
                    },
                    testId: 'finishBtn',
                    type: 'submit',
                    className: 'flex-1 max-w-[12rem] w-0',
                    IconTrailing: ArrowRightIcon,
                },
                key: 'company-save',
            },
        ] }));
}
