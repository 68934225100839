import { useCallback, useEffect, useRef, useState } from 'react';
/**
 * Drop-in replacement for useState but with support for promises.
 * You can set the state to a promise and this hook will enter a loading state until the promise is resolved.
 *
 * The setter function will return the promise but only resolve if the component is still mounted.
 */
export function usePromiseState(defaultValue) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [value, setValue] = useState(defaultValue);
    const [promise, _setPromise] = useState();
    const isMounted = useRef(null);
    useEffect(() => {
        let canceled = false;
        (async () => {
            setLoading(true);
            try {
                if (promise) {
                    const result = await promise;
                    if (!canceled) {
                        setValue(result);
                    }
                }
            }
            catch (e) {
                if (e instanceof Error) {
                    setError(e);
                }
                else {
                    setError(new Error('Unknown error'));
                }
            }
            finally {
                setLoading(false);
            }
        })();
        return () => {
            canceled = true;
        };
    }, [promise]);
    const reset = useCallback(() => {
        setValue(defaultValue);
        setLoading(false);
        setError(null);
    }, [defaultValue]);
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);
    const setPromise = useCallback((promise) => {
        // Wrap the promise to only resolve if the component is still mounted.
        return new Promise((resolve) => {
            if (typeof promise === 'function') {
                promise = promise();
            }
            _setPromise(promise);
            promise.then((value) => {
                isMounted.current && resolve(value);
            }, (_error) => {
                // noop. Error state is handled by useEffect above
            });
        });
    }, []);
    return [value, setPromise, loading, error, reset];
}
