import 'reflect-metadata';
import { CompanyContext } from '../context/company-context';
import { PayrollProviderContext } from '../context/payrollprovider-context';
import { useCallback, useContext, useEffect, useState } from 'react';
import { usePromiseState } from '@hooks/usePromise';
import nestClient, { REQUIRES_COMPANY_KEY, REQUIRES_PAYROLL_PROVIDER_KEY, } from '@api/nest-client';
export function useNestClient(fn, args, defaultValue = null) {
    var _a, _b;
    const [counter, setCounter] = useState(0);
    const company = (_a = useContext(CompanyContext)) === null || _a === void 0 ? void 0 : _a.company;
    const payrollProvider = (_b = useContext(PayrollProviderContext)) === null || _b === void 0 ? void 0 : _b.payrollProvider;
    const dependencyArray = [nestClient, fn, ...args];
    const isCompanyDependent = Reflect.getMetadata(REQUIRES_COMPANY_KEY, nestClient, fn);
    const isPayrollProviderDependent = Reflect.getMetadata(REQUIRES_PAYROLL_PROVIDER_KEY, nestClient, fn);
    if (isCompanyDependent) {
        dependencyArray.push(company === null || company === void 0 ? void 0 : company.slug);
    }
    if (isPayrollProviderDependent) {
        dependencyArray.push(payrollProvider === null || payrollProvider === void 0 ? void 0 : payrollProvider.id);
    }
    const [result, setPromise, loading, error] = usePromiseState(defaultValue);
    const dependenciesSatisfied = isCompanyDependent || isPayrollProviderDependent
        ? (isCompanyDependent && company) ||
            (isPayrollProviderDependent && payrollProvider)
        : true;
    const reload = useCallback(() => {
        setCounter((counter) => counter + 1);
    }, [setCounter]);
    useEffect(() => {
        if (!dependenciesSatisfied) {
            return;
        }
        void setPromise(() => nestClient[fn](...args));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...dependencyArray, dependenciesSatisfied, counter]);
    if (loading || !dependenciesSatisfied) {
        return { loading: true, result, error, reload };
    }
    else {
        return { loading, result: result, error, reload };
    }
}
