import { Button, ButtonSize, ButtonType } from '@components/button';
import { ReactComponent as ChevronsLeft } from '@public/icons/chevrons-left.svg';
import { SidePanel } from '@components/side-panel';
import { classNames } from '@utils/class-names';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DocumentGroupTableWrapper from '@components/documents/document-group-table-wrapper';
import DocumentTableWrapper from '@components/documents/document-table-wrapper';
import React, { useState } from 'react';
import SearchInputField from '@components/search-input-field';
function DocumentListView({ personal }) {
    const { t } = useTranslation('documentList');
    const [searchTerm, setSearchTerm] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const [employeeDocuments, setEmployeeDocuments] = useState(!!personal);
    const year = searchParams.get('year');
    const month = searchParams.get('month');
    const name = searchParams.get('name');
    const [showUpload, setShowUpload] = useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "md:flex justify-between md:space-x-4" },
            React.createElement("div", null,
                React.createElement("h1", null, t('title')),
                React.createElement("div", { className: "sm:flex sm:items-center" },
                    React.createElement("div", { className: "sm:flex-auto" },
                        React.createElement("p", { className: "mt-2 text-sm text-gray-700" }, t('documentView')))))),
        (year || month) && (React.createElement("button", { className: "mt-8 flex text-n500DarkGrey items-center font-medium", onClick: () => setSearchParams({}) },
            React.createElement(ChevronsLeft, { className: "text-p200Green mr-6" }),
            ' ',
            React.createElement("div", null, name))),
        React.createElement("div", { className: "bg-white rounded-2xl mt-8 pt-4 drop-shadow-table" },
            React.createElement("div", { className: "px-6 pb-4 pt-0.5 rounded-lg flex flex-col xl:flex-row xl:justify-between text-sm font-medium gap-x-4 gap-y-2 w-full overflow-hidden" },
                React.createElement("div", { className: classNames('flex flex-col xs:flex-row xl:flex-grow gap-x-4 gap-y-2 xl:justify-between ', personal && '!xl:justify-start') },
                    !personal && (React.createElement("div", { className: "flex gap-0.5" },
                        React.createElement(Button, { onClick: () => setEmployeeDocuments(false), label: t('employer'), buttonType: ButtonType.SWITCH, buttonSize: ButtonSize.SMALL, className: classNames('rounded-r-none w-[130px]', !employeeDocuments &&
                                'bg-backgroundGreen hover:!bg-backgroundGreen') }),
                        React.createElement(Button, { onClick: () => setEmployeeDocuments(true), label: t('employees'), buttonType: ButtonType.SWITCH, buttonSize: ButtonSize.SMALL, className: classNames('rounded-l-none w-[130px]', employeeDocuments &&
                                'bg-backgroundGreen hover:!bg-backgroundGreen') }))),
                    React.createElement(SearchInputField, { setSearchTerm: setSearchTerm, placeHolder: t('search'), className: "border-n400GreyCheteau" }))),
            !name && !searchTerm ? (React.createElement(DocumentGroupTableWrapper, { personal: personal, employeeDocuments: employeeDocuments })) : (React.createElement(DocumentTableWrapper, { searchTerm: searchTerm, personal: personal, employeeDocuments: employeeDocuments })),
            React.createElement(SidePanel, { show: showUpload, onClose: () => setShowUpload(false), title: t('upload'), dismissible: false },
                React.createElement("div", null, "TODO LD-115")))));
}
export function CompanyDocumentView() {
    return React.createElement(DocumentListView, null);
}
export function PersonalDocumentView() {
    return React.createElement(DocumentListView, { personal: true });
}
