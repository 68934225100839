import { Button, ButtonSize, ButtonType } from '@components/button';
import { useAuth } from '../../context/auth-context';
import { useTranslation } from 'react-i18next';
import ErrorComponent from '@components/errors/error';
import React from 'react';
export default function NoCompanyError({ error }) {
    const { logout } = useAuth();
    const { t } = useTranslation('errors');
    return (React.createElement(ErrorComponent, { error: error },
        React.createElement("div", { className: "flex flex-col gap-4" },
            React.createElement(Button, { label: t('tryAgain'), buttonType: ButtonType.PRIMARY, buttonSize: ButtonSize.LARGE, onClick: () => location.reload() }),
            React.createElement(Button, { label: t('logoutButton'), buttonType: ButtonType.SECONDARY, buttonSize: ButtonSize.LARGE, onClick: () => logout() }))));
}
