import { GenericTable } from '@components/table/generic-table';
import { DownloadCell } from '@components/documents/document-table';
import { NavLink } from '@components/nav-link';
import { classNames } from '@utils/class-names';
import { useNestClient } from '@hooks/useNestClient';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState } from 'react';
import dayjs from '@lohndialog/shared/src/util/dayjs';
export default function DocumentDashboardTable({ pageSize, showHeader, navLink, onlyShowPersonalDocuments, }) {
    const [sort, setSort] = useState();
    const { t } = useTranslation('dashboardDocument');
    const documentRequest = useMemo(() => {
        return {
            pageNumber: 0,
            pageSize: pageSize,
            sort: sort !== null && sort !== void 0 ? sort : undefined,
            personal: onlyShowPersonalDocuments || undefined,
            employeeDocuments: onlyShowPersonalDocuments || undefined,
        };
    }, [onlyShowPersonalDocuments, pageSize, sort]);
    const { result: documentSchema } = useNestClient('getDocumentSchema', []);
    const { result: documents, loading } = useNestClient('getPaginatedDocuments', [documentRequest], { data: [], totalCount: 0 });
    const topHeader = (React.createElement("div", { className: classNames('flex items-center', showHeader ? 'justify-between' : 'justify-end') },
        showHeader && React.createElement("p", { className: "font-bold" }, t('topHeader')),
        React.createElement(NavLink, { to: `/${navLink}` },
            React.createElement("p", { className: "text-sm font-medium text-p100Green" }, t('viewAll')))));
    const [selectedColumns, setSelectedColumns] = useState([
        'name',
        'type',
        'uploadDate',
        'category',
        'employeeName',
        'download',
    ]);
    const columnOverrides = useMemo(() => ({
        name: {
            identifier: 'name',
            sortable: true,
            headContent: t('name'),
            CellContent: NameCell,
        },
        type: {
            identifier: 'type',
            sortable: true,
            headContent: t('type'),
            CellContent: TypeCell,
        },
        uploadDate: {
            identifier: 'uploadDate',
            sortable: true,
            headContent: t('uploadDate'),
            CellContent: UploadDateCell,
        },
        category: {
            identifier: 'category',
            sortable: true,
            headContent: t('category'),
            CellContent: CategoryCell,
        },
        employeeName: {
            identifier: 'employeeName',
            sortable: true,
            headContent: t('employeeName'),
            CellContent: EmployeeNameCell,
        },
        download: {
            identifier: 'download',
            sortable: false,
            headContent: React.createElement("span", { className: "sr-only" }, t('download')),
            CellContent: DownloadCell,
        },
    }), [t]);
    return (React.createElement(GenericTable, { topHeader: topHeader, sort: sort, setSort: setSort, loading: loading, data: documents.data, schema: documentSchema, selectedColumns: selectedColumns, setSelectedColumns: setSelectedColumns, getId: (document) => document.id.toString(), columnOverrides: columnOverrides, totalResults: documents.data.length, className: "mt-8" }));
}
const NameCell = ({ rowData: document }) => document.name;
const TypeCell = ({ rowData: document }) => document.type;
const CategoryCell = ({ rowData: document }) => document.category;
const UploadDateCell = ({ rowData: document }) => {
    const { t } = useTranslation('dashboardDocument');
    return dayjs(document.uploadDate).format(t('dateFormat'));
};
const EmployeeNameCell = ({ rowData: document }) => {
    var _a, _b, _c, _d, _e, _f;
    return `${(_c = (_b = (_a = document.assignedEmployee) === null || _a === void 0 ? void 0 : _a.genericFields.lastname) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : ''}, ${(_f = (_e = (_d = document.assignedEmployee) === null || _d === void 0 ? void 0 : _d.genericFields.firstname) === null || _e === void 0 ? void 0 : _e.value) !== null && _f !== void 0 ? _f : ''}`;
};
