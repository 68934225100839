import { HeaderHandler } from '@api/header-handler';
import { PAYROLL_PROVIDER_ID_HEADER } from '@lohndialog/shared/src/constants';
import { useProfile } from './profile-context';
import { useTranslation } from 'react-i18next';
import React, { createContext, useCallback, useContext, useLayoutEffect, useMemo, useState, } from 'react';
import nestClient from '@api/nest-client';
export const PayrollProviderContext = createContext(undefined);
export function usePayrollProvider() {
    const state = useContext(PayrollProviderContext);
    if (!state) {
        throw new Error('usePayrollProvider must be used within an PayrollProviderStateContext');
    }
    return state;
}
export const PayrollProviderProvider = ({ children, }) => {
    var _a, _b;
    const { t } = useTranslation('errors');
    const profile = useProfile();
    const [payrollProvider, _setPayrollProvider] = useState();
    const defaultPayrollProvider = (_a = profile.state.profile) === null || _a === void 0 ? void 0 : _a.payrollProvider;
    const setPayrollIdHeader = useCallback((payrollId) => {
        nestClient.payrollHandler = new HeaderHandler(() => payrollId
            ? {
                [PAYROLL_PROVIDER_ID_HEADER]: payrollId,
            }
            : {});
    }, []);
    const setPayrollProvider = useCallback((payrollProvider) => {
        _setPayrollProvider(payrollProvider);
        setPayrollIdHeader(payrollProvider === null || payrollProvider === void 0 ? void 0 : payrollProvider.id);
    }, [setPayrollIdHeader]);
    // Setting the payrollProvider must be done as early as possible.
    // Otherwise, backend requests might be made before the payrollProvider was set, resulting in error 400.
    useLayoutEffect(() => {
        var _a, _b;
        setPayrollProvider((_b = (_a = profile.state.profile) === null || _a === void 0 ? void 0 : _a.payrollProvider) !== null && _b !== void 0 ? _b : defaultPayrollProvider);
    }, [
        setPayrollProvider,
        defaultPayrollProvider,
        (_b = profile.state.profile) === null || _b === void 0 ? void 0 : _b.payrollProvider,
    ]);
    const state = useMemo(() => {
        let error = undefined;
        if (!profile.state.profile &&
            profile.state.error &&
            !profile.state.pending) {
            error = new Error(t('failedToLoadProfile'));
        }
        if (!payrollProvider &&
            !defaultPayrollProvider &&
            !profile.state.pending &&
            !profile.state.error) {
            error = new Error(t('noPayrollProvider'));
        }
        return {
            payrollProvider: payrollProvider,
            setPayrollProvider: setPayrollProvider,
            error: error,
        };
    }, [
        defaultPayrollProvider,
        payrollProvider,
        profile.state.error,
        profile.state.pending,
        profile.state.profile,
        setPayrollProvider,
        t,
    ]);
    return (React.createElement(PayrollProviderContext.Provider, { value: state }, children));
};
