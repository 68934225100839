import { Button, ButtonSize, ButtonType } from '@components/button';
import { ReactComponent as EditIcon } from '@public/icons/edit.svg';
import { EmployeeStateBadge, HrBadge, InvitationStateBadge, } from '@components/badge';
import { ReactComponent as FiltersLines } from '@public/icons/filters-lines.svg';
import { GenericTable } from '@components/table/generic-table';
import { NavLink } from '@components/nav-link';
import { SidePanel } from '@components/side-panel';
import { ReactComponent as UserPlusIcon } from '@public/icons/user-plus.svg';
import { getInviteActionButton, getStateUpdateActionButton, } from '@components/employees/actionButtonUtils';
import { useTranslation } from 'react-i18next';
import BulkUpdateSlideIn from '@components/employees/bulk-update-slide-in';
import EmployeeFilterPanel from '@components/employees/employee-filter-panel';
import React, { useMemo, useState } from 'react';
import SearchInputField from '@components/search-input-field';
export default function EmployeeTable({ employees, currentPage, numberOfAvailablePages, setCurrentPage, sort, setSort, loading, stateFilter, setStateFilter, employeeSchema, setSearchTerm, totalCount, setEmployeeStateModalToOpen, setEmployeeInviteModalToOpen, refreshEmployees, }) {
    const { t } = useTranslation('employeeList');
    const [slideInInviteModalOpen, setSlideInInviteModalOpen] = useState(false);
    const topHeader = (React.createElement("div", { className: "rounded-lg flex flex-col xl:flex-row xl:justify-between text-sm font-medium gap-x-4 gap-y-2 w-full overflow-hidden" },
        React.createElement(EmployeeFilterPanel, { setFilter: setStateFilter, filter: stateFilter, className: "col-span-3 shadow-xs" }),
        React.createElement("div", { className: "hidden xl:block" }),
        React.createElement("div", { className: "flex flex-col xs:flex-row xl:flex-grow gap-x-4 gap-y-2 xl:justify-end" },
            React.createElement(SearchInputField, { setSearchTerm: setSearchTerm, placeHolder: t('search') }),
            React.createElement(Button, { className: "bg-white", buttonSize: ButtonSize.SMALL, buttonType: ButtonType.SECONDARY, label: t('filter'), IconLeading: FiltersLines }))));
    const [selectedColumns, setSelectedColumns] = useState([
        'name',
        'invitationState',
        'state',
        'jobTitle',
        'personnelNumber',
        'dateOfJoining',
        'userEmail',
        'invite',
        '_stateUpdate',
        '_edit',
    ]);
    const [selectedItems, setSelectedItems] = useState([]);
    const columnOverrides = useMemo(() => ({
        name: {
            headContent: t('name'),
            CellContent: NameCell,
            sortable: true,
            identifier: 'name',
        },
        state: {
            headContent: t('state'),
            CellContent: StateCell,
            sortable: true,
            identifier: 'state',
        },
        userEmail: {
            headContent: t('userEmail'),
            CellContent: (row) => { var _a; return (_a = row.rowData.user) === null || _a === void 0 ? void 0 : _a.email; },
            sortable: true,
            identifier: 'userEmail',
        },
        invite: {
            headContent: t('inviteState'),
            identifier: 'inviteState',
            sortable: true,
            CellContent: (row) => (React.createElement("div", { className: "flex gap-x-2" },
                getInviteActionButton(setEmployeeInviteModalToOpen, t)(row),
                React.createElement(InvitationStateBadge, { state: row.rowData.invitationState }))),
        },
        _stateUpdate: {
            headContent: '',
            identifier: '_stateUpdate',
            CellContent: getStateUpdateActionButton(setEmployeeStateModalToOpen, t),
        },
        _edit: {
            headContent: '',
            CellContent: EditCell,
            identifier: '_edit',
        },
    }), [setEmployeeInviteModalToOpen, setEmployeeStateModalToOpen, t]);
    return (React.createElement(React.Fragment, null,
        React.createElement(GenericTable, { currentPage: currentPage, numberOfAvailablePages: numberOfAvailablePages, setCurrentPage: setCurrentPage, sort: sort, setSort: setSort, loading: loading, topHeader: topHeader, getId: (employee) => employee.id.toString(), data: employees, columnOverrides: columnOverrides, selectedColumns: selectedColumns, setSelectedColumns: setSelectedColumns, schema: employeeSchema, totalResults: totalCount, selectedItems: selectedItems, setSelectedItems: setSelectedItems, toggleAll: () => setSelectedItems((prevState) => prevState.length > 0 ? [] : [...employees]), className: "mt-8", selectedActions: React.createElement(Button, { buttonType: ButtonType.TABLE_ACTION, buttonSize: ButtonSize.SMALL, label: t('invite'), iconClassName: "w-4 h-4", IconLeading: UserPlusIcon, onClick: () => {
                    setSlideInInviteModalOpen(true);
                }, testId: "bulk-delete-button" }) }),
        React.createElement(SidePanel, { show: slideInInviteModalOpen, onClose: () => {
                setSlideInInviteModalOpen(false);
            }, title: t('invite'), dismissible: false },
            React.createElement(BulkUpdateSlideIn, { employeeIds: selectedItems.map((employee) => employee.id), schema: employeeSchema, closeSlideIn: () => {
                    setSlideInInviteModalOpen(false);
                    refreshEmployees();
                } }))));
}
export const NameCell = ({ rowData: employee }) => {
    var _a, _b, _c, _d;
    return (React.createElement("div", { className: "flex" },
        React.createElement("p", { className: "mr-2" }, `${(_b = (_a = employee.genericFields.lastname) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : ''}, ${(_d = (_c = employee.genericFields.firstname) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : ''}`),
        employee.isHr && React.createElement(HrBadge, null)));
};
const StateCell = ({ rowData: employee }) => (React.createElement(EmployeeStateBadge, { state: employee.state }));
const EditCell = ({ rowData: employee }) => (React.createElement(NavLink, { to: `/employees/${employee.id}` },
    React.createElement("button", { className: "bg-p50LightGreen w-[28px] h-[28px] rounded-full text-p100Green flex justify-center items-center" },
        React.createElement(EditIcon, { className: "w-4 h-4" }))));
