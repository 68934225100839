import { FieldType, } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { PayrollProviderStaffRoleEnum } from '@lohndialog/shared/src/constants';
import { getChildNumberFromName } from '@lohndialog/shared/src/validators/FieldIsRequired';
import sortBy from 'lodash.sortby';
export const filterAndSortGenericFieldNamesForGenericForm = (schema, wizardCluster, returnDefaultFieldsOnly = false, overview = false) => {
    var _a;
    const fields = (_a = schema === null || schema === void 0 ? void 0 : schema.fields) !== null && _a !== void 0 ? _a : {};
    const fieldNames = Object.keys(fields).filter((fieldIdentifier) => {
        const field = fields[fieldIdentifier];
        return ((overview
            ? field.overviewWizardCluster === wizardCluster
            : field.wizardCluster === wizardCluster) &&
            (!returnDefaultFieldsOnly || field.visibleByDefault));
    });
    return sortGenericFieldNames(fieldNames, fields);
};
export const filterAndSortGenericFieldNames = (schema, wizardCluster, company, payrollProvider, returnDefaultFieldsOnly = false) => {
    var _a;
    const fields = (_a = schema === null || schema === void 0 ? void 0 : schema.fields) !== null && _a !== void 0 ? _a : {};
    const fieldNames = Object.keys(fields).filter((fieldIdentifier) => {
        const field = fields[fieldIdentifier];
        return (hasReadPermission(field, VisibilityPermission.OVERVIEW, company, payrollProvider) &&
            field.wizardCluster === wizardCluster &&
            (!returnDefaultFieldsOnly || field.visibleByDefault));
    });
    return sortGenericFieldNames(fieldNames, fields);
};
export const filterByRightsAndSortGenericFieldNames = (schema, wizardCluster, entity, company, payrollProvider, returnDefaultFieldsOnly = false) => {
    var _a;
    const fields = (_a = schema === null || schema === void 0 ? void 0 : schema.fields) !== null && _a !== void 0 ? _a : {};
    const fieldNames = Object.keys(fields).filter((fieldIdentifier) => {
        var _a;
        const field = fields[fieldIdentifier];
        if (field.type === FieldType.CHILD) {
            const numberOfChildren = (_a = entity.genericFields.childAllowance) === null || _a === void 0 ? void 0 : _a.value;
            const fieldNumber = getChildNumberFromName(fieldIdentifier);
            if (fieldNumber > (numberOfChildren !== null && numberOfChildren !== void 0 ? numberOfChildren : 0)) {
                return false;
            }
        }
        return (hasReadPermission(field, VisibilityPermission.WIZARD, company, payrollProvider) &&
            field.wizardCluster === wizardCluster &&
            (!returnDefaultFieldsOnly || field.visibleByDefault));
    });
    return sortGenericFieldNames(fieldNames, fields);
};
const sortGenericFieldNames = (fieldNames, fields) => {
    return sortBy(fieldNames, (fieldIdentifier) => fields[fieldIdentifier].sortIndex);
};
export const hasEditPermission = (field, company, payrollProvider, isOverview = false) => {
    var _a, _b;
    const editField = isOverview ? 'editableDashboard' : 'editable';
    const isPP = !!payrollProvider;
    return ((_b = (_a = company === null || company === void 0 ? void 0 : company.company) === null || _a === void 0 ? void 0 : _a.companyRoles.some((role) => {
        var _a;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        return (_a = field[editField]) === null || _a === void 0 ? void 0 : _a[role];
    })) !== null && _b !== void 0 ? _b : (isPP && field[editField][PayrollProviderStaffRoleEnum.PP_ADMIN]));
};
var VisibilityPermission;
(function (VisibilityPermission) {
    VisibilityPermission[VisibilityPermission["OVERVIEW"] = 0] = "OVERVIEW";
    VisibilityPermission[VisibilityPermission["WIZARD"] = 1] = "WIZARD";
})(VisibilityPermission || (VisibilityPermission = {}));
export const hasReadPermission = (field, readPermissionType, companyState, payrollProviderState) => {
    var _a;
    return (((_a = companyState === null || companyState === void 0 ? void 0 : companyState.company) === null || _a === void 0 ? void 0 : _a.companyRoles.some((role) => {
        return readPermissionType === VisibilityPermission.OVERVIEW
            ? field.visibleOverview[role]
            : field.visibleWizard[role];
    })) ||
        ((payrollProviderState === null || payrollProviderState === void 0 ? void 0 : payrollProviderState.payrollProvider) &&
            (readPermissionType === VisibilityPermission.OVERVIEW
                ? field.visibleOverview[PayrollProviderStaffRoleEnum.PP_ADMIN]
                : field.visibleWizard[PayrollProviderStaffRoleEnum.PP_ADMIN])));
};
