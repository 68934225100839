export var EmployeeState;
(function (EmployeeState) {
    EmployeeState["DRAFT"] = "DRAFT";
    EmployeeState["ARCHIVED"] = "ARCHIVED";
    EmployeeState["PENDING_APPROVAL"] = "PENDING_APPROVAL";
    EmployeeState["ACTIVE"] = "ACTIVE";
    EmployeeState["DELETED"] = "DELETED";
})(EmployeeState || (EmployeeState = {}));
export var EmployeeInvitationState;
(function (EmployeeInvitationState) {
    EmployeeInvitationState["NONE"] = "NONE";
    EmployeeInvitationState["INVITED"] = "INVITED";
    EmployeeInvitationState["DECLINED"] = "DECLINED";
    EmployeeInvitationState["EXPIRED"] = "EXPIRED";
    EmployeeInvitationState["ACTIVE"] = "ACTIVE";
})(EmployeeInvitationState || (EmployeeInvitationState = {}));
export var EmployeeSalutation;
(function (EmployeeSalutation) {
    EmployeeSalutation["MALE"] = "M";
    EmployeeSalutation["FEMALE"] = "W";
    EmployeeSalutation["NON_BINARY"] = "D";
    EmployeeSalutation["UNDETERMINED"] = "X";
})(EmployeeSalutation || (EmployeeSalutation = {}));
export var InviteResultEnum;
(function (InviteResultEnum) {
    InviteResultEnum["SUCCESS"] = "SUCCESS";
    InviteResultEnum["NO_EMAIL"] = "NO_EMAIL";
    InviteResultEnum["INTERNAL_ERROR"] = "INTERNAL_ERROR";
})(InviteResultEnum || (InviteResultEnum = {}));
export const VALID_EMPLOYEE_STATE_TRANSITIONS = new Map([
    [undefined, [EmployeeState.DRAFT]],
    [
        EmployeeState.ACTIVE,
        [
            EmployeeState.DRAFT,
            EmployeeState.PENDING_APPROVAL,
            EmployeeState.ACTIVE,
        ],
    ],
    [
        EmployeeState.DRAFT,
        [
            EmployeeState.DRAFT,
            EmployeeState.PENDING_APPROVAL,
            EmployeeState.ACTIVE,
        ],
    ],
    [
        EmployeeState.PENDING_APPROVAL,
        [
            EmployeeState.ACTIVE,
            EmployeeState.DRAFT,
            EmployeeState.PENDING_APPROVAL,
        ],
    ],
]);
// Employees can only set these. All other target states need Admin permissions
export const EMPLOYEE_STATES_EMPLOYEES_CAN_SET = [
    EmployeeState.DRAFT,
    EmployeeState.PENDING_APPROVAL,
];
export const EMPLOYEE_STATES_EMPLOYEES_CAN_EDIT_OWN_DATA = [
    EmployeeState.DRAFT,
    EmployeeState.ACTIVE,
];
