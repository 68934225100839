import { Button, ButtonSize, ButtonType } from '@components/button';
import { ReactComponent as Download } from '@public/icons/download-cloud.svg';
import { EMPLOYEE_PAGE_SIZE } from '@utils/constants';
import { EmployeeInviteModal } from '@components/modals/EmployeeInviteModal';
import { UserCompanyRoleEnum, } from '@lohndialog/shared/src/constants';
import { EmployeeStateModal } from '@components/modals/EmployeeStateModal';
import { ReactComponent as Eye } from '@public/icons/eye.svg';
import { FieldType } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { NavLink } from '@components/nav-link';
import { ReactComponent as Plus } from '@public/icons/plus.svg';
import { useNavigate } from '@hooks/useNavigate';
import { useNestClient } from '@hooks/useNestClient';
import { useTranslation } from 'react-i18next';
import { validateEmail, validateString, } from '@lohndialog/shared/src/validation';
import EmployeeTable from '@components/employees/employee-table';
import FileSaver from 'file-saver';
import InputModal from '@components/modals/input-modal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import nestClient from '@api/nest-client';
export default function Employees() {
    const { t } = useTranslation('employeeList');
    const [currentPage, setCurrentPage] = useState(0);
    const [resultPage, setResultPage] = useState(0);
    const [numberOfAvailablePages, setNumberOfAvailablePages] = useState(0);
    const [sort, setSort] = useState(null);
    const [filter, setFilter] = useState('all');
    const [searchTerm, setSearchTerm] = useState();
    const [inviteModalOpen, setInviteModalOpen] = useState(false);
    const [employeeStateModalOpen, setEmployeeStateModalOpen] = useState();
    const [employeeInviteModalOpen, setEmployeeInviteModalOpen] = useState();
    const navigate = useNavigate();
    const { result: employeeSchema, loading: loadingSchema } = useNestClient('getEmployeeSchema', [true]);
    const employeeRequest = useMemo(() => {
        return {
            pageNumber: currentPage === resultPage ? currentPage : resultPage,
            pageSize: EMPLOYEE_PAGE_SIZE,
            filter: filter,
            searchTerm,
            sort: sort !== null && sort !== void 0 ? sort : undefined,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, filter, searchTerm, sort]);
    const { result: employees, loading, reload, } = useNestClient('getPaginatedEmployees', [employeeRequest], {
        data: [],
        totalCount: 0,
        page: 0,
    });
    const [exporting, setExporting] = useState(false);
    const exportEmployees = useCallback(async () => {
        setExporting(true);
        try {
            const file = await nestClient.exportEmployees();
            FileSaver.saveAs(file);
        }
        finally {
            setExporting(false);
        }
    }, []);
    useEffect(() => {
        setNumberOfAvailablePages(Math.ceil(employees.totalCount / EMPLOYEE_PAGE_SIZE));
        setResultPage(employees.page);
    }, [employees]);
    const mapRoleToUserCompanyRole = (isAdmin) => {
        return isAdmin === 'yes'
            ? [UserCompanyRoleEnum.ADMIN, UserCompanyRoleEnum.USER]
            : [UserCompanyRoleEnum.USER];
    };
    const inviteEmployee = async (input) => {
        if (input.email && input.role) {
            return await nestClient.inviteEmployee(Object.assign(Object.assign({}, input), { email: input.email, role: mapRoleToUserCompanyRole(input.role) }));
        }
    };
    const submitEmployee = async (input) => {
        const employeeId = await inviteEmployee(input);
        if (employeeId) {
            navigate(`/employees/${employeeId}`);
        }
    };
    const updatePage = (newPage) => {
        setResultPage(newPage);
        setCurrentPage(newPage);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "md:flex justify-between md:space-x-4" },
            React.createElement("div", null,
                React.createElement("h1", null, t('title')),
                React.createElement("div", { className: "sm:flex sm:items-center" },
                    React.createElement("div", { className: "sm:flex-auto" },
                        React.createElement("p", { className: "mt-2 text-sm text-gray-700" }, t('employeeView'))))),
            React.createElement("div", { className: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:flex text-sm font-medium items-center justify-items-center lg:space-y-0 gap-3" },
                React.createElement(Button, { IconLeading: Eye, label: t('log'), buttonType: ButtonType.SECONDARY, buttonSize: ButtonSize.LARGE, className: "h-full w-full xl:w-fit bg-white" }),
                React.createElement(Button, { IconLeading: Download, label: t('export'), buttonType: ButtonType.SECONDARY, buttonSize: ButtonSize.LARGE, loading: exporting, className: "h-full w-full xl:w-fit bg-white", onClick: () => void exportEmployees() }),
                React.createElement(Button, { IconLeading: Plus, label: t('invite'), buttonType: ButtonType.PRIMARY, buttonSize: ButtonSize.LARGE, className: "h-full w-full xl:w-fit", onClick: () => setInviteModalOpen(true), "data-testid": "invite-employee-button" }),
                React.createElement(NavLink, { to: "/employees/new", className: "w-full h-full xl:w-fit" },
                    React.createElement(Button, { IconLeading: Plus, label: t('add'), buttonType: ButtonType.PRIMARY, buttonSize: ButtonSize.LARGE, className: "h-full w-full xl:w-fit" })))),
        React.createElement(InputModal, { modalTitle: t('invite'), explanation: React.createElement("p", null, t('inviteDetail')), configObject: {
                firstName: {
                    placeHolder: t('firstName'),
                    title: t('firstName'),
                    required: false,
                    fieldType: FieldType.TEXT,
                },
                lastName: {
                    placeHolder: t('lastName'),
                    title: t('lastName'),
                    required: false,
                    fieldType: FieldType.TEXT,
                },
                email: {
                    placeHolder: t('email'),
                    title: t('email'),
                    required: true,
                    validationFunction: validateEmail,
                    fieldType: FieldType.TEXT,
                },
                role: {
                    placeHolder: t('isHr'),
                    title: t('isHr'),
                    required: true,
                    validationFunction: validateString,
                    fieldType: FieldType.CHECKBOX,
                },
            }, submitFunction: (input) => submitEmployee(input), nextFunction: (input) => inviteEmployee(input), cancelLabel: t('cancel'), submitLabel: t('submit'), nextLabel: t('next'), open: inviteModalOpen, setOpen: setInviteModalOpen }),
        React.createElement(EmployeeTable, { employees: employees.data, currentPage: resultPage, setCurrentPage: updatePage, numberOfAvailablePages: numberOfAvailablePages, sort: sort !== null && sort !== void 0 ? sort : undefined, setSort: setSort, loading: loading || loadingSchema, stateFilter: filter, setStateFilter: setFilter, employeeSchema: employeeSchema, totalCount: employees.totalCount, setSearchTerm: setSearchTerm, setEmployeeInviteModalToOpen: setEmployeeInviteModalOpen, setEmployeeStateModalToOpen: setEmployeeStateModalOpen, refreshEmployees: reload }),
        employeeStateModalOpen && (React.createElement(EmployeeStateModal, { dialogOpen: !!employeeStateModalOpen, setDialogOpen: (open) => !open && setEmployeeStateModalOpen(undefined), employeeId: employeeStateModalOpen, refreshEmployees: reload })),
        employeeInviteModalOpen && (React.createElement(EmployeeInviteModal, { dialogOpen: !!employeeInviteModalOpen, setDialogOpen: (open) => !open && setEmployeeInviteModalOpen(undefined), employeeId: employeeInviteModalOpen, refreshEmployees: reload }))));
}
