import { LoadingSpinner } from '@components/loading-spinner';
import { redirectForRole } from '../routing/redirectForRole';
import { useAuth } from '../context/auth-context';
// eslint-disable-next-line no-restricted-imports
import { useNavigate } from 'react-router-dom';
import { useProfile } from '../context/profile-context';
import { useTranslation } from 'react-i18next';
import NoCompanyError from '@components/errors/NoCompanyError';
import React, { useEffect, useState } from 'react';
export default function LandingPage() {
    const { isAuthenticated, login, isLoading } = useAuth();
    const navigate = useNavigate();
    const profile = useProfile();
    const [error, setError] = useState();
    const { t } = useTranslation('errors');
    useEffect(() => {
        void redirectForRole(isAuthenticated, profile, navigate, setError, t, login, isLoading);
    }, [profile, navigate, isAuthenticated, t, login, isLoading]);
    return error ? React.createElement(NoCompanyError, { error: error }) : React.createElement(LoadingSpinner, null);
}
