import { ReactComponent as ArrowRightIcon } from '@public/icons/arrow-right.svg';
import { Button, ButtonSize, ButtonType } from '@components/button';
import { EMPLOYEE_STATES_EMPLOYEES_CAN_EDIT_OWN_DATA, EmployeeState, SelfServiceState, UserCompanyRoleEnum, } from '@lohndialog/shared/src/constants';
import { FieldType, } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { ReactComponent as HelpCircleIcon } from '@public/icons/help-circle.svg';
import { LoadingSpinner } from '@components/loading-spinner';
import { Wizard } from '@components/wizard/wizard';
import { useCompany } from '../../context/company-context';
import { useNavigate } from '@hooks/useNavigate';
import { useNestClient } from '@hooks/useNestClient';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReviewStep from '@components/employee-wizard/review-step';
import Step1 from '@components/employee-wizard/step1';
import Step2 from '@components/employee-wizard/step2';
import Step3 from '@components/employee-wizard/step3';
import Step4 from '@components/employee-wizard/step4';
export function EmployeeWizard({ initialEmployee, onClose, onFinish, refreshEmployee, }) {
    var _a;
    const [employee, setEmployee] = useState(initialEmployee);
    const [saving, setSaving] = useState(false);
    const { company } = useCompany();
    const isPP = useMemo(() => !!(company === null || company === void 0 ? void 0 : company.companyRoles.includes(UserCompanyRoleEnum.PP_ADMIN)), [company === null || company === void 0 ? void 0 : company.companyRoles]);
    const isAdmin = useMemo(() => isPP || !!(company === null || company === void 0 ? void 0 : company.companyRoles.includes(UserCompanyRoleEnum.ADMIN)), [company === null || company === void 0 ? void 0 : company.companyRoles, isPP]);
    const navigate = useNavigate();
    // update state if state changes from outside to always display the up to date buttons
    useEffect(() => {
        setEmployee(Object.assign(Object.assign({}, employee), { state: initialEmployee.state }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialEmployee]);
    useEffect(() => {
        var _a, _b;
        const genericFields = employee.genericFields;
        for (let i = 14; i >
            ((_b = (_a = employee.genericFields.childAllowance) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : 0); i--) {
            const childFieldName = `child${i}`;
            delete genericFields[childFieldName];
        }
        setEmployee(Object.assign(Object.assign({}, employee), { genericFields }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(_a = employee.genericFields.childAllowance) === null || _a === void 0 ? void 0 : _a.value]);
    const { result: employeeSchema, loading, error, } = useNestClient('getEmployeeSchema', [isAdmin]);
    const { result: companyFields, loading: companyFieldsLoading, error: companyFieldsError, } = useNestClient('getCompanyFields', [], {
        fields: [],
    });
    const { t } = useTranslation('employeeWizard');
    const { t: tCommon } = useTranslation('common');
    const allowEdit = useMemo(() => {
        return (((company === null || company === void 0 ? void 0 : company.companyUserManagement) === SelfServiceState.ACTIVE &&
            EMPLOYEE_STATES_EMPLOYEES_CAN_EDIT_OWN_DATA.includes(employee.state)) ||
            isAdmin);
    }, [company === null || company === void 0 ? void 0 : company.companyUserManagement, employee.state, isAdmin]);
    const allowSave = useMemo(() => allowEdit &&
        (isPP ||
            (employee.state !== EmployeeState.ACTIVE &&
                employee.state !== EmployeeState.PENDING_APPROVAL)), [allowEdit, employee.state, isPP]);
    const finish = useCallback(async () => {
        setSaving(true);
        // There might be some strings in the generic number fields that need to be converted to numbers
        // ToDo: LD-521
        const cleanFields = {};
        Object.entries(employee.genericFields).forEach(([key, value]) => {
            if ((value === null || value === void 0 ? void 0 : value.type) === FieldType.NUMBER) {
                cleanFields[key] = {
                    type: value.type,
                    value: parseFloat(value.value.toString()),
                };
            }
            else {
                cleanFields[key] = value;
            }
        });
        employee.genericFields = cleanFields;
        try {
            await onFinish(employee, employeeSchema);
        }
        catch (_a) {
            refreshEmployee();
        }
        finally {
            setSaving(false);
        }
    }, [employee, employeeSchema, onFinish, refreshEmployee]);
    const updateEmployee = useCallback((fieldIdentifier, type, newValue) => {
        if (newValue === undefined || newValue === '') {
            const rest = Object.assign({}, employee.genericFields);
            delete rest[fieldIdentifier];
            setEmployee((value) => (Object.assign(Object.assign({}, value), { genericFields: rest, hasConfirmedData: false })));
        }
        else if (fieldIdentifier === 'special_consent') {
            setEmployee((value) => (Object.assign(Object.assign({}, value), { hasConfirmedData: newValue })));
        }
        else {
            setEmployee((value) => (Object.assign(Object.assign({}, value), { genericFields: Object.assign(Object.assign({}, value.genericFields), { [fieldIdentifier]: {
                        value: newValue,
                        type,
                    } }), hasConfirmedData: false })));
        }
    }, [employee.genericFields]);
    const steps = useMemo(() => {
        const steps = [
            Step1(employeeSchema, companyFields, !allowEdit),
            Step2(employeeSchema, companyFields, !allowEdit),
            Step3(employeeSchema, companyFields, !allowEdit),
        ];
        if (isAdmin) {
            steps.push(Step4(employeeSchema, companyFields, !allowEdit));
        }
        steps.push(ReviewStep(employeeSchema, () => {
            navigate(isAdmin ? '/employees' : '/dashboard');
        }));
        return steps;
    }, [companyFields, employeeSchema, isAdmin, navigate, allowEdit]);
    if (loading || companyFieldsLoading) {
        return (React.createElement("div", { className: "w-full h-full flex justify-center items-center" },
            React.createElement(LoadingSpinner, null)));
    }
    if (error || companyFieldsError) {
        // let the router handle this error
        throw error;
    }
    return (React.createElement(Wizard, { setValue: updateEmployee, value: employee, onClose: onClose, showCloseButton: true, steps: steps, onFinish: finish, actionButtons: React.createElement(React.Fragment, null,
            React.createElement(Button, { label: t('help'), buttonType: ButtonType.SECONDARY, buttonSize: ButtonSize.SMALL, IconLeading: HelpCircleIcon, 
                // TODO LD-128: make visible as soon as texts are provided
                className: "text-white w-full mb-4 hidden", iconClassName: "text-white" }),
            allowSave ? (React.createElement(Button, { label: t('saveAndExit'), buttonType: ButtonType.PRIMARY, buttonSize: ButtonSize.SMALL, className: "w-full !bg-white !text-p200Green", onClick: () => void finish(), loading: saving })) : (React.createElement(Button, { label: tCommon('button.close'), buttonType: ButtonType.PRIMARY, buttonSize: ButtonSize.SMALL, className: "w-full !bg-white !text-p200Green", onClick: () => {
                    onClose();
                } }))), finalStepActions: [
            allowSave
                ? {
                    buttonProps: {
                        label: tCommon('button.save'),
                        buttonSize: ButtonSize.LARGE,
                        buttonType: ButtonType.PRIMARY,
                        onClick: () => {
                            void finish();
                        },
                        testId: 'finishBtn',
                        type: 'submit',
                        className: 'flex-1 max-w-[12rem] w-0',
                        IconTrailing: ArrowRightIcon,
                    },
                    key: 'employee-save',
                }
                : {
                    buttonProps: {
                        label: tCommon('button.close'),
                        buttonSize: ButtonSize.LARGE,
                        buttonType: ButtonType.PRIMARY,
                        onClick: () => {
                            onClose();
                        },
                        testId: 'closeBtn',
                        type: 'submit',
                        className: 'flex-1 max-w-[12rem] w-0',
                        IconTrailing: ArrowRightIcon,
                    },
                    key: 'employee-close',
                },
        ] }));
}
