import { CompanyContext } from './company-context';
import { ReactComponent as InfoIcon } from '@public/icons/info.svg';
import { NotificationType, } from '@components/notifications/notification';
import { SelfServiceState } from '@lohndialog/shared/src/constants';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { createContext, useContext, useEffect, useLayoutEffect, useMemo, useState, } from 'react';
export const NotificationContext = createContext(undefined);
export const NotificationProvider = ({ children, }) => {
    const [notificationsToDisplay, setNotificationsToDisplay] = useState([]);
    const [notificationCounter, setNotificationCounter] = useState(0);
    const state = useMemo(() => {
        const removeNotification = (id) => {
            setNotificationsToDisplay((notificationsToDisplay) => notificationsToDisplay.filter((notification) => notification.id !== id));
        };
        const addNotification = (notification) => {
            const newNotification = Object.assign(Object.assign({}, notification), { id: notificationCounter, onClose: () => {
                    removeNotification(notificationCounter);
                } });
            setNotificationCounter(notificationCounter + 1);
            setNotificationsToDisplay([
                ...notificationsToDisplay,
                newNotification,
            ]);
            return newNotification.id;
        };
        const addNotifications = (notifications) => {
            const newNotifications = notifications.map((notification, index) => (Object.assign(Object.assign({}, notification), { id: notificationCounter + index, onClose: () => {
                    removeNotification(notificationCounter + index);
                } })));
            setNotificationCounter(notificationCounter + newNotifications.length);
            setNotificationsToDisplay([
                ...notificationsToDisplay,
                ...newNotifications,
            ]);
            return newNotifications.map((element) => element.id);
        };
        return {
            notifications: notificationsToDisplay,
            addNotification,
            addNotifications,
            removeNotification,
        };
    }, [notificationCounter, notificationsToDisplay]);
    return (React.createElement(NotificationContext.Provider, { value: state }, children));
};
export function useProfileNotification() {
    var _a, _b;
    const state = useContext(NotificationContext);
    if (!state) {
        throw new Error('useProfileNotification must be used within a NotificationContext');
    }
    const { notifications, addNotification, removeNotification } = state;
    const { t } = useTranslation('notificationHandler');
    const company = (_a = useContext(CompanyContext)) === null || _a === void 0 ? void 0 : _a.company;
    const [notificationId, setNotificationId] = useState();
    const location = useLocation();
    useEffect(() => {
        var _a;
        if (((_a = company === null || company === void 0 ? void 0 : company.employee) === null || _a === void 0 ? void 0 : _a.id) !== undefined &&
            !company.employeeProfileComplete &&
            !notifications.find((notification) => notification.id === notificationId) &&
            company.companyUserManagement === SelfServiceState.ACTIVE) {
            const id = addNotification({
                notificationType: NotificationType.ERROR,
                title: t('profileIncomplete', {
                    companySlug: company.slug,
                    id: company.employee.id,
                }),
                Icon: InfoIcon,
            });
            setNotificationId(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(_b = company === null || company === void 0 ? void 0 : company.employee) === null || _b === void 0 ? void 0 : _b.id, company === null || company === void 0 ? void 0 : company.employeeProfileComplete]);
    useLayoutEffect(() => {
        if (notificationId !== undefined &&
            location.pathname.includes('/employees/')) {
            removeNotification(notificationId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, notificationId]);
}
