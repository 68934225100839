import { ReactComponent as AlertTriangleIcon } from '@public/icons/alert-triangle.svg';
import { ButtonSize, ButtonType } from '@components/button';
import { Wizard as ReactUseWizard } from 'react-use-wizard';
import { useTranslation } from 'react-i18next';
import Dialog from '@components/modals/dialog';
import React, { useState } from 'react';
export function DashboardWizard({ steps, value, setValue, actionButtons, onClose, onFinish, }) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [saving, setSaving] = useState(false);
    const { t } = useTranslation('wizard');
    return (React.createElement("div", { className: "w-full bg-white" },
        React.createElement(ReactUseWizard, null, steps.map(({ ReactComponent, key }) => (React.createElement(ReactComponent, { value: value, setValue: (...args) => {
                setUnsavedChanges(true);
                setValue(...args);
            }, unsavedChanges: unsavedChanges, key: key })))),
        React.createElement("div", { className: "fixed w-full 2md:w-[calc(100vw-328px)] lg:w-[min(76rem,_calc(100vw-344px))] left-0 2md:left-auto bottom-0 mx-auto h-32 bg-p200Green flex flex-col justify-center" },
            React.createElement("div", { className: "w-full flex flex-col xs:flex-row p-10 gap-4" }, actionButtons)),
        React.createElement(Dialog, { dismissible: true, open: dialogOpen, setOpen: setDialogOpen, title: t('cancelDialog.title'), Icon: AlertTriangleIcon, actions: [
                {
                    buttonProps: {
                        label: t('cancelDialog.saveAndExitBtn'),
                        buttonSize: ButtonSize.SMALL,
                        buttonType: ButtonType.PRIMARY,
                        onClick: () => {
                            var _a;
                            setSaving(true);
                            ((_a = onFinish(value)) !== null && _a !== void 0 ? _a : Promise.resolve()).finally(() => setSaving(false));
                        },
                        loading: saving,
                        tabIndex: 1,
                        testId: 'saveAndExitBtn',
                    },
                },
                {
                    buttonProps: {
                        label: t('cancelDialog.discardBtn'),
                        buttonSize: ButtonSize.SMALL,
                        buttonType: ButtonType.DESTRUCTIVE,
                        onClick: () => {
                            setDialogOpen(false);
                            onClose();
                        },
                        tabIndex: 2,
                        testId: 'discardBtn',
                    },
                },
            ] }, t('cancelDialog.exitWithoutSave'))));
}
