import { Entry, ProfileParagraph } from '@components/profile/profile-paragraph';
import { NavLink } from '@components/nav-link';
import { useCompany } from '../../context/company-context';
import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
export function EmployeeProfileCard() {
    var _a;
    const { company } = useCompany();
    const { t } = useTranslation('profile');
    const getValueFromGenericFields = useCallback((company, keys) => {
        const employee = company.employee;
        if (!Array.isArray(keys)) {
            keys = [keys];
        }
        else if (keys.length === 0) {
            return '';
        }
        const mappedValues = keys.map((x) => {
            var _a;
            const value = (_a = employee === null || employee === void 0 ? void 0 : employee.genericFields[x]) === null || _a === void 0 ? void 0 : _a.value;
            return value ? value : '-';
        });
        if (mappedValues.every((value) => value === '-')) {
            return '-';
        }
        return mappedValues.join(', ');
    }, []);
    const getTranslationOfKeys = useCallback((keys) => {
        if (!Array.isArray(keys)) {
            keys = [keys];
        }
        const property = keys.map((x) => t(x)).join(', ');
        if (!property) {
            return '';
        }
        return `${property}:`;
    }, [t]);
    const genericFieldKeys = [
        ['street', 'streetNumber'],
        'lastname',
        ['postalCode', 'city'],
        'firstname',
        [],
        'phone',
        'email',
        'mobile',
    ];
    if (!company) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(ProfileParagraph, { title: t('employeeData') },
        React.createElement(Entry, { property: `${t('company')}:`, value: company.name }),
        genericFieldKeys.map((keys) => {
            return (React.createElement(Entry, { key: getTranslationOfKeys(keys), property: getTranslationOfKeys(keys), value: getValueFromGenericFields(company, keys) }));
        }),
        React.createElement(NavLink, { to: `/employees/${(_a = company.employee) === null || _a === void 0 ? void 0 : _a.id}` },
            React.createElement("u", null, t('editEmployee')))));
}
