var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21;
import 'reflect-metadata';
import { AUTHORIZATION_FAILED } from '@utils/constants';
import { ApiError } from '@api/errors';
import { BooleanStringEnum } from '@lohndialog/shared/src/util/boolean-string-enum';
import { COMPANY_SLUG_HEADER, EmployeeState, EntityEnum, } from '@lohndialog/shared/src/constants';
import { Environment } from '../environment.global';
import { ServerErrorCode } from '@lohndialog/shared/src/server-error-codes';
import Axios from 'axios';
export const REQUIRES_COMPANY_KEY = 'requiresCompany';
function RequiresCompany() {
    return Reflect.metadata(REQUIRES_COMPANY_KEY, true);
}
export const REQUIRES_PAYROLL_PROVIDER_KEY = 'requiresPayrollProvider';
function RequiresPayrollProvider() {
    return Reflect.metadata(REQUIRES_PAYROLL_PROVIDER_KEY, true);
}
function mapBooleanToStringType(value) {
    if (value === undefined) {
        return BooleanStringEnum.UNDEFINED;
    }
    return value ? BooleanStringEnum.TRUE : BooleanStringEnum.FALSE;
}
const MULTIPART_FORM_DATA = 'multipart/form-data';
const SCHEMA_GET = '/api/schema/get';
const UTF_8_CHARSET = 'text/plain;charset=utf-8';
export class NestClient {
    constructor() {
        this.responseSuccessHandlers = [];
        this.addError = () => {
            // noop
        };
        this.axios = Axios.create();
        this.axios.defaults.withCredentials = true;
    }
    addResponseHandler(handler) {
        this.responseSuccessHandlers.push(handler);
    }
    notifyResponseSuccess(response) {
        return Promise.all(this.responseSuccessHandlers.map((handler) => handler.onSuccessfulResponse(response)));
    }
    setErrorHandler(addError) {
        this.addError = addError;
    }
    checkForbidden(errorCode) {
        if (errorCode === 403) {
            window.dispatchEvent(new Event(AUTHORIZATION_FAILED));
        }
    }
    async checkResponse(response, handleError) {
        if (response.status < 300 && response.status >= 200) {
            await this.notifyResponseSuccess(response);
            return response.data;
        }
        this.checkForbidden(response.status);
        const newError = new ApiError(response.status, response.data.message);
        if (handleError) {
            this.addError(newError);
        }
        throw newError;
    }
    async apiGet(path, params, requestConfig, fallBackResponse) {
        try {
            const url = `${Environment.NEST_API_URL}${path}`;
            return (await this.axios.get(url, await this.getRequestConfig(Object.assign(Object.assign({}, requestConfig), { params })))).data;
        }
        catch (error) {
            if (fallBackResponse) {
                return fallBackResponse;
            }
            else {
                throw error;
            }
        }
    }
    async apiDelete(path, params, fallBackResponse) {
        try {
            const url = `${Environment.NEST_API_URL}${path}`;
            return (await this.axios.delete(url, await this.getRequestConfig({ params }))).data;
        }
        catch (error) {
            if (fallBackResponse) {
                return fallBackResponse;
            }
            else {
                throw error;
            }
        }
    }
    async postRoute(route, body, params, headers, handleError = true, onUploadProgress, responseType) {
        const response = await this.post(route, body, params, headers, onUploadProgress, responseType);
        return this.checkResponse(response, handleError);
    }
    async post(route, body, params, headers, onUploadProgress, responseType) {
        const url = `${Environment.NEST_API_URL}${route}`;
        return this.axios
            .post(url, body, await this.getRequestConfig({
            params,
            headers,
            onUploadProgress,
            responseType,
        }))
            .catch((error) => {
            var _a, _b, _c;
            let message = ServerErrorCode.POST_ROUTE_FAILED;
            let payload;
            if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) &&
                error.response.data.message in
                    ServerErrorCode) {
                message = error.response.data
                    .message;
                switch (message) {
                    case ServerErrorCode.VALIDATION_FAILED:
                        payload = {
                            validationErrors: error.response.data.validationErrors,
                        };
                        break;
                    case ServerErrorCode.NO_EMPLOYEE:
                        payload = {
                            errorKey: ServerErrorCode.NO_EMPLOYEE,
                        };
                        break;
                    case ServerErrorCode.NO_COMPANY:
                        payload = {
                            errorKey: ServerErrorCode.NO_COMPANY,
                        };
                        break;
                    case ServerErrorCode.INVALID_FILE:
                        payload = {
                            errorKey: ServerErrorCode.INVALID_FILE,
                        };
                        break;
                }
            }
            const apiError = new ApiError((_c = (_b = error.response) === null || _b === void 0 ? void 0 : _b.status) !== null && _c !== void 0 ? _c : 503, message, payload);
            this.addError(apiError);
            throw apiError;
        });
    }
    async createEmployee(employee) {
        return await this.postRoute('/api/employees/add', employee);
    }
    async getEmployee(id) {
        return await this.apiGet(`/api/employees/${id}`);
    }
    async updateEmployee(data) {
        await this.postRoute('/api/employees/update', data);
    }
    async submitEmployeeForApproval(data, targetState) {
        let id = data.id;
        if (id === '-1') {
            id = await this.createEmployee(data);
        }
        const payload = Object.assign(Object.assign({}, data), { id, state: targetState });
        return this.updateEmployee(payload);
    }
    async rejectEmployee(data, targetState) {
        const payload = Object.assign(Object.assign({}, data), { state: targetState });
        return await this.postRoute('/api/employees/reject', payload);
    }
    async getPaginatedEmployees(data) {
        var _a;
        let preparedSort = [];
        switch ((_a = data.sort) === null || _a === void 0 ? void 0 : _a.fieldName) {
            case 'name':
                preparedSort = [
                    {
                        fieldName: 'genericField.lastname',
                        direction: data.sort.direction,
                    },
                    {
                        fieldName: 'genericField.firstname',
                        direction: data.sort.direction,
                    },
                ];
                break;
            case 'state':
            case 'inviteState':
            case 'creationDate':
                preparedSort = [data.sort];
                break;
            case 'userEmail':
                preparedSort = [
                    {
                        fieldName: 'extra.user.email',
                        direction: data.sort.direction,
                    },
                ];
                break;
            case null:
            case undefined:
                break;
            default:
                preparedSort = [
                    {
                        fieldName: `genericField.${data.sort.fieldName}`,
                        direction: data.sort.direction,
                    },
                ];
        }
        const params = {
            filter: data.filter,
            itemsPerPage: data.pageSize,
            page: data.pageNumber,
            sort: preparedSort,
            searchTerm: data.searchTerm,
        };
        return await this.apiGet('/api/employees/find', params);
    }
    async getPaginatedUserLessEmployees(ids, pageNumber, pageSize, sort) {
        let preparedSort = [];
        switch (sort === null || sort === void 0 ? void 0 : sort.fieldName) {
            case 'name':
                preparedSort = [
                    {
                        fieldName: 'genericField.lastname',
                        direction: sort.direction,
                    },
                    {
                        fieldName: 'genericField.firstname',
                        direction: sort.direction,
                    },
                ];
                break;
            case null:
            case undefined:
                break;
            default:
                preparedSort = [
                    {
                        fieldName: `genericField.${sort.fieldName}`,
                        direction: sort.direction,
                    },
                ];
        }
        const params = {
            ids: ids,
            page: pageNumber,
            itemsPerPage: pageSize,
            sort: preparedSort,
        };
        return await this.apiGet('/api/employees/userLess', params);
    }
    async inviteEmployee(data) {
        return this.postRoute('/api/employees/invite', data);
    }
    async inviteHrEmployee(data, companySlug) {
        return this.postRoute('/api/employees/invite', data, {}, { [COMPANY_SLUG_HEADER]: companySlug });
    }
    async sendEmployeeInvite(id, input) {
        return this.postRoute(`/api/employees/${id}/sendInvite`, input);
    }
    async sendBulkEmployeeInvite(id) {
        return this.postRoute(`/api/employees/${id}/sendBulkInvite`);
    }
    async withdrawEmployeeInvite(id) {
        return this.postRoute(`/api/employees/${id}/withdrawInviteToken`);
    }
    async createEAUEntry(eAUEntry) {
        return await this.postRoute('/api/eAUs/add', eAUEntry);
    }
    async getEAUSchema() {
        return Promise.resolve({
            fields: {},
        });
    }
    async getEAUEntry(id) {
        return await this.apiGet(`/api/eAUs/${id}`);
    }
    async updateEAUEntry(data) {
        await this.postRoute('/api/eAUs/update', data);
    }
    async getPaginatedEAUEntries(pageNumber, pageSize, sort = null) {
        let preparedSort = [];
        switch (sort === null || sort === void 0 ? void 0 : sort.fieldName) {
            case 'employeeName':
                preparedSort = [
                    {
                        fieldName: 'employee.genericField.lastname',
                        direction: sort.direction,
                    },
                    {
                        fieldName: 'employee.genericField.firstname',
                        direction: sort.direction,
                    },
                ];
                break;
            case null:
            case undefined:
                break;
            default:
                preparedSort = [sort];
        }
        const params = {
            itemsPerPage: pageSize,
            page: pageNumber,
            sort: preparedSort,
        };
        return await this.postRoute('/api/eAUs/find', {}, params);
    }
    // eslint-disable-next-line sonarjs/no-identical-functions
    async getDocumentSchema() {
        return Promise.resolve({
            fields: {},
        });
    }
    async createEmployeeDocumentEntry(documentEntry) {
        return await this.postRoute('/api/documents/employee/add', documentEntry);
    }
    async createPayrollProviderDocumentEntry(documentEntry) {
        return await this.postRoute('/api/documents/payrollProvider/add', documentEntry);
    }
    async getDocument(id) {
        return await this.apiGet(`/api/documents/${id}`);
    }
    async updateDocument(data) {
        await this.postRoute('/api/documents/update', data);
    }
    async deleteDocument(documentId) {
        await this.apiDelete(`/api/documents/delete/${documentId}`);
    }
    async bulkDeleteDocument(documentIds) {
        const params = {
            documentIds,
        };
        await this.apiDelete(`/api/documents/bulkDelete`, params);
    }
    async getAccountingPeriodData(data) {
        var _a;
        let preparedSort = [];
        switch ((_a = data.sort) === null || _a === void 0 ? void 0 : _a.fieldName) {
            case 'timeframe':
            case 'name':
                preparedSort = [
                    {
                        fieldName: 'accountingPeriodYear',
                        direction: data.sort.direction,
                    },
                    {
                        fieldName: 'accountingPeriodMonth',
                        direction: data.sort.direction,
                    },
                ];
                break;
            case null:
            case undefined:
                break;
            default:
                preparedSort = [data.sort];
        }
        const employeeDocumentsFormatted = !!data.employeeDocuments;
        const params = {
            itemsPerPage: data.pageSize,
            page: data.pageNumber,
            sort: preparedSort,
            personal: data.personal,
            employeeDocuments: employeeDocumentsFormatted
                ? BooleanStringEnum.TRUE
                : BooleanStringEnum.FALSE,
        };
        return await this.apiGet(`/api/documents/accountingPeriodData`, params);
    }
    async getPaginatedDocuments(data) {
        var _a;
        let preparedSort = [];
        switch ((_a = data.sort) === null || _a === void 0 ? void 0 : _a.fieldName) {
            case 'employeeName':
                preparedSort = [
                    {
                        fieldName: 'assignedEmployee.genericField.lastname',
                        direction: data.sort.direction,
                    },
                    {
                        fieldName: 'assignedEmployee.genericField.firstname',
                        direction: data.sort.direction,
                    },
                ];
                break;
            case null:
            case undefined:
                break;
            default:
                preparedSort = [data.sort];
        }
        let accountingPeriodMonth;
        if (data.accountingPeriodMonth === 'null') {
            accountingPeriodMonth = data.accountingPeriodMonth;
        }
        else {
            accountingPeriodMonth = data.accountingPeriodMonth
                ? parseInt(data.accountingPeriodMonth)
                : undefined;
        }
        let accountingPeriodYear;
        if (data.accountingPeriodYear === 'null') {
            accountingPeriodYear = data.accountingPeriodYear;
        }
        else {
            accountingPeriodYear = data.accountingPeriodYear
                ? parseInt(data.accountingPeriodYear)
                : undefined;
        }
        const params = {
            itemsPerPage: data.pageSize,
            page: data.pageNumber,
            sort: preparedSort,
            searchTerm: data.searchTerm,
            personal: data.personal,
            accountingPeriodYear,
            accountingPeriodMonth,
            employeeDocuments: mapBooleanToStringType(data.employeeDocuments),
        };
        return await this.apiGet('/api/documents/find', params);
    }
    createUserCompanyRole() {
        throw new Error('Not implemented');
    }
    async getProfile() {
        return await this.apiGet('/api/profile');
    }
    async getEmployeeSchema(includeDisabled) {
        return await this.apiGet(SCHEMA_GET, {
            entity: EntityEnum.EMPLOYEE,
            includeDisabled,
        });
    }
    async searchablePossibleValues(id, searchTerm, language) {
        return await this.apiGet(`/api/schema/searchPossibleValuesForField`, {
            id,
            searchTerm,
            language,
        });
    }
    async getPossibleValueForIdAndName(id, name, language) {
        return await this.apiGet(`/api/schema/getPossibleValueForFieldAndName`, {
            id,
            name,
            language,
        });
    }
    async getPayrollProviderSchema(includeDisabled) {
        return await this.apiGet(SCHEMA_GET, {
            entity: EntityEnum.PAYROLL_PROVIDER,
            includeDisabled,
        });
    }
    async getCompanySchema(includeDisabled, overview) {
        return await this.apiGet(SCHEMA_GET, {
            entity: EntityEnum.COMPANY,
            includeDisabled,
            overview,
        });
    }
    async getPayrollProviderFields(companyId) {
        return await this.apiGet(`/api/companies/payrollProviderFields/${companyId}`);
    }
    async getCompanyFields() {
        return await this.apiGet(`/api/employees/companyFields`);
    }
    async addSchemaFields(data) {
        return await this.postRoute('/api/schema/addFields', Object.assign(Object.assign({}, data.schema), { entity: data.entity }));
    }
    async updateSchemaField(data) {
        return await this.postRoute('/api/schema/updateField', data);
    }
    async deleteSchemaField(id) {
        return await this.apiDelete(`/api/schema/${id}`);
    }
    async getCardCurrentAccountingPeriod() {
        return await this.apiGet('/api/cards/currentAccountingPeriod');
    }
    async getCardNumberOfEmployees() {
        return await this.apiGet('/api/cards/numberOfEmployees');
    }
    async getCardNewEmployees() {
        return await this.apiGet('/api/cards/newEmployees');
    }
    async getUserData(userId) {
        return await this.apiGet(`/api/auth0/getData/${userId}`);
    }
    async updateUserEmail(userId, email) {
        const currentData = await this.getUserData(userId);
        return await this.postRoute('/api/auth0/updateData', {
            id: userId,
            data: {
                email: email,
                name: currentData.name,
                nickname: currentData.nickname,
                picture: currentData.picture,
            },
        });
    }
    async updateUserPassword(userId) {
        const result = await this.postRoute('/api/auth0/changePassword', { user_id: userId });
        return result.ticket;
    }
    getRequestConfig(config) {
        return [
            this.companyHandler,
            this.authHandler,
            this.payrollHandler,
        ].reduce(async (config, handler) => { var _a; return (_a = (await (handler === null || handler === void 0 ? void 0 : handler.onBeforeRequest(await config)))) !== null && _a !== void 0 ? _a : config; }, Promise.resolve(config));
    }
    async getCompany() {
        return await this.apiGet(`/api/companies`);
    }
    async getCompanyById(companyId) {
        return await this.apiGet(`/api/companies/get/${companyId}`);
    }
    async updateCompany(companyDto) {
        return await this.postRoute('/api/companies/update', companyDto, undefined, 
        // set COMPANY_SLUG_HEADER manually because we need it even for payroll provider requests
        { [COMPANY_SLUG_HEADER]: companyDto.slug });
    }
    async acceptInviteToken(tokenId) {
        await this.postRoute(`/api/invite/${tokenId}/accept`);
    }
    async acceptPayrollProviderInviteToken(tokenId) {
        await this.postRoute(`/api/payrollProviderInvite/${tokenId}/accept`);
    }
    async declineInviteToken(tokenId) {
        await this.postRoute(`/api/invite/${tokenId}/decline`);
    }
    async declinePayrollProviderInviteToken(tokenId) {
        await this.postRoute(`/api/payrollProviderInvite/${tokenId}/decline`);
    }
    async getTokenInformation(tokenId) {
        return await this.apiGet(`/api/invite/${tokenId}/information`);
    }
    getPayrollProviderTokenInformation(tokenId) {
        return this.apiGet(`/api/payrollProviderInvite/${tokenId}/information`);
    }
    async createCompany(body) {
        await this.post('/api/companies/create', body);
    }
    async getCompanies() {
        return await this.apiGet('/api/companies/getAll');
    }
    startGenericFieldImport(file, onUploadProgress) {
        const formData = new FormData();
        formData.append('file', file);
        return this.postRoute(`/api/import/GenericField`, formData, undefined, {
            'Content-Type': MULTIPART_FORM_DATA,
        }, undefined, (progressEvent) => {
            var _a;
            onUploadProgress((_a = progressEvent.progress) !== null && _a !== void 0 ? _a : 0);
        });
    }
    startImport(file, onUploadProgress) {
        const formData = new FormData();
        formData.append('file', file);
        return this.postRoute(`/api/import`, formData, undefined, {
            'Content-Type': MULTIPART_FORM_DATA,
        }, undefined, (progressEvent) => {
            var _a;
            onUploadProgress((_a = progressEvent.progress) !== null && _a !== void 0 ? _a : 0);
        });
    }
    uploadDocuments(file, onUploadProgress) {
        const formData = new FormData();
        formData.append('file', file);
        return this.postRoute('/api/payrollProvider/upload', formData, undefined, {
            'Content-Type': MULTIPART_FORM_DATA,
        }, undefined, (progressEvent) => {
            var _a;
            onUploadProgress((_a = progressEvent.progress) !== null && _a !== void 0 ? _a : 0);
        });
    }
    uploadCustomDocuments(file, onUploadProgress) {
        const formData = new FormData();
        formData.append('file', file);
        return this.postRoute('/api/payrollProvider/uploadCustomDocument', formData, undefined, {
            'Content-Type': MULTIPART_FORM_DATA,
        }, undefined, (progressEvent) => {
            var _a;
            onUploadProgress((_a = progressEvent.progress) !== null && _a !== void 0 ? _a : 0);
        });
    }
    async downloadDocuments(documentIds, documentName, isFolder) {
        const isBulk = documentIds.length > 1 || isFolder;
        const bodyParams = {
            documentIds,
            isFolder,
        };
        const blob = await this.postRoute(`/api/documents/download`, bodyParams, undefined, undefined, undefined, undefined, isFolder || documentIds.length ? 'arraybuffer' : 'blob');
        return new File([blob], isBulk ? `${documentName}.zip` : documentName);
    }
    cancelImport(importSession) {
        return this.apiDelete(`/api/import/${importSession.id}`);
    }
    cancelUpload(documentUploadDto) {
        return this.apiDelete(`/api/payrollProvider/deleteUpload/${documentUploadDto.id}`);
    }
    cancelCustomUpload(documentUploadDto) {
        return this.apiDelete(`/api/payrollProvider/deleteCustomUpload/${documentUploadDto.id}`);
    }
    importCompanies({ id: importSessionId }, dryRun = true, skip = []) {
        return this.postRoute('/api/companies/import', {
            importSessionId,
            skip,
            dryRun,
        });
    }
    importEmployees({ id: importSessionId }, companyId, dryRun = true, skip = []) {
        return this.postRoute('/api/employees/import', {
            importSessionId,
            skip,
            dryRun,
            companyId,
        });
    }
    importDocuments({ id: importSessionId }, dryRun = true, skip = []) {
        return this.postRoute('/api/payrollProvider/upload', {
            importSessionId,
            skip,
            dryRun,
        });
    }
    async exportEmployees() {
        const blob = await this.apiGet('/api/employees/export', {}, {
            responseType: 'blob',
        });
        return new File([blob], 'employee_export.csv', {
            type: UTF_8_CHARSET,
        });
    }
    async exportCompanies() {
        const blob = await this.apiGet('/api/companies/export', {}, {
            responseType: 'blob',
        });
        return new File([blob], 'company_export.csv', {
            type: UTF_8_CHARSET,
        });
    }
    async updatePayrollProvider(dto) {
        return this.postRoute(`/api/payrollProvider/${dto.id}`, dto);
    }
    async invitePayrollProviderUser(dto) {
        return this.postRoute('/api/payrollProviderInvite/create', dto);
    }
    async getPaginatedPayrollProviderStaff(data) {
        var _a;
        let preparedSort = [];
        if (((_a = data.sort) === null || _a === void 0 ? void 0 : _a.fieldName) === 'name') {
            preparedSort = [
                {
                    fieldName: 'lastname',
                    direction: data.sort.direction,
                },
                {
                    fieldName: 'firstname',
                    direction: data.sort.direction,
                },
            ];
        }
        else if (data.sort) {
            preparedSort = [data.sort];
        }
        const params = {
            filter: data.filter,
            itemsPerPage: data.pageSize,
            page: data.pageNumber,
            sort: preparedSort,
            searchTerm: data.searchTerm,
        };
        return await this.apiGet('/api/payrollProviderStaff/find', params);
    }
    async updatePayrollProviderStaff(staffMember) {
        await this.postRoute(`/api/payrollProviderStaff/${staffMember.id}`, staffMember);
    }
    async getEmployeesForCompany(companyId) {
        return this.apiGet(`/api/employees/byCompanyId/${companyId}`);
    }
    async getJsonSchema() {
        const blob = await this.apiGet('/api/schema/json', {}, {
            responseType: 'blob',
        });
        return new File([blob], 'schema.json', {
            type: UTF_8_CHARSET,
        });
    }
}
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Omit !== "undefined" && Omit) === "function" ? _a : Object]),
    __metadata("design:returntype", typeof (_b = typeof Promise !== "undefined" && Promise) === "function" ? _b : Object)
], NestClient.prototype, "createEmployee", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", typeof (_c = typeof Promise !== "undefined" && Promise) === "function" ? _c : Object)
], NestClient.prototype, "getEmployee", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", typeof (_d = typeof Promise !== "undefined" && Promise) === "function" ? _d : Object)
], NestClient.prototype, "updateEmployee", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, typeof (_e = typeof EmployeeState !== "undefined" && EmployeeState) === "function" ? _e : Object]),
    __metadata("design:returntype", typeof (_f = typeof Promise !== "undefined" && Promise) === "function" ? _f : Object)
], NestClient.prototype, "submitEmployeeForApproval", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object, typeof (_g = typeof EmployeeState !== "undefined" && EmployeeState) === "function" ? _g : Object]),
    __metadata("design:returntype", typeof (_h = typeof Promise !== "undefined" && Promise) === "function" ? _h : Object)
], NestClient.prototype, "rejectEmployee", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", typeof (_j = typeof Promise !== "undefined" && Promise) === "function" ? _j : Object)
], NestClient.prototype, "inviteEmployee", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", typeof (_k = typeof Promise !== "undefined" && Promise) === "function" ? _k : Object)
], NestClient.prototype, "withdrawEmployeeInvite", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_l = typeof Omit !== "undefined" && Omit) === "function" ? _l : Object]),
    __metadata("design:returntype", typeof (_m = typeof Promise !== "undefined" && Promise) === "function" ? _m : Object)
], NestClient.prototype, "createEAUEntry", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", typeof (_o = typeof Promise !== "undefined" && Promise) === "function" ? _o : Object)
], NestClient.prototype, "getEAUSchema", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", typeof (_p = typeof Promise !== "undefined" && Promise) === "function" ? _p : Object)
], NestClient.prototype, "getEAUEntry", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", typeof (_q = typeof Promise !== "undefined" && Promise) === "function" ? _q : Object)
], NestClient.prototype, "updateEAUEntry", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Number, Number, Object]),
    __metadata("design:returntype", typeof (_s = typeof Promise !== "undefined" && Promise) === "function" ? _s : Object)
], NestClient.prototype, "getPaginatedEAUEntries", null);
__decorate([
    RequiresCompany()
    // eslint-disable-next-line sonarjs/no-identical-functions
    ,
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", typeof (_t = typeof Promise !== "undefined" && Promise) === "function" ? _t : Object)
], NestClient.prototype, "getDocumentSchema", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", typeof (_u = typeof Promise !== "undefined" && Promise) === "function" ? _u : Object)
], NestClient.prototype, "createEmployeeDocumentEntry", null);
__decorate([
    RequiresPayrollProvider(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", typeof (_v = typeof Promise !== "undefined" && Promise) === "function" ? _v : Object)
], NestClient.prototype, "createPayrollProviderDocumentEntry", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", typeof (_w = typeof Promise !== "undefined" && Promise) === "function" ? _w : Object)
], NestClient.prototype, "getDocument", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", typeof (_x = typeof Promise !== "undefined" && Promise) === "function" ? _x : Object)
], NestClient.prototype, "updateDocument", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", typeof (_y = typeof Promise !== "undefined" && Promise) === "function" ? _y : Object)
], NestClient.prototype, "deleteDocument", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Array]),
    __metadata("design:returntype", typeof (_z = typeof Promise !== "undefined" && Promise) === "function" ? _z : Object)
], NestClient.prototype, "bulkDeleteDocument", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", typeof (_0 = typeof Promise !== "undefined" && Promise) === "function" ? _0 : Object)
], NestClient.prototype, "getAccountingPeriodData", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", typeof (_1 = typeof Promise !== "undefined" && Promise) === "function" ? _1 : Object)
], NestClient.prototype, "getPaginatedDocuments", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Object)
], NestClient.prototype, "createUserCompanyRole", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", typeof (_2 = typeof Promise !== "undefined" && Promise) === "function" ? _2 : Object)
], NestClient.prototype, "getEmployeeSchema", null);
__decorate([
    RequiresPayrollProvider(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean]),
    __metadata("design:returntype", typeof (_3 = typeof Promise !== "undefined" && Promise) === "function" ? _3 : Object)
], NestClient.prototype, "getPayrollProviderSchema", null);
__decorate([
    RequiresPayrollProvider(),
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Boolean, Boolean]),
    __metadata("design:returntype", typeof (_4 = typeof Promise !== "undefined" && Promise) === "function" ? _4 : Object)
], NestClient.prototype, "getCompanySchema", null);
__decorate([
    RequiresPayrollProvider(),
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", typeof (_5 = typeof Promise !== "undefined" && Promise) === "function" ? _5 : Object)
], NestClient.prototype, "getPayrollProviderFields", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", typeof (_6 = typeof Promise !== "undefined" && Promise) === "function" ? _6 : Object)
], NestClient.prototype, "getCompanyFields", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", typeof (_7 = typeof Promise !== "undefined" && Promise) === "function" ? _7 : Object)
], NestClient.prototype, "addSchemaFields", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", typeof (_8 = typeof Promise !== "undefined" && Promise) === "function" ? _8 : Object)
], NestClient.prototype, "updateSchemaField", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", typeof (_9 = typeof Promise !== "undefined" && Promise) === "function" ? _9 : Object)
], NestClient.prototype, "deleteSchemaField", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", typeof (_10 = typeof Promise !== "undefined" && Promise) === "function" ? _10 : Object)
], NestClient.prototype, "getCardCurrentAccountingPeriod", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", typeof (_11 = typeof Promise !== "undefined" && Promise) === "function" ? _11 : Object)
], NestClient.prototype, "getCardNumberOfEmployees", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", typeof (_12 = typeof Promise !== "undefined" && Promise) === "function" ? _12 : Object)
], NestClient.prototype, "getCompany", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", typeof (_13 = typeof Promise !== "undefined" && Promise) === "function" ? _13 : Object)
], NestClient.prototype, "updateCompany", null);
__decorate([
    RequiresPayrollProvider(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", typeof (_14 = typeof Promise !== "undefined" && Promise) === "function" ? _14 : Object)
], NestClient.prototype, "createCompany", null);
__decorate([
    RequiresPayrollProvider(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", typeof (_15 = typeof Promise !== "undefined" && Promise) === "function" ? _15 : Object)
], NestClient.prototype, "getCompanies", null);
__decorate([
    RequiresPayrollProvider(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_16 = typeof File !== "undefined" && File) === "function" ? _16 : Object, Function]),
    __metadata("design:returntype", typeof (_17 = typeof Promise !== "undefined" && Promise) === "function" ? _17 : Object)
], NestClient.prototype, "uploadCustomDocuments", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", typeof (_18 = typeof Promise !== "undefined" && Promise) === "function" ? _18 : Object)
], NestClient.prototype, "exportEmployees", null);
__decorate([
    RequiresCompany(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", typeof (_19 = typeof Promise !== "undefined" && Promise) === "function" ? _19 : Object)
], NestClient.prototype, "exportCompanies", null);
__decorate([
    RequiresPayrollProvider(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", typeof (_20 = typeof Promise !== "undefined" && Promise) === "function" ? _20 : Object)
], NestClient.prototype, "invitePayrollProviderUser", null);
__decorate([
    RequiresPayrollProvider(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", typeof (_21 = typeof Promise !== "undefined" && Promise) === "function" ? _21 : Object)
], NestClient.prototype, "getEmployeesForCompany", null);
__decorate([
    RequiresPayrollProvider(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Promise)
], NestClient.prototype, "getJsonSchema", null);
const nestClient = new NestClient();
export default nestClient;
