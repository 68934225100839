import { AuthProvider } from '../context/auth-context';
import { AuthenticatedRoute } from './authenticated-route';
import { AuthorizedCompanyRoute, AuthorizedPayrollProviderRoute, } from './authorized-company-route';
import { CompanyDocumentView, PersonalDocumentView, } from '@views/document-list-view';
import { CompanyProvider } from '../context/company-context';
import { Navigate, Outlet } from 'react-router-dom';
import { PayrollProviderProvider } from '../context/payrollprovider-context';
import { PayrollProviderStaffRoleEnum, UserCompanyRoleEnum, } from '@lohndialog/shared/src/constants';
import { PayrollProviderView } from '@views/payroll-provider-view';
import { ProfileProvider } from '../context/profile-context';
import AddEmployeeView from '@views/add-employee-view';
import BaseView from '@views/base-view';
import CompanyWizardView from '@views/company-wizard-view';
import EditCompanyView from '@views/edit-company-view';
import EditEmployeeView from '@views/edit-employee-view';
import EmployeeDashboard from '@views/employee-dashboard';
import EmployeeInvitationView from '@views/invitation-view';
import Employees from '@views/employees';
import ErrorView from '@views/error-view';
import HrDashboard from '@views/hr-dashboard';
import LandingPage from '@views/LandingPage';
import PayrollProviderEditView from '@views/payroll-provider-edit-view';
import PayrollProviderInvitationView from '@views/payroll-provider-invitation-view';
import PayrollProviderSettingsView from '@views/payroll-provider-settings-view';
import PlaceholderView from '@views/placeholder-view';
import PrCompanyDashboardView from '@views/PrCompanyDashboardView';
import PrEditCompanyView from '@views/PrEditCompanyView';
import ProfileView from '@views/profile-view';
import React from 'react';
export const routes = [
    {
        path: '/',
        element: (React.createElement(AuthProvider, null,
            React.createElement(ProfileProvider, null,
                React.createElement(LandingPage, null)))),
        errorElement: React.createElement(ErrorView, null),
    },
    {
        path: '/invite',
        element: (React.createElement(AuthProvider, null,
            React.createElement(EmployeeInvitationView, null))),
        errorElement: React.createElement(ErrorView, null),
    },
    {
        path: '/admin/invite',
        element: (React.createElement(AuthProvider, null,
            React.createElement(PayrollProviderInvitationView, null))),
        errorElement: React.createElement(ErrorView, null),
    },
    {
        path: '/admin',
        element: (React.createElement(AuthProvider, null,
            React.createElement(ProfileProvider, null,
                React.createElement(PayrollProviderProvider, null,
                    React.createElement(AuthenticatedRoute, null,
                        React.createElement(BaseView, null,
                            React.createElement(Outlet, null))))))),
        errorElement: React.createElement(ErrorView, null),
        children: [
            {
                path: '',
                element: (React.createElement(AuthorizedPayrollProviderRoute, { requiredRoles: [
                        PayrollProviderStaffRoleEnum.PP_ADMIN,
                        PayrollProviderStaffRoleEnum.INTERNAL,
                        PayrollProviderStaffRoleEnum.SENIOR,
                        PayrollProviderStaffRoleEnum.JUNIOR,
                    ] },
                    React.createElement(Navigate, { to: 'dashboard' }))),
                errorElement: React.createElement(ErrorView, null),
            },
            {
                path: 'dashboard',
                element: (React.createElement(AuthorizedPayrollProviderRoute, { requiredRoles: [
                        PayrollProviderStaffRoleEnum.PP_ADMIN,
                        PayrollProviderStaffRoleEnum.INTERNAL,
                        PayrollProviderStaffRoleEnum.SENIOR,
                        PayrollProviderStaffRoleEnum.JUNIOR,
                    ] },
                    React.createElement(PayrollProviderView, null))),
                errorElement: React.createElement(ErrorView, null),
            },
            {
                path: 'mandators',
                element: (React.createElement(AuthorizedPayrollProviderRoute, { requiredRoles: [
                        PayrollProviderStaffRoleEnum.PP_ADMIN,
                        PayrollProviderStaffRoleEnum.INTERNAL,
                        PayrollProviderStaffRoleEnum.SENIOR,
                        PayrollProviderStaffRoleEnum.JUNIOR,
                    ] },
                    React.createElement(PayrollProviderView, null))),
                errorElement: React.createElement(ErrorView, null),
            },
            {
                path: 'documents',
                element: (React.createElement(AuthorizedPayrollProviderRoute, { requiredRoles: [
                        PayrollProviderStaffRoleEnum.PP_ADMIN,
                        PayrollProviderStaffRoleEnum.INTERNAL,
                        PayrollProviderStaffRoleEnum.SENIOR,
                        PayrollProviderStaffRoleEnum.JUNIOR,
                    ] },
                    React.createElement(PlaceholderView, { title: "Dokumente", shouldRedirect: true }))),
            },
            {
                path: 'settings',
                element: (React.createElement(AuthorizedPayrollProviderRoute, { requiredRoles: [PayrollProviderStaffRoleEnum.PP_ADMIN] },
                    React.createElement(PayrollProviderSettingsView, null))),
            },
            {
                path: 'companyOverview/:companyId',
                element: (React.createElement(AuthorizedPayrollProviderRoute, { requiredRoles: [
                        PayrollProviderStaffRoleEnum.PP_ADMIN,
                        PayrollProviderStaffRoleEnum.INTERNAL,
                        PayrollProviderStaffRoleEnum.SENIOR,
                        PayrollProviderStaffRoleEnum.JUNIOR,
                    ] },
                    React.createElement(PrCompanyDashboardView, null))),
                errorElement: React.createElement(ErrorView, null),
            },
            {
                path: 'companySettings/:companyId',
                element: (React.createElement(AuthorizedPayrollProviderRoute, { requiredRoles: [
                        PayrollProviderStaffRoleEnum.PP_ADMIN,
                        PayrollProviderStaffRoleEnum.INTERNAL,
                        PayrollProviderStaffRoleEnum.SENIOR,
                        PayrollProviderStaffRoleEnum.JUNIOR,
                    ] },
                    React.createElement(PrEditCompanyView, null))),
                errorElement: React.createElement(ErrorView, null),
            },
            {
                path: 'edit',
                element: (React.createElement(AuthorizedPayrollProviderRoute, { requiredRoles: [
                        PayrollProviderStaffRoleEnum.PP_ADMIN,
                        PayrollProviderStaffRoleEnum.INTERNAL,
                        PayrollProviderStaffRoleEnum.SENIOR,
                        PayrollProviderStaffRoleEnum.JUNIOR,
                    ] },
                    React.createElement(PayrollProviderEditView, null))),
            },
        ],
    },
    {
        path: '/:companySlug?',
        element: (React.createElement(AuthProvider, null,
            React.createElement(ProfileProvider, null,
                React.createElement(PayrollProviderProvider, null,
                    React.createElement(CompanyProvider, null,
                        React.createElement(AuthenticatedRoute, null,
                            React.createElement(BaseView, null,
                                React.createElement(Outlet, null)))))))),
        errorElement: React.createElement(ErrorView, null),
        children: [
            {
                path: '',
                element: (React.createElement(AuthorizedCompanyRoute, { requiredRoles: [
                        UserCompanyRoleEnum.ADMIN,
                        UserCompanyRoleEnum.PP_ADMIN,
                    ] },
                    React.createElement(HrDashboard, null))),
                errorElement: React.createElement(ErrorView, null),
            },
            {
                path: 'employees',
                element: React.createElement(Outlet, null),
                errorElement: React.createElement(ErrorView, null),
                children: [
                    {
                        path: '',
                        element: (React.createElement(AuthorizedCompanyRoute, { requiredRoles: [
                                UserCompanyRoleEnum.ADMIN,
                                UserCompanyRoleEnum.PP_ADMIN,
                            ] },
                            React.createElement(Employees, null))),
                        errorElement: React.createElement(ErrorView, null),
                    },
                    {
                        path: 'new',
                        element: (React.createElement(AuthorizedCompanyRoute, { requiredRoles: [
                                UserCompanyRoleEnum.ADMIN,
                                UserCompanyRoleEnum.PP_ADMIN,
                            ] },
                            React.createElement(AddEmployeeView, null))),
                        errorElement: React.createElement(ErrorView, null),
                    },
                    {
                        path: ':id',
                        element: (React.createElement(AuthorizedCompanyRoute, { requiredRoles: [
                                UserCompanyRoleEnum.ADMIN,
                                UserCompanyRoleEnum.PP_ADMIN,
                                UserCompanyRoleEnum.USER,
                            ] },
                            React.createElement(EditEmployeeView, null))),
                        errorElement: React.createElement(ErrorView, null),
                    },
                ],
            },
            {
                path: 'documents',
                element: (React.createElement(AuthorizedCompanyRoute, { requiredRoles: [
                        UserCompanyRoleEnum.ADMIN,
                        UserCompanyRoleEnum.PP_ADMIN,
                    ] },
                    React.createElement(CompanyDocumentView, null))),
                errorElement: React.createElement(ErrorView, null),
            },
            {
                path: 'eAU',
                element: (React.createElement(AuthorizedCompanyRoute, { requiredRoles: [
                        UserCompanyRoleEnum.ADMIN,
                        UserCompanyRoleEnum.PP_ADMIN,
                    ] },
                    React.createElement(PlaceholderView, { title: "eAU", shouldRedirect: true }))),
                errorElement: React.createElement(ErrorView, null),
            },
            {
                path: 'dashboard',
                element: (React.createElement(AuthorizedCompanyRoute, { requiredRoles: [UserCompanyRoleEnum.USER] },
                    React.createElement(EmployeeDashboard, null))),
                errorElement: React.createElement(ErrorView, null),
            },
            {
                path: 'myEaus',
                element: (React.createElement(AuthorizedCompanyRoute, { requiredRoles: [UserCompanyRoleEnum.USER] },
                    React.createElement(PlaceholderView, { title: "Meine eAUs", shouldRedirect: true }))),
                errorElement: React.createElement(ErrorView, null),
            },
            {
                path: 'myDocuments',
                element: (React.createElement(AuthorizedCompanyRoute, { requiredRoles: [UserCompanyRoleEnum.USER] },
                    React.createElement(PersonalDocumentView, null))),
                errorElement: React.createElement(ErrorView, null),
            },
            {
                path: 'faq',
                element: React.createElement(PlaceholderView, { title: "FAQ", shouldRedirect: true }),
                errorElement: React.createElement(ErrorView, null),
            },
            {
                path: 'support',
                element: (React.createElement(PlaceholderView, { title: "Support", shouldRedirect: true })),
                errorElement: React.createElement(ErrorView, null),
            },
            {
                path: 'profile',
                element: (React.createElement(AuthorizedCompanyRoute, null,
                    React.createElement(ProfileView, null))),
                errorElement: React.createElement(ErrorView, null),
            },
            {
                path: 'settings',
                element: (React.createElement(AuthorizedCompanyRoute, { requiredRoles: [
                        UserCompanyRoleEnum.ADMIN,
                        UserCompanyRoleEnum.PP_ADMIN,
                    ] },
                    React.createElement(EditCompanyView, null))),
                errorElement: React.createElement(ErrorView, null),
            },
            {
                path: 'edit',
                element: (React.createElement(AuthorizedCompanyRoute, { requiredRoles: [
                        UserCompanyRoleEnum.ADMIN,
                        UserCompanyRoleEnum.PP_ADMIN,
                    ] },
                    React.createElement(CompanyWizardView, null))),
                errorElement: React.createElement(ErrorView, null),
            },
            {
                path: '*',
                element: (React.createElement(AuthorizedCompanyRoute, null,
                    React.createElement(PlaceholderView, { title: "404 Seite nicht gefunden", shouldRedirect: true }))),
                errorElement: React.createElement(ErrorView, null),
            },
        ],
    },
];
