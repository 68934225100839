import { CompanyContext } from '../context/company-context';
import { PayrollProviderContext } from '../context/payrollprovider-context';
import { Value, getTitle } from '@components/generic-summary-card';
import { classNames } from '@utils/class-names';
import { filterByRightsAndSortGenericFieldNames } from '@utils/generic-fields';
import React, { useContext, useMemo } from 'react';
export default function CareInsuranceSummaryCard({ className, title, schema, wizardCluster, entity, roles, }) {
    const company = useContext(CompanyContext);
    const payrollProvider = useContext(PayrollProviderContext);
    const sortedFields = useMemo(() => filterByRightsAndSortGenericFieldNames(schema, wizardCluster, entity, company, payrollProvider, false), [company, entity, payrollProvider, schema, wizardCluster]);
    return (React.createElement("div", { className: classNames(className, 'bg-n200LightGrey rounded-2xl p-6 mb-4') },
        React.createElement("h3", { className: "font-bold text-base leading-5 text-n600DarkBlue mb-6" }, title),
        React.createElement("div", { className: "flex flex-col flex-wrap gap-4" }, sortedFields.map((fieldName) => {
            return (React.createElement("div", { key: fieldName, className: "flex flex-col justify-between" },
                React.createElement("div", { className: "text-xs text-n500DarkGrey font-medium leading-4 mb-1.5" }, getTitle(schema.fields[fieldName])),
                React.createElement("div", { className: "text-sm font-inter text-black font-medium leading-4" },
                    React.createElement(Value, { fieldName: fieldName, field: schema.fields[fieldName], entity: entity, roles: roles }))));
        }))));
}
