import { Button, ButtonSize, ButtonType } from '@components/button';
import { WizardCluster, } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { NavLink } from '@components/nav-link';
import { TextInput } from '@components/input/text-input';
import { useNestClient } from '@hooks/useNestClient';
import { usePayrollProvider } from '../context/payrollprovider-context';
import { useProfile } from '../context/profile-context';
import { usePromiseState } from '@hooks/usePromise';
import { useTranslation } from 'react-i18next';
import { validateUrl } from '@lohndialog/shared/src/validation';
import GenericForm from '@components/generic-forms/generic-form';
import React, { useCallback, useState } from 'react';
import nestClient from '@api/nest-client';
// eslint-disable-next-line no-restricted-imports
import { useNavigate } from 'react-router-dom';
export default function PayrollProviderEditView() {
    var _a, _b, _c, _d, _e;
    const { t } = useTranslation('payrollProviderEdit');
    const profile = useProfile();
    const { payrollProvider: _payrollProvider } = usePayrollProvider();
    const { result: schema, error: errorLoadingSchema } = useNestClient('getPayrollProviderSchema', [false]);
    const [payrollProvider, setPayrollProvider] = useState(_payrollProvider);
    const setGenericField = useCallback((fieldIdentifier, type, newValue) => setPayrollProvider((value = _payrollProvider) => {
        if (!value) {
            return undefined;
        }
        return Object.assign(Object.assign({}, value), { genericFields: Object.assign(Object.assign({}, value.genericFields), { [fieldIdentifier]: {
                    value: newValue,
                    type,
                } }) });
    }), [_payrollProvider]);
    const [_updateResult, setUpdatePromise, updating, error] = usePromiseState(null);
    const navigate = useNavigate();
    const updatePayrollProvider = useCallback(() => {
        void setUpdatePromise((async () => {
            if (!payrollProvider) {
                return;
            }
            await nestClient.updatePayrollProvider(payrollProvider);
            profile.fetchProfile();
            navigate('/admin/settings');
        })());
    }, [navigate, payrollProvider, profile, setUpdatePromise]);
    if (errorLoadingSchema) {
        throw errorLoadingSchema;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex flex-row justify-between items-center" },
            React.createElement("h1", null, t('title'))),
        React.createElement("form", { onSubmit: (event) => {
                updatePayrollProvider();
                event.preventDefault();
            } },
            React.createElement(GenericForm, { value: payrollProvider, setValue: setGenericField, schema: schema, wizardCluster: WizardCluster.BasicInformation }),
            React.createElement(GenericForm, { value: payrollProvider, setValue: setGenericField, schema: schema, wizardCluster: WizardCluster.Address }),
            React.createElement(TextInput, { value: (_a = payrollProvider === null || payrollProvider === void 0 ? void 0 : payrollProvider.dataPrivacyInformation) !== null && _a !== void 0 ? _a : '', onChange: (dataPrivacyInformation) => setPayrollProvider((prevState) => {
                    if (!prevState) {
                        return undefined;
                    }
                    return Object.assign(Object.assign({}, prevState), { dataPrivacyInformation });
                }), title: React.createElement(React.Fragment, null,
                    t('dataPrivacyInformation'),
                    "*"), maxLength: 500, type: "textarea", classNameInput: "resize-y", inputTestId: "input-dataPrivacyInformation" }),
            React.createElement(TextInput, { value: (_b = payrollProvider === null || payrollProvider === void 0 ? void 0 : payrollProvider.imprint) !== null && _b !== void 0 ? _b : '', onChange: (imprint) => setPayrollProvider((prevState) => {
                    if (!prevState) {
                        return undefined;
                    }
                    return Object.assign(Object.assign({}, prevState), { imprint });
                }), title: React.createElement(React.Fragment, null,
                    t('imprint'),
                    "*"), maxLength: 500, type: "textarea", classNameInput: "resize-y", inputTestId: "input-imprint" }),
            React.createElement(TextInput, { value: (_c = payrollProvider === null || payrollProvider === void 0 ? void 0 : payrollProvider.emailFooter) !== null && _c !== void 0 ? _c : '', onChange: (emailFooter) => setPayrollProvider((prevState) => {
                    if (!prevState) {
                        return undefined;
                    }
                    return Object.assign(Object.assign({}, prevState), { emailFooter });
                }), title: React.createElement(React.Fragment, null,
                    t('emailFooter'),
                    "*"), maxLength: 500, type: "textarea", classNameInput: "resize-y", inputTestId: "input-emailFooter" }),
            React.createElement(TextInput, { value: (_d = payrollProvider === null || payrollProvider === void 0 ? void 0 : payrollProvider.logoUrlBig) !== null && _d !== void 0 ? _d : '', onChange: (logoUrlBig) => setPayrollProvider((prevState) => {
                    if (!prevState) {
                        return undefined;
                    }
                    return Object.assign(Object.assign({}, prevState), { logoUrlBig });
                }), title: React.createElement(React.Fragment, null,
                    t('logoBig'),
                    "*"), validate: validateUrl }),
            React.createElement(TextInput, { value: (_e = payrollProvider === null || payrollProvider === void 0 ? void 0 : payrollProvider.logoUrlSmall) !== null && _e !== void 0 ? _e : '', onChange: (logoUrlSmall) => setPayrollProvider((prevState) => {
                    if (!prevState) {
                        return undefined;
                    }
                    return Object.assign(Object.assign({}, prevState), { logoUrlSmall });
                }), title: React.createElement(React.Fragment, null,
                    t('logoSmall'),
                    "*"), validate: validateUrl }),
            React.createElement("p", { className: "text-r600Error" }, error === null || error === void 0 ? void 0 : error.message),
            React.createElement("div", { className: "flex gap-2 justify-end" },
                React.createElement(NavLink, { to: "/admin/settings", addCompanyPrefix: false },
                    React.createElement(Button, { label: t('cancelBtn'), buttonType: ButtonType.SECONDARY, buttonSize: ButtonSize.LARGE, loading: updating, className: "bg-white" })),
                React.createElement(Button, { label: t('saveBtn'), buttonType: ButtonType.PRIMARY, buttonSize: ButtonSize.LARGE, loading: updating, type: "submit", testId: "submitBtn" })))));
}
