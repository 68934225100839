import { DUMMY_AUTH_TOKEN } from '@lohndialog/shared/src/constants';
import { Environment } from '../environment.global';
import { useCallback, useEffect, useState } from 'react';
import { useLocalStorage } from './useLocalStorage';
export function useDummyAuth() {
    const { setItem, getItem, removeItem } = useLocalStorage();
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        const isAuthenticated = getItem('isAuthenticated');
        return isAuthenticated
            ? JSON.parse(isAuthenticated)
            : false;
    });
    const [user, setUser] = useState(undefined);
    const login = useCallback((options) => {
        var _a;
        setIsAuthenticated(true);
        setItem('isAuthenticated', JSON.stringify(true));
        const newUser = {
            sub: '123456',
            given_name: 'Foo',
            family_name: 'Bar',
            email: 'foobar@example.com',
            email_verified: true,
        };
        setUser(newUser);
        setItem('user', JSON.stringify(newUser));
        if ((_a = options === null || options === void 0 ? void 0 : options.authorizationParams) === null || _a === void 0 ? void 0 : _a.redirectUri) {
            const redirectUri = `${Environment.PUBLIC_URL}${options.authorizationParams.redirectUri}`;
            window.location.assign(redirectUri);
        }
        return Promise.resolve();
    }, [setItem]);
    const logout = useCallback((options) => {
        var _a;
        setIsAuthenticated(false);
        removeItem('isAuthenticated');
        setUser(undefined);
        removeItem('user');
        if ((_a = options === null || options === void 0 ? void 0 : options.logoutParams) === null || _a === void 0 ? void 0 : _a.returnTo) {
            window.location.assign(options.logoutParams.returnTo);
        }
        return Promise.resolve();
    }, [removeItem]);
    useEffect(() => {
        const user = getItem('user');
        if (user) {
            setUser(JSON.parse(user));
        }
    }, [getItem]);
    const getAccessTokenSilently = useCallback(() => Promise.resolve(DUMMY_AUTH_TOKEN), []);
    return {
        isAuthenticated,
        user,
        login,
        logout,
        getAccessTokenSilently,
        isLoading: false,
    };
}
