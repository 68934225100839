import { ImportResultCode, } from '@lohndialog/shared/src/request-types/import-request';
import { useTranslation } from 'react-i18next';
import React from 'react';
export const ImportResultCodeDisplay = ({ importResultCode, }) => {
    const { t } = useTranslation('importResult');
    switch (importResultCode) {
        case ImportResultCode.ERRONEOUS_DATA:
        case ImportResultCode.ERROR_DUPLICATES:
            return (React.createElement("p", { className: "text-r600Error font-bold" }, t(importResultCode)));
        case ImportResultCode.SUCCESS:
        case ImportResultCode.DRY_RUN:
            return (React.createElement("p", { className: "text-p100Green font-bold" }, t(importResultCode)));
        default:
            return;
    }
};
export function getSkippedValues(selectedItems, values = []) {
    return values
        .map((value, index) => ({
        value,
        index,
    }))
        .filter(({ value }) => !selectedItems.has(value))
        .map(({ index }) => index);
}
