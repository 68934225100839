import { getTitle } from '@components/generic-forms/generic-form';
import { FieldType } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { MAX_LENGTH_PHONE_NUMBER, validatePhoneNumber, } from '@lohndialog/shared/src/validation';
import { TextInput } from '@components/input/text-input';
import React from 'react';
export const TelField = ({ fieldName, field, fieldValue, setValue, className, disabled, required, shouldValidate, }) => {
    var _a, _b;
    if (field.type !== FieldType.TEL ||
        (fieldValue && fieldValue.type !== FieldType.TEL)) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(TextInput, { key: fieldName, value: (_a = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value) !== null && _a !== void 0 ? _a : '', title: getTitle(field, required), onChange: (newValue) => setValue(fieldName, field.type, newValue), validate: validatePhoneNumber, maxLength: (_b = field.maxLength) !== null && _b !== void 0 ? _b : MAX_LENGTH_PHONE_NUMBER, classNameContainer: className, disabled: disabled, required: required, inputTestId: `textInput-${fieldName}`, shouldValidate: shouldValidate, runInitialValidation: true }));
};
