import { DependentTargets, FieldType, } from '../dtos/entity-schema.dto';
import dayjs from './dayjs';
import { getChildNumberFromName } from '../validators/FieldIsRequired';
export const valueMap = new Map([
    [true, 'yes'],
    [false, 'no'],
    [undefined, undefined],
]);
export const getDefaultValueForCheckbox = (field) => {
    var _a;
    const parseValue = (_a = [...valueMap.entries()].find(([_key, value]) => value === field.defaultValue)) === null || _a === void 0 ? void 0 : _a[0];
    if (parseValue !== undefined) {
        return {
            type: FieldType.CHECKBOX,
            value: parseValue,
        };
    }
    else {
        return undefined;
    }
};
export const getDefaultValueForChild = () => {
    return {
        type: FieldType.CHILD,
        value: {
            firstname: '',
            divertingLastName: '',
            birthDate: dayjs().format('YYYY-MM-DD'),
            proofDate: dayjs().format('YYYY-MM-DD'),
        },
    };
};
export const getDefaultValueForField = (field) => {
    if (!field) {
        return undefined;
    }
    switch (field.type) {
        case FieldType.CHECKBOX:
            return getDefaultValueForCheckbox(field);
        case FieldType.NUMBER:
            if (!field.defaultValue) {
                return undefined;
            }
            try {
                return {
                    type: FieldType.NUMBER,
                    value: parseFloat(field.defaultValue),
                };
            }
            catch (_error) {
                return undefined;
            }
        case FieldType.CHILD:
            return getDefaultValueForChild();
        case FieldType.TEXT:
        case FieldType.COMBOBOX:
        case FieldType.TEL:
        case FieldType.EMAIL:
        case FieldType.URL:
        case FieldType.DROPDOWN:
        case FieldType.DATE:
        case FieldType.DATETIME:
            return field.defaultValue
                ? {
                    type: field.type,
                    value: field.defaultValue,
                }
                : undefined;
    }
};
export const getDynamicPermissionForField = (target, required, value, targetValue, disabled, shouldDisable) => {
    switch (target) {
        case DependentTargets.undefined:
            if ((targetValue === null || targetValue === void 0 ? void 0 : targetValue.value) !== undefined) {
                return {
                    required: false,
                    disabled: shouldDisable || disabled,
                };
            }
            break;
        case DependentTargets.defined:
            if ((targetValue === null || targetValue === void 0 ? void 0 : targetValue.value) === undefined) {
                return {
                    required: false,
                    disabled: shouldDisable || disabled,
                };
            }
            break;
        case DependentTargets.empty:
            if (targetValue === null || targetValue === void 0 ? void 0 : targetValue.value) {
                return {
                    required: false,
                    disabled: shouldDisable || disabled,
                };
            }
            break;
        case DependentTargets.notEmpty:
            if (!(targetValue === null || targetValue === void 0 ? void 0 : targetValue.value)) {
                return {
                    required: false,
                    disabled: shouldDisable || disabled,
                };
            }
            break;
        case DependentTargets.equal:
            if ((targetValue === null || targetValue === void 0 ? void 0 : targetValue.type) !== (value === null || value === void 0 ? void 0 : value.type) ||
                (targetValue === null || targetValue === void 0 ? void 0 : targetValue.value) !== (value === null || value === void 0 ? void 0 : value.value)) {
                return {
                    required: false,
                    disabled: shouldDisable || disabled,
                };
            }
            break;
        case DependentTargets.notEqual:
            if ((targetValue === null || targetValue === void 0 ? void 0 : targetValue.type) === (value === null || value === void 0 ? void 0 : value.type) &&
                (targetValue === null || targetValue === void 0 ? void 0 : targetValue.value) === (value === null || value === void 0 ? void 0 : value.value)) {
                return {
                    required: false,
                    disabled: shouldDisable || disabled,
                };
            }
            break;
        case DependentTargets.startsNotWith:
            if ((targetValue === null || targetValue === void 0 ? void 0 : targetValue.type) === FieldType.TEXT &&
                value &&
                targetValue.value &&
                targetValue.value
                    .trim()
                    .toLowerCase()
                    .startsWith(value.value.trim().toLowerCase())) {
                return {
                    required: false,
                    disabled: shouldDisable || disabled,
                };
            }
            break;
    }
    return { required, disabled };
};
export const getPermissionForField = (fieldName, field, wouldBeRequired, hasEditRights, fieldValues, schema) => {
    var _a, _b;
    let required = wouldBeRequired;
    let disabled = !hasEditRights;
    if (field.type === FieldType.CHILD) {
        required =
            getChildNumberFromName(fieldName) <=
                ((_b = (_a = fieldValues === null || fieldValues === void 0 ? void 0 : fieldValues.genericFields.childAllowance) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : 0);
    }
    if (field.dependentOn) {
        Object.entries(field.dependentOn).forEach(([fieldName, { target, value, shouldDisable }]) => {
            var _a;
            const targetValue = (_a = fieldValues === null || fieldValues === void 0 ? void 0 : fieldValues.genericFields[fieldName]) !== null && _a !== void 0 ? _a : getDefaultValueForField(schema === null || schema === void 0 ? void 0 : schema.fields[fieldName]);
            const dynamicPermissions = getDynamicPermissionForField(target, required, value, targetValue, disabled, shouldDisable);
            required = dynamicPermissions.required;
            disabled = !!dynamicPermissions.disabled;
        });
    }
    return { required, disabled };
};
export const shouldValidateField = (field, fieldValues) => {
    if (field.validatesIf) {
        return Object.entries(field.validatesIf).every(([fieldName, { target, value }]) => {
            var _a;
            const targetValue = (_a = fieldValues === null || fieldValues === void 0 ? void 0 : fieldValues.genericFields[fieldName]) !== null && _a !== void 0 ? _a : getDefaultValueForField(field);
            switch (target) {
                case DependentTargets.undefined:
                    return (targetValue === null || targetValue === void 0 ? void 0 : targetValue.value) === undefined;
                case DependentTargets.defined:
                    return (targetValue === null || targetValue === void 0 ? void 0 : targetValue.value) !== undefined;
                case DependentTargets.empty:
                    return !(targetValue === null || targetValue === void 0 ? void 0 : targetValue.value);
                case DependentTargets.notEmpty:
                    return !!(targetValue === null || targetValue === void 0 ? void 0 : targetValue.value);
                case DependentTargets.equal:
                    return ((targetValue === null || targetValue === void 0 ? void 0 : targetValue.type) === (value === null || value === void 0 ? void 0 : value.type) &&
                        (targetValue === null || targetValue === void 0 ? void 0 : targetValue.value) === (value === null || value === void 0 ? void 0 : value.value));
                case DependentTargets.notEqual:
                    return ((targetValue === null || targetValue === void 0 ? void 0 : targetValue.type) !== (value === null || value === void 0 ? void 0 : value.type) ||
                        (targetValue === null || targetValue === void 0 ? void 0 : targetValue.value) !== (value === null || value === void 0 ? void 0 : value.value));
            }
        });
    }
    return true;
};
