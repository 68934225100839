// eslint-disable-next-line no-restricted-imports
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
export default function PlaceholderView(props) {
    const navigate = useNavigate();
    useEffect(() => {
        if (props.shouldRedirect) {
            navigate('/');
        }
    }, [navigate, props.shouldRedirect]);
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", { className: "text-xl mb-4" }, props.title),
        React.createElement("div", { className: "bg-n400GreyCheteau h-60 p-4" }, "Platzhalter"),
        React.createElement("div", { className: "bg-n200LightGrey h-60" }),
        React.createElement("div", { className: "bg-n400GreyCheteau h-60" }),
        React.createElement("div", { className: "bg-n200LightGrey h-60" }),
        React.createElement("div", { className: "bg-n400GreyCheteau h-60" })));
}
