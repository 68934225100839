import { Button, ButtonSize, ButtonType } from '@components/button';
import { EmployeeImportTable } from '@components/import/employee-import-table';
import { Upload } from '@components/upload/upload';
import { ImportResultCodeDisplay, getSkippedValues, } from '@components/import/utils';
import { MAX_UPLOAD_SIZE_IMPORT_EMPLOYEES } from '@lohndialog/shared/src/constants';
import { usePromiseState } from '@hooks/usePromise';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import nestClient from '@api/nest-client';
export const EmployeeImport = ({ companyId }) => {
    var _a, _b;
    const [importDryRunResult, setImportDryRunPromise, importingDryRun, errorImportingDryRun, _resetImportDryRun,] = usePromiseState(undefined);
    const [importResult, setImportPromise, importing, errorImporting, resetImport,] = usePromiseState(undefined);
    const [fileUploads, setFileUploads] = useState([]);
    const { t } = useTranslation('employeeImport');
    const [selectedItems, setSelectedItems] = useState(new Set());
    // Select all items by default
    useEffect(() => {
        var _a;
        const selectedItems = new Set(importDryRunResult === null || importDryRunResult === void 0 ? void 0 : importDryRunResult.values);
        ((_a = importDryRunResult === null || importDryRunResult === void 0 ? void 0 : importDryRunResult.values.filter((v) => v.skipped)) !== null && _a !== void 0 ? _a : []).forEach((skippedRow) => selectedItems.delete(skippedRow));
        setSelectedItems(selectedItems);
    }, [importDryRunResult === null || importDryRunResult === void 0 ? void 0 : importDryRunResult.values]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex flex-col gap-2 px-6 py-4" },
            React.createElement(Upload, { acceptedFileFormats: {
                    'text/csv': ['.csv'],
                    'text/plain': ['.txt'],
                }, fileUploads: fileUploads, setFileUploads: setFileUploads, maxFileSize: MAX_UPLOAD_SIZE_IMPORT_EMPLOYEES, uploadFuncDB: (file, progressCallback) => {
                    return nestClient.startImport(file, progressCallback);
                }, deleteFuncDB: async (dto) => nestClient.cancelImport(dto), maxFiles: 1 }),
            React.createElement(Button, { label: t('importBtnDryRun'), buttonType: ButtonType.PRIMARY, buttonSize: ButtonSize.LARGE, onClick: () => {
                    var _a;
                    const importSession = (_a = fileUploads[0]) === null || _a === void 0 ? void 0 : _a.remoteId;
                    if (importSession) {
                        void setImportDryRunPromise(nestClient.importEmployees(importSession, companyId, true, getSkippedValues(selectedItems, importDryRunResult === null || importDryRunResult === void 0 ? void 0 : importDryRunResult.values)));
                    }
                }, disabled: fileUploads.length === 0, loading: importingDryRun, testId: "importBtnDryRun" }),
            errorImportingDryRun && (React.createElement("span", { className: "text-r600Error" }, errorImportingDryRun.message)),
            importDryRunResult && (React.createElement(React.Fragment, null,
                React.createElement(ImportResultCodeDisplay, { importResultCode: importDryRunResult.resultCode }),
                React.createElement(EmployeeImportTable, { data: (_a = importResult === null || importResult === void 0 ? void 0 : importResult.values) !== null && _a !== void 0 ? _a : importDryRunResult.values, loading: importingDryRun, selectedItems: selectedItems, setSelectedItems: setSelectedItems, headers: (_b = importResult === null || importResult === void 0 ? void 0 : importResult.headers) !== null && _b !== void 0 ? _b : importDryRunResult.headers })))),
        React.createElement("div", { className: "flex-grow" }),
        importDryRunResult && (React.createElement("div", { className: "sticky bg-white py-2 px-6 bottom-0 text-center" },
            React.createElement(Button, { label: t('importBtn'), testId: "importBtn", buttonType: ButtonType.PRIMARY, buttonSize: ButtonSize.LARGE, className: "w-full", onClick: () => {
                    var _a;
                    const importSession = (_a = fileUploads[0]) === null || _a === void 0 ? void 0 : _a.remoteId;
                    if (importSession) {
                        resetImport();
                        void setImportPromise(nestClient.importEmployees(importSession, companyId, false, getSkippedValues(selectedItems, importDryRunResult.values)));
                    }
                }, disabled: !importDryRunResult, loading: importing }),
            errorImporting && (React.createElement("span", { className: "text-r600Error" }, errorImporting.message)),
            importResult && (React.createElement(ImportResultCodeDisplay, { importResultCode: importResult.resultCode }))))));
};
