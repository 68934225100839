import { Button, ButtonSize, ButtonType } from '@components/button';
import { DOCUMENT_PAGE_SIZE } from '@utils/constants';
import { ReactComponent as EditIcon } from '@public/icons/edit.svg';
import { NavLink } from '@components/nav-link';
import { Tabs } from '@components/tabs';
import { WizardCluster } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { useCompany } from '../context/company-context';
import { useNestClient } from '@hooks/useNestClient';
import { useTranslation } from 'react-i18next';
import DocumentDashboardTable from '@components/dashboard/document-dashboard-table';
import GenericSummaryTab, { containsCollapsibleFields, } from '@components/generic-summary-tab';
import React, { useMemo } from 'react';
export default function EmployeeDashboard() {
    const { t } = useTranslation('employeeDashboard');
    const { t: tLabel } = useTranslation('employeeWizard', {
        keyPrefix: 'clusterLabel',
    });
    const { company } = useCompany();
    const { result: schema, error: errorLoadingSchema } = useNestClient('getEmployeeSchema', [false]);
    if (errorLoadingSchema) {
        throw errorLoadingSchema;
    }
    const employee = company === null || company === void 0 ? void 0 : company.employee;
    const overviewTabs = useMemo(() => {
        return [
            {
                id: 1,
                title: tLabel(WizardCluster.PersonalData),
                content: (React.createElement(React.Fragment, null,
                    React.createElement(GenericSummaryTab, { schema: schema, wizardCluster: WizardCluster.PersonalData, entity: employee }))),
                expandable: containsCollapsibleFields(schema, WizardCluster.PersonalData),
            },
            {
                id: 2,
                title: tLabel(WizardCluster.Address),
                content: (React.createElement(React.Fragment, null,
                    React.createElement(GenericSummaryTab, { schema: schema, wizardCluster: WizardCluster.Address, entity: employee }))),
                expandable: containsCollapsibleFields(schema, WizardCluster.Address),
            },
            {
                id: 3,
                title: tLabel(WizardCluster.PaymentData),
                content: (React.createElement(React.Fragment, null,
                    React.createElement(GenericSummaryTab, { schema: schema, wizardCluster: WizardCluster.PaymentData, entity: employee }))),
                expandable: containsCollapsibleFields(schema, WizardCluster.PaymentData),
            },
            {
                id: 4,
                title: tLabel(WizardCluster.AdditionalData),
                content: (React.createElement(React.Fragment, null,
                    React.createElement(GenericSummaryTab, { schema: schema, wizardCluster: WizardCluster.AdditionalData, entity: employee }))),
                expandable: containsCollapsibleFields(schema, WizardCluster.AdditionalData),
            },
            {
                id: 5,
                title: tLabel(WizardCluster.Tax),
                content: (React.createElement(React.Fragment, null,
                    React.createElement(GenericSummaryTab, { schema: schema, wizardCluster: WizardCluster.Tax, entity: employee }))),
                expandable: containsCollapsibleFields(schema, WizardCluster.Tax),
            },
            {
                id: 6,
                title: tLabel(WizardCluster.Occupation),
                content: (React.createElement(GenericSummaryTab, { schema: schema, wizardCluster: WizardCluster.Occupation, entity: employee })),
                expandable: containsCollapsibleFields(schema, WizardCluster.Occupation),
            },
            {
                id: 7,
                title: tLabel(WizardCluster.RetirementInsurance),
                content: (React.createElement(GenericSummaryTab, { schema: schema, wizardCluster: WizardCluster.RetirementInsurance, entity: employee })),
                expandable: containsCollapsibleFields(schema, WizardCluster.RetirementInsurance),
            },
            {
                id: 8,
                title: tLabel(WizardCluster.HealthInsurance),
                content: (React.createElement(GenericSummaryTab, { schema: schema, wizardCluster: WizardCluster.HealthInsurance, entity: employee })),
                expandable: containsCollapsibleFields(schema, WizardCluster.HealthInsurance),
            },
            {
                id: 9,
                title: tLabel(WizardCluster.CareInsurance),
                content: (React.createElement(GenericSummaryTab, { schema: schema, wizardCluster: WizardCluster.CareInsurance, entity: employee })),
                expandable: containsCollapsibleFields(schema, WizardCluster.CareInsurance),
            },
            {
                id: 10,
                title: tLabel(WizardCluster.AccountingData),
                content: (React.createElement(GenericSummaryTab, { schema: schema, wizardCluster: WizardCluster.AccountingData, entity: employee })),
                expandable: containsCollapsibleFields(schema, WizardCluster.AccountingData),
            },
            {
                id: 11,
                title: tLabel(WizardCluster.Employment),
                content: (React.createElement(GenericSummaryTab, { schema: schema, wizardCluster: WizardCluster.Employment, entity: employee })),
                expandable: containsCollapsibleFields(schema, WizardCluster.Employment),
            },
            {
                id: 12,
                title: tLabel(WizardCluster.Payment),
                content: (React.createElement(GenericSummaryTab, { schema: schema, wizardCluster: WizardCluster.Payment, entity: employee })),
                expandable: containsCollapsibleFields(schema, WizardCluster.Payment),
            },
            {
                id: 13,
                title: tLabel(WizardCluster.Mapping),
                content: (React.createElement(GenericSummaryTab, { schema: schema, wizardCluster: WizardCluster.Mapping, entity: employee })),
                expandable: containsCollapsibleFields(schema, WizardCluster.Mapping),
            },
            {
                id: 14,
                title: tLabel(WizardCluster.NONE),
                content: (React.createElement(GenericSummaryTab, { schema: schema, wizardCluster: WizardCluster.NONE, entity: employee })),
                expandable: containsCollapsibleFields(schema, WizardCluster.NONE),
            },
        ];
    }, [employee, schema, tLabel]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex flex-row justify-between items-center" },
            React.createElement("h1", null, t('title')),
            React.createElement(NavLink, { to: `/employees/${employee === null || employee === void 0 ? void 0 : employee.id}` },
                React.createElement(Button, { buttonSize: ButtonSize.SMALL, buttonType: ButtonType.SECONDARY, label: t('edit'), IconLeading: EditIcon, className: "bg-white" }))),
        React.createElement("p", { className: "font-medium text-n500DarkGrey" }, t('subtitle')),
        React.createElement("div", { className: "grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4 mt-4" }),
        React.createElement(Tabs, { tabs: overviewTabs, className: "mb-6" }),
        React.createElement(Tabs, { tabs: [
                {
                    id: 1,
                    title: 'Dokumente',
                    content: (React.createElement(DocumentDashboardTable, { pageSize: DOCUMENT_PAGE_SIZE, showHeader: false, navLink: 'myDocuments', onlyShowPersonalDocuments: true })),
                },
            ] })));
}
