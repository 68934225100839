var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { CompanyState, EAUStateEnum, EmployeeInvitationState, EmployeeState, PayrollProviderStaffState, } from '@lohndialog/shared/src/constants';
import { ReactComponent as XIcon } from '@public/icons/x.svg';
import { classNames } from '@utils/class-names';
import { useTranslation } from 'react-i18next';
import React from 'react';
export var BADGE_COLOR;
(function (BADGE_COLOR) {
    BADGE_COLOR["GREEN"] = "GREEN";
    BADGE_COLOR["RED"] = "RED";
    BADGE_COLOR["ORANGE"] = "ORANGE";
    BADGE_COLOR["GREY"] = "GREY";
    BADGE_COLOR["YELLOW"] = "YELLOW";
})(BADGE_COLOR || (BADGE_COLOR = {}));
const colors = {
    [BADGE_COLOR.RED]: {
        mainClassNames: 'bg-[#FEF3F2] text-[#B42318]',
        buttonClassNames: undefined,
    },
    [BADGE_COLOR.GREY]: {
        mainClassNames: 'bg-[#F2F4F7] text-[#344054]',
        buttonClassNames: undefined,
    },
    [BADGE_COLOR.GREEN]: {
        mainClassNames: 'bg-backgroundGreen text-[#59BA89]',
        buttonClassNames: undefined,
    },
    [BADGE_COLOR.ORANGE]: {
        mainClassNames: 'bg-[#FEF6EE] text-[#B93815]',
        buttonClassNames: undefined,
    },
    [BADGE_COLOR.YELLOW]: {
        mainClassNames: 'bg-[#FFFAEB] text-[#B54708]',
        buttonClassNames: undefined,
    },
};
export var BADGE_SIZE;
(function (BADGE_SIZE) {
    BADGE_SIZE["SMALL"] = "SMALL";
    BADGE_SIZE["MEDIUM"] = "MEDIUM";
    BADGE_SIZE["LARGE"] = "LARGE";
})(BADGE_SIZE || (BADGE_SIZE = {}));
const sizes = {
    [BADGE_SIZE.SMALL]: 'px-2 py-[3px] text-xs',
    [BADGE_SIZE.MEDIUM]: 'px-2.5 text-sm',
    [BADGE_SIZE.LARGE]: 'px-3 py-1 text-sm',
};
export function Badge({ label, size, color, showDot, deletable, onDelete, }) {
    var _a;
    return (React.createElement("span", { className: classNames(colors[color].mainClassNames, sizes[size], 'inline-flex items-center rounded-full py-0.5 font-medium font-inter') },
        showDot && (React.createElement("svg", { className: "-ml-1 mr-1.5 h-2 w-2", fill: "currentColor", viewBox: "0 0 8 8" },
            React.createElement("circle", { cx: 4, cy: 4, r: 3 }))),
        label,
        deletable && (React.createElement("button", { type: "button", className: classNames('ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full focus:outline-none', (_a = colors[color].buttonClassNames) !== null && _a !== void 0 ? _a : 'hover:!bg-white focus:bg-white focus:bg-opacity-60'), onClick: onDelete },
            React.createElement("span", { className: "sr-only" }),
            React.createElement(XIcon, null)))));
}
export const EAUStateBadge = (_a) => {
    var { state } = _a, props = __rest(_a, ["state"]);
    const { t } = useTranslation('statusBadge');
    let label;
    let color;
    switch (state) {
        case EAUStateEnum.APPROVED:
            label = t('approved');
            color = BADGE_COLOR.GREEN;
            break;
        case EAUStateEnum.DECLINED:
            label = t('declined');
            color = BADGE_COLOR.RED;
            break;
        case EAUStateEnum.PENDING:
            label = t('pending');
            color = BADGE_COLOR.ORANGE;
            break;
        default:
            return null;
    }
    return (React.createElement(Badge, Object.assign({ size: BADGE_SIZE.SMALL, showDot: true, deletable: false }, props, { label: label, color: color })));
};
export const PendingBadge = (props) => {
    const { t } = useTranslation('statusBadge');
    return (React.createElement(Badge, Object.assign({ label: t('pending'), size: BADGE_SIZE.SMALL, color: BADGE_COLOR.ORANGE, showDot: true, deletable: false }, props)));
};
export const EmployeeStateBadge = (_a) => {
    var { state } = _a, props = __rest(_a, ["state"]);
    const { t } = useTranslation('statusBadge');
    let label;
    let color;
    switch (state) {
        case EmployeeState.ACTIVE:
            label = t('active');
            color = BADGE_COLOR.GREEN;
            break;
        case EmployeeState.DELETED:
            label = t('deleted');
            color = BADGE_COLOR.RED;
            break;
        case EmployeeState.DRAFT:
            label = t('draft');
            color = BADGE_COLOR.GREY;
            break;
        case EmployeeState.PENDING_APPROVAL:
            label = t('pending_approval');
            color = BADGE_COLOR.ORANGE;
            break;
        case EmployeeState.ARCHIVED:
            label = t('archived');
            color = BADGE_COLOR.ORANGE;
            break;
        default:
            return null;
    }
    return (React.createElement(Badge, Object.assign({ size: BADGE_SIZE.SMALL, showDot: true, deletable: false }, props, { label: label, color: color })));
};
export const CompanyStateBadge = (_a) => {
    var { state } = _a, props = __rest(_a, ["state"]);
    const { t } = useTranslation('statusBadge');
    let label;
    let color;
    switch (state) {
        case CompanyState.ACTIVE:
            label = t('active');
            color = BADGE_COLOR.GREEN;
            break;
        case CompanyState.DRAFT:
            label = t('draft');
            color = BADGE_COLOR.GREY;
            break;
        default:
            return null;
    }
    return (React.createElement(Badge, Object.assign({ size: BADGE_SIZE.SMALL, showDot: true, deletable: false }, props, { label: label, color: color })));
};
export const HrBadge = (_a) => {
    var props = __rest(_a, []);
    const { t } = useTranslation('statusBadge');
    return (React.createElement(Badge, Object.assign({ size: BADGE_SIZE.SMALL, showDot: true, deletable: false }, props, { label: t('hr'), color: BADGE_COLOR.GREY })));
};
export const InvitationStateBadge = (_a) => {
    var { state } = _a, props = __rest(_a, ["state"]);
    const { t } = useTranslation('statusBadge');
    let label;
    let color;
    switch (state) {
        case EmployeeInvitationState.ACTIVE:
            label = t('active');
            color = BADGE_COLOR.GREEN;
            break;
        case EmployeeInvitationState.EXPIRED:
            label = t('expired');
            color = BADGE_COLOR.GREY;
            break;
        case EmployeeInvitationState.DECLINED:
            label = t('declined');
            color = BADGE_COLOR.ORANGE;
            break;
        case EmployeeInvitationState.NONE:
            label = t('none');
            color = BADGE_COLOR.RED;
            break;
        case EmployeeInvitationState.INVITED:
            label = t('invited');
            color = BADGE_COLOR.YELLOW;
            break;
        default:
            return null;
    }
    return (React.createElement(Badge, Object.assign({ size: BADGE_SIZE.SMALL, showDot: true, deletable: false }, props, { label: label, color: color })));
};
export const PayrollProviderStaffStateBadge = (_a) => {
    var { state } = _a, props = __rest(_a, ["state"]);
    const { t } = useTranslation('statusBadge');
    let label;
    let color;
    switch (state) {
        case PayrollProviderStaffState.ACTIVE:
            label = t('active');
            color = BADGE_COLOR.GREEN;
            break;
        case PayrollProviderStaffState.DISABLED:
            label = t('disabled');
            color = BADGE_COLOR.GREY;
            break;
        default:
            return null;
    }
    return (React.createElement(Badge, Object.assign({ size: BADGE_SIZE.SMALL, showDot: true, deletable: false }, props, { label: label, color: color })));
};
