var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ReactComponent as XIcon } from '@public/icons/x.svg';
import { classNames } from '@utils/class-names';
import React from 'react';
export var CloseButtonType;
(function (CloseButtonType) {
    CloseButtonType["COLORED"] = "COLORED";
    CloseButtonType["GREY"] = "GREY";
})(CloseButtonType || (CloseButtonType = {}));
export var CloseButtonSize;
(function (CloseButtonSize) {
    CloseButtonSize["SMALL"] = "SMALL";
    CloseButtonSize["MEDIUM"] = "MEDIUM";
    CloseButtonSize["LARGE"] = "LARGE";
})(CloseButtonSize || (CloseButtonSize = {}));
export default function CloseButton(_a) {
    var { className, onClick, size, type, testId } = _a, rest = __rest(_a, ["className", "onClick", "size", "type", "testId"]);
    return (React.createElement("button", Object.assign({}, rest, { className: classNames(className, 'focus:ring-4 active:text-white rounded-lg focus:border-none focus-visible:outline-none', size === CloseButtonSize.SMALL && 'p-1.5', size === CloseButtonSize.MEDIUM && 'p-2', size === CloseButtonSize.LARGE && 'p-2.5', type === CloseButtonType.GREY
            ? 'text-n500DarkGrey hover:bg-tertiaryBtnHover focus:ring-n500DarkGrey active:bg-[#858D9D]'
            : 'text-[#9E77ED] hover:bg-[#F9F5FF] active:bg-[#B192F1] focus:ring-[#F4EBFF] focus:bg-[#F9F5FF]'), onClick: onClick }, (testId ? { 'data-testid': testId } : {})),
        React.createElement(XIcon, null)));
}
