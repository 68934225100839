import { ReactComponent as BurgerMenu } from '@public/icons/menu-2.svg';
import { NotificationProvider } from '../context/notification-context';
import { PayrollProviderContext } from '../context/payrollprovider-context';
import { classNames } from '@utils/class-names';
import { useLocation } from 'react-router-dom';
import CompanyLogo from '@components/company-logo';
import Menu from '@components/menu';
import NotificationList from '@components/notifications/notificationList';
import React, { useContext, useMemo, useState } from 'react';
const HIDE_NOTIFICATIONS_ON_PAGE = ['faq', 'support'];
export default function BaseView({ children }) {
    var _a;
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const payrollProvider = (_a = useContext(PayrollProviderContext)) === null || _a === void 0 ? void 0 : _a.payrollProvider;
    const location = useLocation();
    const baseViewProps = useMemo(() => {
        if ((location.pathname.includes('employees') &&
            !location.pathname.replaceAll('/', '').endsWith('employees')) ||
            (location.pathname.includes('companySettings') &&
                location.pathname.includes('admin')) ||
            location.pathname.replaceAll('/', '').endsWith('edit')) {
            return { defaultMargins: false, desktopMenuCollapsed: true };
        }
        return { defaultMargins: true, desktopMenuCollapsed: false };
    }, [location.pathname]);
    const showNotification = useMemo(() => {
        const clearedPath = location.pathname.replaceAll('/', '');
        return !HIDE_NOTIFICATIONS_ON_PAGE.some((page) => clearedPath.endsWith(page));
    }, [location.pathname]);
    return (React.createElement(React.Fragment, null,
        React.createElement(NotificationProvider, null,
            showNotification && React.createElement(NotificationList, null),
            React.createElement("div", { className: "flex flex-row min-h-full w-full" },
                React.createElement(Menu, { sidebarOpen: sidebarOpen, closeSidebar: () => setSidebarOpen((prevState) => !prevState), desktopCollapsed: baseViewProps.desktopMenuCollapsed }),
                React.createElement("div", { className: "flex flex-1 flex-col min-h-full w-full md:w-auto md:min-w-0" },
                    React.createElement("div", { className: "sticky top-0 z-10 pl-4 pr-2 flex h-16 flex-shrink-0 justify-between items-center bg-white border-b border-n200LightGrey 2md:hidden" },
                        React.createElement(CompanyLogo, { logoUrl: payrollProvider === null || payrollProvider === void 0 ? void 0 : payrollProvider.logoUrlBig, width: "180px" }),
                        React.createElement("button", { type: "button", className: "p-4 focus:outline-none focus:ring-2 focus:ring-inset lg:hidden", onClick: () => {
                                setSidebarOpen((prevState) => !prevState);
                            } },
                            React.createElement("span", { className: "sr-only" }, "Open sidebar"),
                            React.createElement(BurgerMenu, { className: "text-n500DarkGrey", "aria-hidden": "true" }))),
                    React.createElement("main", { className: classNames('flex-1 h-[calc(100vh_-_4rem)] 2md:h-auto', baseViewProps.defaultMargins &&
                            'w-full py-6 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8') }, children))))));
}
