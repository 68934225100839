import { getTitle, } from '@components/generic-forms/generic-form';
import { DateInput } from '@components/input/date-input';
import { FieldType } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { ValidationType, validateDateBoundaries, } from '@lohndialog/shared/src/validation';
import React, { useMemo } from 'react';
import dayjs from '@lohndialog/shared/src/util/dayjs';
export const DateField = ({ fieldName, field, fieldValue, setValue, className, disabled, required, shouldValidate, genericFields, supervisingGenericFields, }) => {
    const isDateFieldType = field.type === FieldType.DATE || field.type === FieldType.DATETIME;
    const validator = useMemo(() => {
        if (isDateFieldType) {
            return validateDateBoundaries(field.boundaries, genericFields, supervisingGenericFields, field.entity);
        }
        return undefined;
    }, [field, genericFields, isDateFieldType, supervisingGenericFields]);
    if (!isDateFieldType ||
        (fieldValue && fieldValue.type !== FieldType.DATE)) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(DateInput, { key: fieldName, value: fieldValue ? fieldValue.value : undefined, title: getTitle(field, required), onChange: (newValue) => setValue(fieldName, field.type, (fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.type) === FieldType.DATETIME
            ? dayjs(newValue).toISOString()
            : newValue), validate: validator, classNameContainer: className, disabled: disabled, required: required, inputTestId: `textInput-${fieldName}`, shouldValidate: shouldValidate, validationType: ValidationType.date, runInitialValidation: true }));
};
