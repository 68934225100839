import { CompanyContext } from '../context/company-context';
import { FieldType, } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { InfoText } from '@components/info-text';
import { PayrollProviderContext } from '../context/payrollprovider-context';
import { ReactComponent as XCircleIcon } from '@public/icons/x-circle.svg';
import { classNames } from '@utils/class-names';
import { filterByRightsAndSortGenericFieldNames } from '@utils/generic-fields';
import { getPermissionForField } from '@lohndialog/shared/src/util/generic-fields';
import { localizeString } from '@utils/formatting';
import { useTranslation } from 'react-i18next';
import React, { useContext, useLayoutEffect, useMemo, useState } from 'react';
import dayjs from '@lohndialog/shared/src/util/dayjs';
import i18n from '../i18n';
import nestClient from '@api/nest-client';
export default function GenericSummaryCard({ className, title, schema, wizardCluster, entity, roles, }) {
    const company = useContext(CompanyContext);
    const payrollProvider = useContext(PayrollProviderContext);
    const sortedFields = useMemo(() => filterByRightsAndSortGenericFieldNames(schema, wizardCluster, entity, company, payrollProvider, false), [company, entity, payrollProvider, schema, wizardCluster]);
    return (React.createElement("div", { className: classNames(className, 'bg-n200LightGrey rounded-2xl p-6 mb-4') },
        React.createElement("h3", { className: "font-bold text-base leading-5 text-n600DarkBlue mb-6" }, title),
        React.createElement("div", { className: "flex flex-row flex-wrap gap-4" }, sortedFields.map((fieldName) => {
            return (React.createElement("div", { key: fieldName, className: "flex flex-col justify-between basis-1/3 md:basis-1/5" },
                React.createElement("div", { className: "text-xs text-n500DarkGrey font-medium leading-4 mb-1.5" }, getTitle(schema.fields[fieldName])),
                React.createElement("div", { className: "text-sm font-inter text-black font-medium leading-4" },
                    React.createElement(Value, { fieldName: fieldName, field: schema.fields[fieldName], entity: entity, roles: roles, schema: schema }))));
        }))));
}
export const getTitle = (field) => {
    const title = localizeString(field.label, i18n.language);
    if (field.infoText) {
        return (React.createElement("div", { className: "w-full flex flex-row items-end" },
            React.createElement("span", { className: "flex-shrink hyphens-auto" }, title),
            React.createElement(InfoText, { className: "ml-2", text: localizeString(field.infoText, i18n.language) })));
    }
    else {
        return title;
    }
};
export const Value = ({ fieldName, field, entity, roles, schema, }) => {
    var _a, _b, _c, _d;
    const { t } = useTranslation('common');
    const { t: nestedTranslations } = useTranslation('nestedFields');
    const [possibleFieldValue, setPossibleFieldValue] = useState({});
    const genericFieldValue = entity.genericFields[fieldName];
    const isRequired = roles.some((role) => field.required[role]);
    const { required } = getPermissionForField(fieldName, field, isRequired, undefined, entity, schema);
    useLayoutEffect(() => {
        if (field.type === FieldType.SEARCH) {
            const fetchInitialValue = async () => {
                const genericFieldValue = entity.genericFields[fieldName];
                const rawOptions = await nestClient.getPossibleValueForIdAndName(field.id, (genericFieldValue === null || genericFieldValue === void 0 ? void 0 : genericFieldValue.type) === FieldType.SEARCH
                    ? genericFieldValue.value
                    : '', i18n.language);
                setPossibleFieldValue(rawOptions[0][1]);
            };
            void fetchInitialValue();
        }
    }, [
        fieldName,
        field.id,
        field.type,
        entity.genericFields,
        genericFieldValue,
    ]);
    switch (genericFieldValue === null || genericFieldValue === void 0 ? void 0 : genericFieldValue.type) {
        case FieldType.TEXT:
        case FieldType.EMAIL:
        case FieldType.NUMBER:
        case FieldType.URL:
        case FieldType.TEL:
            if (genericFieldValue.value === '') {
                if (required) {
                    return (React.createElement("span", { className: "text-r600Error" },
                        React.createElement(XCircleIcon, { className: "h-5 w-5", title: t('missingMandatoryField') })));
                }
                else {
                    return React.createElement(React.Fragment, null, "-");
                }
            }
            return React.createElement("div", { className: "break-all" }, genericFieldValue.value);
        case FieldType.DATE:
            if (!genericFieldValue.value) {
                if (required) {
                    return (React.createElement("span", { className: "text-r600Error" },
                        React.createElement(XCircleIcon, { className: "h-5 w-5", title: t('missingMandatoryField') })));
                }
                else {
                    return React.createElement(React.Fragment, null, "-");
                }
            }
            return (React.createElement(React.Fragment, null, dayjs(genericFieldValue.value).format(t('dateFormat'))));
        case FieldType.CHECKBOX:
            if (field.type === FieldType.CHECKBOX) {
                const yesString = (_b = (_a = field.customTranslations) === null || _a === void 0 ? void 0 : _a.yes[i18n.language]) !== null && _b !== void 0 ? _b : t('yes');
                const noString = (_d = (_c = field.customTranslations) === null || _c === void 0 ? void 0 : _c.no[i18n.language]) !== null && _d !== void 0 ? _d : t('no');
                return genericFieldValue.value ? (React.createElement(React.Fragment, null, yesString)) : (React.createElement(React.Fragment, null, noString));
            }
            return genericFieldValue.value ? React.createElement(React.Fragment, null, t('yes')) : React.createElement(React.Fragment, null, t('no'));
        case FieldType.COMBOBOX:
        case FieldType.DROPDOWN:
            /* istanbul ignore next */
            if (field.type !== FieldType.COMBOBOX &&
                field.type !== FieldType.DROPDOWN) {
                return React.createElement(React.Fragment, null, "-");
            }
            return (React.createElement("div", { className: "break-all" }, localizeString(field.possibleValues[genericFieldValue.value], i18n.language, '')));
        case FieldType.CHILD:
            if (field.type !== FieldType.CHILD) {
                return React.createElement(React.Fragment, null, "-");
            }
            return (React.createElement("div", { className: "flex gap-4" },
                React.createElement("div", { className: "flex flex-col" },
                    React.createElement("div", { className: "text-xs text-n500DarkGrey font-medium leading-4 mb-1.5" }, nestedTranslations('firstname')),
                    !genericFieldValue.value.firstname ? (React.createElement("span", { className: "text-r600Error" },
                        React.createElement(XCircleIcon, { className: "h-5 w-5", title: t('missingMandatoryField') }))) : (genericFieldValue.value.firstname)),
                React.createElement("div", { className: "flex flex-col" },
                    React.createElement("div", { className: "text-xs text-n500DarkGrey font-medium leading-4 mb-1.5" }, nestedTranslations('divertingLastName')),
                    !genericFieldValue.value.divertingLastName ? (React.createElement(React.Fragment, null, "-")) : (genericFieldValue.value.divertingLastName)),
                React.createElement("div", { className: "flex flex-col" },
                    React.createElement("div", { className: "text-xs text-n500DarkGrey font-medium leading-4 mb-1.5" }, nestedTranslations('birthDate')),
                    !genericFieldValue.value.birthDate ? (React.createElement("span", { className: "text-r600Error" },
                        React.createElement(XCircleIcon, { className: "h-5 w-5", title: t('missingMandatoryField') }))) : (dayjs(genericFieldValue.value.birthDate).format(t('dateFormat')))),
                React.createElement("div", { className: "flex flex-col" },
                    React.createElement("div", { className: "text-xs text-n500DarkGrey font-medium leading-4 mb-1.5" }, nestedTranslations('proofDate')),
                    !genericFieldValue.value.proofDate ? (React.createElement("span", { className: "text-r600Error" },
                        React.createElement(XCircleIcon, { className: "h-5 w-5", title: t('missingMandatoryField') }))) : (dayjs(genericFieldValue.value.proofDate).format(t('dateFormat'))))));
        case FieldType.SEARCH:
            /* istanbul ignore next */
            if (field.type !== FieldType.SEARCH) {
                return React.createElement(React.Fragment, null, "-");
            }
            return (React.createElement("div", { className: "break-all" }, localizeString(possibleFieldValue, i18n.language, '')));
        default:
            if (required) {
                return (React.createElement("span", { className: "text-r600Error" },
                    React.createElement(XCircleIcon, { className: "h-5 w-5", title: t('missingMandatoryField') })));
            }
            else {
                return React.createElement(React.Fragment, null, "-");
            }
    }
};
