import { ConfirmationDialog } from '@components/wizard/confirmation-dialog';
import { WizardCluster, } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { LoadingSpinner } from '@components/loading-spinner';
import { UserCompanyRoleEnum } from '@lohndialog/shared/src/constants';
import { usePromiseState } from '@hooks/usePromise';
import { useTranslation } from 'react-i18next';
import GenericSummaryCard from '@components/generic-summary-card';
import React, { useCallback, useState } from 'react';
import nestClient from '@api/nest-client';
export default (schema, successfulRedirect) => ({
    key: 'reviewStep',
    isValid: () => true,
    stepLabel: (t) => t('reviewStep.label', {
        ns: 'wizard',
    }),
    stepLabelDetails: (t) => t('reviewStep.labelDetails', {
        ns: 'wizard',
    }),
    ReactComponent: ({ value }) => {
        const { t } = useTranslation('wizard', {
            keyPrefix: 'reviewStep',
        });
        const [dialogOpen, _setDialogOpen] = useState(false);
        const adminRole = [UserCompanyRoleEnum.ADMIN];
        const [submitSuccessful, runSubmit, submitting, submitError, resetSubmit,] = usePromiseState(false);
        const setDialogOpen = useCallback((open) => {
            // When the dialog is opened/closed, reset any errors. This way, the next time the dialog is opened, there is no old error displayed.
            resetSubmit();
            _setDialogOpen(open);
        }, [resetSubmit]);
        if (!schema) {
            return React.createElement(LoadingSpinner, null);
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement("h1", { className: "text-center mb-8" }, t('title')),
                React.createElement("h2", null, t('labelClusterCompanyInformation')),
                React.createElement(GenericSummaryCard, { title: t('subLabelBasicInformation'), entity: value, schema: schema, wizardCluster: WizardCluster.BasicInformation, roles: adminRole }),
                React.createElement(GenericSummaryCard, { title: t('subLabelAccountingInformation'), entity: value, schema: schema, wizardCluster: WizardCluster.AccountingInformation, roles: adminRole }),
                React.createElement(GenericSummaryCard, { title: t('subLabelPaymentInformation'), entity: value, schema: schema, wizardCluster: WizardCluster.PaymentInformation, roles: adminRole }),
                React.createElement("h2", { className: "mt-8" }, t('labelClusterSocialSecurity')),
                React.createElement(GenericSummaryCard, { title: t('subLabelSocialSecurityBasic'), entity: value, schema: schema, wizardCluster: WizardCluster.SocialSecurityBasic, roles: adminRole }),
                React.createElement(GenericSummaryCard, { title: t('subLabelSocialSecurityRegistrationNumber'), entity: value, schema: schema, wizardCluster: WizardCluster.SocialSecurityRegistrationNumber, roles: adminRole }),
                React.createElement("h2", { className: "mt-8" }, t('labelClusterTaxOfficeAndProfessionalAssociation')),
                React.createElement(GenericSummaryCard, { title: t('subLabelTaxOffice'), entity: value, schema: schema, wizardCluster: WizardCluster.TaxOffice, roles: adminRole }),
                React.createElement(GenericSummaryCard, { title: t('subLabelProfessionalAssociation'), entity: value, schema: schema, wizardCluster: WizardCluster.ProfessionalAssociation, roles: adminRole }),
                React.createElement("h2", { className: "mt-8" }, t('labelClusterFinancialAccounting')),
                React.createElement(GenericSummaryCard, { title: t('subLabelFinancialAccountingBasic'), entity: value, schema: schema, wizardCluster: WizardCluster.FinancialAccountingBasic, roles: adminRole }),
                React.createElement(GenericSummaryCard, { title: t('subLabelFinancialAccountingOutputFile'), entity: value, schema: schema, wizardCluster: WizardCluster.FinancialAccountingOutputFile, roles: adminRole }),
                React.createElement(GenericSummaryCard, { title: t('subLabelFinancialAccountingCostUnit'), entity: value, schema: schema, wizardCluster: WizardCluster.FinancialAccountingCostUnit, roles: adminRole }),
                React.createElement("h2", { className: "mt-8" }, t('labelClusterTradeAndCraft')),
                React.createElement(GenericSummaryCard, { title: t('subLabelSocialFund'), entity: value, schema: schema, wizardCluster: WizardCluster.SocialFund, roles: adminRole }),
                React.createElement(GenericSummaryCard, { title: t('subLabelTariffAgreement'), entity: value, schema: schema, wizardCluster: WizardCluster.TariffAgreement, roles: adminRole })),
            React.createElement(ConfirmationDialog, { setDialogOpen: setDialogOpen, roles: adminRole, dialogOpen: dialogOpen, submit: () => void runSubmit(async () => {
                    await nestClient.updateCompany(value);
                    return true;
                }), submitting: submitting, submitError: submitError, submitSuccessful: submitSuccessful, successfulRedirect: successfulRedirect })));
    },
});
