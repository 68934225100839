import { ReactComponent as SearchIcon } from '@public/icons/search.svg';
import { classNames } from '@utils/class-names';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
export default function SearchInputField({ setSearchTerm, placeHolder, className, }) {
    const { t } = useTranslation('searchInputField');
    const [searchFilter, setSearchFilter] = useState();
    const triggerSearch = useMemo(() => {
        return debounce(setSearchTerm, 300);
    }, [setSearchTerm]);
    const onChange = (event) => {
        setSearchFilter(event.target.value);
        triggerSearch(event.target.value);
    };
    return (React.createElement("div", { className: "relative xs:flex-grow xl:max-w-[400px] xl:min-w-[235px]" },
        React.createElement(SearchIcon, { className: "absolute h-4 w-4 top-0 bottom-0 my-auto left-3 text-n450MidGrey" }),
        React.createElement("input", { className: classNames('rounded-lg border border-n300Grey shadow-xs h-[2.75rem] indent-9 font-normal w-full placeholder-n450MidGrey', className), placeholder: placeHolder !== null && placeHolder !== void 0 ? placeHolder : t('search'), value: searchFilter !== null && searchFilter !== void 0 ? searchFilter : '', onChange: onChange, "data-testid": "search-input-field" })));
}
