import { FieldType, } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { getMainHRContactName } from '@components/company-dashboard-wizard/main-hr-contact-field';
import { getOptionalFirstAndLastNameString } from '@utils/getOptionalFirstAndLastNameString';
import { localizeString } from '@utils/formatting';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import i18n from '../../i18n';
export default function CompanyDashboardInformationPanel({ company, companySchema, }) {
    var _a, _b, _c, _d, _e, _f, _g;
    const { t: tCommon } = useTranslation('common');
    const { t } = useTranslation('editCompanyView');
    const currentAccountingMonth = useMemo(() => {
        var _a, _b, _c;
        return (_c = (_b = (_a = company.genericFields.currentAccountingMonth) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.toString().padStart(2, '0')) !== null && _c !== void 0 ? _c : '?';
    }, [(_a = company.genericFields.currentAccountingMonth) === null || _a === void 0 ? void 0 : _a.value]);
    const currentAccountingYear = useMemo(() => {
        var _a, _b, _c;
        return (_c = (_b = (_a = company.genericFields.currentAccountingYear) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.toString().padStart(4, '0')) !== null && _c !== void 0 ? _c : '?';
    }, [(_b = company.genericFields.currentAccountingYear) === null || _b === void 0 ? void 0 : _b.value]);
    const accountingDateTime = useMemo(() => {
        var _a;
        const fieldDefinition = companySchema.fields.accountingDateTime;
        const fieldValue = (_a = company.genericFields.accountingDateTime) === null || _a === void 0 ? void 0 : _a.value;
        if ((fieldDefinition === null || fieldDefinition === void 0 ? void 0 : fieldDefinition.type) === FieldType.DROPDOWN && fieldValue) {
            return localizeString(fieldDefinition.possibleValues[fieldValue], i18n.language);
        }
        return '';
    }, [(_c = company.genericFields.accountingDateTime) === null || _c === void 0 ? void 0 : _c.value, companySchema.fields]);
    const mainPayrollProviderContactPerson = useMemo(() => {
        return getOptionalFirstAndLastNameString(company.mainPayrollProviderContactPerson.firstname, company.mainPayrollProviderContactPerson.lastname);
    }, [
        company.mainPayrollProviderContactPerson.firstname,
        company.mainPayrollProviderContactPerson.lastname,
    ]);
    const differentSalaryFrame = useMemo(() => {
        var _a, _b;
        return ((_b = (_a = company.genericFields.differentSalaryFrame) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '');
    }, [(_d = company.genericFields.differentSalaryFrame) === null || _d === void 0 ? void 0 : _d.value]);
    const mainHRContactPersonName = useMemo(() => {
        return getMainHRContactName(company.mainHRContactPerson);
    }, [company.mainHRContactPerson]);
    return (React.createElement("div", { className: "grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 2k:grid-cols-6 gap-4 bg-white py-2.5 px-8 rounded-2xl" },
        React.createElement("div", null,
            React.createElement("p", null, t('accountingPeriode')),
            React.createElement("p", null, `${currentAccountingMonth}/${currentAccountingYear}`)),
        React.createElement("div", null,
            React.createElement("p", null, t('immediateReporting')),
            React.createElement("p", null, tCommon((_g = (_f = (_e = company.genericFields.immediateReporting) === null || _e === void 0 ? void 0 : _e.value) === null || _f === void 0 ? void 0 : _f.toString()) !== null && _g !== void 0 ? _g : 'undefined'))),
        React.createElement("div", null,
            React.createElement("p", null, t('accountingDateTime')),
            React.createElement("p", null, accountingDateTime)),
        React.createElement("div", null,
            React.createElement("p", null, t('salaryFrame')),
            React.createElement("p", null, differentSalaryFrame)),
        React.createElement("div", null,
            React.createElement("p", null, t('mainHRContactPerson')),
            React.createElement("p", null, mainHRContactPersonName)),
        React.createElement("div", null,
            React.createElement("p", null, t('mainPayrollProviderContactPerson')),
            React.createElement("p", null, mainPayrollProviderContactPerson))));
}
