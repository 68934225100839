import { CompanyStateBadge } from '@components/badge';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
export default function CompanyDashboardPanel({ company, }) {
    var _a, _b, _c;
    const { t } = useTranslation('editCompanyView');
    const companyName = useMemo(() => {
        var _a;
        return (_a = company.genericFields.name) === null || _a === void 0 ? void 0 : _a.value;
    }, [(_a = company.genericFields.name) === null || _a === void 0 ? void 0 : _a.value]);
    const mandatorNumber = useMemo(() => {
        var _a, _b;
        return ((_b = (_a = company.genericFields.mandatorNumber) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '');
    }, [(_b = company.genericFields.mandatorNumber) === null || _b === void 0 ? void 0 : _b.value]);
    return (React.createElement("div", { className: "xs:flex justify-between" },
        React.createElement("div", { className: "flex" },
            React.createElement("div", { className: "profile-image w-16 h-16 bg-p200Green rounded-full flex flex-shrink-0 justify-center items-center text-white font-semibold text-3xl" }, (_c = companyName === null || companyName === void 0 ? void 0 : companyName.charAt(0).toUpperCase()) !== null && _c !== void 0 ? _c : ''),
            React.createElement("div", { className: "mx-5" },
                React.createElement("p", { className: "uppercase text-2xl xs:text-3xl hyphens-auto overflow-hidden font-medium" }, companyName),
                React.createElement("p", { className: "text-n500DarkGrey" }, mandatorNumber))),
        React.createElement("div", { className: "mx-5 xs:mx-0" },
            React.createElement("p", { className: "text-n500DarkGrey font-medium text-sm" }, t('status')),
            React.createElement(CompanyStateBadge, { state: company.state }))));
}
