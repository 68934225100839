export var ServerErrorCode;
(function (ServerErrorCode) {
    ServerErrorCode["POST_ROUTE_FAILED"] = "POST_ROUTE_FAILED";
    ServerErrorCode["GET_ROUTE_FAILED"] = "GET_ROUTE_FAILED";
    ServerErrorCode["VALIDATION_FAILED"] = "VALIDATION_FAILED";
    ServerErrorCode["UPLOAD_FAILED"] = "UPLOAD_FAILED";
    ServerErrorCode["NO_COMPANY"] = "NO_COMPANY";
    ServerErrorCode["NO_EMPLOYEE"] = "NO_EMPLOYEE";
    ServerErrorCode["INVALID_FILE"] = "INVALID_FILE";
    ServerErrorCode["MANDATOR_NUMBER_ALREADY_USED"] = "MANDATOR_NUMBER_ALREADY_USED";
    ServerErrorCode["SLUG_ALREADY_USED"] = "SLUG_ALREADY_USED";
})(ServerErrorCode || (ServerErrorCode = {}));
