import './i18n';
import './styles/globals.scss';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { routes } from './routing/routes';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './report-web-vitals';
const router = createBrowserRouter(routes);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(React.createElement(React.StrictMode, null,
    React.createElement(RouterProvider, { router: router })));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
