import { FieldType, } from '@lohndialog/shared/src/dtos/entity-schema.dto';
export const getGenericFieldsWithDefaultValues = (schema) => {
    const fields = {};
    Object.entries(schema.fields).forEach(([key, specs]) => {
        if (specs.type === FieldType.CHILD || specs.type === FieldType.SEARCH) {
            return;
        }
        if (specs.defaultValue) {
            if (specs.type === FieldType.NUMBER) {
                const value = parseFloat(specs.defaultValue);
                if (!isNaN(value)) {
                    fields[key] = {
                        value,
                        type: specs.type,
                    };
                }
            }
            else if (specs.type === FieldType.CHECKBOX) {
                const value = specs.defaultValue === 'yes';
                fields[key] = {
                    value,
                    type: specs.type,
                };
            }
            else {
                fields[key] = {
                    value: specs.defaultValue,
                    type: specs.type,
                };
            }
        }
    });
    return fields;
};
