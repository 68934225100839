import { Button, ButtonSize, ButtonType } from '@components/button';
import { ConfirmationDialog } from '@components/wizard/confirmation-dialog';
import { EMPLOYEE_STATES_EMPLOYEES_CAN_EDIT_OWN_DATA, EmployeeState, SelfServiceState, UserCompanyRoleEnum, } from '@lohndialog/shared/src/constants';
import { FieldType, WizardCluster, } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { LoadingSpinner } from '@components/loading-spinner';
import { MAX_NUMBER_OF_INDIVIDUAL_NOTIFICATIONS } from '@lohndialog/shared/src/validation';
import { NotificationContext } from '../../context/notification-context';
import { SignatureBox } from '@components/SignatureBox';
import { addGenericNotificationFunction, addSpecificNotificationsFunction, } from '@utils/addNotificationUtils';
import { useCompany } from '../../context/company-context';
import { usePromiseState } from '@hooks/usePromise';
import { useTranslation } from 'react-i18next';
import CareInsuranceSummaryCard from '@components/care-insurance-summary-card';
import GenericSummaryCard from '@components/generic-summary-card';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import nestClient from '@api/nest-client';
export default (schema, successfulRedirect) => ({
    key: 'reviewStep',
    isValid: () => true,
    stepLabel: (t) => t('reviewStep.label', {
        ns: 'wizard',
    }),
    stepLabelDetails: (t) => t('reviewStep.labelDetails', {
        ns: 'wizard',
    }),
    // eslint-disable-next-line sonarjs/cognitive-complexity
    ReactComponent: ({ value, setValue }) => {
        const { t } = useTranslation('wizard', {
            keyPrefix: 'reviewStep',
        });
        const { t: tLabel } = useTranslation('employeeWizard', {
            keyPrefix: 'clusterLabel',
        });
        const { company } = useCompany();
        const roles = useMemo(() => { var _a; return (_a = company === null || company === void 0 ? void 0 : company.companyRoles) !== null && _a !== void 0 ? _a : []; }, [company]);
        const [dialogOpen, _setDialogOpen] = useState(false);
        const notificationState = useContext(NotificationContext);
        const { t: tErrorHandling } = useTranslation('errorHandling');
        const addSpecificNotifications = useCallback(addSpecificNotificationsFunction, [tErrorHandling, notificationState]);
        const addGenericNotification = useCallback(addGenericNotificationFunction, [tErrorHandling, notificationState]);
        const handleReject = useCallback(async () => {
            await nestClient.rejectEmployee(value, EmployeeState.DRAFT);
            successfulRedirect();
        }, [value]);
        const isEmployee = useMemo(() => !!(company === null || company === void 0 ? void 0 : company.companyRoles.some((role) => [UserCompanyRoleEnum.USER].includes(role))), [company === null || company === void 0 ? void 0 : company.companyRoles]);
        const isHr = useMemo(() => !!(company === null || company === void 0 ? void 0 : company.companyRoles.some((role) => [UserCompanyRoleEnum.ADMIN].includes(role))), [company === null || company === void 0 ? void 0 : company.companyRoles]);
        const isPP = useMemo(() => !!(company === null || company === void 0 ? void 0 : company.companyRoles.some((role) => [UserCompanyRoleEnum.PP_ADMIN].includes(role))), [company === null || company === void 0 ? void 0 : company.companyRoles]);
        const companyUserManagementActive = useMemo(() => (company === null || company === void 0 ? void 0 : company.companyUserManagement) === SelfServiceState.ACTIVE, [company === null || company === void 0 ? void 0 : company.companyUserManagement]);
        const allowSubmitChangesForReview = useMemo(() => companyUserManagementActive &&
            EMPLOYEE_STATES_EMPLOYEES_CAN_EDIT_OWN_DATA.includes(value.state) &&
            isEmployee &&
            !isHr, [companyUserManagementActive, isEmployee, isHr, value.state]);
        const targetState = useMemo(() => {
            if (allowSubmitChangesForReview) {
                return EmployeeState.PENDING_APPROVAL;
            }
            else if (isHr) {
                return EmployeeState.ACTIVE;
            }
            else {
                return value.state;
            }
        }, [allowSubmitChangesForReview, isHr, value.state]);
        const [submitSuccessful, runSubmit, submitting, submitError, resetSubmit,] = usePromiseState(false);
        const setDialogOpen = useCallback((open) => {
            // When the dialog is opened/closed, reset any errors. This way, the next time the dialog is opened, there is no old error displayed.
            resetSubmit();
            _setDialogOpen(open);
        }, [resetSubmit]);
        if (!schema) {
            return React.createElement(LoadingSpinner, null);
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement("h1", { className: "text-center mb-8" }, t('title')),
                React.createElement("h2", null, t('subtitle', { defaultValue: '' })),
                React.createElement(GenericSummaryCard, { title: tLabel(WizardCluster.PersonalData), entity: value, schema: schema, wizardCluster: WizardCluster.PersonalData, roles: roles }),
                React.createElement(GenericSummaryCard, { title: tLabel(WizardCluster.Address), entity: value, schema: schema, wizardCluster: WizardCluster.Address, roles: roles }),
                React.createElement(GenericSummaryCard, { title: tLabel(WizardCluster.PaymentData), entity: value, schema: schema, wizardCluster: WizardCluster.PaymentData, roles: roles }),
                React.createElement(GenericSummaryCard, { title: tLabel(WizardCluster.AdditionalData), entity: value, schema: schema, wizardCluster: WizardCluster.AdditionalData, roles: roles }),
                React.createElement(GenericSummaryCard, { title: tLabel(WizardCluster.Tax), entity: value, schema: schema, wizardCluster: WizardCluster.Tax, roles: roles }),
                React.createElement(GenericSummaryCard, { title: tLabel(WizardCluster.Occupation), entity: value, schema: schema, wizardCluster: WizardCluster.Occupation, roles: roles }),
                React.createElement(GenericSummaryCard, { title: tLabel(WizardCluster.RetirementInsurance), entity: value, schema: schema, wizardCluster: WizardCluster.RetirementInsurance, roles: roles }),
                React.createElement(GenericSummaryCard, { title: tLabel(WizardCluster.HealthInsurance), entity: value, schema: schema, wizardCluster: WizardCluster.HealthInsurance, roles: roles }),
                React.createElement(CareInsuranceSummaryCard, { title: tLabel(WizardCluster.CareInsurance), entity: value, schema: schema, wizardCluster: WizardCluster.CareInsurance, roles: roles }),
                React.createElement(GenericSummaryCard, { title: tLabel(WizardCluster.AccountingData), entity: value, schema: schema, wizardCluster: WizardCluster.AccountingData, roles: roles }),
                React.createElement(GenericSummaryCard, { title: tLabel(WizardCluster.Employment), entity: value, schema: schema, wizardCluster: WizardCluster.Employment, roles: roles }),
                React.createElement(GenericSummaryCard, { title: tLabel(WizardCluster.Payment), entity: value, schema: schema, wizardCluster: WizardCluster.Payment, roles: roles }),
                React.createElement(GenericSummaryCard, { title: tLabel(WizardCluster.Mapping), entity: value, schema: schema, wizardCluster: WizardCluster.Mapping, roles: roles }),
                React.createElement(GenericSummaryCard, { title: tLabel(WizardCluster.NONE), entity: value, schema: schema, wizardCluster: WizardCluster.NONE, roles: roles }),
                companyUserManagementActive && !isHr && !isPP && (React.createElement(SignatureBox, { onChange: (confirmed) => setValue('special_consent', FieldType.CHECKBOX, confirmed), checked: value.hasConfirmedData })),
                allowSubmitChangesForReview && !submitSuccessful && (React.createElement(Button, { label: t('submitChangesButton'), buttonType: ButtonType.PRIMARY, buttonSize: ButtonSize.LARGE, className: "text-center inline-block", onClick: () => setDialogOpen(true), testId: 'submit-changes-button', disabled: !value.hasConfirmedData })),
                React.createElement("div", { className: "flex gap-2" },
                    isHr && !submitSuccessful && (React.createElement(Button, { label: t('submitChangesAndActivateButton'), buttonType: ButtonType.PRIMARY, buttonSize: ButtonSize.LARGE, className: "text-center inline-block", onClick: () => setDialogOpen(true), testId: 'submit-changes-and-activate-button' })),
                    isHr &&
                        value.state ===
                            EmployeeState.PENDING_APPROVAL &&
                        !submitSuccessful && (React.createElement(Button, { label: t('rejectChangesButton'), buttonType: ButtonType.PRIMARY, buttonSize: ButtonSize.LARGE, className: "text-center inline-block", onClick: () => void handleReject(), testId: 'activate-button' })))),
            React.createElement(ConfirmationDialog, { setDialogOpen: setDialogOpen, roles: roles, dialogOpen: dialogOpen, submit: () => void runSubmit(async () => {
                    nestClient.setErrorHandler((apiError) => {
                        const validationErrors = apiError.errorData.validationErrors;
                        if (validationErrors.length <
                            MAX_NUMBER_OF_INDIVIDUAL_NOTIFICATIONS) {
                            addSpecificNotifications(validationErrors, schema, notificationState, tErrorHandling);
                        }
                        else {
                            addGenericNotification(notificationState, tErrorHandling);
                        }
                    });
                    await nestClient.submitEmployeeForApproval(value, targetState);
                    return true;
                }), submitting: submitting, submitError: submitError, submitSuccessful: submitSuccessful, successfulRedirect: successfulRedirect })));
    },
});
