import React from 'react';
export function Entry({ property, value, }) {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "font-bold" }, property),
        React.createElement("div", { className: "break-words" }, value)));
}
export function ProfileParagraph({ title, children, }) {
    return (React.createElement("div", { className: "drop-shadow-table bg-white rounded-lg py-4 px-6" },
        React.createElement("h1", { className: "text-p100Green" }, title),
        React.createElement("div", { className: "grid grid-cols-4 gap-x-8 gap-y-3 my-5 items-start" }, children)));
}
