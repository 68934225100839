import { CheckboxField } from '@components/generic-forms/checkbox-field';
import { ChildField } from '@components/generic-forms/child-field';
import { ComboboxField } from '@components/generic-forms/combobox-field';
import { CompanyContext, } from '../../context/company-context';
import { DateField } from '@components/generic-forms/date-field';
import { getDateValidatorFn, getNumberValidatorFn, getStringValidatorFn, } from '@lohndialog/shared/src/validation';
import { DropdownField } from '@components/generic-forms/dropdown-field';
import { EmailField } from '@components/generic-forms/email-field';
import { FieldType, } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { InfoText } from '../info-text';
import { NumberField } from '@components/generic-forms/number-field';
import { PayrollProviderContext, } from '../../context/payrollprovider-context';
import { SearchField } from '@components/generic-forms/search-field';
import { TelField } from '@components/generic-forms/telephone-field';
import { TextField } from '@components/generic-forms/text-fields';
import { UrlField } from '@components/generic-forms/url-field';
import { UserCompanyRoleEnum } from '@lohndialog/shared/src/constants';
import { filterAndSortGenericFieldNamesForGenericForm, hasEditPermission, } from '@utils/generic-fields';
import { getChildNumberFromName } from '@lohndialog/shared/src/validators/FieldIsRequired';
import { getDefaultValueForField, getPermissionForField, shouldValidateField, } from '@lohndialog/shared/src/util/generic-fields';
import { localizeString } from '@utils/formatting';
import React, { useContext, useMemo } from 'react';
import i18n from '../../i18n';
const FormLayoutWrapper = ({ children }) => (React.createElement("div", { className: "grid grid-cols-6 md:grid-cols-12 gap-x-8 gap-y-3 mt-3 items-end" }, children));
/**
 * Allow overriding the class for specific, known fields.
 * This is used to control the width of certain fields thereby grouping them.
 * The field order must ensure that the fields all come right after one another.
 * Otherwise, the layout will break.
 */
const classOverrides = {
    streetNumber: 'col-span-6 md:col-span-3 md:-mr-3',
    additionStreetNumber: 'col-span-6 md:col-span-3',
    residentCountry: 'col-span-6 md:col-span-3 md:-mr-3',
    postalCode: 'col-span-6 md:col-span-3',
    apprenticeship: 'col-span-6 md:col-span-12',
    formOfEmployment: 'col-span-6 md:col-span-12 md:w-1/2 md:pr-4',
    iban: 'col-span-6 md:col-span-3 md:-mr-2',
    bic: 'col-span-6 md:col-span-3 md:-ml-2',
    currentAccountingMonth: 'col-span-6 md:col-span-3 md:-mr-2',
    currentAccountingYear: 'col-span-6 md:col-span-3 md:-ml-2',
};
const getDisabledAndRequiredForField = (fieldName, field, company, fieldValues, payrollProvider, schema, forceReadOnly, isOverview = false) => {
    var _a, _b, _c, _d;
    const required = [
        ...((_b = (_a = company === null || company === void 0 ? void 0 : company.company) === null || _a === void 0 ? void 0 : _a.companyRoles) !== null && _b !== void 0 ? _b : []),
        ...((_d = (_c = payrollProvider === null || payrollProvider === void 0 ? void 0 : payrollProvider.payrollProvider) === null || _c === void 0 ? void 0 : _c.roles) !== null && _d !== void 0 ? _d : []),
    ].some((role) => field.required[role]);
    return getPermissionForField(fieldName, field, required, hasEditPermission(field, company, payrollProvider, isOverview) &&
        !forceReadOnly, fieldValues, schema);
};
const getFieldForType = (field, fieldName, required, disabled, classOverride, setValue, value, supervisingValue, fieldValue, defaultValue) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    if (field.type !== FieldType.SEARCH &&
        fieldValue === undefined &&
        defaultValue !== undefined) {
        fieldValue = defaultValue;
        setValue(fieldName, field.type, fieldValue.value);
    }
    switch (field.type) {
        case FieldType.TEXT:
            return (React.createElement(TextField, { key: fieldName, fieldValue: fieldValue, setValue: setValue, field: field, fieldName: fieldName, className: classOverride, disabled: disabled, validate: getStringValidatorFn(field.validatorFn), required: required, shouldValidate: shouldValidateField(field, value), genericFields: (_a = value === null || value === void 0 ? void 0 : value.genericFields) !== null && _a !== void 0 ? _a : {} }));
        case FieldType.DROPDOWN:
            return (React.createElement(DropdownField, { key: fieldName, fieldValue: fieldValue, setValue: setValue, field: field, fieldName: fieldName, className: classOverride, disabled: disabled, validate: getStringValidatorFn(field.validatorFn), required: required, shouldValidate: shouldValidateField(field, value), genericFields: (_b = value === null || value === void 0 ? void 0 : value.genericFields) !== null && _b !== void 0 ? _b : {} }));
        case FieldType.COMBOBOX:
            return (React.createElement(ComboboxField, { key: fieldName, fieldValue: fieldValue, setValue: setValue, field: field, fieldName: fieldName, className: classOverride, disabled: disabled, validate: getStringValidatorFn(field.validatorFn), required: required, shouldValidate: shouldValidateField(field, value), genericFields: (_c = value === null || value === void 0 ? void 0 : value.genericFields) !== null && _c !== void 0 ? _c : {} }));
        case FieldType.SEARCH:
            return (React.createElement(SearchField, { key: fieldName, fieldValue: fieldValue, setValue: setValue, field: field, fieldName: fieldName, className: classOverride, disabled: disabled, validate: getStringValidatorFn(field.validatorFn), required: required, shouldValidate: shouldValidateField(field, value), genericFields: (_d = value === null || value === void 0 ? void 0 : value.genericFields) !== null && _d !== void 0 ? _d : {} }));
        case FieldType.DATE:
        case FieldType.DATETIME:
            return (React.createElement(DateField, { key: fieldName, fieldValue: fieldValue, setValue: setValue, field: field, fieldName: fieldName, className: classOverride, disabled: disabled, validate: getDateValidatorFn(field.validatorFn), genericFields: (_e = value === null || value === void 0 ? void 0 : value.genericFields) !== null && _e !== void 0 ? _e : {}, supervisingGenericFields: (_f = supervisingValue === null || supervisingValue === void 0 ? void 0 : supervisingValue.genericFields) !== null && _f !== void 0 ? _f : {}, required: required, shouldValidate: shouldValidateField(field, value) }));
        case FieldType.NUMBER:
            return (React.createElement(NumberField, { key: fieldName, fieldValue: fieldValue, setValue: setValue, field: field, fieldName: fieldName, className: classOverride, disabled: disabled, validate: getNumberValidatorFn(field.validatorFn), required: required, shouldValidate: shouldValidateField(field, value), genericFields: (_g = value === null || value === void 0 ? void 0 : value.genericFields) !== null && _g !== void 0 ? _g : {} }));
        case FieldType.EMAIL:
            return (React.createElement(EmailField, { key: fieldName, fieldValue: fieldValue, setValue: setValue, field: field, fieldName: fieldName, className: classOverride, disabled: disabled, validate: getStringValidatorFn(field.validatorFn), required: required, shouldValidate: shouldValidateField(field, value), genericFields: (_h = value === null || value === void 0 ? void 0 : value.genericFields) !== null && _h !== void 0 ? _h : {} }));
        case FieldType.TEL:
            return (React.createElement(TelField, { key: fieldName, fieldValue: fieldValue, setValue: setValue, field: field, fieldName: fieldName, className: classOverride, disabled: disabled, validate: getStringValidatorFn(field.validatorFn), required: required, shouldValidate: shouldValidateField(field, value), genericFields: (_j = value === null || value === void 0 ? void 0 : value.genericFields) !== null && _j !== void 0 ? _j : {} }));
        case FieldType.URL:
            return (React.createElement(UrlField, { key: fieldName, fieldValue: fieldValue, setValue: setValue, field: field, fieldName: fieldName, className: classOverride, disabled: disabled, validate: getStringValidatorFn(field.validatorFn), required: required, shouldValidate: shouldValidateField(field, value), genericFields: (_k = value === null || value === void 0 ? void 0 : value.genericFields) !== null && _k !== void 0 ? _k : {} }));
        case FieldType.CHECKBOX:
            return (React.createElement(CheckboxField, { key: fieldName, fieldValue: fieldValue, setValue: setValue, field: field, fieldName: fieldName, className: classOverride, disabled: disabled, validate: getStringValidatorFn(field.validatorFn), required: required, shouldValidate: shouldValidateField(field, value), genericFields: (_l = value === null || value === void 0 ? void 0 : value.genericFields) !== null && _l !== void 0 ? _l : {} }));
        case FieldType.CHILD:
            if (getChildNumberFromName(fieldName) >
                ((_o = (_m = value === null || value === void 0 ? void 0 : value.genericFields.childAllowance) === null || _m === void 0 ? void 0 : _m.value) !== null && _o !== void 0 ? _o : 0)) {
                return null;
            }
            return (React.createElement(ChildField, { key: fieldName, fieldValue: fieldValue, setValue: setValue, field: field, fieldName: fieldName, className: "col-span-3", disabled: disabled, validate: getStringValidatorFn(field.validatorFn), required: required, shouldValidate: shouldValidateField(field, value), genericFields: (_p = value === null || value === void 0 ? void 0 : value.genericFields) !== null && _p !== void 0 ? _p : {} }));
    }
};
export default ({ schema, wizardCluster, value, supervisingValue, setValue, overview = false, customFields = [], forceReadOnly, }) => {
    const company = useContext(CompanyContext);
    const payrollProvider = useContext(PayrollProviderContext);
    const sortedFields = useMemo(() => filterAndSortGenericFieldNamesForGenericForm(schema, wizardCluster, false, overview), [overview, schema, wizardCluster]);
    return (React.createElement(FormLayoutWrapper, null,
        sortedFields.map((fieldName) => {
            var _a, _b;
            const field = schema === null || schema === void 0 ? void 0 : schema.fields[fieldName];
            if (!field) {
                return;
            }
            // different display in dashboard based on role...
            const shouldUseOverride = overview
                ? [
                    'currentAccountingMonth',
                    'currentAccountingYear',
                ].includes(fieldName) &&
                    ((_a = company === null || company === void 0 ? void 0 : company.company) === null || _a === void 0 ? void 0 : _a.companyRoles.includes(UserCompanyRoleEnum.PP_ADMIN))
                : true;
            const defaultValue = getDefaultValueForField(field);
            const fieldValue = value === null || value === void 0 ? void 0 : value.genericFields[fieldName];
            const classOverride = shouldUseOverride
                ? (_b = classOverrides[fieldName]) !== null && _b !== void 0 ? _b : 'col-span-6'
                : 'col-span-6';
            const { required, disabled } = getDisabledAndRequiredForField(fieldName, field, company, value, payrollProvider, schema, forceReadOnly, overview);
            return getFieldForType(field, fieldName, required, disabled, classOverride, setValue, value, supervisingValue, fieldValue, defaultValue);
        }),
        ...customFields));
};
export const getTitle = (field, required) => {
    const title = localizeString(field.label, i18n.language);
    if (field.infoText) {
        return (React.createElement("div", { className: "w-full flex flex-row justify-between items-end" },
            React.createElement("span", { className: "flex-shrink hyphens-auto" },
                title,
                required ? '*' : ''),
            React.createElement(InfoText, { text: localizeString(field.infoText, i18n.language) })));
    }
    else {
        return (React.createElement(React.Fragment, null,
            title,
            required ? '*' : ''));
    }
};
