import { useAuth } from './auth-context';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState, } from 'react';
import nestClient from '@api/nest-client';
export const ProfileStateContext = createContext(undefined);
export const ProfileProvider = ({ children, profile }) => {
    const [actionState, setActionState] = useState(profile
        ? {
            action: 'fetch',
            pending: false,
            fulfilled: true,
            profile: profile,
        }
        : {
            action: 'fetch',
            pending: false,
            fulfilled: false,
        });
    const resetToggle = useRef(false);
    const wrapApiCall = useCallback((call, action, shouldReset = false) => {
        const newActionState = {
            action,
            pending: true,
            fulfilled: false,
            profile: actionState.profile,
        };
        setActionState(newActionState);
        return call()
            .then((profile) => {
            setActionState(Object.assign(Object.assign({}, newActionState), { pending: false, fulfilled: true, profile }));
            resetToggle.current = shouldReset;
        })
            .catch((error) => {
            setActionState(Object.assign(Object.assign({}, newActionState), { pending: false, fulfilled: false, error: error }));
        });
    }, [actionState.profile]);
    const fetchProfile = useCallback(() => {
        void wrapApiCall(() => nestClient.getProfile(), 'fetch');
    }, [wrapApiCall]);
    const state = useMemo(() => ({
        state: actionState,
        fetchProfile,
    }), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actionState]);
    const { isAuthenticated, isLoading } = useAuth();
    useEffect(() => {
        const { profile, pending, error } = actionState;
        if (!profile && !pending && !error && isAuthenticated && !isLoading) {
            fetchProfile();
        }
    }, [actionState, fetchProfile, isAuthenticated, isLoading]);
    return (React.createElement(ProfileStateContext.Provider, { value: state }, children));
};
export function useProfile() {
    const state = useContext(ProfileStateContext);
    if (!state) {
        throw new Error('useProfile must be used within a ProfileProvider');
    }
    return state;
}
