import { EmployeeWizard } from '@components/employee-wizard/employee-wizard';
import { LoadingSpinner } from '@components/loading-spinner';
import { MAX_NUMBER_OF_INDIVIDUAL_NOTIFICATIONS } from '@lohndialog/shared/src/validation';
import { NotificationContext } from '../context/notification-context';
import { UserCompanyRoleEnum } from '@lohndialog/shared/src/constants';
import { addGenericNotificationFunction, addSpecificNotificationsFunction, } from '@utils/addNotificationUtils';
import { useCompany } from '../context/company-context';
import { useNavigate } from '@hooks/useNavigate';
import { useNestClient } from '@hooks/useNestClient';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useContext } from 'react';
import nestClient from '@api/nest-client';
export default function EditEmployeeView() {
    var _a;
    const { company } = useCompany();
    const notificationState = useContext(NotificationContext);
    const { t } = useTranslation('errorHandling');
    const redirectUrl = (company === null || company === void 0 ? void 0 : company.companyRoles.includes(UserCompanyRoleEnum.ADMIN)) ||
        (company === null || company === void 0 ? void 0 : company.companyRoles.includes(UserCompanyRoleEnum.PP_ADMIN))
        ? '/employees'
        : '/dashboard';
    const navigate = useNavigate();
    const params = useParams();
    const addSpecificNotifications = useCallback(addSpecificNotificationsFunction, [t, notificationState]);
    const addGenericNotification = useCallback(addGenericNotificationFunction, [
        t,
        notificationState,
    ]);
    const { result: employee, error, reload, } = useNestClient('getEmployee', [(_a = params.id) !== null && _a !== void 0 ? _a : '']);
    if (error) {
        throw error;
    }
    return (React.createElement(React.Fragment, null, employee ? (React.createElement(EmployeeWizard, { initialEmployee: employee, onClose: () => navigate({ pathname: redirectUrl }), onFinish: async (employee, employeeSchema) => {
            if (employee.id) {
                if (employeeSchema) {
                    nestClient.setErrorHandler((apiError) => {
                        const validationErrors = apiError.errorData.validationErrors;
                        if (validationErrors.length <
                            MAX_NUMBER_OF_INDIVIDUAL_NOTIFICATIONS) {
                            addSpecificNotifications(validationErrors, employeeSchema, notificationState, t);
                        }
                        else {
                            addGenericNotification(notificationState, t);
                        }
                    });
                }
                await nestClient
                    .updateEmployee(employee)
                    .then(() => {
                    navigate({ pathname: redirectUrl });
                });
            }
        }, refreshEmployee: reload })) : (React.createElement(LoadingSpinner, null))));
}
