var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { 
// eslint-disable-next-line no-restricted-imports
NavLink as OriginalNavLink, useParams, } from 'react-router-dom';
import React from 'react';
// This NavLink drop-in will prefix all links with the currently selected company
export const NavLink = (_a) => {
    var { addCompanyPrefix = true } = _a, props = __rest(_a, ["addCompanyPrefix"]);
    const { companySlug } = useParams();
    const linkPrefix = companySlug && addCompanyPrefix ? `/${companySlug}` : '';
    return React.createElement(OriginalNavLink, Object.assign({}, props, { to: `${linkPrefix}${props.to}` }));
};
