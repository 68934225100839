import { ReactComponent as CheckIcon } from '@public/icons/check.svg';
import { InlineActionButton } from '@components/inline-action-button';
import { ReactComponent as UserPlusIcon } from '@public/icons/user-plus.svg';
import React from 'react';
export const getInviteActionButton = (setEmployeeInviteModalToOpen, t) => {
    const InvitationCell = ({ rowData: employee, }) => (React.createElement(InlineActionButton, { Icon: UserPlusIcon, title: t('actionButton.invite'), onClick: () => setEmployeeInviteModalToOpen(employee.id), testId: `inviteButton-${employee.id}` }));
    return InvitationCell;
};
export const getStateUpdateActionButton = (setEmployeeStateModalToOpen, t) => {
    const StateUpdateCell = ({ rowData: employee, }) => (React.createElement(InlineActionButton, { Icon: CheckIcon, title: t('actionButton.state'), onClick: () => setEmployeeStateModalToOpen(employee.id), testId: `stateButton-${employee.id}` }));
    return StateUpdateCell;
};
