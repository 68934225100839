import { PayrollProviderStaffRoleEnum, SelfServiceState, UserCompanyRoleEnum, } from '@lohndialog/shared/src/constants';
import { getRedirectUri } from '@utils/auth-utils';
export const redirectForRole = async (isAuthenticated, profile, navigate, setError, t, login, isLoading) => {
    if (!isLoading) {
        if (isAuthenticated) {
            if (!profile.state.pending && profile.state.profile) {
                handleLoggedInWithProfileRedirect(profile, navigate, setError, t);
            }
        }
        else {
            await login({
                authorizationParams: {
                    redirect_uri: getRedirectUri(),
                },
            });
        }
    }
};
export const calculateRedirectFor = (companySlug, employeeId, isSelfService, employeeComplete, navigate) => {
    if (isSelfService && !employeeComplete) {
        navigate(`/${companySlug}/employees/${employeeId}`);
    }
    else {
        navigate(`/${companySlug}/dashboard`);
    }
};
const handleLoggedInWithProfileRedirect = (profile, navigate, setError, t) => {
    var _a;
    const ppRole = (_a = profile.state.profile.payrollProvider) === null || _a === void 0 ? void 0 : _a.roles.includes(PayrollProviderStaffRoleEnum.PP_ADMIN);
    if (ppRole) {
        navigate('/admin/dashboard');
        return;
    }
    const adminCompany = profile.state.profile.companies.find((company) => company.companyRoles.includes(UserCompanyRoleEnum.ADMIN));
    if (adminCompany) {
        navigate(`/${adminCompany.slug}/`);
        return;
    }
    const employeeCompany = profile.state.profile.companies.find((company) => company.companyRoles.includes(UserCompanyRoleEnum.USER));
    if (employeeCompany === null || employeeCompany === void 0 ? void 0 : employeeCompany.employee) {
        calculateRedirectFor(employeeCompany.slug, employeeCompany.employee.id, employeeCompany.companyUserManagement === SelfServiceState.ACTIVE, employeeCompany.employeeProfileComplete, navigate);
        return;
    }
    setError(new Error(t('noCompany')));
};
