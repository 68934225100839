import { Button, ButtonSize, ButtonType } from '@components/button';
import { CompanyImport } from '@components/import/CompanyImport';
import { CustomDocumentImport } from '@components/import/CustomDocumentImport';
import { DocumentImport } from '@components/import/DocumentImport';
import { ReactComponent as Download } from '@public/icons/download-cloud.svg';
import { EmployeeImport } from '@components/import/employee-import';
import { FieldType, } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { ReactComponent as Plus } from '@public/icons/plus.svg';
import { SidePanel } from '@components/side-panel';
import { UploadModal } from '@components/modals/UploadModal';
import { UserCompanyRoleEnum } from '@lohndialog/shared/src/constants';
import { useNestClient } from '@hooks/useNestClient';
import { useProfile } from '../context/profile-context';
import { useTranslation } from 'react-i18next';
import { validateEmail, validateRegex, } from '@lohndialog/shared/src/validation';
import CompanyList from '@components/company-list';
import FileSaver from 'file-saver';
import InputModal from '@components/modals/input-modal';
import React, { useCallback, useState } from 'react';
import nestClient from '@api/nest-client';
export function PayrollProviderView() {
    var _a;
    const { t } = useTranslation('payrollProvider');
    const [addHrEmployeeModalOpen, setAddHrEmployeeModalOpen] = useState(false);
    const [addCompanyModalOpen, setAddCompanyModalOpen] = useState(false);
    const [importModalOpen, setImportModalOpen] = useState(false);
    const [showEmployeeImport, setShowEmployeeImport] = useState(false);
    const [showDocumentImport, setShowDocumentImport] = useState(false);
    const [showCompanyImport, setShowCompanyImport] = useState(false);
    const [interactingCompany, setInteractingCompany] = useState();
    const [showCustomDocumentUpload, setShowCustomDocumentUpload] = useState(false);
    const profile = useProfile();
    const inviteHrEmployee = useCallback(async ({ email, firstName, lastName, company, }) => {
        if (!email || !firstName || !lastName || !company) {
            return;
        }
        return await nestClient.inviteHrEmployee({
            email,
            role: [UserCompanyRoleEnum.ADMIN, UserCompanyRoleEnum.USER],
            firstName,
            lastName,
        }, company);
    }, []);
    const [exporting, setExporting] = useState(false);
    const exportCompanies = useCallback(async () => {
        setExporting(true);
        try {
            const file = await nestClient.exportCompanies();
            FileSaver.saveAs(file);
        }
        finally {
            setExporting(false);
        }
    }, []);
    const { result: companies, loading: loadingCompanies, error: errorLoadingCompanies, reload: reloadCompanies, } = useNestClient('getCompanies', [], []);
    const { result: companySchema, loading: loadingCompanySchema, error: errorLoadingCompanySchema, } = useNestClient('getCompanySchema', [true, false], { fields: [] });
    const addCompany = useCallback(async (input) => {
        if (input.name && input.slug && input.mandatorNumber) {
            await nestClient.createCompany({
                slug: input.slug,
                genericFields: {
                    name: {
                        value: input.name,
                        type: FieldType.TEXT,
                    },
                    mandatorNumber: {
                        type: FieldType.TEXT,
                        value: input.mandatorNumber.toString(),
                    },
                },
            });
            profile.fetchProfile();
            reloadCompanies();
        }
    }, [profile, reloadCompanies]);
    if (errorLoadingCompanies) {
        throw errorLoadingCompanies;
    }
    if (errorLoadingCompanySchema) {
        throw errorLoadingCompanySchema;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "md:flex justify-between md:space-x-4 space-y-4" },
            React.createElement("div", null,
                React.createElement("h1", null, t('title')),
                React.createElement("p", { className: "font-medium text-n500DarkGrey" }, t('subtitle'))),
            React.createElement("div", { className: "grid grid-cols-2 lg:flex text-sm font-medium items-center justify-items-center lg:space-y-0 gap-3" },
                React.createElement(Button, { IconLeading: Plus, label: t('addHrEmployee'), buttonType: ButtonType.SECONDARY, buttonSize: ButtonSize.LARGE, className: "h-full xl:h-fit w-full lg:w-fit bg-white", onClick: () => setAddHrEmployeeModalOpen(true), "data-testid": "add-hr-button" }),
                React.createElement(Button, { IconLeading: Plus, label: t('addCompany'), buttonType: ButtonType.PRIMARY, buttonSize: ButtonSize.LARGE, className: "h-full xl:h-fit w-full lg:w-fit", onClick: () => setAddCompanyModalOpen(true), "data-testid": "add-company-button" }),
                React.createElement(Button, { IconLeading: Download, label: t('export'), buttonType: ButtonType.SECONDARY, buttonSize: ButtonSize.LARGE, className: "h-full xl:h-fit w-full lg:w-fit bg-white", onClick: () => void exportCompanies(), disabled: exporting, testId: "btnExportCompanies" }))),
        React.createElement(InputModal, { modalTitle: t('addCompany'), explanation: React.createElement("p", null, t('addCompanyExplanation')), configObject: {
                name: {
                    placeHolder: t('name'),
                    title: t('name'),
                    required: true,
                    fieldType: FieldType.TEXT,
                },
                slug: {
                    placeHolder: t('slug'),
                    title: t('slug'),
                    required: true,
                    fieldType: FieldType.TEXT,
                },
                mandatorNumber: {
                    placeHolder: t('mandatorNumber'),
                    title: t('mandatorNumber'),
                    required: true,
                    validationFunction: validateRegex('^\\d{1,5}$'),
                    fieldType: FieldType.TEXT,
                },
            }, submitFunction: (input) => addCompany(input), cancelLabel: t('cancel'), submitLabel: t('submit'), open: addCompanyModalOpen, setOpen: setAddCompanyModalOpen }),
        React.createElement(InputModal, { modalTitle: t('addHrEmployee'), explanation: React.createElement("p", null, t('addHrEmployeeExplanation')), configObject: {
                company: {
                    title: t('company'),
                    required: true,
                    fieldType: FieldType.COMBOBOX,
                    possibleValues: companies.reduce((aggregator, company) => {
                        var _a;
                        aggregator[company.slug] = {
                            de: (_a = company.genericFields.name) === null || _a === void 0 ? void 0 : _a.value,
                        };
                        return aggregator;
                    }, {}),
                },
                firstName: {
                    placeHolder: t('firstName'),
                    title: t('firstName'),
                    required: true,
                    fieldType: FieldType.TEXT,
                },
                lastName: {
                    placeHolder: t('lastName'),
                    title: t('lastName'),
                    required: true,
                    fieldType: FieldType.TEXT,
                },
                email: {
                    placeHolder: t('email'),
                    title: t('email'),
                    required: true,
                    fieldType: FieldType.TEXT,
                    validationFunction: validateEmail,
                },
            }, submitFunction: (input) => inviteHrEmployee(input), cancelLabel: t('cancel'), submitLabel: t('submit'), open: addHrEmployeeModalOpen, setOpen: setAddHrEmployeeModalOpen }),
        React.createElement(UploadModal, { dialogOpen: importModalOpen, setDialogOpen: setImportModalOpen, setEmployeeUploadOpen: () => {
                setShowEmployeeImport(true);
                setImportModalOpen(false);
            }, setDocumentUploadOpen: () => {
                setShowDocumentImport(true);
                setImportModalOpen(false);
            }, setCompanyUploadOpen: () => {
                setShowCompanyImport(true);
                setImportModalOpen(false);
            }, setCustomDocumentUploadOpen: () => {
                setShowCustomDocumentUpload(true);
                setImportModalOpen(false);
            } }),
        React.createElement(CompanyList, { className: "mt-8", companies: companies, schema: companySchema, loading: loadingCompanies || loadingCompanySchema, setImportModalOpen: (companyDto) => {
                setImportModalOpen(true);
                setInteractingCompany(companyDto);
            } }),
        React.createElement(SidePanel, { show: showEmployeeImport, onClose: () => {
                setShowEmployeeImport(false);
                setInteractingCompany(undefined);
            }, title: t('importEmployees'), dismissible: false },
            React.createElement(EmployeeImport, { companyId: (_a = interactingCompany === null || interactingCompany === void 0 ? void 0 : interactingCompany.id) !== null && _a !== void 0 ? _a : '' })),
        React.createElement(SidePanel, { show: showDocumentImport, onClose: () => {
                setShowDocumentImport(false);
                setInteractingCompany(undefined);
            }, title: t('importDocuments'), dismissible: false },
            React.createElement(DocumentImport, null)),
        React.createElement(SidePanel, { show: showCompanyImport, onClose: () => {
                setShowCompanyImport(false);
                setInteractingCompany(undefined);
            }, title: t('importCompanies'), dismissible: false },
            React.createElement(CompanyImport, null)),
        React.createElement(SidePanel, { show: showCustomDocumentUpload, onClose: () => {
                setShowCustomDocumentUpload(false);
                setInteractingCompany(undefined);
            }, title: t('uploadCustomDocument'), dismissible: false },
            React.createElement(CustomDocumentImport, { company: interactingCompany, closeSlideIn: () => setShowCustomDocumentUpload(false) }))));
}
