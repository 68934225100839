const ONE_MINUTE = 1000 * 60;
export const ONE_DAY = ONE_MINUTE * 60 * 24;
export const COMPANY_SLUG_HEADER = 'x-company';
export const PAYROLL_PROVIDER_ID_HEADER = 'x-payroll-provider';
export const API_KEY_HEADER = 'x-api-key';
export var UserCompanyRoleEnum;
(function (UserCompanyRoleEnum) {
    /**
     The "normal" user/employee of a company with no special permissions.
     */
    UserCompanyRoleEnum["USER"] = "User";
    /**
     The hr user/employee with additional permissions to i.e. view & edit the employees payment data.
     */
    UserCompanyRoleEnum["ADMIN"] = "Admin";
    /**
     The company superuser. Created as a role with different permissions then the @type {ADMIN}.
     This role is assigned to @type {PayrollProviderStaffRoleEnum.PP_ADMIN} when such a user switches into the company context.
     */
    UserCompanyRoleEnum["PP_ADMIN"] = "PP_Admin";
})(UserCompanyRoleEnum || (UserCompanyRoleEnum = {}));
/**
 * Defines the role of a user within a payroll provider.
 * ADMIN - Can add/edit other staff members
 * INTERNAL, JUNIOR, SENIOR - To be defined
 */
export var PayrollProviderStaffRoleEnum;
(function (PayrollProviderStaffRoleEnum) {
    PayrollProviderStaffRoleEnum["PP_ADMIN"] = "PP_Admin";
    PayrollProviderStaffRoleEnum["INTERNAL"] = "INTERNAL";
    PayrollProviderStaffRoleEnum["JUNIOR"] = "JUNIOR";
    PayrollProviderStaffRoleEnum["SENIOR"] = "SENIOR";
})(PayrollProviderStaffRoleEnum || (PayrollProviderStaffRoleEnum = {}));
export var PayrollProviderStaffState;
(function (PayrollProviderStaffState) {
    PayrollProviderStaffState["ACTIVE"] = "ACTIVE";
    PayrollProviderStaffState["DISABLED"] = "DISABLED";
})(PayrollProviderStaffState || (PayrollProviderStaffState = {}));
export var EntityEnum;
(function (EntityEnum) {
    EntityEnum["EMPLOYEE"] = "EMPLOYEE";
    EntityEnum["EAU"] = "EAU";
    EntityEnum["DOCUMENT"] = "DOCUMENT";
    EntityEnum["USER"] = "USER";
    EntityEnum["COMPANY"] = "COMPANY";
    EntityEnum["PAYROLL_PROVIDER"] = "PAYROLL_PROVIDER";
})(EntityEnum || (EntityEnum = {}));
export var CompanyState;
(function (CompanyState) {
    CompanyState["DRAFT"] = "DRAFT";
    CompanyState["ACTIVE"] = "ACTIVE";
    // ...
})(CompanyState || (CompanyState = {}));
export var EAUStateEnum;
(function (EAUStateEnum) {
    EAUStateEnum["APPROVED"] = "APPROVED";
    EAUStateEnum["DECLINED"] = "DECLINED";
    EAUStateEnum["PENDING"] = "PENDING";
})(EAUStateEnum || (EAUStateEnum = {}));
export var InvitationState;
(function (InvitationState) {
    InvitationState["OPEN"] = "OPEN";
    InvitationState["ACCEPTED"] = "ACCEPTED";
    InvitationState["DECLINED"] = "DECLINED";
    InvitationState["WITHDRAWN"] = "WITHDRAWN";
})(InvitationState || (InvitationState = {}));
export var SelfServiceState;
(function (SelfServiceState) {
    SelfServiceState["ACTIVE"] = "ACTIVE";
    SelfServiceState["INACTIVE"] = "INACTIVE";
})(SelfServiceState || (SelfServiceState = {}));
export const DUMMY_AUTH_TOKEN = 'dummyToken';
export const INVITATION_TOKEN = 'token';
export const KILO_BYTE = 1000;
export const MEGA_BYTE = 1000 * KILO_BYTE;
export const MAX_UPLOAD_SIZE_IMPORT_GENERIC_FIELD_VALUES = 1 * MEGA_BYTE;
export const MAX_UPLOAD_SIZE_IMPORT_EMPLOYEES = 10 * MEGA_BYTE;
export const MAX_UPLOAD_SIZE_DOCUMENTS = 10 * MEGA_BYTE;
export const MS_IN_ONE_SECOND = 1000;
export const MS_IN_ONE_MINUTE = MS_IN_ONE_SECOND * 60;
export const MS_IN_ONE_HOUR = MS_IN_ONE_MINUTE * 60;
export const MS_IN_ONE_DAY = MS_IN_ONE_HOUR * 24;
/**
 * File encoding used when importing CSV and Text files.
 */
export const TEXT_IMPORT_ENCODING = 'windows-1252';
export * from './employee';
