import { getTitle } from '@components/generic-forms/generic-form';
import { FieldType } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { TextInput } from '@components/input/text-input';
import { validateEmail } from '@lohndialog/shared/src/validation';
import React from 'react';
export const EmailField = ({ fieldName, field, fieldValue, setValue, className, disabled, required, shouldValidate, }) => {
    var _a;
    if (field.type !== FieldType.EMAIL ||
        (fieldValue && fieldValue.type !== FieldType.EMAIL)) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(TextInput, { key: fieldName, value: (_a = fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value) !== null && _a !== void 0 ? _a : '', title: getTitle(field, required), onChange: (newValue) => setValue(fieldName, field.type, newValue), validate: validateEmail, maxLength: field.maxLength, classNameContainer: className, disabled: disabled, required: required, inputTestId: `textInput-${fieldName}`, shouldValidate: shouldValidate, runInitialValidation: true }));
};
