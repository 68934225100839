import { ButtonSize, ButtonType } from '@components/button';
import { useTranslation } from 'react-i18next';
import Dialog from '@components/modals/dialog';
import React from 'react';
export const UploadModal = ({ dialogOpen, setDialogOpen, setEmployeeUploadOpen, setDocumentUploadOpen, setCompanyUploadOpen, setCustomDocumentUploadOpen, }) => {
    const { t } = useTranslation('payrollProvider', {
        keyPrefix: 'modal',
    });
    return (React.createElement(Dialog, { dismissible: true, open: dialogOpen, setOpen: setDialogOpen, title: t('title'), actions: [
            {
                buttonProps: {
                    label: t('importEmployees'),
                    buttonSize: ButtonSize.SMALL,
                    buttonType: ButtonType.SECONDARY,
                    onClick: setEmployeeUploadOpen,
                    tabIndex: 0,
                    testId: 'importEmployee',
                },
            },
            {
                buttonProps: {
                    label: t('importDocuments'),
                    buttonSize: ButtonSize.SMALL,
                    buttonType: ButtonType.SECONDARY,
                    onClick: setDocumentUploadOpen,
                    tabIndex: 1,
                    testId: 'importDocuments',
                },
            },
            {
                buttonProps: {
                    label: t('importCompanies'),
                    buttonSize: ButtonSize.SMALL,
                    buttonType: ButtonType.SECONDARY,
                    onClick: setCompanyUploadOpen,
                    tabIndex: 2,
                    testId: 'importCompanies',
                },
            },
            {
                buttonProps: {
                    label: t('uploadCustomDocument'),
                    buttonSize: ButtonSize.SMALL,
                    buttonType: ButtonType.SECONDARY,
                    onClick: setCustomDocumentUploadOpen,
                    tabIndex: 3,
                    testId: 'uploadCustomDocument',
                },
            },
        ] }));
};
