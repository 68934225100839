import { getTitle } from '@components/generic-forms/generic-form';
import { FieldType } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { NumberInput } from '@components/input/number-input';
import { classNames } from '@utils/class-names';
import { validateRange } from '@lohndialog/shared/src/validation';
import React, { useMemo } from 'react';
export const NumberField = ({ fieldName, field, fieldValue, setValue, className, disabled, validate, required, shouldValidate, }) => {
    var _a, _b, _c, _d;
    const isNumberType = field.type === FieldType.NUMBER;
    const minValue = isNumberType ? (_a = field.range) === null || _a === void 0 ? void 0 : _a.min : undefined;
    const maxValue = isNumberType ? (_b = field.range) === null || _b === void 0 ? void 0 : _b.max : undefined;
    const validator = useMemo(() => {
        if (validate) {
            return validate;
        }
        return isNumberType &&
            (minValue !== undefined || maxValue !== undefined)
            ? validateRange(minValue, maxValue)
            : undefined;
    }, [isNumberType, maxValue, minValue, validate]);
    if (field.type !== FieldType.NUMBER ||
        (fieldValue && fieldValue.type !== FieldType.NUMBER)) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement(NumberInput, { key: fieldName, name: fieldName, value: fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value, title: getTitle(field, required), onChange: (newValue) => setValue(fieldName, field.type, newValue), validate: validator, classNameContainer: className, classNameInput: classNames('[appearance:textfield]', '[&::-webkit-outer-spin-button]:appearance-none', '[&::-webkit-inner-spin-button]:appearance-none'), min: (_c = field.range) === null || _c === void 0 ? void 0 : _c.min, max: (_d = field.range) === null || _d === void 0 ? void 0 : _d.max, disabled: disabled, required: required, inputTestId: `textInput-${fieldName}`, shouldValidate: shouldValidate, percentage: field.percentage, runInitialValidation: true }));
};
