import { WizardCluster, } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { LoadingSpinner } from '@components/loading-spinner';
import { useTranslation } from 'react-i18next';
import GenericForm from '@components/generic-forms/generic-form';
import React, { useCallback } from 'react';
export default (schema, supervisingValue) => ({
    key: 'step3',
    isValid: () => true,
    stepLabel: (t) => t('step3.label', {
        ns: 'companyWizard',
    }),
    stepLabelDetails: (t) => t('step3.labelDetails', {
        ns: 'companyWizard',
    }),
    ReactComponent: ({ value, setValue }) => {
        const { t } = useTranslation('companyWizard', {
            keyPrefix: 'step3',
        });
        const setGenericField = useCallback((fieldIdentifier, type, newValue) => {
            setValue(fieldIdentifier, type, newValue);
        }, [setValue]);
        if (!schema) {
            return React.createElement(LoadingSpinner, null);
        }
        return (React.createElement("div", null,
            React.createElement("h1", null, t('title')),
            React.createElement("h2", null, t('subLabelTaxOffice')),
            React.createElement(GenericForm, { value: value, supervisingValue: supervisingValue, setValue: setGenericField, schema: schema, wizardCluster: WizardCluster.TaxOffice }),
            React.createElement("h2", null, t('subLabelProfessionalAssociation')),
            React.createElement(GenericForm, { value: value, supervisingValue: supervisingValue, setValue: setGenericField, schema: schema, wizardCluster: WizardCluster.ProfessionalAssociation })));
    },
});
