import { useCallback } from 'react';
import { useCompany } from '../context/company-context';
// eslint-disable-next-line no-restricted-imports
import { useNavigate as useNavigate_orig } from 'react-router-dom';
export const useNavigate = () => {
    const navigate = useNavigate_orig();
    const { company } = useCompany();
    return useCallback((args) => {
        const prefix = company ? company.slug : '';
        if (typeof args === 'string') {
            navigate(prefixPathname(args, prefix));
        }
        else {
            navigate(Object.assign(Object.assign({}, args), { pathname: args.pathname
                    ? prefixPathname(args.pathname, prefix)
                    : undefined }));
        }
    }, [company, navigate]);
};
const prefixPathname = (pathname, prefix) => {
    if (pathname.startsWith('/')) {
        // absolute path should be prefixed
        return '/' + prefix + pathname;
    }
    else {
        // relative path should not be prefixed
        return pathname;
    }
};
