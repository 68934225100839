import { useDropzone } from 'react-dropzone';
import { ReactComponent as UploadIcon } from '@public/icons/upload-cloud.svg';
import { classNames } from '@utils/class-names';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import i18n from '../../i18n';
import prettyBytes from 'pretty-bytes';
export function Dropzone({ onDrop, maxFileSize, acceptedFileFormats, maxFiles, }) {
    const { t } = useTranslation('upload');
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: onDrop,
        accept: acceptedFileFormats,
        maxSize: maxFileSize,
        maxFiles,
    });
    const acceptedFilesString = useMemo(() => {
        const fileExtensions = Object.values(acceptedFileFormats).reduce((acc, values) => acc.concat(values.map((value) => value.substring(1).toUpperCase())), []);
        const formatString = fileExtensions.join(', ');
        const index = formatString.lastIndexOf(',');
        if (index >= 0) {
            return `${formatString.substring(0, index)} ${t('or')}${formatString.substring(index + 1)}`;
        }
        return formatString;
    }, [acceptedFileFormats, t]);
    return (React.createElement("div", Object.assign({}, getRootProps(), { className: classNames(isDragActive ? 'bg-tertiaryBtnHover' : 'bg-white', 'flex flex-col items-center w-full max-w-full overflow-hidden rounded-lg shadow-xs border border-n300Grey p-3 cursor-pointer'), "data-testid": "drop-zone" }),
        React.createElement(UploadIcon, { className: "flex-shrink-0 h-10 w-10 p-2 mb-3 rounded-full text-n500DarkGrey border-[3px] border-n200LightGrey bg-white", "aria-hidden": "true" }),
        React.createElement("div", null,
            React.createElement("label", { htmlFor: "file-upload", className: "font-medium text-[#4A4A4A] cursor-pointer" },
                React.createElement("span", null, t('uploadClick')),
                React.createElement("input", Object.assign({}, getInputProps()))),
            ' ',
            React.createElement("span", { className: "text-n500DarkGrey" }, t('uploadDragAndDrop'))),
        React.createElement("div", { className: "mt-0.5 text-xs text-n500DarkGrey" },
            React.createElement("div", { className: "flex justify-center" }, t('acceptedFileFormats', {
                acceptedFileFormats: acceptedFilesString,
            })),
            React.createElement("div", { className: "flex justify-center" }, t('fileSizeRestriction', {
                maxFileSize: prettyBytes(maxFileSize, {
                    locale: i18n.language,
                }),
                maxFileSizeBytes: maxFileSize,
            })))));
}
