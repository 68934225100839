var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { classNames } from '@utils/class-names';
import React from 'react';
export const InlineActionButton = (_a) => {
    var { Icon, disabled, testId } = _a, rest = __rest(_a, ["Icon", "disabled", "testId"]);
    return (React.createElement("button", Object.assign({ className: "w-7 h-7 bg-white rounded-full xs border border-n300Grey flex items-center justify-center", disabled: disabled, "data-testid": testId }, rest),
        React.createElement(Icon, { className: classNames('w-4 h-4', disabled ? 'text-backgroundGray' : 'text-p100Green') })));
};
