const RETURN_TO_QUERY_PARAM = 'returnTo';
export const getRedirectUri = () => {
    const defaultRedirectUri = new URL(window.location.origin);
    defaultRedirectUri.searchParams.set(RETURN_TO_QUERY_PARAM, encodeURIComponent(window.location.href.replace(window.location.origin, '')));
    return defaultRedirectUri.toString();
};
export const parseRedirectUri = () => {
    const returnTo = new URL(window.location.href).searchParams.get(RETURN_TO_QUERY_PARAM);
    if (returnTo) {
        return decodeURIComponent(returnTo);
    }
    else {
        return null;
    }
};
