import { Upload } from '@components/upload/upload';
import { MAX_UPLOAD_SIZE_DOCUMENTS } from '@lohndialog/shared/src/constants';
import React, { useState } from 'react';
import nestClient from '@api/nest-client';
export const DocumentImport = () => {
    const [fileUploads, setFileUploads] = useState([]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex flex-col gap-2 px-6 py-4" },
            React.createElement(Upload, { acceptedFileFormats: {
                    'application/pdf': ['.pdf'],
                }, fileUploads: fileUploads, setFileUploads: setFileUploads, maxFileSize: MAX_UPLOAD_SIZE_DOCUMENTS, uploadFuncDB: (file, progressCallback) => {
                    return nestClient.uploadDocuments(file, progressCallback);
                }, deleteFuncDB: async (dto) => await nestClient.cancelUpload(dto) })),
        React.createElement("div", { className: "flex-grow" })));
};
