import { getTitle } from '@components/generic-forms/generic-form';
import { FieldType } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { RadioGroup } from '@components/radio-group';
import { classNames } from '@utils/class-names';
import { useTranslation } from 'react-i18next';
import { valueMap } from '@lohndialog/shared/src/util/generic-fields';
import React from 'react';
import i18n from '../../i18n';
export const CheckboxField = ({ fieldName, field, fieldValue, setValue, className, disabled, validate, required, shouldValidate, }) => {
    var _a, _b, _c, _d;
    const { t } = useTranslation('checkbox');
    if (field.type !== FieldType.CHECKBOX ||
        (fieldValue && fieldValue.type !== FieldType.CHECKBOX)) {
        return React.createElement(React.Fragment, null);
    }
    const yesString = (_b = (_a = field.customTranslations) === null || _a === void 0 ? void 0 : _a.yes[i18n.language]) !== null && _b !== void 0 ? _b : t('yes');
    const noString = (_d = (_c = field.customTranslations) === null || _c === void 0 ? void 0 : _c.no[i18n.language]) !== null && _d !== void 0 ? _d : t('no');
    return (React.createElement(RadioGroup, { displayValue: (option) => (option === 'yes' ? yesString : noString), options: ['yes', 'no'], onChange: (checked) => setValue(fieldName, field.type, checked === 'yes'), value: valueMap.get(fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.value), title: getTitle(field, required), className: classNames(className, 'self-center', disabled ? 'text-n500DarkGrey' : ''), disabled: disabled, validate: validate, inputTestId: `textInput-${fieldName}`, shouldValidate: shouldValidate, required: required, runInitialValidation: true }));
};
