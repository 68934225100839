import { Select } from '@components/input/select';
import { getOptionalFirstAndLastNameString } from '@utils/getOptionalFirstAndLastNameString';
import { useTranslation } from 'react-i18next';
import React from 'react';
export const getMainHRContactName = (mainHRContactPerson) => {
    var _a, _b, _c, _d;
    if (!mainHRContactPerson) {
        return '';
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (mainHRContactPerson.genericFields) {
        const genericFields = mainHRContactPerson
            .genericFields;
        const firstName = (_a = genericFields.firstname) === null || _a === void 0 ? void 0 : _a.value;
        const lastName = (_b = genericFields.lastname) === null || _b === void 0 ? void 0 : _b.value;
        return getOptionalFirstAndLastNameString(firstName, lastName);
    }
    else {
        const user = mainHRContactPerson;
        const userName = getOptionalFirstAndLastNameString((_c = user.payrollProviderRole) === null || _c === void 0 ? void 0 : _c.firstname, (_d = user.payrollProviderRole) === null || _d === void 0 ? void 0 : _d.lastname);
        if (!userName) {
            return user.email;
        }
        else {
            return userName;
        }
    }
};
export const MainHRContactField = ({ mainHRContactPerson, setMainHrContactPerson, possibleContactPersons, hasAdminRights, }) => {
    const { t } = useTranslation('mainHRContactField');
    return (React.createElement(Select, { key: 'mainHRContactPerson', value: mainHRContactPerson === null || mainHRContactPerson === void 0 ? void 0 : mainHRContactPerson.id, displayValue: (value) => getMainHRContactName(possibleContactPersons.find((possibleContactPerson) => possibleContactPerson.id === value)), options: possibleContactPersons.map((contactPerson) => contactPerson.id), title: t('mainHrContactPerson'), onChange: (newValue) => {
            if (newValue !== undefined) {
                setMainHrContactPerson(possibleContactPersons.find((possibleContactPerson) => possibleContactPerson.id === newValue));
            }
        }, classNameContainer: 'col-span-6', disabled: !hasAdminRights }));
};
