import { EmployeeStateBadge } from '@components/badge';
import { classNames } from '@utils/class-names';
// eslint-disable-next-line no-restricted-imports
import { useNavigate, useParams } from 'react-router-dom';
import { useProfile } from '../../context/profile-context';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo } from 'react';
import SortingPaginatedTable from '@components/table/sorting-paginated-table';
import dayjs from '@lohndialog/shared/src/util/dayjs';
function HighlightActiveRowWrapper({ slug, activeText, children, }) {
    const { companySlug } = useParams();
    const isActiveCompany = useCallback((slug) => {
        return slug === companySlug;
    }, [companySlug]);
    return (React.createElement("span", { className: classNames(isActiveCompany(slug) && 'text-p100Green') }, activeText && isActiveCompany(slug) ? activeText : children));
}
export function CompanySwitch() {
    var _a, _b, _c, _d;
    const { t } = useTranslation('profile');
    const profile = useProfile();
    const topHeader = (React.createElement("div", { className: "flex justify-between items-center" },
        React.createElement("h1", { className: "text-p100Green" }, t('companyData'))));
    const column = useMemo(() => [
        {
            identifier: 'name',
            sortable: false,
            headContent: t('company'),
            CellContent: NameCell,
        },
        {
            identifier: 'dateOfJoining',
            sortable: false,
            headContent: t('dateOfJoining'),
            CellContent: DateOfJoiningCell,
        },
        {
            identifier: 'status',
            sortable: false,
            headContent: t('status'),
            CellContent: StatusCell,
        },
        {
            identifier: 'email',
            sortable: false,
            headContent: t('email'),
            CellContent: EmailCell,
        },
        {
            identifier: 'changeCompany',
            sortable: false,
            headContent: '',
            CellContent: ChangeCompanyCell,
        },
    ], [t]);
    return (React.createElement(SortingPaginatedTable, { topHeader: topHeader, setSort: () => null, loading: false, data: (_b = (_a = profile.state.profile) === null || _a === void 0 ? void 0 : _a.companies) !== null && _b !== void 0 ? _b : [], getId: (profileCompany) => profileCompany.slug, columns: column, totalResults: (_d = (_c = profile.state.profile) === null || _c === void 0 ? void 0 : _c.companies.length) !== null && _d !== void 0 ? _d : 0, hideResultCounter: true }));
}
const NameCell = ({ rowData: profileCompany, }) => (React.createElement(HighlightActiveRowWrapper, { slug: profileCompany.slug }, profileCompany.name));
const DateOfJoiningCell = ({ rowData: profileCompany, }) => {
    var _a;
    const { t } = useTranslation('profile');
    return (React.createElement(HighlightActiveRowWrapper, { slug: profileCompany.slug }, ((_a = profileCompany.employee) === null || _a === void 0 ? void 0 : _a.genericFields.dateOfJoining)
        ? dayjs(profileCompany.employee.genericFields.dateOfJoining
            .value).format(t('dateFormat'))
        : ''));
};
const StatusCell = ({ rowData: profileCompany, }) => { var _a; return React.createElement(EmployeeStateBadge, { state: (_a = profileCompany.employee) === null || _a === void 0 ? void 0 : _a.state }); };
const EmailCell = ({ rowData: profileCompany, }) => {
    var _a, _b;
    return (React.createElement(HighlightActiveRowWrapper, { slug: profileCompany.slug }, (_b = (_a = profileCompany.employee) === null || _a === void 0 ? void 0 : _a.genericFields.email) === null || _b === void 0 ? void 0 : _b.value));
};
const ChangeCompanyCell = ({ rowData: profileCompany, }) => {
    const { t } = useTranslation('profile');
    const navigate = useNavigate();
    return (React.createElement(HighlightActiveRowWrapper, { slug: profileCompany.slug, activeText: t('currentlyActive') },
        React.createElement("button", { onClick: () => {
                navigate(`/${profileCompany.slug}/profile`);
            } },
            React.createElement("u", null, t('changeCompany')))));
};
