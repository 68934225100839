var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { TextInput, } from '@components/input/text-input';
import { validateRange } from '@lohndialog/shared/src/validation';
import React from 'react';
export const NumberInput = (_a) => {
    var _b;
    var { value } = _a, rest = __rest(_a, ["value"]);
    return (React.createElement(TextInput, Object.assign({ value: (_b = value === null || value === void 0 ? void 0 : value.toString()) !== null && _b !== void 0 ? _b : '', transform: NumberTransformer, type: "number", validate: validateRange(rest.min, rest.max) }, rest)));
};
export const NumberTransformer = (input) => {
    const addedPointZero = input.endsWith('.0');
    if (addedPointZero) {
        return input;
    }
    const result = parseFloat(input);
    if (!isNaN(result)) {
        return Math.round(result * 100) / 100;
    }
    return undefined;
};
