import { getRedirectUri } from '@utils/auth-utils';
import { useAuth } from '../context/auth-context';
import React from 'react';
export const AuthenticatedRoute = ({ children, }) => {
    const { isAuthenticated, login, isLoading } = useAuth();
    if (isAuthenticated) {
        return React.createElement(React.Fragment, null, children);
    }
    else if (!isLoading) {
        (async () => {
            await login({
                authorizationParams: {
                    redirectUri: getRedirectUri(),
                },
            });
        })();
    }
};
