import { ReactComponent as CheckIcon } from '@public/icons/check.svg';
import { ReactComponent as ChevronDownIcon } from '@public/icons/chevron-down.svg';
import { Combobox as HeadlessCombobox } from '@headlessui/react';
import { classNames } from '@utils/class-names';
import { useValidation } from '@hooks/useValidation';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import escapeRegExp from 'lodash.escaperegexp';
export default function Combobox({ displayValue, options, filterFn = (option, query, regex) => !!regex.exec(displayValue(option)), value, onChange, title, validate: validateFn, errorMessages, classNameContainer, classNameInput, disabled = false, onUserInput, onBlur, required, runInitialValidation = false, hasMoreOptions = false, }) {
    const [query, setQuery] = useState('');
    const filteredOptions = useMemo(() => {
        const regex = new RegExp(escapeRegExp(query), 'i');
        return options.filter((option) => filterFn(option, query, regex));
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterFn, options.length, query]);
    const [isValid, errorMessage, runValidation] = useValidation({
        validateFn: validateFn,
        messageKeys: errorMessages,
        required,
    });
    useEffect(() => {
        if (runInitialValidation && value) {
            runValidation(value);
        }
    }, 
    // Ignore eslint error because we want to run this effect only once, not every time the value changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [runValidation, runInitialValidation]);
    const textBoxStyle = classNames('px-3.5 py-2.5 border-box rounded-md border border-n400GreyCheteau w-full text-n600DarkBlue shadow-input focus:shadow-none leading-snug', !isValid
        ? 'border-r300Error focus:ring-4 focus:ring-[#FEE4E2]'
        : 'focus:border-p200Green focus:ring-4 focus:ring-[#F1FDF7]');
    const onUserInputCallback = useCallback((input) => {
        if (onUserInput) {
            onUserInput(input);
        }
        setQuery(input);
    }, [onUserInput]);
    return (React.createElement(HeadlessCombobox, { as: "div", value: value, onChange: (value) => {
            onChange(value);
            runValidation(value);
        }, className: classNameContainer, disabled: disabled },
        React.createElement(HeadlessCombobox.Label, { className: classNames('w-full text-sm font-medium inline-block leading-tight', disabled ? 'text-n500DarkGrey' : 'text-n600DarkBlue') }, title),
        React.createElement("div", { className: "relative" },
            React.createElement(HeadlessCombobox.Input, { className: classNames(classNameInput, textBoxStyle, disabled ? '!text-n500DarkGrey bg-n100Whisper' : ''), onChange: (event) => onUserInputCallback(event.target.value), displayValue: displayValue, "data-testid": "comboboxInput", onBlur: () => {
                    runValidation(value);
                    onBlur ? onBlur() : undefined;
                } }),
            React.createElement(HeadlessCombobox.Button, { className: "absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none", "data-testid": "comboboxExpandBtn" }, !disabled && (React.createElement(ChevronDownIcon, { className: "h-5 w-5 text-gray-400", "aria-hidden": "true" }))),
            filteredOptions.length > 0 && (React.createElement(HeadlessCombobox.Options, { className: "absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" },
                filteredOptions.map((option, index) => (React.createElement(HeadlessCombobox.Option, { key: `${displayValue(option)}-${index}`, value: option, className: ({ active }) => classNames('relative cursor-default select-none py-2 pl-3 pr-9', active
                        ? 'bg-p200Green text-white'
                        : 'text-n600DarkBlue'), "data-testid": `option-${option}` }, ({ active, selected }) => (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: classNames('block truncate', selected && 'font-semibold') }, displayValue(option)),
                    selected && (React.createElement("span", { className: classNames('absolute inset-y-0 right-0 flex items-center pr-4', active
                            ? 'text-white'
                            : 'text-indigo-600') },
                        React.createElement(CheckIcon, { className: "h-5 w-5", "aria-hidden": "true" })))))))),
                hasMoreOptions && filteredOptions.length !== 1 && (React.createElement("p", { className: "ml-3" }, "..."))))),
        React.createElement("span", { "data-testid": "errorMessage", className: classNames('block text-n600DarkBlue text-sm mt-1 opacity-0 transition-opacity min-h-[40px]', !isValid && 'opacity-100', !isValid && 'font-bold !text-r100Red') },
            "\u200B",
            errorMessage)));
}
