import { LoadingSpinner } from '@components/loading-spinner';
import { UserCompanyRoleEnum } from '@lohndialog/shared/src/constants';
import { useCompany } from '../context/company-context';
import { useNestClient } from '@hooks/useNestClient';
import CompanyDashboard from '@components/company-dashboard/company-dashboard';
import React, { useEffect, useState } from 'react';
import nestClient from '@api/nest-client';
export default function EditCompanyView() {
    const [company, setCompany] = useState();
    const [updateCounter, setUpdateCounter] = useState(0);
    const [error, setError] = useState();
    const { company: profileCompany } = useCompany();
    useEffect(() => {
        const getCompany = async () => {
            try {
                const company = await nestClient.getCompany();
                setCompany(company);
            }
            catch (error) {
                setError(error);
            }
        };
        void getCompany();
    }, [updateCounter]);
    const { result: companySchema, loading: schemaLoading, error: schemaError, } = useNestClient('getCompanySchema', [true, true], { fields: [] }); // is always admin
    if (error || schemaError) {
        throw error;
    }
    if (!company || schemaLoading) {
        return React.createElement(LoadingSpinner, null);
    }
    return (React.createElement(CompanyDashboard, { company: company, companySchema: companySchema, setUpdateCounter: setUpdateCounter, isAdmin: false, hasAdminRights: !!(profileCompany === null || profileCompany === void 0 ? void 0 : profileCompany.companyRoles.includes(UserCompanyRoleEnum.PP_ADMIN)) }));
}
