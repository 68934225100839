import { Button, ButtonSize, ButtonType } from '@components/button';
import { ReactComponent as ChevronsDownIcon } from '@public/icons/chevrons-down.svg';
import { ReactComponent as ChevronsUpIcon } from '@public/icons/chevrons-up.svg';
import { Tab } from '@headlessui/react';
import { classNames } from '@utils/class-names';
import { useTranslation } from 'react-i18next';
import React, { createContext, useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import useHorizontalScroll from '@hooks/useHorizontalScroll';
export const TabsContext = createContext({
    expanded: false,
});
export const Tabs = ({ tabs, className, withoutMargin, }) => {
    const { t } = useTranslation('tabs');
    const [selectedIndex, _setSelectedIndex] = useState(0);
    const [expanded, setExpanded] = useState(false);
    const contextState = useMemo(() => ({
        // If a tab is not specified as being expandable, treat it as already expanded. This makes sure that no information is hidden.
        expanded: expanded || !tabs[selectedIndex].expandable,
    }), [tabs, selectedIndex, expanded]);
    const tabRefs = useRef(new Map());
    const setSelectedIndex = useCallback((newIndex) => {
        var _a;
        _setSelectedIndex(newIndex);
        (_a = tabRefs.current.get(tabs[newIndex].id)) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
            block: 'nearest',
            inline: 'nearest',
        });
    }, [tabs]);
    useEffect(() => {
        tabRefs.current = new Map(tabs
            .map(({ id }) => [id, tabRefs.current.get(id)])
            .filter((e) => !!e[1]));
    }, [tabs]);
    return (React.createElement(Tab.Group, { as: "div", className: classNames('bg-white rounded-2xl pb-4 shadow-table overflow-hidden', className), selectedIndex: selectedIndex, onChange: setSelectedIndex },
        React.createElement(TabsContext.Provider, { value: contextState },
            React.createElement(Tab.List, { className: "flex justify-between" },
                React.createElement("div", { className: "overflow-x-auto scrollbar-hide flex-1 flex", ref: useHorizontalScroll() },
                    React.createElement("div", { className: "border-b border-n300Grey flex flex-row flex-grow w-max" }, tabs.map(({ id, title, disabled }) => {
                        return (React.createElement(Tab, { key: id, className: classNames('py-6 px-3 relative', 'focus:outline-0 focus:bg-n100Whisper', 'text-n500DarkGrey text-sm font-medium leading-tight', "after:content-[''] after:border-p200Green after:absolute after:left-0 after:right-0 after:top-full", 'ui-selected:text-p200Green ui-selected:after:border-b', disabled
                                ? 'text-n400GreyCheteau'
                                : ''), ref: (el) => {
                                if (el) {
                                    tabRefs.current.set(id, el);
                                }
                                else {
                                    tabRefs.current.delete(id);
                                }
                            }, disabled: disabled }, title));
                    }))),
                tabs[selectedIndex].expandable && (React.createElement("div", { className: "flex items-center border-b border-n300Grey pr-6 pl-2 bg-white" },
                    React.createElement(Button, { IconLeading: contextState.expanded
                            ? ChevronsUpIcon
                            : ChevronsDownIcon, label: contextState.expanded
                            ? t('collapse')
                            : t('expand'), buttonType: ButtonType.SECONDARY, buttonSize: ButtonSize.SMALL, className: "self-center", onClick: () => setExpanded((prevState) => !prevState) })))),
            React.createElement(Tab.Panels, { className: withoutMargin ? '' : 'm-6' }, tabs.map(({ id, content }) => {
                return React.createElement(Tab.Panel, { key: id }, content);
            })))));
};
