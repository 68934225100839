import { useCallback } from 'react';
// When applied as a ref to an element, that element will scroll horizontally if possible.
export default function useHorizontalScroll() {
    return useCallback((container) => {
        container === null || container === void 0 ? void 0 : container.addEventListener('wheel', (e) => {
            const canScrollHorizontally = container.scrollWidth > container.clientWidth;
            if (canScrollHorizontally && e.deltaY) {
                container.scrollLeft += e.deltaY;
                e.preventDefault();
            }
        });
    }, []);
}
