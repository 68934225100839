import { Button, ButtonSize, ButtonType } from '@components/button';
import { EmployeeState, VALID_EMPLOYEE_STATE_TRANSITIONS, } from '@lohndialog/shared/src/constants';
import { LoadingSpinner } from '@components/loading-spinner';
import { usePromiseState } from '@hooks/usePromise';
import { useTranslation } from 'react-i18next';
import Dialog from '@components/modals/dialog';
import React, { useEffect, useState } from 'react';
import nestClient from '@api/nest-client';
export const EmployeeStateModal = ({ dialogOpen, setDialogOpen, employeeId, refreshEmployees, }) => {
    const { t } = useTranslation('employeeStateModal');
    const { t: tStatus } = useTranslation('statusBadge');
    const [employee, setEmployee] = useState();
    const [submitSuccessful, runSubmit, submitting, submittingError] = usePromiseState(false);
    useEffect(() => {
        const getEmployee = async () => {
            setEmployee(await nestClient.getEmployee(employeeId));
        };
        void getEmployee();
    }, [employeeId, submitSuccessful]);
    let content;
    if (submittingError) {
        content = React.createElement("p", null, t('error'));
    }
    else if (!employee) {
        content = React.createElement(LoadingSpinner, null);
    }
    else {
        content = (React.createElement("div", { className: "flex gap-2 flex-wrap" }, Object.values(EmployeeState).map((state) => {
            var _a;
            return (React.createElement(Button, { key: state, label: tStatus(state), buttonType: state === employee.state
                    ? ButtonType.PRIMARY
                    : ButtonType.SECONDARY, buttonSize: ButtonSize.LARGE, onClick: () => {
                    void runSubmit(async () => {
                        await nestClient.updateEmployee(Object.assign(Object.assign({}, employee), { state }));
                        refreshEmployees();
                        return true;
                    });
                }, disabled: !((_a = VALID_EMPLOYEE_STATE_TRANSITIONS.get(employee.state)) === null || _a === void 0 ? void 0 : _a.includes(state)), loading: submitting }));
        })));
    }
    return (React.createElement(Dialog, { dismissible: true, open: dialogOpen, setOpen: setDialogOpen, title: t('title'), actions: [] }, content));
};
