import { Button, ButtonSize, ButtonType } from '@components/button';
import { Upload } from '@components/upload/upload';
import { LoadingSpinner } from '@components/loading-spinner';
import { MAX_UPLOAD_SIZE_DOCUMENTS } from '@lohndialog/shared/src/constants';
import { MONTH_MAX, MONTH_MIN } from '@utils/constants';
import { NumberInput } from '@components/input/number-input';
import { Select } from '@components/input/select';
import { TextInput } from '@components/input/text-input';
import { UploadStatus } from '@components/upload/types';
import { clamp } from '@lohndialog/shared/src/util/range';
import { splitAtLastOccurrence } from '@lohndialog/shared/src/util/split-at-last-occurrence';
import { useTranslation } from 'react-i18next';
import Combobox from '@components/input/combobox';
import React, { useEffect, useMemo, useState } from 'react';
import dayjs from '@lohndialog/shared/src/util/dayjs';
import nestClient from '@api/nest-client';
var DocumentTargetGroupEnum;
(function (DocumentTargetGroupEnum) {
    DocumentTargetGroupEnum["EMPLOYEE_DOCUMENT"] = "EMPLOYEE_DOCUMENT";
    DocumentTargetGroupEnum["EMPLOYER_DOCUMENT"] = "EMPLOYER_DOCUMENT";
})(DocumentTargetGroupEnum || (DocumentTargetGroupEnum = {}));
var DocumentTypeEnum;
(function (DocumentTypeEnum) {
    DocumentTypeEnum["PAYROLL_ACCOUNTING"] = "PAYROLL_ACCOUNTING";
})(DocumentTypeEnum || (DocumentTypeEnum = {}));
var DocumentTitleEnum;
(function (DocumentTitleEnum) {
    DocumentTitleEnum["CERTIFICATION"] = "CERTIFICATION";
    DocumentTitleEnum["EVALUATION"] = "EVALUATION";
    DocumentTitleEnum["EXPORT_FINANCIAL_ACCOUNTING"] = "EXPORT_FINANCIAL_ACCOUNTING";
    DocumentTitleEnum["PAYMENT_FILE"] = "PAYMENT_FILE";
    DocumentTitleEnum["OTHER"] = "OTHER";
})(DocumentTitleEnum || (DocumentTitleEnum = {}));
const titleOptionMap = new Map([
    [
        DocumentTargetGroupEnum.EMPLOYER_DOCUMENT,
        new Map([
            [
                DocumentTypeEnum.PAYROLL_ACCOUNTING,
                [
                    DocumentTitleEnum.CERTIFICATION,
                    DocumentTitleEnum.EVALUATION,
                    DocumentTitleEnum.EXPORT_FINANCIAL_ACCOUNTING,
                    DocumentTitleEnum.PAYMENT_FILE,
                    DocumentTitleEnum.OTHER,
                ],
            ],
        ]),
    ],
    [
        DocumentTargetGroupEnum.EMPLOYEE_DOCUMENT,
        new Map([
            [
                DocumentTypeEnum.PAYROLL_ACCOUNTING,
                [
                    DocumentTitleEnum.CERTIFICATION,
                    DocumentTitleEnum.EVALUATION,
                    DocumentTitleEnum.OTHER,
                ],
            ],
        ]),
    ],
]);
export const CustomDocumentImport = ({ company, closeSlideIn, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { t } = useTranslation('uploadCustomDocument');
    const [fileUploads, setFileUploads] = useState([]);
    const [documentTargetGroup, setDocumentTargetGroup] = useState(DocumentTargetGroupEnum.EMPLOYEE_DOCUMENT);
    const [documentType, setDocumentType] = useState(DocumentTypeEnum.PAYROLL_ACCOUNTING);
    const [documentTitle, setDocumentTitle] = useState(titleOptionMap.get(documentTargetGroup).get(documentType)[0]);
    const [employees, setEmployees] = useState(undefined);
    const [selectedEmployee, setSelectedEmployee] = useState();
    const currentAccountingDate = useMemo(() => {
        const currentDate = dayjs();
        if (currentDate.date() < 15) {
            return currentDate.subtract(1, 'month');
        }
        return currentDate;
    }, []);
    const [month, setMonth] = useState(() => {
        var _a, _b;
        return clamp((_b = (_a = company === null || company === void 0 ? void 0 : company.genericFields.currentAccountingMonth) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : currentAccountingDate.month() + 1, MONTH_MIN, MONTH_MAX);
    });
    const [year, setYear] = useState((_b = (_a = company === null || company === void 0 ? void 0 : company.genericFields.currentAccountingYear) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : currentAccountingDate.year());
    const [comment, setComment] = useState();
    const [fileName, setFileName] = useState();
    useEffect(() => {
        if (!company) {
            setEmployees(undefined);
        }
        else {
            nestClient
                .getEmployeesForCompany(company.id)
                .then((response) => {
                setEmployees(response);
            })
                .catch(() => {
                setEmployees([]);
            });
        }
    }, [company]);
    useEffect(() => {
        const options = titleOptionMap
            .get(documentTargetGroup)
            .get(documentType);
        if (!options.includes(documentTitle)) {
            setDocumentTitle(options[0]);
        }
    }, [documentTargetGroup, documentTitle, documentType]);
    useEffect(() => {
        if (documentTargetGroup === DocumentTargetGroupEnum.EMPLOYER_DOCUMENT) {
            setSelectedEmployee(undefined);
        }
    }, [documentTargetGroup]);
    const isValid = useMemo(() => {
        var _a;
        const valid = true;
        if (!fileName) {
            return false;
        }
        if (month !== undefined &&
            !isNaN(month) &&
            !(month > 0 && month < 13 && year !== undefined)) {
            return false;
        }
        if (year && year < 0) {
            return false;
        }
        if (!fileUploads.length ||
            fileUploads[0].status !== UploadStatus.SUCCESSFUL ||
            !((_a = fileUploads[0].remoteId) === null || _a === void 0 ? void 0 : _a.id) ||
            !fileUploads[0].name) {
            return false;
        }
        if (documentTargetGroup === DocumentTargetGroupEnum.EMPLOYEE_DOCUMENT &&
            !selectedEmployee) {
            return false;
        }
        return valid;
    }, [
        documentTargetGroup,
        fileUploads,
        month,
        selectedEmployee,
        year,
        fileName,
    ]);
    if (!company) {
        return React.createElement(LoadingSpinner, null);
    }
    const isEmployeeDocument = documentTargetGroup === DocumentTargetGroupEnum.EMPLOYEE_DOCUMENT;
    return (React.createElement("div", { className: "flex flex-col h-full" },
        React.createElement("div", { className: "grow" },
            React.createElement("div", { className: "m-6" },
                React.createElement("p", { className: "font-semibold my-2 hyphens-auto" }, t('companyName', {
                    companyName: (_d = (_c = company.genericFields.name) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : '',
                })),
                React.createElement("p", { className: "font-semibold my-2" }, t('companyNumber', {
                    companyNumber: (_f = (_e = company.genericFields.mandatorNumber) === null || _e === void 0 ? void 0 : _e.value) !== null && _f !== void 0 ? _f : '',
                })),
                React.createElement("p", { className: "text-sm text-n500DarkGrey" }, t('explanation'))),
            React.createElement("div", { className: "m-6" },
                React.createElement(TextInput, { value: fileName !== null && fileName !== void 0 ? fileName : '', onChange: (newFileName) => setFileName(newFileName === '' ? undefined : newFileName), title: React.createElement(React.Fragment, null,
                        t('filename'),
                        "*"), inputTestId: "input-fileName", classNameContainer: "bg-n200LightGrey px-4 pt-4 rounded-xl", type: "text" })),
            React.createElement("div", { className: "m-6" },
                React.createElement(Select, { key: "document-target-group", value: documentTargetGroup, displayValue: (value) => t(value), options: Object.values(DocumentTargetGroupEnum), title: React.createElement(React.Fragment, null,
                        t('documentTargetGroup'),
                        "*"), onChange: (newValue) => {
                        setDocumentTargetGroup(newValue);
                    }, classNameContainer: "bg-n200LightGrey px-4 pt-4 rounded-xl" })),
            React.createElement("div", { className: "m-6" },
                React.createElement(Select, { key: "document-type", value: documentType, displayValue: (value) => t(value), options: Object.values(DocumentTypeEnum), title: React.createElement(React.Fragment, null,
                        t('documentType'),
                        "*"), onChange: (newValue) => {
                        setDocumentType(newValue);
                    }, classNameContainer: "bg-n200LightGrey px-4 pt-4 rounded-xl" })),
            React.createElement("div", { className: "m-6" },
                React.createElement(Select, { key: "document-title", value: documentTitle, displayValue: (value) => t(value), options: (_h = (_g = titleOptionMap
                        .get(documentTargetGroup)) === null || _g === void 0 ? void 0 : _g.get(documentType)) !== null && _h !== void 0 ? _h : [], title: React.createElement(React.Fragment, null,
                        t('documentTitle'),
                        "*"), onChange: (newValue) => {
                        setDocumentTitle(newValue);
                    }, classNameContainer: "bg-n200LightGrey px-4 pt-4 rounded-xl" })),
            isEmployeeDocument && employees && (React.createElement("div", { className: "m-6" },
                React.createElement(Combobox, { options: employees, onChange: setSelectedEmployee, displayValue: (employee) => {
                        var _a, _b, _c, _d, _e, _f;
                        return employee
                            ? `${(_b = (_a = employee.genericFields.personnelNumber) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : ''} ${(_d = (_c = employee.genericFields.firstname) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : ''} ${(_f = (_e = employee.genericFields.lastname) === null || _e === void 0 ? void 0 : _e.value) !== null && _f !== void 0 ? _f : ''}`
                            : '';
                    }, value: selectedEmployee, title: React.createElement(React.Fragment, null,
                        t('employee'),
                        "*"), classNameContainer: "bg-n200LightGrey px-4 pt-4 rounded-xl" }))),
            React.createElement("div", { className: "m-6" },
                React.createElement("div", { className: "flex justify-between bg-n200LightGrey rounded-xl" },
                    React.createElement(NumberInput, { key: "month", title: t('month'), value: month, classNameContainer: "px-4 pt-4 grow", onChange: setMonth, max: MONTH_MAX, min: MONTH_MIN, classNameInput: "grow" }),
                    React.createElement(NumberInput, { key: "year", title: t('year'), value: year, classNameContainer: "px-4 pt-4", onChange: setYear }))),
            React.createElement("div", { className: "m-6" },
                React.createElement("p", null, t('selectFile')),
                React.createElement(Upload, { acceptedFileFormats: {
                        'text/plain': ['.txt'],
                        'text/csv': ['.csv'],
                        'text/xml': ['.xml', '.xsl', '.xslx'],
                        'application/pdf': ['.pdf'],
                        'application/msword': ['.doc'],
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                        'image/jpeg': ['.jpg', '.jpeg'],
                        'image/gif': ['.gif'],
                        'image/png': ['.png'],
                        'application/dat': ['.dat'],
                    }, fileUploads: fileUploads, setFileUploads: setFileUploads, maxFileSize: MAX_UPLOAD_SIZE_DOCUMENTS, uploadFuncDB: (file, progressCallback) => {
                        return nestClient.uploadCustomDocuments(file, progressCallback);
                    }, deleteFuncDB: async (dto) => await nestClient.cancelCustomUpload(dto), maxFiles: 1 })),
            React.createElement("div", { className: "m-6" },
                React.createElement(TextInput, { value: comment !== null && comment !== void 0 ? comment : '', onChange: (newComment) => setComment(newComment === '' ? undefined : newComment), title: t('comment'), inputTestId: "input-comment", classNameInput: "h-24", type: "textarea" }))),
        React.createElement("div", { className: "sticky bg-white py-2 px-6 bottom-0 text-right" },
            React.createElement(Button, { label: t('uploadBtn'), testId: "uploadBtn", buttonType: ButtonType.PRIMARY, buttonSize: ButtonSize.LARGE, className: "w-1/2", onClick: () => {
                    if (isValid) {
                        const [originalFileName, type] = splitAtLastOccurrence(fileUploads[0].name, '.');
                        nestClient
                            .createPayrollProviderDocumentEntry({
                            id: fileUploads[0].remoteId.id,
                            name: fileName,
                            fileName: originalFileName,
                            genericFields: {},
                            category: t(documentTitle),
                            accountingPeriodMonth: month,
                            accountingPeriodYear: year,
                            assignedCompany: { id: company.id },
                            assignedEmployee: selectedEmployee
                                ? { id: selectedEmployee.id }
                                : undefined,
                            fileCreationDate: dayjs().toISOString(),
                            type: type.toUpperCase(),
                            documentType: t(documentType),
                            uploadDate: dayjs().toISOString(),
                            comment: comment,
                        })
                            .then(() => {
                            closeSlideIn();
                        })
                            .catch(() => {
                            // do nothing
                        });
                    }
                }, disabled: !isValid }))));
};
