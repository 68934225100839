import { Transition } from '@headlessui/react';
import { classNames } from '@utils/class-names';
import CloseButton, { CloseButtonSize, CloseButtonType, } from '@components/close-button';
import React, { Fragment, useState } from 'react';
import parse from 'html-react-parser';
export var NotificationType;
(function (NotificationType) {
    NotificationType["SUCCESS"] = "SUCCESS";
    NotificationType["INFO"] = "INFO";
    NotificationType["ERROR"] = "ERROR";
})(NotificationType || (NotificationType = {}));
const colors = {
    [NotificationType.SUCCESS]: {
        textClass: 'text-p100Green',
        backgroundClass: 'bg-p100Green',
    },
    [NotificationType.INFO]: {
        textClass: 'text-n450MidGrey',
        backgroundClass: 'bg-n450MidGrey',
    },
    [NotificationType.ERROR]: {
        textClass: 'text-r100Red',
        backgroundClass: 'bg-r100Red',
    },
};
export function Notification({ onClose, notificationType, title, children, Icon, }) {
    const [open, setOpen] = useState(true);
    return (React.createElement(React.Fragment, null,
        React.createElement(Transition, { appear: true, show: open, as: Fragment, enter: "transform ease-out duration-300 transition", enterFrom: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2", enterTo: "translate-y-0 opacity-100 sm:translate-x-0", leave: "transition ease-in duration-100", leaveFrom: "opacity-100", leaveTo: "opacity-0", afterLeave: onClose },
            React.createElement("div", { className: "pointer-events-auto w-[496px] max-w-full overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 py-3 pr-3 relative" },
                React.createElement("div", { className: classNames(colors[notificationType].backgroundClass, 'rounded-lg absolute top-3.5 bottom-3.5 w-[3px]') }),
                React.createElement("div", { className: "flex items-center" },
                    React.createElement("div", { className: "ml-4 flex flex-shrink-0" },
                        React.createElement(Icon, { className: classNames(colors[notificationType].textClass, 'h-6 w-6'), "aria-hidden": "true" })),
                    React.createElement("div", { className: "flex w-0 flex-1 justify-between" },
                        React.createElement("div", { className: "ml-3.5 w-0 flex-1" },
                            React.createElement("p", { className: "text-sm font-medium text-n600DarkBlue" }, parse(title)),
                            React.createElement("p", { className: "mt-0.5 text-xs text-n500DarkGrey" }, children))),
                    React.createElement("div", { className: "ml-4 flex flex-shrink-0" },
                        React.createElement(CloseButton, { type: CloseButtonType.GREY, onClick: () => setOpen(false), size: CloseButtonSize.SMALL, tabIndex: 0, "data-testid": "closeNotificationBtn" })))))));
}
