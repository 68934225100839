import { Button, ButtonSize, ButtonType } from '@components/button';
import { PayrollProviderStaffRoleEnum, PayrollProviderStaffState, } from '@lohndialog/shared/src/constants';
import { Select } from '@components/input/select';
import { TextInput } from '@components/input/text-input';
import { useTranslation } from 'react-i18next';
import { validatePhoneNumber } from '@lohndialog/shared/src/validation';
import React, { useCallback, useMemo, useState } from 'react';
export default function PayrollProviderEditSlideIn({ staffMember, loading, updateStaffMember, }) {
    var _a, _b, _c;
    const { t } = useTranslation('ppSlideIn');
    const [firstname, setFirstname] = useState(staffMember.firstname);
    const [lastname, setLastname] = useState(staffMember.lastname);
    const [role, setRole] = useState(staffMember.role);
    const [nickname, setNickname] = useState(staffMember.nickname);
    const [phone, setPhone] = useState(staffMember.phone);
    const updateMember = useCallback(() => {
        const updatedStaffMember = Object.assign(Object.assign({}, staffMember), { firstname,
            lastname,
            role,
            nickname,
            phone });
        void updateStaffMember(updatedStaffMember);
    }, [
        firstname,
        lastname,
        phone,
        role,
        staffMember,
        updateStaffMember,
        nickname,
    ]);
    const madeNoChanges = useMemo(() => firstname === staffMember.firstname &&
        lastname === staffMember.lastname &&
        role === staffMember.role &&
        nickname === staffMember.nickname &&
        phone === staffMember.phone, [firstname, lastname, role, nickname, phone, staffMember]);
    const deactivateMember = useCallback(() => {
        void updateStaffMember(Object.assign(Object.assign({}, staffMember), { state: PayrollProviderStaffState.DISABLED }));
    }, [updateStaffMember, staffMember]);
    return (React.createElement("div", { className: "flex flex-col h-full" },
        React.createElement("div", { className: "grow" },
            React.createElement("div", { className: "m-6" },
                React.createElement(TextInput, { value: firstname, onChange: (newName) => setFirstname(newName), title: t('firstname'), inputTestId: "input-firstname", classNameContainer: "bg-n200LightGrey px-4 pt-4 rounded-xl" })),
            React.createElement("div", { className: "m-6" },
                React.createElement(TextInput, { value: lastname, onChange: (newName) => setLastname(newName), title: t('lastname'), inputTestId: "input-lastname", classNameContainer: "bg-n200LightGrey px-4 pt-4 rounded-xl" })),
            React.createElement("div", { className: "m-6" },
                React.createElement(TextInput, { key: "PP-Email", value: (_c = (_b = (_a = staffMember.user) === null || _a === void 0 ? void 0 : _a.email) !== null && _b !== void 0 ? _b : staffMember.inviteEmail) !== null && _c !== void 0 ? _c : '', title: t('email'), onChange: () => {
                        // do nothing as this is a read-only field
                    }, classNameContainer: "bg-n200LightGrey px-4 pt-4 rounded-xl", disabled: true })),
            React.createElement("div", { className: "m-6" },
                React.createElement(Select, { key: "PP-Role", value: role, displayValue: (value) => t(value), options: Object.keys(PayrollProviderStaffRoleEnum), title: t('role'), onChange: (newValue) => {
                        if (newValue) {
                            setRole(newValue);
                        }
                    }, classNameContainer: "bg-n200LightGrey px-4 pt-4 rounded-xl" })),
            React.createElement("div", { className: "m-6" },
                React.createElement(TextInput, { value: nickname !== null && nickname !== void 0 ? nickname : '', onChange: (newName) => setNickname(newName === '' ? undefined : newName), title: t('nickname'), inputTestId: "input-nickname", classNameContainer: "bg-n200LightGrey px-4 pt-4 rounded-xl" })),
            React.createElement("div", { className: "m-6" },
                React.createElement(TextInput, { key: "PP-Phone", value: phone !== null && phone !== void 0 ? phone : '', title: t('phone'), onChange: (newValue) => setPhone(newValue === '' ? undefined : newValue), validate: validatePhoneNumber, inputTestId: "input-phone", classNameContainer: "bg-n200LightGrey px-4 pt-4 rounded-xl" })),
            React.createElement("div", { className: "m-6" },
                React.createElement(Button, { label: t('deactivateBtn'), testId: "deactivateBtn", buttonType: ButtonType.PRIMARY, buttonSize: ButtonSize.LARGE, className: "w-1/2", onClick: deactivateMember, loading: loading, disabled: staffMember.state === 'DISABLED' }))),
        React.createElement("div", { className: "sticky bg-white py-2 px-6 bottom-0 text-right" },
            React.createElement(Button, { label: t('updateBtn'), testId: "updateBtn", buttonType: ButtonType.PRIMARY, buttonSize: ButtonSize.LARGE, className: "w-1/2", onClick: updateMember, loading: loading, disabled: madeNoChanges }))));
}
