import { WizardCluster, } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { LoadingSpinner } from '@components/loading-spinner';
import { useTranslation } from 'react-i18next';
import GenericForm from '@components/generic-forms/generic-form';
import React, { useCallback } from 'react';
export default (schema, supervisingValue, forceReadOnly) => ({
    key: 'step1',
    isValid: () => true,
    stepLabel: (t) => t('step1.label', {
        ns: 'employeeWizard',
    }),
    stepLabelDetails: (t) => t('step1.labelDetails', {
        ns: 'employeeWizard',
    }),
    ReactComponent: ({ value, setValue }) => {
        const { t } = useTranslation('employeeWizard', {
            keyPrefix: 'step1',
        });
        const { t: clusterTranslations } = useTranslation('employeeWizard', {
            keyPrefix: 'clusterLabel',
        });
        const setGenericField = useCallback((fieldIdentifier, type, newValue) => {
            setValue(fieldIdentifier, type, newValue);
        }, [setValue]);
        if (!schema) {
            return React.createElement(LoadingSpinner, null);
        }
        return (React.createElement("div", null,
            React.createElement("h1", null, t('title')),
            React.createElement("h2", null, clusterTranslations(WizardCluster.PersonalData)),
            React.createElement(GenericForm, { value: value, supervisingValue: supervisingValue, setValue: setGenericField, schema: schema, wizardCluster: WizardCluster.PersonalData, forceReadOnly: forceReadOnly }),
            React.createElement("h2", { className: "mt-8" }, clusterTranslations(WizardCluster.Address)),
            React.createElement(GenericForm, { value: value, supervisingValue: supervisingValue, setValue: setGenericField, schema: schema, wizardCluster: WizardCluster.Address, forceReadOnly: forceReadOnly }),
            React.createElement("h2", { className: "mt-8" }, clusterTranslations(WizardCluster.PaymentData)),
            React.createElement(GenericForm, { value: value, supervisingValue: supervisingValue, setValue: setGenericField, schema: schema, wizardCluster: WizardCluster.PaymentData, forceReadOnly: forceReadOnly }),
            React.createElement("h2", { className: "mt-8" }, clusterTranslations(WizardCluster.AdditionalData)),
            React.createElement(GenericForm, { value: value, supervisingValue: supervisingValue, setValue: setGenericField, schema: schema, wizardCluster: WizardCluster.AdditionalData, forceReadOnly: forceReadOnly })));
    },
});
