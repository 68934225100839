import { ReactComponent as AlertTriangle } from '@public/icons/alert-triangle.svg';
import { ReactComponent as ArchiveIcon } from '@public/icons/archive.svg';
import { ReactComponent as ArrowBack } from '@public/icons/arrow-left.svg';
import { Button, ButtonSize, ButtonType } from './button';
import { ReactComponent as ChevronUpIcon } from '@public/icons/chevron-up.svg';
import { CompanyContext } from '../context/company-context';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { ReactComponent as FileIcon } from '@public/icons/file-text.svg';
import { ReactComponent as FolderIcon } from '@public/icons/folder.svg';
import { ReactComponent as HomeIcon } from '@public/icons/home.svg';
import { LogoutButton } from '@components/auth';
import { NavLink } from '@components/nav-link';
import { PayrollProviderStaffRoleEnum, UserCompanyRoleEnum, } from '@lohndialog/shared/src/constants';
import { ReactComponent as SettingsIcon } from '@public/icons/settings.svg';
import { ReactComponent as UserCheckIcon } from '@public/icons/user-check.svg';
import { ReactComponent as UsersIcon } from '@public/icons/users.svg';
import { classNames } from '@utils/class-names';
import { useLocation, useParams } from 'react-router-dom';
import { useProfile } from '../context/profile-context';
import { useRole } from '@hooks/useRole';
import { useTranslation } from 'react-i18next';
import CompanyLogo from '@components/company-logo';
import React, { Fragment, useContext, useId, useMemo, } from 'react';
const MenuItem = ({ href, onClick, Icon, label, showLabel, requiredRoles, addCompanyPrefix = true, clearCompany = false, }) => {
    const labelId = useId();
    const hasRole = useRole(requiredRoles);
    const companyState = useContext(CompanyContext);
    const { pathname } = useLocation();
    const { companySlug } = useParams();
    const isBaseActive = useMemo(() => {
        if (!href) {
            return false;
        }
        if (href === '/') {
            if (companySlug) {
                return pathname.endsWith(`/${companySlug}/`);
            }
            else {
                return pathname === '/';
            }
        }
        return true;
    }, [companySlug, href, pathname]);
    if (!hasRole) {
        return React.createElement(React.Fragment, null);
    }
    const content = (React.createElement(React.Fragment, null,
        React.createElement(Icon, { className: "h-6 w-6 flex-shrink-0", "aria-hidden": "true" }),
        React.createElement("span", { id: labelId, className: classNames('ml-3', !showLabel && 'hidden group-hover:block') }, label)));
    return href ? (React.createElement(NavLink, { to: href, onClick: onClick, addCompanyPrefix: addCompanyPrefix, className: ({ isActive }) => classNames('menuitem', isActive && isBaseActive
            ? 'bg-p200Green hover:bg-primaryBtnHover text-white'
            : 'text-n500DarkGrey hover:bg-n100Whisper active:bg-n200LightGrey', 'group flex items-center rounded-full px-5 py-3 text-sm font-medium pointer-events-auto'), "aria-labelledby": labelId, onClickCapture: () => {
            if (clearCompany && companyState) {
                companyState.setCompany(undefined);
            }
        } }, content)) : (React.createElement("div", { className: "menuitem group flex items-center rounded-full px-5 py-3 text-sm font-medium pointer-events-auto hover:bg-n100Whisper" }, content));
};
const ProfileIndicator = ({ showLabel }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const ROUTE = 'profile';
    const { state } = useProfile();
    const company = (_a = useContext(CompanyContext)) === null || _a === void 0 ? void 0 : _a.company;
    const location = useLocation();
    const isOpen = useMemo(() => location.pathname.includes(ROUTE), [location]);
    if (!state.profile) {
        return React.createElement(React.Fragment, null);
    }
    const firstname = (_d = (_c = (_b = company === null || company === void 0 ? void 0 : company.employee) === null || _b === void 0 ? void 0 : _b.genericFields.firstname) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : '';
    const lastname = (_g = (_f = (_e = company === null || company === void 0 ? void 0 : company.employee) === null || _e === void 0 ? void 0 : _e.genericFields.lastname) === null || _f === void 0 ? void 0 : _f.value) !== null && _g !== void 0 ? _g : '';
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "flex group/person mb-8" },
            React.createElement(NavLink, { to: `/${ROUTE}`, className: ({ isActive }) => classNames('flex space-x-3 pointer-events-auto p-3 !-my-3 rounded-full w-full', isActive
                    ? 'bg-p200Green [&_.profile-image]:bg-white [&_.profile-image]:text-n600DarkBlue [&_.name]:text-white [&_.email]:text-n200LightGrey'
                    : '[&_.profile-image]:bg-p200Green [&_.profile-image]:text-white [&_.name]:text-n600DarkBlue [&_.email]:text-n500DarkGrey hover:bg-n100Whisper active:bg-n200LightGrey group-hover/person:bg-n100Whisper group-active/person:bg-n200LightGrey') },
                React.createElement("div", { className: "profile-image w-10 h-10 rounded-full flex flex-shrink-0 justify-center items-center text-white font-semibold text-base" },
                    firstname.substring(0, 1),
                    lastname.substring(0, 1)),
                showLabel && (React.createElement("div", { className: "flex flex-grow flex-col justify-center min-w-0 pr-10" },
                    React.createElement("span", { className: "name block leading-tight text-ellipsis whitespace-nowrap overflow-hidden text-sm" },
                        firstname,
                        " ",
                        lastname),
                    React.createElement("span", { className: "email block leading-tight text-ellipsis whitespace-nowrap overflow-hidden text-sm" }, state.profile.email)))),
            showLabel && (React.createElement("div", { className: "-ml-10 h-10 w-10 flex flex-shrink-0 items-center" },
                React.createElement(LogoutButton, { onlyIcon: true, className: classNames(isOpen
                        ? 'text-n400GreyCheteau hover:text-n200LightGrey'
                        : 'text-n500DarkGrey hover:text-p200Green', 'flex-shrink-0 rounded-full') })))),
        !showLabel && (React.createElement("div", { className: "px-2" },
            React.createElement(LogoutButton, { onlyIcon: false })))));
};
const ResponsiblePerson = ({ showLabel }) => {
    var _a, _b, _c, _d, _e, _f;
    const { t } = useTranslation('responsiblePerson');
    const company = (_a = useContext(CompanyContext)) === null || _a === void 0 ? void 0 : _a.company;
    const hasAdminRole = useRole([UserCompanyRoleEnum.ADMIN]);
    const profile = useProfile();
    if ((!hasAdminRole && !(company === null || company === void 0 ? void 0 : company.responsiblePerson)) ||
        (hasAdminRole && !((_b = profile.state.profile) === null || _b === void 0 ? void 0 : _b.payrollProvider))) {
        return React.createElement(React.Fragment, null);
    }
    const name = hasAdminRole
        ? `${(_d = (_c = profile.state.profile) === null || _c === void 0 ? void 0 : _c.payrollProvider) === null || _d === void 0 ? void 0 : _d.name}`
        : (_e = company === null || company === void 0 ? void 0 : company.responsiblePerson) === null || _e === void 0 ? void 0 : _e.name;
    const label = (React.createElement(React.Fragment, null,
        React.createElement("span", { className: "block leading-tight text-xs text-n500DarkGrey" }, t('label')),
        React.createElement("p", { className: "text-left block leading-tight overflow-hidden text-n600DarkBlue break-words" }, name)));
    return (React.createElement("a", { className: classNames('pointer-events-auto group/contact group-hover/contact:w-full', showLabel && 'w-full'), title: name, href: !hasAdminRole
            ? `mailto:${name}<${(_f = company === null || company === void 0 ? void 0 : company.responsiblePerson) === null || _f === void 0 ? void 0 : _f.email}>`
            : undefined, target: "_blank", rel: "noreferrer" },
        React.createElement(Button, { buttonType: ButtonType.SECONDARY, buttonSize: ButtonSize.SMALL, IconLeading: hasAdminRole ? HomeIcon : UserCheckIcon, className: classNames('group-hover/contact:w-full', showLabel && 'w-full'), alignContentStart: true, label: showLabel ? (React.createElement("div", { className: "flex flex-col items-start flex-grow ml-1.5" }, label)) : (React.createElement("div", { className: "group-hover/contact:block flex flex-col overflow-hidden items-start text-left" }, label)) })));
};
const ContactWrapper = ({ showLabel, children }) => {
    var _a;
    const company = (_a = useContext(CompanyContext)) === null || _a === void 0 ? void 0 : _a.company;
    const buttonContent = useMemo(() => {
        var _a, _b;
        return (React.createElement(React.Fragment, null,
            React.createElement(ArchiveIcon, { className: "h-6 w-6 flex-shrink-0", "aria-hidden": "true" }),
            React.createElement("div", { className: classNames('flex flex-col flex-grow', showLabel
                    ? 'ml-3'
                    : 'hidden group-hover/contact:block group-hover/contact:ml-3 items-start text-left') },
                React.createElement("p", { className: "text-n600DarkBlue" }, (_a = company === null || company === void 0 ? void 0 : company.name) !== null && _a !== void 0 ? _a : ''),
                React.createElement("div", { className: "w-full" }),
                React.createElement("span", { className: "text-xs" }, `(${(_b = company === null || company === void 0 ? void 0 : company.mandatorNumber) !== null && _b !== void 0 ? _b : ''})`))));
    }, [company, showLabel]);
    if (!company) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement("div", { className: classNames(showLabel && 'w-full') },
        React.createElement("div", { className: "menuitem mx-auto w-full max-w-sm group/contact", "data-testid": "contact-wrapper-menu-button" }, showLabel ? (React.createElement(Disclosure, { defaultOpen: !showLabel }, ({ open }) => (React.createElement("div", { className: classNames(open &&
                'outline outline-n300Grey outline-2 rounded-2xl bg-white') },
            React.createElement(Disclosure.Button, { disabled: !showLabel, className: classNames(open ? 'rounded-t-xl' : 'rounded-full', 'flex w-full justify-between px-5 py-3 text-left text-n500DarkGrey hover:bg-n100Whisper active:bg-n200LightGrey text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 items-center') },
                buttonContent,
                React.createElement(ChevronUpIcon, { className: classNames(open && 'rotate-180 transform', 'h-5 w-5 text-n500DarkGrey flex-shrink-0') })),
            React.createElement(Disclosure.Panel, { className: "p-2" }, children))))) : (React.createElement("div", { className: "group-hover/contact:outline outline-n300Grey outline-2 bg-white flex w-full justify-between px-5 py-3 text-left text-n500DarkGrey hover:bg-n100Whisper active:bg-n200LightGrey text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 items-center rounded-full" }, buttonContent)))));
};
const Divider = ({ className }) => (React.createElement("hr", { className: classNames('border-n300Grey border-b-2 border-t-0 !my-6', '[&_+_&]:hidden', // Hide all but the first divider if multiple of them are adjacent
    className) }));
const PPMenuContent = ({ collapsed, onClick, }) => {
    const { t } = useTranslation('mainMenu');
    return (React.createElement(React.Fragment, null,
        React.createElement(MenuItem, { onClick: onClick, href: "/admin/dashboard", Icon: HomeIcon, label: t('payrollDashboard'), showLabel: !collapsed, requiredRoles: [
                PayrollProviderStaffRoleEnum.PP_ADMIN,
                PayrollProviderStaffRoleEnum.INTERNAL,
                PayrollProviderStaffRoleEnum.SENIOR,
                PayrollProviderStaffRoleEnum.JUNIOR,
            ], addCompanyPrefix: false }),
        React.createElement(MenuItem, { onClick: onClick, href: "/admin/mandators", Icon: UsersIcon, label: t('mandators'), showLabel: !collapsed, requiredRoles: [
                PayrollProviderStaffRoleEnum.PP_ADMIN,
                PayrollProviderStaffRoleEnum.INTERNAL,
                PayrollProviderStaffRoleEnum.SENIOR,
                PayrollProviderStaffRoleEnum.JUNIOR,
            ], addCompanyPrefix: false }),
        React.createElement(MenuItem, { onClick: onClick, href: "/admin/documents", Icon: FolderIcon, label: t('documents'), showLabel: !collapsed, requiredRoles: [
                PayrollProviderStaffRoleEnum.PP_ADMIN,
                PayrollProviderStaffRoleEnum.INTERNAL,
                PayrollProviderStaffRoleEnum.SENIOR,
                PayrollProviderStaffRoleEnum.JUNIOR,
            ], addCompanyPrefix: false }),
        React.createElement(MenuItem, { onClick: onClick, href: "/admin/settings", Icon: SettingsIcon, label: t('settings'), showLabel: !collapsed, requiredRoles: [PayrollProviderStaffRoleEnum.PP_ADMIN], addCompanyPrefix: false })));
};
const PersonalMenuContent = ({ collapsed, onClick, }) => {
    const { t } = useTranslation('mainMenu');
    return (React.createElement(React.Fragment, null,
        React.createElement(MenuItem, { onClick: onClick, href: "/dashboard", Icon: HomeIcon, label: t('personalDashboard'), showLabel: !collapsed, requiredRoles: [UserCompanyRoleEnum.USER] }),
        React.createElement(MenuItem, { onClick: onClick, href: "/myDocuments", Icon: FolderIcon, label: t('myDocuments'), showLabel: !collapsed, requiredRoles: [UserCompanyRoleEnum.USER] })));
};
const ContactMenuContent = ({ collapsed, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(ContactWrapper, { showLabel: !collapsed },
            React.createElement(ResponsiblePerson, { showLabel: !collapsed }))));
};
const GeneralMenuContent = ({ collapsed }) => {
    var _a, _b, _c, _d, _e, _f;
    const profile = useProfile();
    return (React.createElement(React.Fragment, null,
        React.createElement(ProfileIndicator, { showLabel: !collapsed }),
        React.createElement("div", { className: "h-8" }),
        React.createElement(CompanyLogo, { logoUrl: collapsed
                ? (_b = (_a = profile.state.profile) === null || _a === void 0 ? void 0 : _a.payrollProvider) === null || _b === void 0 ? void 0 : _b.logoUrlSmall
                : (_d = (_c = profile.state.profile) === null || _c === void 0 ? void 0 : _c.payrollProvider) === null || _d === void 0 ? void 0 : _d.logoUrlBig, link: (_f = (_e = profile.state.profile) === null || _e === void 0 ? void 0 : _e.payrollProvider) === null || _f === void 0 ? void 0 : _f.website, className: "flex justify-start px-3 mb-8", width: collapsed ? '40px' : '220px' })));
};
const CompanyMenuContent = ({ collapsed, onClick, }) => {
    const { t } = useTranslation('mainMenu');
    return (React.createElement(React.Fragment, null,
        React.createElement(MenuItem, { onClick: onClick, href: "/", Icon: HomeIcon, label: t('companyDashboard'), showLabel: !collapsed, requiredRoles: [
                UserCompanyRoleEnum.ADMIN,
                UserCompanyRoleEnum.PP_ADMIN,
            ] }),
        React.createElement(MenuItem, { onClick: onClick, href: "/employees", Icon: UsersIcon, label: t('employees'), showLabel: !collapsed, requiredRoles: [
                UserCompanyRoleEnum.ADMIN,
                UserCompanyRoleEnum.PP_ADMIN,
            ] }),
        React.createElement(MenuItem, { onClick: onClick, href: "/documents", Icon: FolderIcon, label: t('documents'), showLabel: !collapsed, requiredRoles: [
                UserCompanyRoleEnum.ADMIN,
                UserCompanyRoleEnum.PP_ADMIN,
            ] }),
        React.createElement(MenuItem, { onClick: onClick, href: "/eAU", Icon: FileIcon, label: t('eau'), showLabel: !collapsed, requiredRoles: [
                UserCompanyRoleEnum.ADMIN,
                UserCompanyRoleEnum.PP_ADMIN,
            ] }),
        React.createElement(MenuItem, { onClick: onClick, href: "/settings", Icon: SettingsIcon, label: t('companySettings'), showLabel: !collapsed, requiredRoles: [
                UserCompanyRoleEnum.ADMIN,
                UserCompanyRoleEnum.PP_ADMIN,
            ] })));
};
const MenuContent = ({ onClick, collapsed = false, }) => {
    var _a, _b, _c;
    const hasPPAdminRole = useRole([PayrollProviderStaffRoleEnum.PP_ADMIN]);
    const company = (_a = useContext(CompanyContext)) === null || _a === void 0 ? void 0 : _a.company;
    const { pathname } = useLocation();
    const isAdminContent = pathname.startsWith('/admin');
    const hasAdminRole = useRole([UserCompanyRoleEnum.ADMIN]);
    const isPPAdminOnly = !hasAdminRole && hasPPAdminRole;
    const { t } = useTranslation('mainMenu');
    return (React.createElement("nav", { className: classNames('space-y-1 flex-grow flex flex-col w-full mb-8 px-3', collapsed && 'md:items-start') },
        isAdminContent ? (React.createElement(PPMenuContent, { collapsed: collapsed, onClick: onClick })) : (hasPPAdminRole && (React.createElement(React.Fragment, null,
            isPPAdminOnly && !collapsed && (React.createElement("p", { className: "mx-4 mb-8 font-bold" }, t('adminInfo', {
                companyName: (_b = company === null || company === void 0 ? void 0 : company.name) !== null && _b !== void 0 ? _b : '',
            }))),
            isPPAdminOnly && collapsed && (React.createElement(MenuItem, { Icon: AlertTriangle, label: t('adminInfo', {
                    companyName: (_c = company === null || company === void 0 ? void 0 : company.name) !== null && _c !== void 0 ? _c : '',
                }), showLabel: !collapsed })),
            React.createElement(MenuItem, { onClick: onClick, href: "/admin/dashboard", Icon: ArrowBack, label: t('backAdmin'), showLabel: !collapsed, requiredRoles: [
                    PayrollProviderStaffRoleEnum.PP_ADMIN,
                ], addCompanyPrefix: false, clearCompany: true })))),
        company && hasPPAdminRole && React.createElement(Divider, null),
        company && (React.createElement(CompanyMenuContent, { collapsed: collapsed, onClick: onClick })),
        company && React.createElement(Divider, null),
        React.createElement(PersonalMenuContent, { collapsed: collapsed, onClick: onClick }),
        company ? (React.createElement(React.Fragment, null,
            React.createElement(Divider, { className: "flex-grow" }),
            React.createElement(ContactMenuContent, { collapsed: collapsed }))) : (React.createElement("div", null)),
        React.createElement(Divider, { className: "flex-grow" }),
        React.createElement(GeneralMenuContent, { collapsed: collapsed })));
};
export default function Menu({ sidebarOpen, closeSidebar, desktopCollapsed, }) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Transition.Root, { show: sidebarOpen, as: Fragment },
            React.createElement(Dialog, { as: "div", className: "relative z-40 2md:hidden", onClose: closeSidebar },
                React.createElement("div", { className: "fixed inset-0 top-16 z-40 flex" },
                    React.createElement(Transition.Child, { as: Fragment, enter: "transition ease-in-out duration-300 transform", enterFrom: "-translate-x-full", enterTo: "translate-x-0", leave: "transition ease-in-out duration-300 transform", leaveFrom: "translate-x-0", leaveTo: "-translate-x-full" },
                        React.createElement(Dialog.Panel, { className: "relative flex w-full max-w-sm  flex-1 flex-col bg-white" },
                            React.createElement("div", { className: "pt-5 h-0 flex-1 flex flex-col overflow-y-auto h-full w-full max-h-screen" },
                                React.createElement(MenuContent, { onClick: closeSidebar }))))))),
        React.createElement("div", { className: classNames('hidden max-h-full 2md:block min-h-full flex-shrink-0 bg-white', desktopCollapsed
                ? 'has-[.menuitem:hover]:-mr-48  has-[.menuitem:hover]:w-[280px] w-[88px]'
                : 'w-[280px]') },
            React.createElement("div", { className: "sticky top-0 flex flex-col justify-between overflow-y-auto relative scrollbar-hide pt-8 h-full w-full max-h-screen z-40" },
                React.createElement(MenuContent, { collapsed: desktopCollapsed })))));
}
