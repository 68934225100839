import { FieldType, } from '../dtos/entity-schema.dto';
import { getDynamicPermissionForField } from '../util/generic-fields';
export const getChildNumberFromName = (fieldName) => {
    const parsedResult = parseInt(fieldName.split('child')[1]);
    if (isNaN(parsedResult)) {
        return -1;
    }
    return parsedResult;
};
export const fieldIsRequired = (roles, fieldKey, fieldDefinition, fieldValues) => {
    var _a, _b;
    let required = roles.some((role) => fieldDefinition.required[role]);
    if (fieldDefinition.type === FieldType.CHILD) {
        required =
            getChildNumberFromName(fieldKey) <=
                ((_b = (_a = fieldValues.childAllowance) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : 0);
    }
    if (fieldDefinition.dependentOn) {
        Object.entries(fieldDefinition.dependentOn).forEach(([fieldName, { target, value }]) => {
            const targetValue = fieldValues[fieldName];
            const dynamicPermission = getDynamicPermissionForField(target, required, value, targetValue);
            required = dynamicPermission.required;
        });
    }
    return required;
};
