import { useTranslation } from 'react-i18next';
import React from 'react';
export default function ({ error, children, }) {
    const { t } = useTranslation('errors');
    return (React.createElement("div", { className: "flex flex-col items-center justify-center h-full p-4 text-center", "data-testid": "error" },
        React.createElement("div", { className: "text-xl font-bold mb-5" }, t('title')),
        React.createElement("p", { className: "mb-5" },
            React.createElement(React.Fragment, null,
                error &&
                    'statusText' in error && (React.createElement("i", null, error.statusText)),
                error && 'message' in error && (React.createElement("i", null, error.message)),
                !error && 'Unknown error')),
        children));
}
