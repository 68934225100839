import { DOCUMENT_PAGE_SIZE } from '@utils/constants';
import { UserCompanyRoleEnum } from '@lohndialog/shared/src/constants';
import { useNestClient } from '@hooks/useNestClient';
import { useRole } from '@hooks/useRole';
import { useSearchParams } from 'react-router-dom';
import DocumentTable from '@components/documents/document-table';
import React, { useEffect, useMemo, useState } from 'react';
export default function DocumentTableWrapper({ searchTerm, personal, employeeDocuments, }) {
    const [forceUpdateCounter, setForceUpdateCounter] = useState(0);
    const hasRole = useRole([UserCompanyRoleEnum.PP_ADMIN]);
    const [currentPage, setCurrentPage] = useState(0);
    const [resultPage, setResultPage] = useState(0);
    const [numberOfAvailablePages, setNumberOfAvailablePages] = useState(0);
    const [sort, setSort] = useState(null);
    const [searchParams] = useSearchParams();
    const year = searchParams.get('year');
    const month = searchParams.get('month');
    const { result: documentSchema, loading: loadingSchema } = useNestClient('getDocumentSchema', []);
    const documentRequest = useMemo(() => {
        return {
            pageNumber: currentPage === resultPage ? currentPage : resultPage,
            pageSize: DOCUMENT_PAGE_SIZE,
            searchTerm: searchTerm,
            sort: sort !== null && sort !== void 0 ? sort : undefined,
            personal: personal,
            accountingPeriodYear: year !== null && year !== void 0 ? year : undefined,
            accountingPeriodMonth: month !== null && month !== void 0 ? month : undefined,
            employeeDocuments: employeeDocuments,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, searchTerm, sort, forceUpdateCounter, employeeDocuments]);
    const { result: documents, loading: loading } = useNestClient('getPaginatedDocuments', [documentRequest], { data: [], totalCount: 0, page: 0 });
    useEffect(() => {
        setNumberOfAvailablePages(Math.ceil(documents.totalCount / DOCUMENT_PAGE_SIZE));
        setResultPage(documents.page);
    }, [documents]);
    const updatePage = (newPage) => {
        setResultPage(newPage);
        setCurrentPage(newPage);
    };
    return (React.createElement(DocumentTable, { documents: documents.data, currentPage: resultPage, setCurrentPage: updatePage, numberOfAvailablePages: numberOfAvailablePages, sort: sort !== null && sort !== void 0 ? sort : undefined, setSort: setSort, loading: loading || loadingSchema, documentSchema: documentSchema, totalCount: documents.totalCount, setForceUpdateCounter: setForceUpdateCounter, forceUpdateCounter: forceUpdateCounter, isPP: hasRole }));
}
