import { Dialog as HeadlessDialog, Transition } from '@headlessui/react';
import CloseButton, { CloseButtonSize, CloseButtonType, } from '@components/close-button';
import React, { Fragment } from 'react';
export default function BaseModal({ open, setOpen, dismissible = true, title, content, Icon, actions, showCloseIcon = dismissible, testId, }) {
    return (React.createElement(Transition.Root, { show: open, as: Fragment, "data-testid": testId },
        React.createElement(HeadlessDialog, { as: "div", className: "relative z-50", onClose: () => dismissible && setOpen(false) },
            React.createElement(Transition.Child, { as: Fragment, enter: "ease-out duration-300", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "ease-in duration-200", leaveFrom: "opacity-100", leaveTo: "opacity-0" },
                React.createElement("div", { className: "fixed inset-0 bg-n100Whisper bg-opacity-75 transition-opacity" })),
            React.createElement("div", { className: "fixed inset-0 z-10 overflow-y-auto" },
                React.createElement("div", { className: "flex min-h-full justify-center p-4 text-center items-center sm:p-0" },
                    React.createElement(Transition.Child, { as: Fragment, enter: "ease-out duration-300", enterFrom: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95", enterTo: "opacity-100 translate-y-0 sm:scale-100", leave: "ease-in duration-200", leaveFrom: "opacity-100 translate-y-0 sm:scale-100", leaveTo: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" },
                        React.createElement(HeadlessDialog.Panel, { className: "relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6" },
                            React.createElement(HeadlessDialog.Title, { as: "h3", className: "mt-8 font-semibold text-[30px] leading-[38px] text-n600DarkBlue text-center" }, title),
                            showCloseIcon && (React.createElement("div", { className: "absolute top-4 right-4" },
                                React.createElement(CloseButton, { type: CloseButtonType.GREY, onClick: () => setOpen(false), size: CloseButtonSize.SMALL, tabIndex: 0 }))),
                            React.createElement("div", { className: "sm:flex sm:flex-col sm:items-center sm:space-y-4 mt-8" },
                                Icon && (React.createElement("div", { className: "mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-r100Red bg-opacity-20 sm:mx-0 sm:h-10 sm:w-10" },
                                    React.createElement(Icon, { className: "h-6 w-6 text-r100Red", "aria-hidden": "true" }))),
                                React.createElement("div", { className: "mt-3 sm:mt-0 text-left" }, content)),
                            actions)))))));
}
