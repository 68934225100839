import { ButtonSize, ButtonType } from '@components/button';
import { UserCompanyRoleEnum } from '@lohndialog/shared/src/constants';
import { useTranslation } from 'react-i18next';
import Dialog from '@components/modals/dialog';
import React from 'react';
export const ConfirmationDialog = ({ dialogOpen, setDialogOpen, submit, submitSuccessful, submitting, roles, submitError, successfulRedirect, }) => {
    const { t } = useTranslation('wizard', {
        keyPrefix: 'reviewStep',
    });
    return (React.createElement(Dialog, { dismissible: true, open: dialogOpen, setOpen: setDialogOpen, title: roles.includes(UserCompanyRoleEnum.ADMIN)
            ? t('dialog.titleHR')
            : t('dialog.title'), actions: submitSuccessful
            ? [
                {
                    buttonProps: {
                        label: t('dialog.buttonClose'),
                        buttonSize: ButtonSize.SMALL,
                        buttonType: ButtonType.PRIMARY,
                        onClick: successfulRedirect,
                        tabIndex: 0,
                        testId: 'closeBtn',
                    },
                },
            ]
            : [
                {
                    buttonProps: {
                        label: roles.includes(UserCompanyRoleEnum.ADMIN)
                            ? t('dialog.buttonActivate')
                            : t('dialog.buttonSubmit'),
                        buttonSize: ButtonSize.SMALL,
                        buttonType: ButtonType.PRIMARY,
                        onClick: () => {
                            void submit();
                        },
                        tabIndex: 0,
                        testId: 'submitBtn',
                        loading: submitting,
                    },
                },
                {
                    buttonProps: {
                        label: t('dialog.buttonCancel'),
                        buttonSize: ButtonSize.SMALL,
                        buttonType: ButtonType.SECONDARY,
                        onClick: () => {
                            setDialogOpen(false);
                        },
                        tabIndex: 1,
                        testId: 'cancelBtn',
                    },
                },
            ] },
        React.createElement("p", null, roles.includes(UserCompanyRoleEnum.ADMIN)
            ? t('dialog.descriptionHR')
            : t('dialog.descriptionEmployee')),
        submitSuccessful && (React.createElement("p", { className: "text-p200Green" }, t('submitSuccessful'))),
        submitError && (React.createElement("p", { className: "text-r600Error" }, t('submitError')))));
};
