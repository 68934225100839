import { DASHBOARD_PAGE_SIZE } from '@utils/constants';
import { EAUStateBadge } from '@components/badge';
import { GenericTable } from '@components/table/generic-table';
import { NavLink } from '@components/nav-link';
import { useNestClient } from '@hooks/useNestClient';
import { useTranslation } from 'react-i18next';
import React, { useMemo, useState } from 'react';
import dayjs from '@lohndialog/shared/src/util/dayjs';
export default function EAUDashboardTable() {
    const [sort, setSort] = useState();
    const { t } = useTranslation('dashboardEAU');
    const { result: ecodSchema } = useNestClient('getEAUSchema', []);
    const { result: eAUs, loading } = useNestClient('getPaginatedEAUEntries', [0, DASHBOARD_PAGE_SIZE, sort !== null && sort !== void 0 ? sort : undefined], { data: [], totalCount: 0 });
    const topHeader = (React.createElement("div", { className: "flex justify-between items-center" },
        React.createElement("p", { className: "font-bold" }, t('topHeader')),
        React.createElement(NavLink, { to: '/eAU' },
            React.createElement("p", { className: "text-sm font-medium text-p100Green" }, t('viewAll')))));
    const [selectedColumns, setSelectedColumns] = useState([
        'employeeName',
        'startDate',
        'endDateIncluding',
        'creationDate',
        'state',
    ]);
    const columnOverrides = useMemo(() => ({
        employeeName: {
            identifier: 'employeeName',
            sortable: true,
            headContent: t('employeeName'),
            CellContent: NameCell,
        },
        startDate: {
            identifier: 'startDate',
            sortable: true,
            headContent: t('startDate'),
            CellContent: StartDateCell,
        },
        endDateIncluding: {
            identifier: 'endDateIncluding',
            sortable: true,
            headContent: t('endDateIncluding'),
            CellContent: EndDateCell,
        },
        creationDate: {
            identifier: 'creationDate',
            sortable: true,
            headContent: t('creationDate'),
            CellContent: CreationDateCell,
        },
        state: {
            identifier: 'state',
            sortable: true,
            headContent: t('state'),
            CellContent: StateCell,
        },
    }), [t]);
    return (React.createElement(GenericTable, { topHeader: topHeader, sort: sort, setSort: setSort, loading: loading, schema: ecodSchema, selectedColumns: selectedColumns, setSelectedColumns: setSelectedColumns, getId: (eAU) => eAU.id.toString(), data: eAUs.data, columnOverrides: columnOverrides, totalResults: eAUs.data.length, className: "mt-8" }));
}
const NameCell = ({ rowData: eAU }) => {
    var _a, _b, _c, _d, _e, _f;
    return `${(_c = (_b = (_a = eAU.employee) === null || _a === void 0 ? void 0 : _a.genericFields.lastname) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : ''}, ${(_f = (_e = (_d = eAU.employee) === null || _d === void 0 ? void 0 : _d.genericFields.firstname) === null || _e === void 0 ? void 0 : _e.value) !== null && _f !== void 0 ? _f : ''}`;
};
const StartDateCell = ({ rowData: eAU }) => {
    const { t } = useTranslation('dashboardEAU');
    return dayjs(eAU.startDate).format(t('dateFormat'));
};
const EndDateCell = ({ rowData: eAU }) => {
    const { t } = useTranslation('dashboardEAU');
    return dayjs(eAU.endDateIncluding).format(t('dateFormat'));
};
const CreationDateCell = ({ rowData: eAU }) => {
    const { t } = useTranslation('dashboardEAU');
    return dayjs(eAU.creationDate).format(t('dateFormat'));
};
const StateCell = ({ rowData: eAU }) => (React.createElement(EAUStateBadge, { state: eAU.state }));
