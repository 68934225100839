import { Button, ButtonSize, ButtonType } from '@components/button';
import { FieldType, } from '@lohndialog/shared/src/dtos/entity-schema.dto';
import { RadioGroup } from '@components/radio-group';
import { TextInput } from '@components/input/text-input';
import { ValidationResult, } from '@lohndialog/shared/src/validation';
import { classNames } from '@utils/class-names';
import { localizeString } from '@utils/formatting';
import { usePromiseState } from '@hooks/usePromise';
import { useTranslation } from 'react-i18next';
import BaseModal from '@components/modals/base.modal';
import Combobox from '@components/input/combobox';
import React, { useCallback, useMemo, useState } from 'react';
import i18n from '../../i18n';
export default function InputModal({ modalTitle, explanation, configObject, submitFunction, cancelLabel, submitLabel, nextFunction, open, setOpen, nextLabel, groupValidator, }) {
    const [value, setValue] = useState({});
    const { t } = useTranslation(['inputModal', 'errorMessages']);
    const inputsAreValid = useMemo(() => {
        return Object.keys(configObject)
            .map((key) => {
            const validationFunction = configObject[key].validationFunction;
            const required = configObject[key].required;
            if (validationFunction) {
                return (validationFunction(value[key]) ===
                    ValidationResult.VALID);
            }
            else if (required) {
                return value[key] !== undefined && value[key] !== '';
            }
            else {
                return true;
            }
        })
            .reduce((valid, functionResult) => valid && functionResult, true);
    }, [value, configObject]);
    const groupIsValid = useMemo(() => {
        if (!groupValidator) {
            return true;
        }
        return Object.entries(groupValidator).every(([_name, configObject]) => {
            const valuesToCheck = configObject.keys.map((key) => value[key]);
            return (configObject.validationFunction(valuesToCheck) ===
                ValidationResult.VALID);
        });
    }, [groupValidator, value]);
    const [_submitResult, setSubmit, submitting, submitError, reset] = usePromiseState(null);
    const resetEverythingAndClose = useCallback(() => {
        setOpen(false);
        setValue({});
        reset();
    }, [setOpen, setValue, reset]);
    return (React.createElement(BaseModal, { open: open, setOpen: (open) => {
            setOpen(open);
            reset();
        }, title: modalTitle, showCloseIcon: false, testId: "input-modal", actions: React.createElement(React.Fragment, null,
            submitError && (React.createElement("p", { "data-testid": "input-modal-error", className: "text-r600Error" }, t(submitError.message, { ns: 'errorMessages' }))),
            React.createElement("div", { className: "mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 flex flex-col items-center space-y-2 sm:space-y-0", style: {
                    direction: nextFunction ? 'ltr' : 'rtl',
                } },
                React.createElement(Button, { buttonSize: ButtonSize.LARGE, buttonType: ButtonType.PRIMARY, onClick: () => {
                        void setSubmit(submitFunction(value)).then(() => resetEverythingAndClose());
                    }, disabled: !(inputsAreValid && groupIsValid), label: submitLabel !== null && submitLabel !== void 0 ? submitLabel : t('submit'), "data-testid": "input-modal-submit", className: "w-full", loading: submitting }),
                nextFunction && (React.createElement(Button, { buttonSize: ButtonSize.LARGE, buttonType: ButtonType.PRIMARY, onClick: () => {
                        void setSubmit(nextFunction(value)).then(() => setValue({}));
                    }, disabled: !(inputsAreValid && groupIsValid), label: nextLabel !== null && nextLabel !== void 0 ? nextLabel : t('next'), "data-testid": "input-modal-next", className: "w-full", loading: submitting })),
                React.createElement(Button, { buttonSize: ButtonSize.LARGE, buttonType: ButtonType.SECONDARY, onClick: () => resetEverythingAndClose(), label: cancelLabel !== null && cancelLabel !== void 0 ? cancelLabel : t('cancel'), "data-testid": "input-modal-cancel", className: "w-full" }))), content: React.createElement("div", { className: "w-full" },
            React.createElement("div", { className: "my-4 text-n500DarkGrey" }, explanation),
            Object.keys(configObject).map((key) => {
                var _a;
                const config = configObject[key];
                switch (config.fieldType) {
                    case FieldType.TEXT:
                        return (React.createElement(TextInput, { key: key, value: (_a = value[key]) !== null && _a !== void 0 ? _a : '', onChange: (input) => setValue(Object.assign(Object.assign({}, value), { [key]: input })), placeholder: config.placeHolder, required: config.required, validate: config.validationFunction, title: config.title, inputTestId: `input-modal-input-${key}`, disabled: false }));
                    case FieldType.CHECKBOX:
                        return (React.createElement(RadioGroup, { key: key, displayValue: (option) => option === 'yes'
                                ? t('yes')
                                : t('no'), options: ['yes', 'no'], onChange: (checked) => setValue(Object.assign(Object.assign({}, value), { [key]: checked })), validate: config.validationFunction, value: value[key], title: config.title, inputTestId: `input-modal-input-${key}` }));
                    case FieldType.COMBOBOX:
                        return (React.createElement(Combobox, { key: key, options: Object.keys(config.possibleValues), onChange: (newValue) => setValue(Object.assign(Object.assign({}, value), { [key]: newValue })), displayValue: (option) => localizeString(config.possibleValues[option], i18n.language, ''), value: value[key], title: config.title }));
                    default:
                        return React.createElement(React.Fragment, null);
                }
            }),
            groupValidator && (React.createElement("span", { className: classNames('block text-n600DarkBlue text-sm mt-1 opacity-0 transition-opacity relative w-full min-h-[40px]', !groupIsValid &&
                    'font-bold !text-r100Red opacity-100') },
                "\u200B",
                React.createElement("span", { className: "absolute max-w-full" }, t('groupError'))))) }));
}
