import { Button, ButtonSize, ButtonType } from '@components/button';
import { GenericTable } from '@components/table/generic-table';
import { ReactComponent as DownloadIcon } from '@public/icons/download.svg';
import { LoadingSpinner } from '@components/loading-spinner';
import { ReactComponent as TrashIcon } from '@public/icons/trash-2.svg';
import { useTranslation } from 'react-i18next';
import FileSaver from 'file-saver';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from '@lohndialog/shared/src/util/dayjs';
import nestClient from '@api/nest-client';
export default function DocumentTable({ documents, documentSchema, currentPage, numberOfAvailablePages, setCurrentPage, sort, setSort, loading, totalCount, setForceUpdateCounter, forceUpdateCounter, isPP, }) {
    const { t } = useTranslation('documentList');
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([
        'name',
        'type',
        'uploadDate',
        'category',
        'employeeName',
        'download',
    ]);
    useEffect(() => {
        const index = selectedColumns.indexOf('delete');
        if (isPP && index === -1) {
            setSelectedColumns([...selectedColumns, 'delete']);
        }
        else if (index !== -1) {
            selectedColumns.splice(index, 1);
            setSelectedColumns([...selectedColumns]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPP]);
    const triggerReload = useCallback(() => {
        setForceUpdateCounter(++forceUpdateCounter);
    }, [forceUpdateCounter, setForceUpdateCounter]);
    const bulkDeleteDocuments = useCallback(async () => {
        try {
            await nestClient.bulkDeleteDocument(selectedItems.map((item) => item.id));
            setSelectedItems([]);
        }
        finally {
            triggerReload();
        }
    }, [selectedItems, triggerReload]);
    const columnOverrides = useMemo(() => {
        const DeleteCell = ({ rowData: document, }) => {
            const [deleting, setDeleting] = useState(false);
            const deleteFile = useCallback(async (documentId) => {
                setDeleting(true);
                try {
                    await nestClient.deleteDocument(documentId);
                }
                finally {
                    setDeleting(false);
                    triggerReload();
                }
            }, []);
            if (deleting) {
                return (React.createElement(LoadingSpinner, { className: "w-[28px] h-[28px] text-p100Green ml-0" }));
            }
            return (React.createElement("button", { className: "bg-p50LightGreen w-[28px] h-[28px] rounded-full text-p100Green flex justify-center items-center", onClick: () => void deleteFile(document.id), "data-testid": `document-delete-button-${document.id}` },
                React.createElement(TrashIcon, { className: "w-4 h-4" })));
        };
        const EmployeeNameCell = ({ rowData: document, }) => {
            var _a, _b, _c;
            if (document.assignedEmployee) {
                return `${((_a = document.assignedEmployee.genericFields.lastname) === null || _a === void 0 ? void 0 : _a.value)
                    ? document.assignedEmployee.genericFields.lastname
                        .value + ', '
                    : ''}${(_c = (_b = document.assignedEmployee.genericFields.firstname) === null || _b === void 0 ? void 0 : _b.value) !== null && _c !== void 0 ? _c : ''}`;
            }
            return t('noEmployee');
        };
        const columns = {
            name: {
                identifier: 'name',
                sortable: true,
                headContent: t('name'),
                CellContent: NameCell,
            },
            type: {
                identifier: 'type',
                sortable: true,
                headContent: t('type'),
                CellContent: TypeCell,
            },
            uploadDate: {
                identifier: 'uploadDate',
                sortable: true,
                headContent: t('uploadDate'),
                CellContent: UploadDateCell,
            },
            category: {
                identifier: 'category',
                sortable: true,
                headContent: t('category'),
                CellContent: CategoryCell,
            },
            employeeName: {
                identifier: 'employeeName',
                sortable: true,
                headContent: t('employeeName'),
                CellContent: EmployeeNameCell,
            },
            download: {
                identifier: 'download',
                sortable: false,
                headContent: React.createElement("span", { className: "sr-only" }, t('download')),
                CellContent: DownloadCell,
            },
            delete: {
                identifier: 'delete',
                sortable: false,
                headContent: React.createElement("span", { className: "sr-only" }, t('delete')),
                CellContent: DeleteCell,
            },
        };
        return columns;
    }, [t, triggerReload]);
    return (React.createElement(GenericTable, { currentPage: currentPage, numberOfAvailablePages: numberOfAvailablePages, setCurrentPage: setCurrentPage, sort: sort, setSort: setSort, loading: loading, getId: (document) => document.id.toString(), data: documents, columnOverrides: columnOverrides, selectedColumns: selectedColumns, setSelectedColumns: setSelectedColumns, schema: documentSchema, totalResults: totalCount, selectedItems: selectedItems, setSelectedItems: setSelectedItems, toggleAll: () => setSelectedItems((prevState) => {
            const entries = prevState.map((entry) => entry.id);
            const currentNonSelectedItems = documents.filter((document) => !entries.includes(document.id));
            if (currentNonSelectedItems.length === 0) {
                return [];
            }
            else {
                return [...prevState, ...currentNonSelectedItems];
            }
        }), selectedActions: isPP ? (React.createElement(Button, { buttonType: ButtonType.DESTRUCTIVE, buttonSize: ButtonSize.SMALL, label: t('delete'), iconClassName: "w-4 h-4", IconLeading: TrashIcon, onClick: () => {
                void bulkDeleteDocuments();
            }, testId: "bulk-delete-button" })) : undefined, className: "rounded-t-none", altLayout: true }));
}
const NameCell = ({ rowData: document }) => document.name;
const TypeCell = ({ rowData: document }) => document.type;
const CategoryCell = ({ rowData: document }) => document.category;
const UploadDateCell = ({ rowData: document }) => {
    const { t } = useTranslation('dashboardDocument');
    return dayjs(document.uploadDate).format(t('dateFormat'));
};
export const DownloadCell = ({ rowData: document, }) => {
    const [downloading, setDownloading] = useState(false);
    const downloadFileSingleFile = useCallback(async (documentId, documentName) => {
        setDownloading(true);
        try {
            const file = await nestClient.downloadDocuments([documentId], documentName, false);
            FileSaver.saveAs(file);
        }
        finally {
            setDownloading(false);
        }
    }, []);
    if (downloading) {
        return (React.createElement(LoadingSpinner, { className: "w-[28px] h-[28px] text-p100Green ml-0" }));
    }
    return (React.createElement("button", { className: "bg-p50LightGreen w-[28px] h-[28px] rounded-full text-p100Green flex justify-center items-center", onClick: () => void downloadFileSingleFile(document.id, `${document.fileName}.${document.type.toLowerCase()}`) },
        React.createElement(DownloadIcon, { className: "w-4 h-4" })));
};
