import { ReactComponent as AlertCircleIcon } from '@public/icons/alert-circle.svg';
import { NotificationType } from '@components/notifications/notification';
import { localizeString } from '@utils/formatting';
import i18n from '../i18n';
export const addSpecificNotificationsFunction = (validationErrors, companySchema, notificationState, t) => {
    const notifications = validationErrors.map((entry) => {
        const title = localizeString(companySchema.fields[entry.property].label, i18n.language);
        return {
            notificationType: NotificationType.ERROR,
            title: t('formattedError', {
                property: title,
                code: entry.result,
            }),
            Icon: AlertCircleIcon,
        };
    });
    notificationState === null || notificationState === void 0 ? void 0 : notificationState.addNotifications(notifications);
};
export const addGenericNotificationFunction = (notificationState, t) => {
    notificationState === null || notificationState === void 0 ? void 0 : notificationState.addNotification({
        notificationType: NotificationType.ERROR,
        title: t('generalErrorMessage'),
        Icon: AlertCircleIcon,
    });
};
