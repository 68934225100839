import { EmployeeState } from '@lohndialog/shared/src/constants';
import { EmployeeWizard } from '@components/employee-wizard/employee-wizard';
import { getGenericFieldsWithDefaultValues } from '@utils/getGenericFieldsWithDefaultValues';
import { useNavigate } from '@hooks/useNavigate';
import { useNestClient } from '@hooks/useNestClient';
import React, { useMemo } from 'react';
import nestClient from '@api/nest-client';
export default function AddEmployeeView() {
    const navigate = useNavigate();
    const { result: schema } = useNestClient('getEmployeeSchema', [false]);
    const initialGenericFields = useMemo(() => (schema ? getGenericFieldsWithDefaultValues(schema) : {}), [schema]);
    return (React.createElement(EmployeeWizard, { initialEmployee: {
            id: '-1',
            creationDate: new Date().toISOString(),
            state: EmployeeState.DRAFT,
            user: undefined,
            genericFields: initialGenericFields,
            hasConfirmedData: false,
        }, onClose: () => navigate({ pathname: '/employees' }), onFinish: async (employee) => nestClient.createEmployee(employee).then(() => {
            navigate({ pathname: '/employees' });
        }), refreshEmployee: () => {
            // Do nothing
        } }));
}
