var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { classNames } from '@utils/class-names';
import React, { useCallback, useEffect, useRef } from 'react';
export const Checkbox = React.forwardRef((_a, forwardedRef) => {
    var { indeterminate } = _a, props = __rest(_a, ["indeterminate"]);
    const ref = useRef();
    const updateIndeterminate = useCallback(() => {
        if (ref.current) {
            ref.current.indeterminate = indeterminate !== null && indeterminate !== void 0 ? indeterminate : false;
        }
    }, [indeterminate]);
    useEffect(updateIndeterminate, [updateIndeterminate]);
    return (React.createElement("input", Object.assign({}, props, { ref: (instance) => {
            ref.current = instance;
            if (typeof forwardedRef === 'function') {
                forwardedRef(instance);
            }
            else if (forwardedRef !== null) {
                forwardedRef.current = instance;
            }
            updateIndeterminate();
        }, className: classNames('h-4 w-4 rounded !border-n400GreyCheteau text-p200Green focus:ring-p200Green', props.checked ? '!border-p200Green' : '', props.className), type: "checkbox" })));
});
