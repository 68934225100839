import { Button, ButtonSize, ButtonType } from '@components/button';
import { ReactComponent as EditIcon } from '@public/icons/edit.svg';
import { ReactComponent as ToggleIcon } from '@public/icons/toggle-left.svg';
// eslint-disable-next-line no-restricted-imports
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';
export default function CompanyDashboardHeader({ company, isAdmin, }) {
    const { t } = useTranslation('editCompanyView');
    const navigate = useNavigate();
    return (React.createElement("div", { className: "xl:flex justify-between" },
        React.createElement("h1", null, t('editCompany')),
        React.createElement("div", { className: "flex mt-4 md:mt-0 gap-3" },
            React.createElement(Button, { label: t('changeStatus'), buttonType: ButtonType.PRIMARY, buttonSize: ButtonSize.LARGE, disabled: true, IconLeading: ToggleIcon }),
            React.createElement(Button, { label: t('edit'), buttonType: ButtonType.PRIMARY, buttonSize: ButtonSize.LARGE, onClick: () => {
                    navigate({
                        pathname: isAdmin
                            ? `/admin/companySettings/${company.id}`
                            : `/${company.slug}/edit`,
                    });
                }, IconLeading: EditIcon, testId: "edit-company-button" }))));
}
