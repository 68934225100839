import { CompanyDashboardWizard } from '@components/company-dashboard-wizard/company-dashboard-wizard';
import { MAX_NUMBER_OF_INDIVIDUAL_NOTIFICATIONS } from '@lohndialog/shared/src/validation';
import { NotificationContext } from '../../context/notification-context';
import { Tabs } from '@components/tabs';
import { addGenericNotificationFunction, addSpecificNotificationsFunction, } from '@utils/addNotificationUtils';
// eslint-disable-next-line no-restricted-imports
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PlaceholderView from '@views/placeholder-view';
import React, { useCallback, useContext, useMemo } from 'react';
import nestClient from '@api/nest-client';
export default function CompanyDashboardEditTable({ company, forceUpdate, isAdmin, hasAdminRights, }) {
    const navigate = useNavigate();
    const notificationState = useContext(NotificationContext);
    const { t: tError } = useTranslation('errorHandling');
    const { t } = useTranslation('editCompanyView');
    const addSpecificNotifications = useCallback(addSpecificNotificationsFunction, [tError, notificationState]);
    const redirectUrl = useMemo(() => {
        return isAdmin ? `/admin/dashboard` : `/${company.slug}/employees`;
    }, [company.slug, isAdmin]);
    const addGenericNotification = useCallback(addGenericNotificationFunction, [
        tError,
        notificationState,
    ]);
    return (React.createElement("div", { className: "pb-32" },
        React.createElement(Tabs, { withoutMargin: true, tabs: [
                {
                    id: 1,
                    title: t('baseData'),
                    content: React.createElement(PlaceholderView, { title: "" }),
                    disabled: true,
                },
                {
                    id: 2,
                    title: t('payrollData'),
                    content: (React.createElement(CompanyDashboardWizard, { initialCompany: company, onClose: () => navigate({ pathname: redirectUrl }), onFinish: async (company, companySchema) => {
                            if (company.id) {
                                nestClient.setErrorHandler((apiError) => {
                                    const validationErrors = apiError.errorData
                                        .validationErrors;
                                    if (validationErrors.length <
                                        MAX_NUMBER_OF_INDIVIDUAL_NOTIFICATIONS) {
                                        addSpecificNotifications(validationErrors, companySchema, notificationState, tError);
                                    }
                                    else {
                                        addGenericNotification(notificationState, tError);
                                    }
                                });
                                await nestClient.updateCompany(company);
                                forceUpdate();
                            }
                        }, hasAdminRights: hasAdminRights })),
                    default: true,
                },
                {
                    id: 3,
                    title: t('costGroups'),
                    content: React.createElement(PlaceholderView, { title: "" }),
                    disabled: true,
                },
                {
                    id: 4,
                    title: t('payrollGroups'),
                    content: React.createElement(PlaceholderView, { title: "" }),
                    disabled: true,
                },
                {
                    id: 5,
                    title: t('salaryTypes'),
                    content: React.createElement(PlaceholderView, { title: "" }),
                    disabled: true,
                },
                {
                    id: 6,
                    title: t('documentTypes'),
                    content: React.createElement(PlaceholderView, { title: "" }),
                    disabled: true,
                },
                {
                    id: 7,
                    title: t('placesOfBusiness'),
                    content: React.createElement(PlaceholderView, { title: "" }),
                    disabled: true,
                },
            ], className: "overflow-unset h-fit" })));
}
