import { Button, ButtonSize, ButtonType } from '@components/button';
import { DashboardWizard } from '@components/wizard/dashboard-wizard';
import { LoadingSpinner } from '@components/loading-spinner';
import { useNestClient } from '@hooks/useNestClient';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo, useState } from 'react';
import Step1 from '@components/company-dashboard-wizard/step1';
export function CompanyDashboardWizard({ initialCompany, onClose, onFinish, hasAdminRights, }) {
    const [company, setCompany] = useState(initialCompany);
    const [saving, setSaving] = useState(false);
    const { t } = useTranslation('companyWizard');
    const { result: companySchema, loading, error, } = useNestClient('getCompanySchema', [true, true], { fields: [] }); // is always admin
    const { result: payrollProviderFields, loading: payrollProviderFieldsLoading, error: payrollProviderFieldsError, } = useNestClient('getPayrollProviderFields', [company.id], {
        fields: [],
    });
    const finish = useCallback(async () => {
        setSaving(true);
        try {
            await onFinish(company, companySchema);
        }
        finally {
            setSaving(false);
        }
    }, [company, companySchema, onFinish]);
    const reset = useCallback(() => {
        setCompany(initialCompany);
    }, [initialCompany]);
    const updateCompany = useCallback((fieldIdentifier, type, newValue) => {
        if (newValue === undefined || newValue === '') {
            const rest = Object.assign({}, company.genericFields);
            delete rest[fieldIdentifier];
            setCompany((value) => (Object.assign(Object.assign({}, value), { genericFields: rest })));
        }
        else {
            setCompany((value) => (Object.assign(Object.assign({}, value), { genericFields: Object.assign(Object.assign({}, value.genericFields), { [fieldIdentifier]: {
                        value: newValue,
                        type,
                    } }) })));
        }
    }, [company.genericFields]);
    const setMainHrContactPerson = useCallback((newValue) => {
        setCompany((value) => (Object.assign(Object.assign({}, value), { mainHRContactPerson: newValue })));
    }, []);
    const setMainPayrollProviderContactPerson = useCallback((newValue) => {
        setCompany((value) => (Object.assign(Object.assign({}, value), { mainPayrollProviderContactPerson: newValue })));
    }, []);
    const steps = useMemo(() => {
        var _a, _b;
        return [
            Step1(companySchema, setMainHrContactPerson, setMainPayrollProviderContactPerson, (_a = company.possibleContactPersons) !== null && _a !== void 0 ? _a : [], (_b = company.possiblePayrollProviderContactPersons) !== null && _b !== void 0 ? _b : [], hasAdminRights, payrollProviderFields, company.mainHRContactPerson, company.mainPayrollProviderContactPerson),
        ];
    }, [
        company.mainHRContactPerson,
        company.mainPayrollProviderContactPerson,
        company.possibleContactPersons,
        company.possiblePayrollProviderContactPersons,
        companySchema,
        hasAdminRights,
        payrollProviderFields,
        setMainHrContactPerson,
        setMainPayrollProviderContactPerson,
    ]);
    if (loading || payrollProviderFieldsLoading) {
        return (React.createElement("div", { className: "w-full h-full flex justify-center items-center" },
            React.createElement(LoadingSpinner, null)));
    }
    if (error || payrollProviderFieldsError) {
        // let the router handle this error
        throw error;
    }
    return (React.createElement(DashboardWizard, { setValue: updateCompany, value: company, onClose: onClose, showCloseButton: true, steps: steps, onFinish: finish, actionButtons: React.createElement(React.Fragment, null,
            React.createElement(Button, { label: t('cancel'), buttonType: ButtonType.PRIMARY, buttonSize: ButtonSize.SMALL, className: "w-full !bg-white !text-p200Green", onClick: reset, loading: saving }),
            React.createElement(Button, { label: t('save'), buttonType: ButtonType.PRIMARY, buttonSize: ButtonSize.SMALL, className: "w-full !bg-white !text-p200Green", onClick: () => void finish(), loading: saving })) }));
}
