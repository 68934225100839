import { LoadingSpinner } from '@components/loading-spinner';
import { classNames } from '@utils/class-names';
import React from 'react';
export const DashboardCard = ({ className, Icon, title, children, loading = false, }) => {
    return (React.createElement("div", { className: classNames(className, 'drop-shadow-table bg-white rounded-lg p-4 h-40 flex flex-col') },
        React.createElement(Icon, { className: "h-10 w-10 text-n500DarkGrey" }),
        React.createElement("div", { className: "flex-grow-[2]" }),
        React.createElement("p", { className: "text-p100Green text-lg font-bold leading-5" }, title),
        React.createElement("div", { className: "flex-grow-[1]" }),
        loading ? (React.createElement(LoadingSpinner, { className: "h-5 w-5" })) : (React.createElement("div", { className: "text-3xl font-bold min-h-[2.5rem]" }, children))));
};
