import { AuthenticatedInvitationView } from '@components/invitation/authenticated-invitation-view';
import { UnauthenticatedInvitationView } from '@components/invitation/unauthenticated-invitation-view';
import { useAuth } from '../../context/auth-context';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';
export default function InvitationView({ isPayrollProviderToken, }) {
    const [searchParams, _setSearchParams] = useSearchParams();
    const { t } = useTranslation('invitation');
    const { isAuthenticated } = useAuth();
    const tokenId = searchParams.get('token');
    if (!tokenId) {
        return (React.createElement("div", { className: "flex flex-col items-center justify-center h-full" },
            React.createElement("div", { className: "text-xl mb-5" }, t('noToken'))));
    }
    return (React.createElement("div", { className: "flex flex-col items-center justify-center h-full" },
        React.createElement("div", { className: "text-xl font-bold mb-5" }, "Lohndialog"),
        isAuthenticated ? (React.createElement(AuthenticatedInvitationView, { tokenId: tokenId, isPayrollProviderToken: isPayrollProviderToken })) : (React.createElement(UnauthenticatedInvitationView, null))));
}
