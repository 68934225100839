import { ReactComponent as CalendarIcon } from '@public/icons/calendar.svg';
import { DASHBOARD_PAGE_SIZE } from '@utils/constants';
import { DashboardCard } from '@components/dashboard/dashboard-card';
import { EmployeeState } from '@lohndialog/shared/src/constants';
import { EmployeeStateBadge, PendingBadge } from '@components/badge';
import { ReactComponent as UserPlusIcon } from '@public/icons/user-plus.svg';
import { ReactComponent as UsersIcon } from '@public/icons/users.svg';
import { useNestClient } from '@hooks/useNestClient';
import { useTranslation } from 'react-i18next';
import DocumentDashboardTable from '@components/dashboard/document-dashboard-table';
import EAUDashboardTable from '@components/dashboard/eAU-dashboard-table';
import React from 'react';
export default function HrDashboard() {
    const { t } = useTranslation('dashboard');
    const { result: currentAccountingPeriod, loading: loadingCurrentAccountingPeriod, } = useNestClient('getCardCurrentAccountingPeriod', []);
    const { result: numberOfEmployees, loading: loadingNumberOfEmployees } = useNestClient('getCardNumberOfEmployees', []);
    const { result: newEmployeesResult, loading: loadingNewEmployeesResult } = useNestClient('getCardNewEmployees', []);
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null, t('title')),
        React.createElement("p", { className: "font-medium text-n500DarkGrey" }, t('subtitle')),
        React.createElement("div", { className: "grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4 mt-4" },
            React.createElement(DashboardCard, { className: "col-span-2", title: t('currentAccountingPeriod'), Icon: CalendarIcon, loading: loadingCurrentAccountingPeriod }, currentAccountingPeriod),
            React.createElement(DashboardCard, { className: "col-span-2", title: t('numberOfEmployees'), Icon: UsersIcon, loading: loadingNumberOfEmployees }, numberOfEmployees),
            React.createElement(DashboardCard, { className: "col-span-2", title: t('newEmployees'), Icon: UserPlusIcon, loading: loadingNewEmployeesResult }, newEmployeesResult && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "text-lg flex flex-wrap gap-x-6 mt-2 md:mt-0 xl:mt-2" },
                    React.createElement("div", { className: "flex items-center" },
                        React.createElement(EmployeeStateBadge, { state: EmployeeState.DRAFT }),
                        React.createElement("span", { className: "ml-1.5" }, newEmployeesResult[EmployeeState.DRAFT])),
                    React.createElement("div", { className: "flex items-center" },
                        React.createElement(PendingBadge, null),
                        React.createElement("span", { className: "ml-1.5" }, newEmployeesResult[EmployeeState.PENDING_APPROVAL])),
                    React.createElement("div", { className: "flex items-center" },
                        React.createElement(EmployeeStateBadge, { state: EmployeeState.ACTIVE }),
                        React.createElement("span", { className: "ml-1.5" }, newEmployeesResult[EmployeeState.ACTIVE]))))))),
        React.createElement(EAUDashboardTable, null),
        React.createElement(DocumentDashboardTable, { pageSize: DASHBOARD_PAGE_SIZE, navLink: 'documents', showHeader: true, onlyShowPersonalDocuments: false })));
}
