var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button, ButtonSize, ButtonType } from '@components/button';
import { Environment } from '../environment.global';
import { ReactComponent as LogOutIcon } from '@public/icons/log-out.svg';
import { useAuth } from '../context/auth-context';
import { useTranslation } from 'react-i18next';
import Dialog from '@components/modals/dialog';
import React, { useState } from 'react';
export const LogoutButton = (_a) => {
    var { onlyIcon } = _a, rest = __rest(_a, ["onlyIcon"]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const { logout } = useAuth();
    const { t } = useTranslation('logout');
    return (React.createElement(React.Fragment, null,
        onlyIcon ? (React.createElement("button", { onClick: () => setDialogOpen(true), className: rest.className },
            React.createElement(LogOutIcon, null))) : (React.createElement(Button, { className: "menuitem !mt-6 pointer-events-auto group", label: React.createElement("span", { className: "hidden group-hover:block" }, t('logoutButton')), onClick: () => setDialogOpen(true), buttonType: ButtonType.SECONDARY, buttonSize: ButtonSize.SMALL, IconLeading: LogOutIcon })),
        React.createElement(Dialog, { dismissible: true, open: dialogOpen, setOpen: setDialogOpen, title: t('dialogTitle'), actions: [
                {
                    buttonProps: {
                        label: t('logoutButton'),
                        buttonSize: ButtonSize.SMALL,
                        buttonType: ButtonType.SECONDARY,
                        onClick: () => {
                            logout({
                                logoutParams: {
                                    returnTo: `${Environment.PUBLIC_URL}/`,
                                },
                            });
                        },
                        tabIndex: 1,
                        testId: 'logoutBtn',
                    },
                },
                {
                    buttonProps: {
                        label: t('cancel'),
                        buttonSize: ButtonSize.SMALL,
                        buttonType: ButtonType.PRIMARY,
                        onClick: () => {
                            setDialogOpen(false);
                        },
                        tabIndex: 2,
                        testId: 'discardBtn',
                    },
                },
            ] })));
};
